export default{

    //Fehlermeldung
    error1: 'Systemfehler, bitte wenden Sie sich an den Kundendienst',
    error2: 'Login abgelaufen, bitte loggen Sie sich erneut ein!',
    
    
    meun1: 'Zuhause',
    meun2: 'Markttrend',
    meun3: 'BNBN-Abonnement',
    meun4: 'Mining-Maschine kaufen',
    meun5: 'schnelle Beförderung',
    meun6: 'Whitepaper',
    meun7: 'Ankündigung',
    meun8: 'Anmelden',
    meun9: 'Registrieren',
    meun10: 'Geldbörse',
    meun10_1: 'Spot-Konto',
    meun10_2: 'U-basiertes Vertragskonto',
    meun10_3: 'Coin-margined Vertragskonto',
    meun10_4: 'Zweitbasierter Vertrag',
    meun10_5: 'Finanzkonto',
    meun10_6: 'Wallet-Verlauf',
    meun11: 'Bestellen',
    meun11_1: 'Abonnementbestellung',
    meun11_2: 'Bergbauauftrag',
    meun12: 'Kundenservice',
    meun12_1: 'Kundendienstinformationen',
    meun12_2: 'Kundendienstnummer',
    meun12_3: 'Kundendienst-E-Mail',
    meun13: 'roter Umschlag',
    meun13_2:'Roten Umschlag erhalten',
    meun13_3:'Bitte geben Sie den Abholcode für den roten Umschlag ein',
    meun13_4:'Abbrechen',
    meun13_6:'Empfangen',
    meun13_7:'Stornierung erfolgreich',
    meun13_1: 'Kontoverwaltung',
    meun13_5: 'Abmelden',
    meun14_1: 'Sprache',
    meun14_3: 'Sprache auswählen',
    
    scan: 'iOS- und Android-Scancode zum Herunterladen',
    footmeun1: 'Über',
    footmeun1_1: 'Über uns',
    footmeun1_2: 'Mach mit',
    footmeun1_3: 'Plattform-Ankündigung',
    footmeun1_4: 'Weißbuch',
    footmeun2: 'Hilfecenter',
    footmeun2_1: 'Anfängerleitfaden',
    footmeun2_2: 'FAQ',
    footmeun2_3: 'Trading Guide',
    footmeun2_4: 'Währungsinformationen',
    footmeun3: 'Nutzungsbedingungen',
    footmeun3_1: 'Haftungsausschluss',
    footmeun3_2: 'Datenschutzerklärung',
    footmeun3_3: 'Benutzervereinbarung',
    footmeun3_4: 'Beschreibung der Gebühr',
    footmeun4: 'Kontaktieren Sie uns',
    footmeun4_1: 'Kundendienst-E-Mail',
    footmeun4_2: 'Geschäftskooperation',
    footmeun4_3: 'Antrag auf Währungsnotierung',
    footmeun4_4: 'Beschwerdebericht',
    Fußtext: 'BNBN Global Subscription Platform',
    Countdown:'Aktivität ist beendet',
    indexTxt1: 'BNBN globales Erstabonnement',
    indexTxt2: 'BNBN globales Erstabonnement',
    indexTxt3: 'Countdown bis online',
    indexLabel1: 'Markttrend',
    indexVal1: 'Kryptowährungs-Echtzeitpreis',
    indexLabel2: 'BNBN-Abonnement',
    indexVal2: 'Direkt mit einem digitalen Asset austauschen',
    indexLabel3: 'Mining-Maschine kaufen',
    indexVal3: 'BNBN-Kryptowährungs-Mining',
    indexLabel4: 'Schnelle Werbung',
    indexVal4: 'Abonnement für Benutzervorteile',
    
    indexh5: 'Sicherheit auf finanzieller Ebene',
    indexh6: 'Extrem schnelle Ein- und Auszahlungen',
    indexh7: 'Globale Dienste',
    indexh8: 'Streng ausgewählte Projekte',
    indexp1: 'BITISAN wurde von einer Gruppe früher Bitcoin-Teilnehmer und Geeks gegründet. Die Kernmitglieder des Teams kommen von bekannten Unternehmen wie Google, Microsoft, Alibaba und Tencent. Sie verfügen über umfassende Forschungs- und Entwicklungskompetenzen und umfangreiche Erfahrung im Betrieb des Internets Produkte. ',
    indexp2: 'BITISAN ist als grundlegender Blockchain-Dienstleister positioniert und verpflichtet sich, qualitativ hochwertige Krypto-Asset-Handelsplattformen für globale Benutzer bereitzustellen. Es hält sich an das Grundprinzip "Tu nichts Böses" und besteht darauf, Kunden mit Ehrlichkeit zu bedienen. Fairness und Begeisterung sowie eine offene Einstellung heißen alle Partner/Projekte willkommen, die den grundlegenden Interessen der Nutzer dienen. ',
    indexp3: 'Umfassendes Finanzrisikokontrollsystem und Anti-Diebstahl-System, Hot- und Cold-Wallets und Multi-Signatur-System gewährleisten die Sicherheit des Fonds',
    indexp4: 'Ein- und Auszahlungen können in kürzester Zeit in 3 Minuten abgeschlossen werden, mit manueller Online-Überprüfung rund um die Uhr, um Kunden davor zu schützen, die besten Investitionsmöglichkeiten zu verpassen',
    indexp5: 'Globale Netzwerkabdeckung für Unternehmensdienste, die Ihnen hilft, in globale Krypto-Assets zu investieren und mit globalen Benutzern zu handeln',
    indexp6: 'Wählen Sie strikt qualitativ hochwertige Verschlüsselungsprojekte aus und filtern Sie 80 % der Projekte mit extrem hohem Risiko für Sie',
    Markt: 'Marktinformationen',
    //Neue Währungsabonnement-Wallet

    wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',

    usdt:'USDT',
    Plattform:'BNBN',
    usdt1: 'Verfügbares Guthaben',
    usdt2: 'Guthaben sperren',
    platform1: 'Zurückziehen',
    Plattform2: 'Aufladen',
    platform3: 'Abonnement',
    Transaktion: 'Transaktionsdetails',
    transaktion0: 'Seriennummer',
    transaktion1: 'Transaktionsnummer',
    transaktion2: 'Transaktionsmenge',
    transaktion3: 'Transaktionszeit',
    transaktion4: 'USDT-Aufladung',
    transaktion5: 'Aufladeadresse',
    transaktion6: 'USDT-Auszahlung',
    transaktion7: 'Für eine Auszahlung ist eine bestimmte Bearbeitungsgebühr erforderlich',
    transaktion8: 'Abhebungsadresse',
    transaktion9: 'Auszahlungsbetrag',
    transaktion10: 'Abbrechen',
    transaktion11: 'OK',
    transaktion12: 'Abonnement',
    transaktion13: 'Der Mengenwert des Abonnements bezieht sich auf den abonnierten USDT-Betrag',
    transaktion14: 'Abonnementmenge',
    transaktion15: 'Abonnementmenge muss größer als 0 sein',
    transaktion16: 'Auszahlungsbetrag muss größer als 0 sein',
    transaktion17: 'Währungstyp',
    transaktion18: 'Noch keine Transaktionsdetails',
    transaktion19: 'Bitte wählen Sie eine Aufladeadresse aus',
    transaktion20: 'Bitte klicken Sie nach 10 Minuten erneut auf die Schaltfläche',
    transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    //Lock-up-Mining
    mining1: 'Abonnement',
    mining2: 'Kaufpreis',
    mining3: 'Täglicher Gewinn',
    mining4: 'Sperrtage',
    mining5: 'Kauflevel',
    mining6: 'Gesamtkauferlös',
    mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
    //einladen
    Invitationusers1:'Einladungslink',
    Invitationusers2:'Anzahl der Eingeladenen',
    Invitationusers3:'Einladungsnummer',
    Invitationusers4:'Benutzer einladen',
    inviteusers5:'Einladungszeit',
    Invitationusers6: 'Kopieren erfolgreich',
    inviteusers7:'Es sind noch keine Benutzer eingeladen',
    einladenusers8:'kopieren',
    inviteusers10:'Number of subscribers', //Number of subscribers
    inviteusers11:'Is it active',
    // Befehl
    order1: 'Abonnementbestellung',
    order2: 'Finanzverwaltungsauftrag',
    order3: 'Abonnementnummer',
    order4: 'Abonnementmenge',
    order5: 'Abonnementzeit',
    order7: 'Finanzverwaltungsnummer',
    order8: 'Gesperrte Menge',
    order9: 'Belohnungsmenge',
    order10: 'Startzeit',
    order11: 'Endzeit',
    //Persönliches Zentrum
    personal1: 'Persönliche Informationen',
    personal2: 'Authentifizierungsstatus mit echtem Namen',
    personal3: 'Authentifiziert',
    personal4: 'Authentifizierung',
    personal5: 'Klicken, um ohne Authentifizierung zu authentifizieren',
    personal6: 'Klicken Sie zur Authentifizierung, wenn die Authentifizierung fehlschlägt',
    personal7: 'Benutzername',
    personal8: 'E-Mail',
    personal9: 'Mobiltelefonnummer',
    personal10: 'VIP-Level',
    personal11: 'Ordentliches Mitglied',
    personal12: 'Einladungscode',
    personal13:'Authentifizierungsinformationen',
    personal14:'Bitte laden Sie Informationen zur Identitätsauthentifizierung hoch',
    personal15:'Passwort ändern',
    personal16:'ursprüngliches Passwort',
    personal17:'Passwort ändern',
    personal18:'Passwort bestätigen',
    personal19:'Bitte Passwort eingeben',
    personal20:'Die Länge des Passworts darf nicht weniger als 6 Zeichen betragen',
    personal21:'Bitte geben Sie Ihr Passwort erneut ein',
    personal22:'Die doppelt eingegebenen Passwörter sind inkonsistent',
    Authentication1:'Authentifizierungsinformationen hinzufügen',
    Authentifizierung2:'Upload bestätigen',
    Authentifizierung3:'Es können nur JPG/PNG-Dateien hochgeladen werden, und zwar nicht mehr als 2 MB',
    Authentifizierung4:'Datei hochladen',
    Authentication5:'Benutzer hinzugefügt und Datei erfolgreich hochgeladen',
    Authentication6:'Hinzufügen des Benutzers und Hochladen der Datei fehlgeschlagen',
    Authentication7:'Hochgeladene Avatar-Bilder dürfen nur im JPG-Format vorliegen!',
    Authentication8:'Die Größe des hochgeladenen Avatar-Bildes darf 2 MB nicht überschreiten!',
    Authentication9: 'Mehr als 5 Dateien',
    // Anmeldung
    account0:'Konto ',
    Konto: 'BNBNCOIN-Kontoanmeldung',
    phone: 'Handynummer',
    Email: 'Email',
    Zeichen: 'Anmelden',
    Passwort: 'Passwort',
    Passwort2: 'Passwort',
    passwort3: 'Passwort vergessen ',
    account2: 'Konto erstellen ',
    logErr: 'Bitte geben Sie Ihre Kontonummer ein',
    logErr2: 'Bitte Passwort eingeben',
    logErr3: 'Die Länge des Passworts darf nicht weniger als 6 Zeichen betragen',
    logSuccess: 'Anmeldung erfolgreich',
    resetpassword:'Passwort zurücksetzen',
    zurücksetzen:'reset',

    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
    
    // registrieren
    account3: 'Persönliches Konto erstellen',
    phone1: 'Mobiltelefonregistrierung',
    email1: 'E-Mail-Registrierung',
    email2: 'E-Mail-Adresse',
    Code: 'SMS-Bestätigungscode',
    code2: 'Bestätigungscode senden',
    code3: 'Bestätigungscode',
    Passwort4: 'Passwort bestätigen',
    einladen: 'Einladungscode',
    zustimmen: 'Ich habe gelesen und stimme zu',
    Vereinbarung: 'Benutzervereinbarung',
    registrieren: 'Registrieren',
    regErr: 'Bitte geben Sie den SMS-Bestätigungscode ein',
    bestätigenpwdtip: 'Bitte geben Sie das Bestätigungspasswort ein',
    bestätigenpwderr: 'Die beiden Passworteingaben sind inkonsistent!',
    //Passwort vergessen
    wjtab: 'Handy-Passwort zurücksetzen',
    wjtab2: 'E-Mail-Passwort zurücksetzen',
    wjlabel: 'Neues Passwort',
    wjerr: 'Bitte geben Sie ein neues Passwort ein',
    Tailpage:'Endseite',
	homepage:'Startseite',
	lastpage:'letzte Seite',
	nextpage:'nächste Seite',
	off:'schließen',
	problem:'Wenn Sie Probleme haben, einschließlich Echtnamen Authentifizierung oder Einzahlungen, die noch nicht angekommen sind, wenden Sie sich bitte rechtzeitig an den Kundenservice',
    //Allgemeiner Hinweis
    Ankündigungstitel:'Ankündigungstitel',
    Ankündigung:  '<p>Nach dem Mainnet-Start im April 2019 demonstrierte Binance Chain seine Hochgeschwindigkeitsleistung und sein Design mit hohem Durchsatz. Sein Hauptaugenmerk liegt auf der nativen dezentralen Anwendung ("dApp") Binance Chain DEX (dezentrale Börse), die in der Lage ist, Millionen von Trades in kurzer Zeit abzuwickeln, was die geringe Leistung ihrer Handelsmaschine widerspiegelt. </p>\
    <p>Flexibilität und Verfügbarkeit gehen oft mit Leistung einher. Wenn Menschen sich darauf konzentrieren, einen bequemen Ort für die Ausgabe und den Handel digitaler Vermögenswerte bereitzustellen, bringt dieses Design auch \
    Einschränkungen bis zu einem gewissen Grad. Die lauteste Stimme in der Community hat gesehen\
    Binance Chain fügt programmierbare Erweiterungsfunktionen hinzu oder wird\
    Um es ganz klar auszudrücken: Es handelt sich um intelligente Verträge und Funktionen virtueller Maschinen. </p><p>Aufgrund der derzeit eingeschränkten Funktionalität der Binance Chain ist es für Emittenten und Eigentümer digitaler Vermögenswerte schwierig, neue hinzuzufügen\
    Verteilen Sie die Funktionalität auf Ihre Assets oder führen Sie jede Form von  Community-Management und Community-Aktivitäten. </p>\
    <p>Trotz hoher Erwartungen an Binance, intelligente Vertragsfunktionen hinzuzufügen\
    Chain, es sei eine "schwere Entscheidung" gewesen. Die Ausführung intelligenter Verträge kann DEXs verlangsamen und den Handel mit Vermögenswerten unsicherer machen. Sogar dieser Kompromiss\
    Erträglich, die einfachste Lösung, die man sich vorstellen kann, könnte die Implementierung virtueller Systeme sein.\
    Maschine basierend auf dem aktuellen zugrunde liegenden Tendermint-Konsensprotokoll und dem Haupt-RPC\
    Binance-Chain-Schnittstelle. Allerdings erhöht diese Lösung die Lernkosten der bestehenden dApp-Community und wird keine beliebte Lösung werden. \
    Hier schlagen wir das Konzept der parallelen Binance-Chain-Blockchain vor, um einen hohen Wert aufrechtzuerhalten.\
    Die Leistung von nativem DEX unterstützt auch Smart-Contract-Funktionen. </p>',
    // Innovationslabor
    labTxt: 'Bitisan Lab•Innovationserfolge teilen',
    labTxt2: 'Ein offenes Win-Win-Labor für Investoren, Projektparteien, Börsen und andere Parteien',
    labTab1: 'Alle',
    labTab2: 'Im Begriff zu beginnen',
    labTab3: 'In Bearbeitung',
    labTab4: 'Verteilen',
    labTab5: 'Abgeschlossen',
    tokendistribute: 'Token wird verteilt',
    Aktivitätstyp: 'Abonnementtyp',
    labStart: 'Startzeit',
    labEnd: 'Endzeit',
    labStartPlaceholder: 'Bitte wählen Sie eine Startzeit',
    labDetail: 'Details anzeigen',
    labSpeen: 'Fortschritt',
    labNumber: 'Gesamtanzahl der Aktivitäten',
    labType1: 'Zum ersten Mal online (Eilkauf)',
    labType2: 'Erstmals online (gleichmäßig aufgeteilt)',
    labType3: 'Positionsfreigabe',
    labType4: 'Kostenloses Abonnement',
    labType5: 'Cloud Mining Machine-Abonnement',
    labType6: 'Sperrfreigabe',
    baseinfo: 'Grundlegende Informationen',
    Regeldetail: 'Typbeschreibung',
    releaseType: 'Release-Typ',
    releaseType0: 'Gleiche Freigabe',
    releaseType1: 'Freigabe mit gleichem Verhältnis',
    Sperre: 'freigeben',
    releaseDetail: 'Releasedetails',
    Sperrtag: 'Tag',
    lockweek: 'Woche',
    lockmonth: 'Monat',
    lockyear: 'Jahr',
    releasePercent: 'Freigabeverhältnis',
    lockFee: 'Sperrschwelle',
    releaseTimes: 'Mehrere freigeben',
    Zeiten: 'Zeiten',
    Publishprice: 'Abonnementpreis',
    holdcoin: 'Position erforderliche Währung',
    limittimes: 'Persönliche Kauflimitzeiten',
    limitamount: 'Persönliche Einkaufslimitmenge',
    unbegrenzt: 'kein Limit',
    leveloneCount: 'Anzahl der benötigten Level-1-Freunde',
    bereitsbetrag: 'Gekaufte Menge',
    leftamount: 'verbleibender Betrag',
    myalreadyholdamount: 'Meine teilnehmenden Bestände',
    bereitsholdamount: 'Gesamtbestand der an der Veranstaltung teilnehmenden Bestände',
    currentdivided: 'Meine aktuelle Position kann in Verträge aufgeteilt werden',
    Holdtips: 'Die Teilnahme an Aktivitäten vom Typ [Freigeben halten] erfordert das Sperren von Positionen bis zum Ende der Aktivität',
    inputholdamount: 'Geben Sie den Sperrbetrag ein',
    pleaseinputamount: 'Bitte geben Sie den Rückzahlungsbetrag ein',
    pleaseinputholdamount: 'Bitte geben Sie den Sperrbetrag ein',
    inputamount: 'Geben Sie den Umtauschbetrag ein',
    inputminingamount: 'Geben Sie die Anzahl der zu kaufenden Mining-Maschinen ein',
    teilnehmen: 'Jetzt teilnehmen',
    Achtung: 'Hinweis',
    Attentiontxt1: '1. Nach der Anmeldung zur Teilnahme an Aktivitäten vom Typ "Positionsfreigabe", "Kostenloses Abonnement", "Cloud-Mining-Maschinen-Abonnement" und "Sperren" werden die Vermögenswerte eingefroren und nach Abschluss der Aktivität wieder freigegeben oder entsperrt über',
    Attentiontxt2: '2. Nach der Teilnahme an Aktivitäten vom Typ [Position Sharing], [Kostenloses Abonnement], [Cloud Mining Machine-Abonnement], [Lock-up] dürfen Benutzer ihren Teilnahmeantrag nicht stornieren.',
    Attentiontxt3: '3. Wenn die Veranstaltung aufgrund der Projektpartei oder anderer Faktoren höherer Gewalt abgesagt wird, werden die eingefrorenen Vermögenswerte auf den ursprünglichen Weg zurückgeführt',
    Attentiontxt4: '* Das endgültige Interpretationsrecht dieser Veranstaltung liegt beim offiziellen Bitisan (WWW.BITISAN.NET)',
    // Münzmarginiertes Vertragskonto
    Überweisung: 'Geldtransfer',
    Platzhalter: 'Währung suchen',
    bith1: 'Vertragstransaktion',
    bith2: 'Kontokapital',
    bit3: 'Unrealisierter Gewinn und Verlust',
    bith4: 'Verfügbare Marge',
    bith5: 'Marge verwendet',
    bith6: 'Einfriermarge',
    bith7: 'Vertragskonto',
    // Spotkonto
    btn1: 'Aufladen',
    btn2: 'Zurückziehen',
    btn3: 'Übertragung',
    btn4: 'Einzahlung',
    btn5: 'Münzen abheben',
    totalAssets: 'Gesamtvermögen umgerechnet',
    th1: 'Währungsname',
    th2: 'Verfügbare Vermögenswerte',
    th3: 'Eingefrorene Vermögenswerte',
    th4: 'Freizugebende Vermögenswerte',
    th5: 'Operation',
    // aufladen
    czTxt: 'Digitale Währung aufladen',
    czTxt2: 'Währung',
    czTxt3: 'Netzwerk übertragen',
    czTxt4: 'Verfügbares Guthaben',
    czTxt5: 'Abhebungsadresse',
    czTxt6: 'Auszahlungsbetrag',
    czTxt7: 'Betrag erhalten',
    czTxt8: 'Bearbeitungsgebühr',
    czTxt9: 'Warme Erinnerung',
    czTxt10: 'Mindesteinzahlungsbetrag:',
    czTxt10_1: 'Aufladungen, die unter dem Mindestbetrag liegen, werden nicht gutgeschrieben.',
    czTxt11: 'Bitte hinterlegen Sie keine Nicht-Währungs-Vermögenswerte an der oben genannten Adresse, da die Vermögenswerte sonst nicht abgerufen werden können.',
    czTxt12: 'Nachdem Sie an der oben genannten Adresse aufgeladen haben, ist eine Bestätigung vom gesamten Blockchain-Netzwerkknoten erforderlich. Im Allgemeinen wird das Konto nach 3 Netzwerkbestätigungen vom Haupt-Blockchain-Netzwerk gutgeschrieben.',
    czTxt13: 'Ihre Aufladeadresse ändert sich nicht häufig und Sie können wiederholt aufladen. Sollte sich eine Änderung ergeben, werden wir unser Bestes tun, um Sie durch Website-Ankündigungen oder E-Mails zu benachrichtigen.',
    czTxt14: 'Bitte stellen Sie sicher, dass Ihr Computer und Browser sicher sind, um zu verhindern, dass Informationen manipuliert werden oder verloren gehen.',
    czTxt15: 'Aufladedatensatz',
    czTxt16: 'Um die Sicherheit der Gelder zu gewährleisten, führen wir eine manuelle Überprüfung der Auszahlung durch, wenn sich Ihre Kontosicherheitsrichtlinie ändert, Ihr Passwort geändert wird oder Sie eine neue Adresse zum Abheben von Münzen verwenden. Bitte haben Sie etwas Geduld und warten Sie auf das Personal. um Sie telefonisch oder per E-Mail zu kontaktieren.',
    czTxt17: 'Der Mindestauszahlungsbetrag beträgt:',
    czth1: 'Entzugszeit',
    czth2: 'von',
    czth3: 'zu',
    czth4: 'Menge des Flash-Austauschs',
    czth5: 'Umtauschverhältnis',
    czth6: 'Umtauschmenge',
    placeholder2: 'Bitte eingeben',
    placeholder3: 'Bitte auswählen',
    czEmpty: 'Kein Datensatz',
    logintip: 'Bitte melden Sie sich zuerst an! ',
    cztip: 'Sie haben keine Aufladeadresse beantragt, klicken Sie bitte auf die Schaltfläche, um die Aufladeadresse zu erhalten',
    czget: 'Aufladeadresse abrufen',
    czplaceholder: 'Bitte wählen Sie ein Protokoll aus',
    //Zweites Vertragskonto
    mAccount: 'Zweites Vertragskonto',
    // Wallet-Verlauf
    btn6: 'Suchen',
    wtTxt: 'Start- und Endzeit',
    wtTxt2: 'Operationstyp',
    wtth1: 'Handelszeit',
    wtth2: 'Typ',
    wtth3: 'Währung',
    wtth4: 'Menge',
    wtth5: 'Bearbeitungsgebühr zahlbar',
    wtth6: 'Abzug der Bearbeitungsgebühr',
    wtth7: 'Tatsächliche Gebühr',
    wtth8: 'Status',
    // Konto Sicherheit
    nav1: 'Kontosicherheit',
    nav2: 'Meine Einladung',
    nav3: 'Meine Aktionskarte',
    acTxt: 'Sicherheitsstufe',
    acbtn1: 'Nicht authentifiziert',
    acbtn2: 'binden',
    acbtn3: 'Ändern',
    acbtn4: 'Authentifiziert',
    acbtn5: 'Gebunden',
    acbtn6: 'Einstellungen',
    acbtn7: 'in Prüfung',
    acbtn8: 'Wiederholen',
    acTxt2: 'Authentifizierung mit echtem Namen',
    acTxt3: 'Mailbox',
    acTxt4: 'Mobiltelefon',
    acTxt5: 'Login-Passwort',
    acTxt6: 'Fondspasswort',
    acTxt7: 'Google Authenticator',
    acTxt8: 'Fondspasswort festlegen',
    acTxt9: 'Fondspasswort ändern',
    acTxt10: 'Fondspasswort abrufen',
    acDesc2: 'Um die Sicherheit Ihres Kontos zu gewährleisten, führen Sie bitte vor dem Handel eine Echtnamen-Authentifizierung durch!',
    acDesc3: 'E-Mail binden',
    acDesc4: 'Handy binden',
    acDesc5: 'Beim Anmelden bei der Plattform verwenden',
    acDesc6: 'Wenn sich das Guthaben auf dem Konto ändert, muss das Passwort für das Guthaben überprüft werden',
    acDesc7: 'Bitte binden Sie den Google-Authentifikator',
    acDesc8: 'Google-Authentifikator wurde gebunden',
    acDesc9: 'Bindung aufheben',
    acTit: 'Handybindung',
    acTit2: 'E-Mail-Bindung',
    acTit3: 'Google-Bindung',
    acTit4: 'Google-Bindung aufheben',
    mailtip: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    emailerr2: 'Das E-Mail-Format ist falsch, bitte erneut eingeben',
    Agreementtip: 'Bitte lesen Sie die Nutzungsvereinbarung und stimmen Sie ihr zu',
    acverify: 'Die Überprüfung ist fehlgeschlagen',
    nochmal: 'Bitte versuchen Sie es noch einmal',
    reviewedmsg: 'Bitte führen Sie zuerst die Verifizierung Ihres echten Namens durch',
    //Login-Passwort ändern
    chTxt: 'Login-Passwort ändern',
    chTxt2: 'Code zum Binden scannen',
    chTxt3: 'Bitte geben Sie den Bestätigungscode ein',
    chTxt4: 'Original-Fondspasswort',
    chTxt5: 'Neues Fondspasswort',
    chTxt6: 'Neues Passwort bestätigen',
    chTxt7: 'Passwort vergessen',
    chTxt8: 'Mobiltelefonverifizierung ändern',
    chTxt9: 'Ursprüngliches Login-Passwort',
    chTxt10: 'Neues Login-Passwort',
    chTxt11: 'E-Mail-Bestätigungscode',
    chTxt12: 'Verifizierungscode für Mobiltelefon',
    telerr: 'Mobiltelefonnummer ist falsch',
    emailerr: 'E-Mail ist falsch',
    codeerr: 'Bestätigungscode ist falsch',
    speichern: 'speichern',
    chtip: 'Bitte geben Sie das Original-Passwort ein',
    chtip2: 'Bitte geben Sie ein neues Login-Passwort mit mindestens 6 Ziffern ein',
    chtip3: 'Neues Login-Passwort ist inkonsistent',
    chtip4: 'Bitte geben Sie das korrekte Fondspasswort ein',
    chtip5: 'Passwort darf nicht weniger als 6 Zeichen lang sein',
    chtip6: 'Bitte geben Sie ein Passwort mit mindestens 6 Ziffern ein',
    chtip7: 'Passwort inkonsistent',
    chtip8: 'Bitte geben Sie das Fondspasswort ein',
    chtip9: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
    chtip10: 'Bitte geben Sie Ihr Login-Passwort ein',
    realnametip: 'Bitte geben Sie Ihren richtigen Namen ein',
    idcardtip: 'Bitte geben Sie Ihre ID-Nummer ein',
    
    // Bezahlverfahren
    clTxt: 'Bitte legen Sie Ihre Zahlungsmethode fest und verwenden Sie unbedingt Ihr Echtnamenkonto',
    clTxt2: 'Zahlungsmethode hinzufügen',
    edit: 'Bearbeiten',
    delete: 'löschen',
    Name : 'Name',
    clTxt3: 'ID-Nummer',
    clTxt4: 'IBAN-Nummer',
    clTxt5: 'Alle Zahlungsmethoden',
    clLink1: 'Kontoname',
    clLink2: 'Zahlungsmethode bearbeiten',
    clLink3: 'Zahlungsdetails (optional)',
    clplaceholder1: 'Bitte geben Sie Ihren vollständigen Namen ein',
    clplaceholder2: 'Bitte geben Sie Ihr Bankkonto-Passwort ein',
    clplaceholder3: 'Bitte geben Sie Ihre Zahlungsdaten ein',
    cltip: 'Besonderer Tipp',
    cltip2: 'Bitte geben Sie für die sofortige Zahlung unbedingt Ihre Bankkartennummer an. Geben Sie keine weiteren Bank- oder Zahlungsmethodendaten an. Sie müssen Zahlungs-/Quittungsinformationen für die Bank Ihrer Wahl hinzufügen. ',
    cltip3: 'Warme Erinnerung: Wenn Sie digitale Währung verkaufen, wird dem Käufer die von Ihnen gewählte Zahlungsmethode angezeigt. Bitte bestätigen Sie, dass die Informationen korrekt ausgefüllt sind. Bitte verwenden Sie ein Zahlungskonto, das mit der KYC-Identitätsüberprüfung Ihrer Binance-Plattform übereinstimmt. ',
    clTxt6: 'Optional',
    hochladen: 'hochladen',
    uploadTip: '(Unterstützt das JPG/JPEG/PNG/BMP-Format, weniger als 1 MB)',
    //Meine Promotionkarte
    tgbtn: 'Einlösungscode',
    tgth1: 'Kartenname',
    tgth2: 'Einlösungscode',
    tgth3: 'Kartenwährung',
    tgth4: 'Kartenvorderseite',
    tgth5: 'Gesamtzahl',
    tgth6: 'Eingelöst',
    tgth7: 'Abholzeit',
    tgbtn2: 'Werbematerial',
    tgbtn3: 'Jetzt einlösen',
    tgplaceholder: 'Bitte geben Sie den Einlösecode ein',
    Exchangesuccess: 'Umtausch erfolgreich! Bitte gehen Sie zur Vermögensverwaltung, um den Kontostand zu überprüfen!',
    // meine Einladung
    inth1: 'Benutzer einladen',
    inth2: 'Registrierungszeit',
    inth3: 'Echtname-Status',
    // meine Anzeige
    adTxt: 'Meine Anzeige',
    adTxt2: 'Wenn der Mindesttransaktionsbetrag der Anzeige zuzüglich der Bearbeitungsgebühr größer ist als die verbleibende Menge der Anzeige, wird die Anzeige automatisch entfernt',
    adbtn: 'Bedingung löschen',
    adbtn2: 'in den Regalen',
    adbtn3: 'Entfernen',
    adth1: 'Anzeigennummer',
    adth2: 'Anzeigentyp',
    adth3: 'Bestelllimit',
    adth4: 'Restmenge',
    adtip: 'Sind Sie sicher, dass Sie löschen möchten',
    num_text1: 'Bitte geben Sie ein, was Sie möchten',
    num_text2: 'Menge',
    // aktueller Delegierter
    wTxt: 'Auf Lager',
    wTxt2: 'Aktuelle Delegation',
    wTxt3: 'Handelspaar',
    wTxt4: 'Handelsrichtung',
    wth1: 'Zeit',
    wth2: 'Richtung',
    wth3: 'Preis',
    wth4: 'Dealed',
    wth5: 'Transaktionsbetrag',
    wth6: 'Delegate-Typ',
    wth7: 'Triggerpreis',
    wth8: 'Transaktionspreis',
    wth9: 'Höhe der Provision',
    wth10: 'Abrechnung Gewinn und Verlust',
    wth11: 'Delegationsstatus',
    wth12: 'Öffnen/Schließen',
    wth13: 'Marge',
    wbtn: 'Bestellung stornieren',
    wtip: 'Stornierungstipp',
    wtiptxt: 'Bestätigen Sie die Stornierung der Bestellung?',
    // Verdienen Sie Münzen
    zbplaceholder: 'Geben Sie die Bestellnummer ein, um mit der Suche zu beginnen',
    zbtab1: 'Anlageinvestitionsplan',
    zbtab2: 'Anlageinvestitionsdatensatz',
    zbth1: 'Investitionszyklus',
    zbth2: 'an/aus',
    zbth3: 'Kumulierter Investitionsbetrag',
    zbth4: 'Menge halten',
    zbth5: 'durchschnittliche Kosten',
    zbth6: 'Unrealisierter Gewinn und Verlust',
    zbth7: 'Nächstes Anlagedatum',
    zbth8: 'Planerstellungsdatum',
    // Aktueller Delegat -1
    etTxt: 'Announcement Center',
    etTxt2: 'Scannen zum Teilen',
    etmenu1: 'Kryptowährung',
    etmenu2: 'Ankündigung des Bitisan-Server-Updates',
    //Aktuelle Provision-3
    et2menu1: 'RX. Wellenfeld-Einführung',
    et2menu2: 'Ist Bitcoin ein Schneeballsystem?',
    // Historische Kommission
    hisTxt: 'Historische Kommission',
    // Meine Bestellung
    orTab1: 'Unbezahlt',
    orTab2: 'Bezahlt',
    orTab3: 'Complete',
    orTab4: 'Storniert',
    orTab5: 'Ansprechend',
    ortho1: 'Bestellnummer',
    ortho2: 'Transaktionswährung',
    ortho3: 'Transaktionstyp',
    ortho4: 'Handelsobjekt',
    ortho5: 'Betrag',
    // Optionsvertrag
    qcTab1: 'Vorherige Ergebnisse',
    qcTab2: 'Echtzeitkurse',
    qcTxt: 'Nein.',
    qcTxt2: 'Punkt',
    qcTxt3: 'Öffnungszeit',
    qcTxt4: 'Schließungszeit',
    qcTxt5: 'Eröffnungspreis',
    qcTxt6: 'Schlusskurs',
    qcTxt7: 'Gesamtkaufpreis',
    qcTxt8: 'Kauf- und Verkaufsbetrag',
    qcTxt9: 'Meine offene Position',
    qcTxt10: 'Prognosefortschritt',
    qcTxt11: 'Eröffnungspreis',
    qcTxt12: 'Schlusspreis',
    qcTxt13: 'Prognosezeitraum dieses Zeitraums',
    qcbtn1: 'Bullish',
    qcbtn2: 'bärisch',
    qcth1: 'Vertragslaufzeit',
    qcth2: 'Eröffnungsbetrag',
    qcth3: 'Vorhersagerichtung',
    qcth4: 'Vorhersageergebnis',
    qcth5: 'Bonusbetrag',
    qcth6: 'Positionseröffnungsgebühr',
    qcth7: 'Pumpe',
    
    // Währungsdetails
    Internet: 'Netzwerkverbindungsfehler',
    mplaceholder: 'Geben Sie den zu suchenden Währungsnamen ein',
    mTab: 'Auswählen',
    mth: 'Sammlung',
    mth1: 'Aktuellster Preis',
    mth2: '24h Höhen und Tiefen',
    mTxt: '24h höchster Preis',
    mTxt2: '24h niedrigster Preis',
    mTxt3: 'Transaktionsmenge',
    mTxt4: 'Förderquote',
    mTxt5: 'an',
    mTxt6: 'Hoch',
    mTxt7: 'Niedrig',
    mTxt8: 'Empfangen',
    indexth: 'Gesamttransaktionsbetrag',
    indexbtn: 'Details',
    indexbtn1:'Preis',
    indexbtn2:'Menge',
    indexbtn3:'Transaktionsvolumen',
    indexbtn4:'time',
    
    echat1: 'K-Liniendiagramm',
    echat2: 'Tiefenkarte',
    mTab2: 'Aktuelle Position',
    mTab3: 'Delegationsverlauf',
    mth3: 'Entschädigung garantiert',
    mth4: 'Eröffnungspreis',
    mth5: 'Periode',
    mth6: 'Schlusskurs',
    mth7: 'Gewinn',
    mTxt9: 'Handelsmodus',
    mTxt10: 'Positionseröffnungszeit',
    mTxt11: 'Gewinnrate',
    mTxt12: 'Balance',
    mTxt13: 'Volumen kaufen',
    mTxt14: '中',
    //Anzeige erstellen
    cadTxt: 'Eine Werbetransaktion erstellen',
    cadTxt2: 'Wenn Sie häufig handeln, können Sie Ihre eigenen Handelsanzeigen erstellen. ',
    cadTxt2_1: 'Wenn Sie nur gelegentlich handeln, empfehlen wir Ihnen, direkt zu suchen',
    cadTxt2_2: 'Das Erstellen einer Handelsanzeige ist kostenlos. ',
    cadLink2: 'Handelsanzeige',
    cadTxt3: 'Wenn Sie die erstellte Anzeige direkt bearbeiten möchten, überprüfen Sie bitte',
    cadLink3: 'Meine Anzeige',
    cadTxt4: 'Marktbeteiligungspreis',
    cadTxt5: 'Prämie bezieht sich auf den Prozentsatz über dem aktuellen Marktpreis für den Verkauf',
    cadTxt6: 'Preisformel',
    cadTxt7: '[Tipp] Sie können zum persönlichen Zentrum gehen, um Zahlungsmethoden zu binden/hinzuzufügen',
    cadTxt8: 'Der maximale Transaktionsbetrag darf Ihren Gesamtverkaufsbetrag 0CNYI nicht überschreiten',
    cadTxt9: 'Wenn ein Benutzer nach der Aktivierung über diese Anzeige eine Transaktion mit Ihnen initiiert, sendet das System automatisch die von Ihnen ausgewählte automatische Antwortphrase an die andere Partei. ',
    cadTxt10: 'Prämie bezieht sich auf den Prozentsatz über dem aktuellen Marktpreis für den Kauf',
    cadLabel1: 'Ich möchte:',
    cadLabel2: 'Währungstransaktion:',
    cadLabel3: 'Land:',
    cadLabel4: 'Währung:',
    cadLabel5: 'Festpreis aktivieren',
    cadLabel6: 'Premium:',
    cadLabel7: 'Transaktionspreis',
    cadLabel8: 'Verkaufsmenge:',
    cadLabel9: 'Zahlungsmethode:',
    cadLabel10: 'Mindesttransaktionsbetrag:',
    cadLabel11: 'Maximaler Transaktionsbetrag:',
    cadLabel12: 'Automatische Antwort aktivieren',
    cadLabel13: 'Fondspasswort:',
    cadLabel14: 'Bemerkungen:',
    cadLabel15: 'Festpreis:',
    cadLabel16: 'Autoreply:',
    cadLabel17: 'Menge kaufen:',
    Radio: 'online zu verkaufen',
    radio2: 'Online kaufen',
    cadplaceholder: 'Bitte legen Sie Ihre Prämie fest',
    cadplaceholder2: 'Bitte geben Sie die Menge ein, die Sie verkaufen möchten',
    cadplaceholder3: 'Bitte geben Sie den gewünschten Mindesttransaktionsbetrag ein',
    cadplaceholder4: 'Bitte geben Sie den gewünschten maximalen Transaktionsbetrag ein',
    cadplaceholder5: 'Sie können Ihre speziellen Anforderungen in den Bemerkungsinformationen angeben, z. B. Anforderungen an Käufer, Online-Zeit usw.',
    cadplaceholder6: 'Bitte geben Sie Ihren Transaktionspreis ein',
    cadplaceholder7: 'Nach Erhalt der Bestellung automatisch auf die Informationen des Käufers antworten, z. B. Zahlungsmethode, Zahlungskontonummer usw. ',
    cadplaceholder8: 'Bitte geben Sie die Menge ein, die Sie kaufen möchten',
    caderr1: 'Bitte geben Sie Ihren Mindesttransaktionsbetrag ein',
    caderr2: 'Der Mindesttransaktionsbetrag muss größer oder gleich 100 sein! ',
    caderr3: 'Der minimale Transaktionsbetrag muss kleiner sein als der maximale Transaktionsbetrag',
    caderr4: 'Bitte geben Sie Ihren maximalen Transaktionsbetrag ein! ',
    caderr5: 'Bitte geben Sie eine Ganzzahl ein',
    caderr6: 'Der maximale Transaktionsbetrag muss größer sein als der minimale Transaktionsbetrag! ',
    caderr7: 'Der maximale Transaktionsbetrag darf den Gesamtbetrag Ihrer Verkäufe nicht überschreiten',
    caderr8: 'Bitte wählen Sie eine Transaktionsmethode',
    Absenden: 'Absenden',
    cadnew: 'Nach der Aktivierung wird Ihr Währungspreis nicht mit dem Markt schwanken und der Preis bleibt unverändert.',
    //Schneller Austausch
    dhTxt: 'Ein-Klick-Austausch und Vorteile genießen',
    dhTxt2: 'Limitpreis',
    dhTxt3: 'Verkaufen',
    dhTxt4: 'Kaufen',
    dhTxt5: 'maximum',
    dhTxt6: 'Betrag eingeben',
    dhplaceholder: 'Verfügbares Guthaben der Spot-Wallet',
    dhSuccess: 'Herzlichen Glückwunsch! Einlösung erfolgreich! ',
    // Fiat-Währungstransaktion
    crTxt: 'Fiat-Währungstransaktion',
    crTxt2: 'Bequemer, sicherer und schneller Kauf und Verkauf digitaler Währungen',
    crTxt3: 'Dynamische Anpassung',
    crTxt4: 'Keine Bearbeitungsgebühr',
    crTxt5: 'Sofortige Transaktion',
    crTxt6: 'Plattformgarantie',
    crTxt7: 'Echtzeitschwankungen gemäß Marktpreisen',
    crTxt8: 'Was Benutzer sehen, ist, was sie bekommen, und es fallen keine Plattformgebühren außer dem Kauf- und Verkaufspreis an',
    crTxt9: 'Einführung einer Plattform, die Händlern, intelligentem Abgleich und Transaktionsaufträgen dient, ohne auf den Abgleich warten zu müssen',
    crTxt10: 'Plattformzertifizierte Händler, sicher und geschützt, 24-Stunden-Kundendienst zum Schutz von Transaktionen',
    crth1: 'Händler',
    crth2: 'Anzahl der Transaktionen',
    crth3: 'Zahlungsmethode',
    crth4: 'Limit',
    crth5: 'Stückpreis',
    crbtn: 'Kauf',
    crbtn2: 'Händler werden',
    // Fiat-Währungstransaktion 1
    cr1Txt: 'Herzlichen Glückwunsch! Ihre Überprüfung der Händlerzertifizierung wurde bestanden',
    cr1Txt2: 'Informationen vorbereiten',
    cr1Txt3: 'Zur Überprüfung einreichen',
    cr1Txt4: 'Authentifiziert',
    cr1Txt5: 'Sie haben die folgenden Berechtigungen',
    cr1Txt6: 'Exklusiver Stand',
    cr1Txt7: 'Eins-zu-eins-Service',
    cr1Txt8: 'Händler verfügen über exklusive Werbestände, um die Erfolgsquote bei Transaktionen zu erhöhen',
    cr1Txt9: 'Geringere Bearbeitungsgebühr',
    cr1btn: 'Werbung veröffentlichen',
    cr1btn2: 'Antrag auf Übergabe',
    // Regelmäßiges Finanzmanagement
    reTxt: 'Bitisan Financial Management• Anlagelösung aus einer Hand',
    reTxt2: 'Sofort Geld verdienen, einfach & sicher',
    reTab: 'Regelmäßige Finanzverwaltung',
    reTab2: 'Anlageinvestitionen und Finanzmanagement',
    reTxt3: 'Regulär',
    reTxt4: 'Flexible Anpassung, höheres potenzielles Einkommen',
    reTxt6: 'Anlageinvestition',
    reTxt7: 'Beginnen Sie mit Anlageinvestitionen und steigern Sie Ihr Vermögen',
    reTxt8: 'Alle 18 periodischen Produkte erweitern',
    reth1: 'Annualisierte Rendite',
    reth2: 'Laufzeit (Tage)',
    reth3: 'Transaktionslimit',
    reth4: 'Produkt',
    reth5: 'Historische Kapitalrendite',
    rebtn: 'Plan erstellen',
    redTxt: 'Erstelle eins',
    redTxt2: 'Anlageinvestitionsplan',
    redTxt3: 'Geben Sie den Investitionsbetrag ein',
    redTxt4: 'Mein verfügbares Guthaben beträgt',
    redTxt5: 'Verfügbar',
    redTxt6: 'Zyklusperiode',
    redTxt7: 'Jeden Tag',
    redTxt8: 'Wöchentlich',
    redTxt9: 'Monatlich',
    redTxt10: 'Ortszeit',
    redTxt11: 'Ihr erster automatischer Investitionszyklus wird sein',
    redTxt12: 'Start',
    bestätigen: 'bestätigen',
    bestätigen2: 'OK',
    abbrechen: 'abbrechen',
    Überarbeitung: 'in Bearbeitung',
    redcompleted: 'Abgeschlossen',
    minNumErr: 'Weniger als der Mindeststartbetrag',
    minNumErr2: 'Die Startmenge darf nicht 0 sein',
    buyamounttip: 'Bitte geben Sie den Kaufbetrag ein',
    pricetipwarning: 'Der Transaktionsbetrag darf nicht höher sein als',
    buyamounttipwarning: 'Kaufbetrag darf nicht höher sein als',
    sellamounttip: 'Bitte geben Sie den Verkaufsbetrag ein',
    sellamounttipwarning: 'Die Verkaufsmenge darf nicht höher sein als',
    sellpricetip: 'Bitte geben Sie den Verkaufspreis ein',
    sellpricetipwarning: 'Der Verkaufspreis darf nicht höher sein als',
    Sellmore: 'Das Höchste, was Sie verkaufen können',
    Verkaufseinheit: 'Stück',
    loginFirst: 'Bitte melden Sie sich zuerst an',
    betsuccess: 'Herzlichen Glückwunsch! Die Wette war erfolgreich!',
    selectAmount: 'Bitte wählen Sie den Wettbetrag aus',
    balancenotenough: 'Unzureichendes verfügbares Guthaben!',
    balancenotenough2: 'Unzureichendes Gleichgewicht!',
    minlimitamountfailed: 'Der Einlösungsbetrag darf nicht unter dem Mindesteinlösungsbetrag liegen!',
    maxlimitamountfailed: 'Der Einlösungsbetrag darf nicht größer sein als der maximale Kaufbetrag!',
    limittimesfailed: 'Die Anzahl Ihrer Teilnahmen übersteigt die maximale Anzahl persönlicher Einkäufe!',
    commitfailed: 'Teilnahme senden fehlgeschlagen!',
    // Spothandel
    trplaceholder: 'query',
    trplaceholder2: 'Aktuellster Preis',
    trplaceholder3: 'Zum besten Preis auf dem Markt kaufen',
    trplaceholder4: 'Zum besten Preis auf dem Markt verkaufen',
    triggleplaceholder: 'Der Standard-Orderpreis ist der Marktpreis',
    triggerpriceshouldbigger: 'Triggerpreis muss größer als 0 sein',
    marginModeSuccessTip: 'Vertragskontomodus erfolgreich geändert',
    noenoughbalance: 'Kontomarge ist unzureichend',
    pleaseinputopenvolume: 'Das Öffnungsvolumen muss größer als 0 sein',
    pleaseinputcorrectopenvolume: 'Bitte geben Sie das korrekte Öffnungsvolumen ein',
    pleaseinputrightentrustprice: 'Bitte geben Sie den korrekten Bestellpreis ein',
    noenoughposition: 'Unzureichende liquidierbare Positionen',
    pleaseinputclosevolume: 'Die Abschlussmenge muss größer als 0 sein',
    pleaseinputcorrectclosevolume: 'Bitte geben Sie das korrekte Schließvolumen ein',
    Option1: 'Antenne',
    Option2: 'Wochenlinie',
    Option3: 'Monatszeile',
    trTxt: 'Transaktionsbetrag',
    trTxt2: 'Marktpreis',
    trTxt3: 'Verkaufsvolumen',
    trSuccess: 'Übermittlung erfolgreich',
    //u-basiert
    udTxt: 'Margin-Modus',
    udTxt2: 'Vertragsmehrfach anpassen',
    udTxt3: 'Hebelwirkung',
    udTxt4: 'Perpetual',
    udTxt4_1: 'Perpetual',
    udTxt5: 'Übertragbarer Betrag',
    udTxt6: 'Menge übertragen',
    udTxt7: 'Alle übertragen',
    udTxt8: 'Perpetual Account',
    ubtn: 'Volle Position',
    ubtn2: 'Isolierte Position',
    uplaceholder: 'Konto mit unbefristetem Vertrag',
    uradio1: 'Time-Sharing',
    uradio2: '15 Minuten',
    uradio3: '1 Stunde',
    uradio4: '4 Stunden',
    uradio5: '1 Tag',
    uth1: 'Vertragsmehrfach',
    uth2: 'Umsatz',
    uth3: 'Ertrag',
    uth4: 'Position',
    uth5: 'Messbar',
    uth6: 'Durchschnittlicher Eröffnungspreis',
    uth7: 'Starke Parität',
    uth8: 'Aktuelle Einzahlung',
    uth9: 'Marginsatz',
    zhang: '张',
    or :'oder',
    ureg: 'Jetzt registrieren',
    uTxt8: 'Transaktion durchführen',
    uTab: 'Eine Position eröffnen',
    uTab2: 'Position schließen',
    uTxt9: 'kann lange öffnen',
    uTxt10: 'Kann kurz geöffnet werden',
    uTxt11: 'Bestellpreis',
    uplaceholder2: 'Transaktion zum besten Preis auf dem Markt',
    uTxt12: 'Kaufen, um Long zu eröffnen',
    uTxt13: 'Verkaufen und Short eröffnen',
    Nahaufnahme: 'Buy to close',
    closedown: 'Verkaufen, um Long zu schließen',
    uTab3: 'Gewinn mitnehmen und Verlust stoppen',
    uTab3_yprice: 'Take-Profit-Preis',
    uTab3_sprice: 'Stop-Loss-Preis',
    uTab3Txt: 'Take Profit/Stop Loss',
    uTxt14: 'Mein Vertragskonto',
    ulabel1: 'Kontomodus',
    ulabel2: 'Gesamtkontoeinkommen',
    ulabel3: 'Positionsrand',
    ulabel4: 'Fondsauslastungsrate',
    ulabel5: 'Mehrere',
    ulabel6: 'leer',
    uTxt15: 'Lang',
    uTxt16: 'Kurz',
    Gesamt: 'Gesamt',
    limited_price: 'Limit-Preis-Bestellung',
    market_price: 'Marktpreisbestellung',
    spot_price: 'Gewinn mitnehmen und Verlust stoppen',
    ent_status1: 'Delegiert',
    ent_status2: 'Abgebrochen',
    ent_status3: 'Delegation fehlgeschlagen',
    ent_status4: 'Delegation erfolgreich',
    ent_statusblast: 'Liquidation',
    uTxt17: 'Long-Positionen können geschlossen werden',
    uTxt18: 'Short-Position kann geschlossen werden',
    //
    prev: 'vorheriger Artikel',
    weiter: 'nächster Artikel',
    // Promotionpartner
    prtxt: 'Mein Einladungslink',
    kopieren: 'kopieren',
    prtxt2: 'Mein Freund der ersten Ebene',
    prtxt3: 'Mein Freund der zweiten Ebene',
    prtxt4: 'Provisionseinnahmen (entspricht USDT)',
    prtxt5: 'Extra Belohnung',
    prtxt6: 'Partnerschaftsebene',
    prtit: 'Regeldetails',
    prdesc: 'Bitisans [Promotion-Partner] ist eine Promotion-Aktivität mit der höchsten Rabattquote und dem längsten Rabattzeitraum (maximaler Rabatt auf Lebenszeit) im gesamten Netzwerk, die es Promotern, die entsprechende Anstrengungen unternehmen, ermöglicht, echte Partner der Plattform zu werden. Genießen Sie die Vorteile des gemeinsamen Wachstums mit der Bitisan-Handelsplattform! Die spezifischen Details lauten wie folgt: ',
    prli1: '1. Um das Konzept digitaler Assets zu fördern und den Nutzerkreis zu erweitern, hat Bitisan das [Promotion Partner]-Programm ins Leben gerufen, das seit langem wirksam ist. ',
    prli2: '2. Das Einladen von Freunden ist in zwei Ebenen unterteilt. Wenn A B einlädt, ist B der Freund der ersten Ebene. Wenn B C einlädt, ist C der Freund der ersten Ebene. ',
    prli3: '3. Wenn Sie sich als Werbefreund registrieren, müssen Sie den vom Einladenden bereitgestellten Link verwenden oder den Einladungscode des Einladenden manuell eingeben, um der Freund der ersten Ebene des Einladenden zu werden. ',
    prli4: '4. Der Rabattanreiz kann erst wirksam werden, nachdem sich der Eingeladene über den Einladungscode registriert und die Authentifizierung mit echtem Namen abgeschlossen hat. ',
    prli5: '5. Der Rabattzeitraum beträgt N Monate ab dem Zeitpunkt, an dem der Eingeladene die Authentifizierung mit echtem Namen abschließt. Je nach Stufe ist das Rabattverhältnis unterschiedlich. ',
    prli6: '6. Der Aktionsrabattwert ist die Transaktionsgebühr der Währung des untergeordneten Freundes, und die Rabattwährung sind die drei wichtigsten Marktbasiswährungen, nämlich USDT, BTC und ETH.',
    prli7: '7. Das Rabattverhältnis und die Rabattdauer korrelieren positiv mit der Anzahl der beförderten Freunde der ersten Ebene. Je mehr Personen Sie befördern, desto höher ist das Rabattverhältnis, das Sie genießen können. Das spezifische Aktionsrabattverhältnis ist wie folgt:' ,
    prli8: '8. Die Top 10 oder Top 100 in der Rangliste der Werbekommissionen erhalten von Zeit zu Zeit zusätzliche Belohnungen, die offiziell von Bitisan vergeben werden. ',
    prli9: '9. Sollten andere Aktivitäten im Widerspruch zu den Aktivitäten des [Werbepartners] stehen, werden vorübergehende Änderungen nach Rücksprache beschlossen. Wir hoffen auf Ihr Verständnis seitens der Partner. ',
    prli10: 'Zehn Partner der Stufen L4 (Gouverneur), L5 (Präfekt) und L6 (Jiedushi) genießen Dividendenprämien von 5 %, 10 % bzw. 15 % der gesamten jährlichen Provisionsrückerstattung.',
    prli11: '11. Das endgültige Interpretationsrecht dieser Aktivität liegt bei Bitisan. ',
    prth1: 'Ebene',
    prth2: 'Anzahl der Freunde der ersten Ebene',
    prth3: 'Rabattverhältnis/Rabattzeit für Freunde der ersten Ebene',
    prth4: 'Rabattverhältnis/Rabattzeit für Freunde der zweiten Ebene',
    prth5: 'Partnerdividenden',
    prlev1: 'Li Zheng',
    prlev2: 'Bezirksrichter',
    prlev3: 'Präfekt',
    prlev4: 'Gouverneur',
    prlev5: 'Präfekt',
    prlev6: 'Jiedushi',
    prtd1: 'Monat',
    prtd2: 'Lebenslange Provision',
    prtit2: 'Beispiel',
    prtxt7: 'Wenn der Benutzer Xiao Yan 100 Freunde der ersten Ebene einlädt und jeder seiner Freunde der ersten Ebene 5 Freunde der zweiten Ebene einlädt, hat Xiao Yan 100 Freunde der ersten Ebene und 500 Freunde der zweiten Ebene. Wenn die durchschnittliche Person 3.000 Yuan pro Tag handelt, beträgt das monatliche Einkommen von Xiao Yan ungefähr: (100*3000*0,001*30 % + 500*3000*0,001*10 %) * 30 = 7200/Monat. ',
    prtxt8: 'Wenn der Benutzer Xiao Yan 1.000 Freunde der ersten Ebene einlädt und jeder seiner Freunde der ersten Ebene 5 Freunde der zweiten Ebene einlädt, hat Xiao Yan 1.000 Freunde der ersten Ebene und 5.000 Freunde der zweiten Ebene. Wenn die durchschnittliche Person 3.000 Yuan pro Tag handelt, beträgt das monatliche Einkommen von Xiao Yan ungefähr: (1000*3000*0,001*50 % + 5000*3000*0,001*20 %) * 30 = 135.000/Monat. ',
    prtit3: 'Promotion Toolbox',
    prtit4: 'Gesamtaktionsrabatt TOP20 (Gesamtliste)',
    prtit5: 'TOP20-Promoter (Gesamtliste)',
    prtxt9: 'Statistische Frist:',
    prth6: 'Rangliste',
    prth7: 'Mitglied',
    prth8: 'Aktionsnummer',
    prth9: 'Rabatt (entspricht USDT)',
    prth10: 'Extra Belohnung',
    prtip1: 'Die oben genannten Provisionsdaten werden nicht in Echtzeit aktualisiert und das System zählt und aktualisiert sie alle 24 Stunden.',
    prtip2: 'Die Anzahl der Eingeladenen ist die Anzahl der Freunde der ersten Ebene, und diese Liste ist die Rangfolge des gesamten Rabattbetrags',
    prtip3: 'Die Anzahl der Eingeladenen ist die Anzahl der Freunde der ersten Ebene, und diese Liste ist die Rangfolge der Anzahl der Eingeladenen der ersten Ebene',
    // Münzen einzahlen
    recth1: 'Ankunftszeit',
    recth2: 'Netzwerkprotokoll',
    recth3: 'Einzahlungsadresse',
    recth4: 'Auflademenge',
    recbtn: 'Code zum Aufladen abheben',
    recplaceholder: 'Bitte geben Sie den Auszahlungscode ein',
    recstatus_0: 'Fehlgeschlagen',
    recstatus_1: 'Nicht empfangen',
    recstatus_2: 'Angekommen',
    // Münzen abheben
    wittit: 'Abhebung digitaler Währungen',
    wittext: 'Auszahlungsdatensatz',
    wittext2: 'Auszahlungscode',
    wittext3: 'Liste der Auszahlungscodes',
    witbtn: 'Münzen mit Code abheben',
    witbtn2: 'Währungsabhebungscode generieren',
    witdia: 'Sicherheitsüberprüfung',
    witdia2: 'Auszahlungscode erfolgreich generiert',
    witplaceholder: 'Bitte geben Sie den Auszahlungsbetrag ein',
    witerr: 'Bitte Währung auswählen',
    Adresstipp: 'Bitte geben Sie die Adresse ein',
    witstate: 'eingelöst werden',
    witstate2: 'fehlgeschlagen',
    witstate3: 'Austausch abgeschlossen',
    witstatus_1: 'in Prüfung',
    witstatus_2: 'Übertragung läuft',
    witstatus_3: 'Fehlgeschlagen',
    witstatus_4: 'Erfolg',
    // Operationstyp
    Typ1: 'Übertragung',
    Typ2: 'Coin-to-Coin-Transaktion',
    Typ3: 'Legale Währung kaufen',
    Typ4: 'Legale Währung verkaufen',
    Typ5: 'Aktivitätsbelohnung',
    Typ6: 'Beförderungsprämie',
    Typ7: 'Dividende',
    Typ8: 'Abstimmung',
    Typ9: 'Manuelles Aufladen',
    Typ10: 'Paar',
    Typ11: 'Aktivitätseinlösung',
    Typ12: 'C2C kaufen',
    Typ13: 'C2C verkaufen',
    Typ14: 'Roten Umschlag senden',
    Typ15: 'Roten Umschlag erhalten',
    Typ16: 'Münzcode abheben',
    Typ17: 'Auszahlungscode und Einzahlung',
    Typ18: 'Befristete Vertragsabwicklungsgebühr',
    Typ19: 'Perpetual Contract Profit',
    Typ20: 'Perpetual Contract Loss',
    Typ21: 'Optionsvertrag fehlgeschlagen',
    Typ22: 'Optionsvertragsbearbeitungsgebühr',
    Typ23: 'Optionsvertragsbonus',
    Typ24: 'Vertragsrabatt',
    Typ25: 'Level-Belohnung',
    Typ26: 'Plattformgebühreneinnahmen',
    Typ27: 'Zweiter Vertrag fehlgeschlagen',
    Typ28: 'Zweiter Vertragsbonus',
    Typ29: 'Finanzverwaltungsinteresse',
    Typ30: 'Mittelausgabe',
    Typ31: 'Gebühr für die Mittelbeschaffung',
    Typ32: 'Anlageinvestition',
    Typ33: 'Anlageinvestition verkaufen',
    Typ34: 'Normal kaufen',
    Typ35: 'Regelmäßige Rückzahlung',
    Typ36: 'Coin-basierte Vertragsübertragung',
    Typ37: 'Coin-basierter Vertragstransfer aus',
    Typ38: 'U-basierte Vertragsübertragung',
    Typ39: 'U-basierter Vertragstransfer aus',
    Typ40: 'Zweiter Vertragstransfertransfer',
    Typ41: 'Zweiter Vertragstransfer aus',
    Typ42: 'Münztransfertransfer',
    Typ43: 'Münztransfer aus',
    // Bankkonto
    lctit: 'Finanzverwaltungskonto',
    lcbtn: 'Finanztransfer',
    lcth1: 'Kumulierte Zinsen',
    day: 'TagTag',
    lctxt: 'Aktivitätswährung',
    lctxt2: 'Währungen akzeptieren',
    lctxt3: 'Startbetrag',
    lctxt4: 'Zeit auswählen',
    Jahr: 'Jahr',
    Monat: 'Monat',
    // Finanzverwaltungsauftrag
    ldtab1: 'Positionsdatensatz',
    ldtab2: 'Datensatz schließen',
    ldth1: 'Verdiensttag starten',
    ldth2: 'Tag der Erlösung',
    ldth3: 'Verdienstschätzung',
    ldth4: 'Gewinn',
    // Fester Investitionsplan
    Attit: 'Anlageinvestitionsplan ändern',
    attxt: 'Investitionsmenge',
    attxt2: 'Investitionszyklus',
    atth1: 'fester Anlagetermin',
    atth2: 'fester Investitionsbetrag',
    atth3: 'Transaktionsrate',
    Erfolg: 'Erfolg',
    // Innovationsmanagement
    cxth1: 'Name',
    cxth2: 'Typ',
    cxth3: 'Abonnementmenge',
    cxth4: 'Teilnehmende Währungen',
    cxth5: 'Abonnementeinheit',
    cxth6: 'Aktueller Status',
    cxth7: 'Transaktionsvolumen',
    cxth8: 'Erstellungszeit',
    cxnav1: 'Ich habe teilgenommen',
    cxnav2: 'Meine Mining-Maschine',
    cxnav3: 'Mein Schloss',
    cxtit2: 'Meine Mining-Maschinenliste',
    cxtit3: 'Meine Sperrliste',
    cxkth1: 'Ausgabewährung',
    cxkth2: 'Ausgabezyklus',
    cxkth3: 'Mining-Zeit',
    cxkth4: 'Abgebaute Zeit',
    cxkth5: 'Grundkapazität',
    cxkth6: 'Tatsächliche Kapazität',
    cxstate1: 'Nicht bereitgestellt',
    cxstate2: 'Bereitgestellt',
    cxstate3: 'Widerrufen',
    cxstate4: 'Muss noch abgeschlossen werden',
    cxstate5: 'Beendet',
    cxstate6: 'Wird veröffentlicht',
    cxstate7: 'Freigeben',
    cxcth1: 'Währung freigeben',
    cxcth2: 'Totale Sperre',
    cxcth3: 'Gesamtveröffentlichungszeitraum',
    cxcth4: 'Zyklus freigegeben',
    cxcth5: 'Basisversion',
    cxcth6: 'Tatsächliche Veröffentlichung',
    mehr: 'mehr',
    // über uns
    abtit: 'Plattformfunktionen',
    abtit2: 'Über uns',
    abtit3: 'Kontaktieren Sie uns',
    abtit4: 'Bitisan Community',
    abdesc: 'Bitisan ist die weltweit führende Handelsplattform für digitale Vermögenswerte mit Technologie, registriert auf den Cayman Islands und ihr Kernbetriebsteam befindet sich in Hongkong. Die Kernmitglieder von Bitisan stammen aus führenden Internet- und Finanzunternehmen, und die meisten Mitglieder glauben fest an Bitcoin und Blockchain. Wir sind fest davon überzeugt, dass Blockchain das traditionelle monopolistische Finanzsystem verändern und eine demokratischere und autonomere Gesellschaftsstruktur schaffen wird. ',
    abdesc2: 'Die Bitisan-Plattform verfügt über eine professionelle Transaktionsstruktur auf Finanzebene und eine selbst entwickelte Transaktions-Engine mit hohem Parallelitätsspeicher. Die Plattform verwendet ein vollständig kaltes Auflade-Wallet-System + Multi-Signatur + DDOS-Angriffssystem mit hohem Schutz und andere Transaktionen. Strukturen zur Gewährleistung der Sicherheit von Kundenvermögen. ',
    abdesc3: 'Damit Kunden Investitionsmöglichkeiten besser nutzen können, führt Bitisan ein flaches Management innerhalb der Kundendienstabteilung ein und richtet einen extrem schnellen Service-Reaktionsmechanismus ein. Der Asset-Kundendienstmanager ist das ganze Jahr über rund um die Uhr online, um Kunden mit Dienstleistungen zu versorgen. B. das Aufladen und Abheben von Vermögenswerten, um sicherzustellen, dass die Ein- und Auszahlungen von Vermögenswerten der Kunden innerhalb von 5 Minuten abgeschlossen sind. ',
    abdesc4: 'Bitisan wählt strikt qualitativ hochwertige Projekte aus und bietet sichere und stabile Vermögensverwahrungsdienste. Bitisan folgt dem Konzept von Ehrlichkeit, Fairness, Begeisterung und Offenheit und ist bestrebt, den ultimativen Austausch für Benutzer zu schaffen, der sicher, zuverlässig, effizient und freundlich ist. ',
    abdesc5: 'Obwohl es derzeit nur einen kleinen Kreis digitaler Vermögenswerte gibt, glauben wir, dass diese Art von Vermögenswerten, die jeder vollständig kontrollieren kann, in Zukunft definitiv populär werden wird. Lassen Sie uns zusammenarbeiten und freuen uns darauf!',
    abdesc6: 'Kundendienst',
    abdesc7: 'technischer Support',
    abdesc8: 'Antrag auf Währungsnotierung',
    abdesc9: 'Beschwerden und Vorschläge',
    abdesc10: 'Geschäftskooperation',
    abdesc11: 'WeChat',
    abdesc12: 'Weibo',
    abdesc13: 'Twitter',
    abdesc14: 'Münzgebrauch',
    abdesc15: 'Telegramm',
    abdesc16: 'Fügen Sie bmate601 als WeChat-Freund hinzu und treten Sie der WeChat-Wohlfahrtsgemeinschaft bei',
    // Verifiziert
    realtxt: 'richtiger Name',
    realtxt2: 'ID-Kartennummer',
    realtxt3: 'Foto von der Vorderseite des Personalausweises',
    realtxt4: 'Rückseitenfoto des Personalausweises',
    realtxt5: 'Foto mit Personalausweis und persönlicher Unterschrift',
    realtxt6: '1. JPG- und PNG-Formate werden unterstützt, und die Dateigröße beträgt weniger als 8 MB. Eine Änderung oder Blockierung ist nicht zulässig. ',
    realtxt7: '2. Das Foto muss ohne Hut aufgenommen werden und es wird empfohlen, kein Make-up zu tragen. Die Gesichtszüge der Person, die den Ausweis hält, sind deutlich sichtbar und die Arme sind vollständig sichtbar.',
    realtxt8: '3. Sie müssen das Zeugnis und die handschriftliche Kopie (Name + Bewerbungsdatum) gleichzeitig in der Hand halten. Alle meine Handlungen auf dieser Website werden von mir selbst durchgeführt, ich bin mir der damit verbundenen Risiken bewusst und dazu bereit. tragen alle rechtlichen Konsequenzen dieses Kontos.',
    realbtn: 'Zum Hochladen klicken',
    realstate: 'Nicht mit echtem Namen',
    realstate2: 'Realisiert',
    uploadimg: 'Bitte laden Sie ein Foto von der Vorderseite Ihres Personalausweises hoch',
    uploadimg2: 'Bitte laden Sie das Rückseitenfoto Ihres Personalausweises hoch',
    uploadimg3: 'Bitte laden Sie ein Foto Ihres Personalausweises hoch',
    uploaderr: 'Die Größe des hochgeladenen Bildes darf nicht größer sein',
    save_success: 'Erfolgreich gespeichert!',
    save_failure: 'Speichern fehlgeschlagen!',
    // überweisen
    schließen: 'schließen',
    hztxt: 'Übertragung bestätigen',
    //Händlerzertifizierung
    sendtip1: 'Um die Sicherheit Ihres Geldes zu gewährleisten, führen Sie bitte zuerst eine [Authentifizierung mit echtem Namen] durch! ',
    sendtip2: 'Um die Sicherheit Ihres Geldes zu gewährleisten, führen Sie bitte zuerst die [Mobiltelefonbindung] durch! ',
    sendtip3: 'Zur Sicherheit Ihres Geldes legen Sie bitte zuerst ein [Fondspasswort] fest! ',
    Submittip4: 'Bitte binden Sie mindestens eine Zahlungsmethode ein',
    aderr1: 'Bitte geben Sie die richtige Nummer ein',
    aderr2: 'Der Prämienwert beträgt 0-20',
    aderr3: 'Der Prämienwert beträgt 0-20 und darf nicht 0 sein',
    aderr4: 'Bitte geben Sie den korrekten Festpreis ein',
    aderr5: 'Bitte geben Sie die richtige Zahl ein und der maximale Transaktionsbetrag überschreitet nicht 100 Münzen',
    orderTypeNo: 'Nein',
    orderTypeYes: 'Ja',
    gewinnen: 'Erfolg',
    verlieren: 'Misserfolg',
    gebunden: 'Gleichstand',
    cancelsuccess: 'Stornierung erfolgreich!',
    Regale: 'Die Anzeige kann erst gelöscht werden, nachdem sie aus den Regalen entfernt wurde!',
    Regale2: 'Hinzufügungen können erst bearbeitet werden, nachdem sie entfernt wurden! ',
    Unternehmen: 'Bitte beantragen Sie zuerst die Händlerzertifizierung!' ,
    swappleaseselectwallet: 'Bitte wählen Sie zuerst das Ein-/Auszahlungskonto aus',
    swappleaseinputamount: 'Bitte geben Sie den Überweisungsbetrag ein',
    clickchange: 'Klicken, um die Übertragungsrichtung zu ändern',
    daterange: 'Bitte wählen Sie einen Suchzeitraum aus',
    // API
    apierr: 'Bitte geben Sie Bemerkungsinformationen ein',
    apierr2: 'Bitte geben Sie die IP-Adresse ein',
    apierr3: 'Bitte geben Sie die richtige IP-Adresse ein',
    apierr4: 'Bitte geben Sie den Bestätigungscode ein',
    clickget: 'Zum Erhalten klicken',
    Apisekunde: 'Sekunde',
    apitips: 'Der Schlüssel wird nur angezeigt, wenn er hinzugefügt wird, bitte speichern Sie ihn rechtzeitig',
    apiaccess: 'Schlüssel',
    // anpassen
    do_favorite: 'Favorit',
    cancel_favorite: 'Favorit abbrechen',
    fail_favorite: 'fehlgeschlagen',
    deptotal: 'kumulativ',
    Diagramm: 'Diagramm',
    Buch: 'Auftragsbuch',
    Handel: 'Letzte Transaktion',
    pt_title: 'Geschenkkarte mit digitaler Währung',
    pt_desc: 'Nach der Einlösung werden Sie automatisch zu einem Freund mit niedrigerem Level und machen Geschenke an Freunde, Verwandte, Kunden und Partner. Helfen Sie Werbepartnern, besser zu werben.',
    pt_more: 'Weitere Tools, bitte bleiben Sie dran',
    pt_tips: 'Wenn Sie eine gute Idee haben, senden Sie bitte eine E-Mail an promotion@Bitisan.biz. Sie werden belohnt, wenn Sie sie übernehmen!',
    pt_card_amount: 'Betrag der Kartenvorderseite',
    pt_card_deadline: 'Gültigkeitszeitraum',
    pt_card_noend: 'Auf unbestimmte Zeit',
    pt_card_btn: 'Aktionspartner können 30 Karten kostenlos erhalten (ca. 2.000 Yuan)',
    pt_card_btn_login: 'Nach dem Einloggen erhalten Sie 30 kostenlose Karten',
    pt_card_rule: 'Regeldetails',
    pt_card_summary: 'Geschenkkarten in digitaler Währung sind eines der Werbetools, die entwickelt wurden, um Werbepartnern die Möglichkeit zu geben, Offline-Freunde besser einzuladen. Wenn Nutzer der Werbezielgruppe Geschenkkarten einlösen, werden sie automatisch zu Offline-Freunden des Veranstalters.',
    pt_card_rule1: '1. Jeder Benutzer ist auf 30 kostenlose Geschenkkarten beschränkt. Wenn mehr Geschenkkarten ausgestellt werden müssen, muss der Benutzer für die Anpassung bezahlen. Der Kartenwert, das Logo, der erläuternde Text usw. können angepasst werden. Bitte senden Sie eine E-Mail an für Anpassungsanforderungen.',
    pt_card_rule2: '2. Jede kostenlose Geschenkkarte kann nur einmal pro Benutzer eingelöst werden. Jede offiziell von Bitisan ausgestellte kostenlose Geschenkkarte kann nur einmal pro Benutzer eingelöst werden. Selbst wenn kostenlose Geschenkkarten mit unterschiedlichen Einlösungscodes von verschiedenen Benutzern erhalten werden, ist dies möglich Lösen Sie nur eine kostenlose Geschenkkarte ein. Für individuell angefertigte Geschenkkarten gilt diese Einschränkung nicht.',
    pt_card_rule3: '3. Bevor der Benutzer die Geschenkkarte einlöst, wird der Benutzer automatisch als Freund der ersten Ebene der Karte zugeordnet, wenn es keinen Einladenden gibt (d. h. wenn bei der Registrierung des Kontos kein Einladungscode eingegeben wurde). Zum Beispiel hat A den Einladungscode bei der Registrierung des Kontos nicht eingegeben. Zu diesem Zeitpunkt gehört A keinem Freund der ersten oder zweiten Ebene, aber wenn A die von B ausgestellte Geschenkkarte einlöst, dann A automatisch wird Bs erster Freund.',
    pt_card_rule4: '4. Um Betrug vorzubeugen, wird der Buchbetrag der Geschenkkarte nicht in Echtzeit eingelöst. Nach Erhalt muss die Authentifizierung mit echtem Namen abgeschlossen werden, bevor sie in Betrieb genommen werden kann.',
    pt_card_rule5: '5. Sie müssen die Authentifizierung mit Ihrem echten Namen durchführen, wenn Sie kostenlose Geschenkkarten erhalten. Sie müssen die Authentifizierung mit Ihrem echten Namen nicht durchführen, wenn Sie sie einlösen. Dies dient dazu, Veranstaltern die schnellere Gewinnung von Freunden auf niedrigerem Niveau zu erleichtern.',
    pt_card_rule6: '6. Das endgültige Auslegungsrecht für kostenlose Geschenkkarten liegt bei Bitisan.',
    pt_card_day: 'Tag',
    pt_card_title_tips: '2.000 Yuan Beförderungszuschuss erhalten',
    pt_invite_desc: 'Exklusiver QR-Code. Registrieren Sie sich über den QR-Code, um automatisch ein untergeordneter Freund zu werden. Geeignet zur Weiterleitung in verschiedene Gruppen oder Foren.',
    saveimage: 'Bild speichern',
    imagetips: 'Wenn Sie das Bild nicht normal herunterladen können, verwenden Sie bitte ein Screenshot-Tool, um einen Screenshot zu erstellen und ihn zu speichern!',
    pt_card_receivew_success: 'Herzlichen Glückwunsch! Die Geschenkkarte für die Partneraktion wurde erfolgreich erhalten! Bitte gehen Sie zu [Personal Center] -> [Card and Coupon Center], um dies zu überprüfen!',
    usepromotion: 'Werbematerialien verwenden',
    context_title: 'Exklusiver Einlösungscode für Aktionspartner',
    context_title1: 'Scannen, um mehr zu erfahren',
    context_title2: 'Mein Einladungscode',
    context_title3: 'Einladungsbild',
    pt_card_title: 'Partner-Werbekarte',
    copysuccess: 'Kopieren erfolgreich! ',
    copyerr: 'Kopieren fehlgeschlagen! Bitte manuell kopieren',
    noopening: 'Keine Optionskontrakte ausstehend',
    createSuccess: 'Erfolgreich erstellt',
    tradewarning1: 'Geben Sie bis zu 2 Dezimalstellen ein',
    tradewarning2: 'Der Bestellbetrag beträgt',
    tradewarning3: 'Geben Sie bis zu 8 Dezimalstellen ein',
    tradewarning4: 'Die Bestellmenge beträgt',
    tradeconfirmbuyin: 'Kauf bestätigen',
    tradeconfirmsellout: 'Verkauf bestätigen',
    foot:{

    aboutus1:'Über uns',
    aboutus: '<p>Nach dem Start des Mainnets im April 2019 stellte Binance Chain seine hohe Geschwindigkeit unter Beweis\
    und Hochdurchsatz-Designs. Sein Hauptaugenmerk liegt auf nativen dezentralen Anwendungen (\
    dApp) Binance Chain DEX (dezentraler Austausch), wurde getestet, um Millionen zu verarbeiten\
    Das Handelsvolumen in einem kurzen Zeitraum zeigt die geringe Latenz seiner Handelsmaschine.\
    Match-Funktion. </p>\
    <p>Flexibilität und Verfügbarkeit hängen oft eng mit der Leistung zusammen. Wenn es darum geht, die Ausgabe und den Handel digitaler Vermögenswerte zu erleichtern, bringt dieses Design auch \
    Teilweise eingeschränkt. Die lauteste Stimme in der Community hat gesehen Binance Chain fügt programmierbare Erweiterungsfunktionen hinzu oder fügt \
    Um es ganz klar auszudrücken: Es ist die Funktion von Smart Contracts und virtuellen Maschinen. Aufgrund der derzeit eingeschränkten Funktionalität der Binance Chain ist es für Emittenten und Eigentümer digitaler Vermögenswerte schwierig, neue hinzuzufügen.\
    Dezentralisieren Sie die Funktionalität in ihren Assets oder führen Sie jede Form von \
    Community-Management und Community-Aktivitäten. \
    Obwohl die Erwartungen an die Hinzufügung intelligenter Vertragsfunktionen zu Binance hoch sind\
    Chain, es sei eine "schwere Entscheidung" gewesen. Die Ausführung intelligenter Verträge kann DEXs verlangsamen und den Vermögenshandel unsicherer machen. Sogar dieser Kompromiss\
    Erträglich, die einfachste Lösung könnte darin bestehen, virtuelle\
    Maschine basierend auf dem aktuellen zugrunde liegenden Tendermint-Konsensprotokoll und dem Haupt-RPC\
    Binance-Chain-Schnittstelle. Allerdings wird diese Lösung die Lernkosten für die bestehende dApp-Community erhöhen und keine beliebte Lösung sein. \
    Hier schlagen wir das Konzept der parallelen Blockchain der Binance Chain vor, um hoch zu bleiben\
    Die Leistung von lokalem DEX bei gleichzeitiger freundlicher Unterstützung für Smart-Contract-Funktionen. \
    Nach dem Aufbau von zwei parallelen Blockchains im Binance-Chain-Ökosystem\
    Die Blockchain wird unabhängig funktionieren und verschiedene Dienste bereitstellen. Die neue Parallelkette wird Binance Smart Chain (im Folgenden BNBN genannt) heißen, während das bestehende Mainnet weiterhin Binance Chain (im Folgenden BNB genannt) heißen wird. \
    Das Design von BNBN folgt den folgenden Prinzipien:\
    Eigenständige Blockchain: Technisch gesehen ist BNBN eine eigenständige Blockchain und keine Second-Layer-Lösung. Die meisten grundlegenden technischen und geschäftlichen Funktionen des BSC sollten\
    Unabhängig, d. h. selbst wenn BNB für kurze Zeit stoppt, kann BNBN immer noch gut laufen. \
    Kompatibel mit Ethereum: Die erste praktische und weit verbreitete Smart-Contract-Plattform ist \
    Äther. Um relativ ausgereifte Anwendungen und Communities zu verbinden, wählt BSC\
    Um mit dem bestehenden Ethereum-Mainnet kompatibel zu sein. Das bedeutet, dass die meisten dApps, Ökosysteme\
    Teile und Werkzeuge sind ohne Modifikation oder BSC-kompatibel.\
    Nur geringfügige Änderungen; BNBN-Knoten erfordern nur ähnliche oder etwas höhere Hardware.\
    Betriebsspezifikationen und Bedienkenntnisse. Diese Implementierung sollte  bereitstellen\
    Platz für weitere Kompatibilität mit zukünftigen Versionen von BNBN und Ethereum\
    Konsens basierend auf Eigenkapitalversprechen und On-Chain-Management: Eigenkapitalbasierter Konsens\
    Das Abstecken (PoS) ist umweltfreundlicher und\
    Gemeinschaftsführung. Es ist zu erwarten, dass dieser Konsens besser sein wird.\
    Die Leistung ist besser als der PoW-Konsens, d. h. die Blockgenerierungszeit ist kurz und\
    Hohe Fähigkeit zur Transaktionsverarbeitung. Lokale kettenübergreifende Kommunikation: BNB und \
    BNBN wird nativ die kettenübergreifende Kommunikation zwischen zwei Blockchains unterstützen. Das\
    Das Kommunikationsprotokoll sollte zwei-\
    Art und Weise, dezentral und misstrauisch gegenüber Dritten. Es wird sich auf den Transfer konzentrieren.\
    Digitale Vermögenswerte zwischen BNB und BNBN, d. h. BEP2-Token und andere BNBN-Token\
    Später eingeführt. Das Protokoll sollte sich bis auf wenige Ausnahmen nicht übermäßig mit anderen in der Blockchain gespeicherten Informationen befassen. </p>\
    <p>Basierend auf den oben genannten Designprinzipien wird das Konsensprotokoll von BNBN implementiert\
    Folgende Ziele:\
    Die Blockzeit sollte kürzer als die Ethereum-Zeit sein, beispielsweise 5 Sekunden oder sogar weniger. \
    Es gibt eine begrenzte Zeit, um auf den Abschluss der Transaktion zu warten, beispielsweise etwa 1 Minute oder weniger. \
    Ohne Inflation Einkommen\
    Der Blockchain-Anteil stammt aus Bearbeitungsgebühren, die in Form von BNBN bezahlt werden.\
    .Seien Sie so kompatibel wie möglich mit Ethereum. \
    Es verfügt über einen On-Chain-Governance-Mechanismus, der auf einer Eigenkapitalzusage basiert. \
    Eigenkapitalbasiertes Eigenkapital-Ermächtigungszertifikat\
    Obwohl Proof of Work (PoW) nachweislich implementiert ist\
    Dezentrales Netzwerk, es ist nicht umweltfreundlich und erfordert viel \
    Anzahl der Akteure, die die Netzwerksicherheit gewährleisten. \
    Ethereum und mehrere andere Netzwerke wie MATIC Bor, TOMOChain, GoChain und xDAI,\
    Verwenden Sie den Proof of Authorization (PoA) oder seine Variationen in verschiedenen Szenarien, einschließlich\
    Testnet und Mainnet. PoA kann 51 % der Angriffe abwehren, was effektiver ist.\
    Verhindern Sie, dass einige byzantinische Knoten Böses tun. Wählen Sie PoA als Basis\
    Ein Konsens zu erreichen ist eine der idealen Möglichkeiten. \
    Inspiriert durch das oben Gesagte kombiniert BNBN DPoS und PoA, um einen Konsens zu erzielen. Lösung\
    Folgendes wird verwendet: \
    Blöcke werden von einer begrenzten Anzahl von Validatoren generiert, die abwechselnd generieren.\
    Auf PoA-Art blockiert, ähnlich der Clique-Konsens-Engine von Ethereum\
    Eine Reihe von Validatoren wurde ausgewählt und\
    Eliminierung der On-Chain-Governance auf der Grundlage einer Eigenkapitalzusage. \
    Während der Ursprungsblockierungsphase des Netzwerkstarts werden viele vertrauenswürdige Knoten\
    Fungiert als erster Satz von Validatoren. Nachdem die Blockproduktion begonnen hat, kann jeder\
    Sie können als Kandidat an der Wahl der Validatoren teilnehmen. Der Status der Eigenkapitalverpfändung bestimmt, welche 21 Knoten mit dem meisten Eigenkapital die nächste Gruppe bilden.\
    Validator. Solche Wahlen und Ausscheidungen finden alle 24 Stunden statt. \
    BNBN ist das Symbol für die BNB-Eigenkapitalverpfändung. \
    Um die Zusammenarbeit mit dem Ethereum-Konsensprotokoll aufrechtzuerhalten (einschließlich\
    Bevorstehendes Upgrade), BNBN hat sich entschieden, sich bei der Aktienverwaltung auf BNB zu verlassen. Es gibt ein Modul, das dem BNBN-Eigenkapital gewidmet ist.\
    Wetten Sie auf BNB. Es wird BNB-Eigenkapitalzusagen von BNBN-Inhabern akzeptieren und berechnen\
    Die Menge der Knoten mit den größten Einsätzen. Immer wenn UTC Null erreicht, sendet BNB \
    Überprüfbare kettenübergreifende "ValidatorSetUpdate"-Nachricht, die BNBN benachrichtigt, seinezu aktualisieren\
    Validator-Set. Bevor ein neuer Block generiert wird, prüfen vorhandene BNBN-Validatoren regelmäßig, ob  vorhanden ist.\
    Die Nachricht ValidatorSetUpdate wird an BNB weitergeleitet.\
    Wenn dies der Fall ist, aktualisieren sie den Validatorsatz nach einer bestimmten Höhe (d. h. einem vordefinierten Blockintervall). \
    Wenn BNB beispielsweise alle 5 Sekunden einen Block generiert und der Prüfzeitraum 240 Blöcke beträgt, ist der aktuelle\
    Der Validatorsatz wird innerhalb von 1200 Sekunden (20 Minuten) überprüft und für den nächsten Zyklus aktualisiert. \
    Sicherheit und Endgültigkeit\
    Wenn man bedenkt, dass mehr als die Hälfte der ½*N+1-Validatoren ehrlich und vertrauenswürdig sind, funktionieren PoA-basierte Netzwerke im Allgemeinen sicher und korrekt. \
    In einigen Fällen können byzantinische Validatoren das Netzwerk jedoch immer noch angreifen, beispielsweise durch einen Klonangriff. Um sicherzustellen, dass BNBN die gleiche Höhe hat\
    Aus Gründen der BNB-Sicherheit empfehlen wir BNBN-Benutzern, zu warten, bis der Block empfangen wird.\
    Bestätigt durch mehrere ⅔*N+1 verschiedene Validatoren. Auf diese Weise kann BNBN erreichen \
    Eine Sicherheitsstufe ähnlich der von BNB, die Byzanz weniger als 1/3*N tolerieren kann.\
    Validator. Wenn für 21 Validatoren die Blockzeit 5 Sekunden beträgt, dann ⅔* N+1\
    Verschiedene Validatoren benötigen (2/3*21+1)*5=75 Sekunden zur Bestätigung. Bei jeder größeren BNBN-Anwendung muss wahrscheinlich ⅔*N+1 gewartet werden, um ein relativ sicheres Endergebnis zu gewährleisten. \
    Dies wird später im Abschnitt Eigenkapitalverpfändung und -verwaltung vorgestellt. Diese Strafe\
    Durch diesen Mechanismus werden bösartige Validatoren in sehr kurzer Zeit entlarvt.\
    , und macht Klonangriffe sehr schwierig durchzuführen oder sehr unwirtschaftlich, selbst wenn\
    implementieren. Durch diesen Strafmechanismus genügen ½*N+1 oder weniger Blöcke, um zu erfüllen.\
    Endgültigkeit der meisten Transaktionen. \
    Alle BNB-Validatoren im aktuellen Validator-Set erhalten Einnahmen aus den gezahlten Gebühren.\
    Da BNB kein inflationärer Token ist, generiert es keine Mining-Einnahmen.\
    Wie zum Beispiel die Bitcoin- und Ethereum-Netzwerke. Bearbeitungsgebühren sind das Haupteinkommen\
    Validator. Da BNB auch ein Utility-Token für andere Anwendungen ist\
    Validatoren profitieren weiterhin von den anderen Vorteilen, die das Halten von BNBN mit sich bringt. </p>',
    Neuling: '',
    newbie1: 'Anfängerleitfaden',
    commonproblem:'',
    commonproblem1: 'Häufig gestellte Fragen',
    Information: '',
    information1: 'Währungsinformationen',
    Haftungsausschluss: '',
    Haftungsausschluss1: 'Haftungsausschluss',
    Datenschutzklausel: '',
    Privacyclause1: 'Datenschutzklausel',
    Vereinbarung: '',
    Agreement1:'Benutzervereinbarung',
    },
    Handelsinfo: {
    emaildone: 'E-Mail wurde authentifiziert',
    emailundo: 'E-Mail nicht authentifiziert',
    teldone: 'Mobiltelefonnummer wurde verifiziert',
    telundo: 'Handynummer nicht verifiziert',
    idcarddone: 'Ausweis wurde authentifiziert',
    idcardundo: 'Ausweis nicht authentifiziert',
    Exchangetimes: 'Anzahl der Transaktionen',
    Preis: 'Preis',
    num: 'Menge',
    paymethod: 'Zahlungsmethode',
    Exchangelimitamount: 'Transaktionslimit',
    Standort: 'Standort',
    location_text: 'China',
    Exchangeperiod: 'Transaktionszeitraum',
    Minute: 'Minute',
    amounttip: 'Bitte geben Sie den Betrag ein',
    numtip: 'Bitte geben Sie die Menge ein',
    Bemerkungstipp: 'Teilen Sie ihm Ihr Anliegen mit',
    Bemerkungtitel: 'Bemerkungsinformationen',
    Exchangetitle: 'Transaktionsnotizen',
    Exchange_tip1: 'Nachdem Sie eine Transaktionsanforderung initiiert haben, wird die digitale Währung gesperrt und von der Plattform geschützt. Wenn Sie ein Verkäufer sind, können Sie nach dem Einleiten einer Transaktionsanforderung eine Aufladung vornehmen und auf die Zahlung des Käufers warten. Käufer zahlen innerhalb der Zahlungsfrist. Sie geben die auf dem Treuhandkonto hinterlegte digitale Währung nach Zahlungseingang frei. ',
    Exchange_tip2: 'Bitte lesen Sie vor dem Handel die "Plattform-Netzwerk-Servicebedingungen" und Hilfedokumente wie FAQs und den Transaktionsleitfaden. ',
    Exchange_tip3: 'Vorsicht vor Betrügern! Bitte überprüfen Sie vor dem Handel die vom Benutzer erhaltenen Bewertungen und schenken Sie neu erstellten Konten mehr Aufmerksamkeit. ',
    Exchange_tip4: 'Bitte beachten Sie, dass Rundungen und Preisschwankungen den endgültigen Betrag der gehandelten digitalen Währung beeinflussen können." Der von Ihnen eingegebene Festbetrag bestimmt den Endbetrag, und der Betrag in der digitalen Währung wird gleichzeitig mit der Anforderung aus dem Live-Wechselkurs berechnet. ',
    Exchange_tip5: 'Treuhanddienste schützen Käufer und Verkäufer von Online-Transaktionen."Im Streitfall werden wir alle bereitgestellten Informationen auswerten und die hinterlegte digitale Währung an ihren rechtmäßigen Eigentümer übergeben. ',
    warning1: 'Geben Sie bis zu 2 Dezimalstellen ein',
    warning2: 'Der Bestellbetrag beträgt',
    warning3: 'Geben Sie bis zu 8 Dezimalstellen ein',
    warning4: 'Die Bestellmenge beträgt',
    bestätigenbuyin: 'Kauf bestätigen',
    bestätigensellout: 'Verkauf bestätigen',
    buyin: 'kaufen',
    Ausverkauf: 'Verkaufen',
    warning5: 'Bitte füllen Sie die Bestellung nach Bedarf aus',
} }