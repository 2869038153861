//意大利
export default {
    // Messaggi di errore
    error1: 'Errore di sistema, contattare il supporto clienti',
    error2: 'Sessione scaduta, effettua nuovamente l\'accesso!',
    
    meun1: 'Home',
    meun2: 'Tendenze di mercato',
    meun3: 'Acquisto BNBN',
    meun4: 'Compra minatori',
    meun5: 'Promozione rapida',
    meun6: 'Whitepaper',
    meun7: 'Annunci',
    meun8: 'Accedi',
    meun9: 'Registrati',
    meun10: 'Portafoglio',
    meun10_1: 'Conto spot',
    meun10_2: 'Conto contratti U-based',
    meun10_3: 'Conto contratti token-based',
    meun10_4: 'Contratti al secondo',
    meun10_5: 'Conto investimenti',
    meun10_6: 'Cronologia portafoglio',
    meun11: 'Ordini',
    meun11_1: 'Ordini di acquisto',
    meun11_2: 'Ordini di mining',
    meun12: 'Assistenza clienti',
    meun12_1: 'Informazioni assistenza',
    meun12_2: 'Numero assistenza',
    meun12_3: 'Email assistenza',
    meun13: 'Buono',
    meun13_2: 'Riscatta buono',
    meun13_3: 'Inserisci codice buono',
    meun13_4: 'Annulla',
    meun13_6: 'Riscatta',
    meun13_7: 'Annullamento riuscito',
    meun13_1: 'Gestione account',
    meun13_5: 'Esci dal login',
    meun14_1: 'Lingua',
    meun14_3: 'Scegli lingua',
    
    scan: 'Scansione QR per iOS & Android',
    footmeun1: 'Info',
    footmeun1_1: 'Chi siamo',
    footmeun1_2: 'Unisciti a noi',
    footmeun1_3: 'Annunci della piattaforma',
    footmeun1_4: 'Whitepaper',
    footmeun2: 'Centro Assistenza',
    footmeun2_1: 'Guida per principianti',
    footmeun2_2: 'Domande frequenti',
    footmeun2_3: 'Guida alla negoziazione',
    footmeun2_4: 'Informazioni sulle monete',
    footmeun3: 'Termini e condizioni',
    footmeun3_1: 'Esclusioni di responsabilità',
    footmeun3_2: 'Informativa sulla privacy',
    footmeun3_3: 'Contratto con l\'utente',
    footmeun3_4: 'Spiegazione delle tariffe',
    footmeun4: 'Contattaci',
    footmeun4_1: 'Email assistenza clienti ',
    footmeun4_2: 'Collaborazioni commerciali',
    footmeun4_3: 'Richiesta di elenco criptovaluta',
    footmeun4_4: 'Segnalazioni e reclami',
    foottext: 'Piattaforma globale di acquisto BNBN',
    countdown: 'Attività conclusa',
    indexTxt1: 'Acquisto globale in anteprima di BNBN',
    'Elenco criptovaluta': '',
    footmeun4_4: 'Segnalazioni e reclami',
    foottext: 'Piattaforma globale di acquisto BNBN',
    countdown: 'Attività conclusa',
    indexTxt1: 'Acquisto globale in anteprima di BNBN',
    indexTxt2: 'Acquisto globale in anteprima di BNBN',
    indexTxt3: 'Conto alla rovescia all\'avvio',
    indexLabel1: 'Tendenze di mercato',
    indexVal1: 'Prezzi in tempo reale delle criptovalute',
    indexLabel2: 'Acquisto BNBN',
    indexVal2: 'Scambia una singola risorsa digitale direttamente',
    indexLabel3: 'Acquista macchine per mining BNBN',
    indexVal3: 'Mining di criptovaluta BNBN',
    indexLabel4: 'Promozione veloce',
    indexVal4: 'Benefici per gli acquirenti dell\'acquisto',
    
    indexh5: 'Sicurezza finanziaria di livello elevato',
indexh6: 'Ricarica e prelievo istantanei',
indexh7: 'Servizi a livello globale',
indexh8: 'Progetti selezionati con cura',
indexp1: 'BITISAN è stato fondato da un gruppo di pionieri di Bitcoin e appassionati di tecnologia, con membri chiave provenienti da aziende leader quali Google, Microsoft, Alibaba e Tencent, caratterizzati da profonde competenze in Ricerca & Sviluppo e cospicua esperienza nell\'operazione di prodotti internet.',
indexp2: 'BITISAN si colloca come fornitore di servizi infrastrutturali per la blockchain, dedicandosi alla creazione di una piattaforma di scambio di asset crittografici di qualità per gli utenti a livello mondiale. Aderendo ai principi cardine di "Non fare il male", BITISAN garantisce onestà, giustizia ed entusiasmo nel servire i clienti, mantenendo un atteggiamento aperto verso ogni partnership/progetto che possa giovare agli interessi fondamentali degli utenti.',
indexp3: 'Sistema completo di gestione del rischio finanziario e di sicurezza anti-furto, con portafogli cold/hot e sistema multi-firma per assicurare la sicurezza dei fondi',
indexp4: 'Ricariche e prelievi completati in tempi brevissimi, fino a 3 minuti, con revisione manuale 24 ore al giorno per tutelare i clienti e non farli perdere le migliori opportunità d\'investimento',
indexp5: 'Copertura globale della rete dei servizi commerciali per supportare gli investimenti negli asset crittografici a livello planetario e consentire scambi con utenti in tutto il mondo',
indexp6: 'Selezione rigorosa di progetti di criptovalute di alta qualità per filtrare l\'80% dei progetti ad alto rischio',
market: 'Informazioni di mercato',
wallet1: 'Recharge Quantity',
wallet2: 'The current USDT payment blockchain protocol is TRC20',
wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
wallet4: 'Order Number',
wallet5: 'Payment amount',
wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
wallet7: 'Payment timeout, please initiate payment again!',
    usdt: 'USDT',
    platform: 'BNBN',
    usdt1: 'Saldo disponibile',
    usdt2: 'Saldo bloccato',
    platform1: 'Ritiro',
    platform2: 'Carica',
    platform3: 'Acquisto',
    transaction: 'Dettagli transazione',
    transaction0: 'Numero progressivo',
    transaction1: 'Numero transazione',
    transaction2: 'Quantità transazione',
    transaction3: 'Ora transazione',
    transaction4: 'Carica USDT',
    transaction5: 'Indirizzo di ricarica',
    transaction6: 'Ritiro USDT',
    transaction7: 'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
    transaction8: 'Indirizzo di ritiro',
    transaction9: 'Quantità di ritiro',
    transaction10: 'Annulla',
    transaction11: 'Conferma',
    transaction12: 'Acquisto',
    transaction13: 'La quantità di acquisto si riferisce alla quantità di USDT da acquistare',
    transaction14: 'Quantità di acquisto',
    transaction15: 'La quantità di acquisto deve essere maggiore di 50',
    transaction16: 'La quantità di ritiro deve essere maggiore di 10',
    transaction17: 'Tipo di valuta',
    transaction18: 'Nessuna transazione disponibile al momento',
    transaction19: 'Seleziona un indirizzo di ricarica',
    transaction20: 'Per favore clicca di nuovo il pulsante tra 10 minuti',
    transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
  transaction24: 'The amount of exchange must be greater than 0',
  transaction25: 'Il ritiro richiede la detrazione di una commissione',
    //锁仓挖矿
    mining1: 'Acquisto',
    mining2: 'Prezzo d\'acquisto',
    mining3: 'Guadagno giornaliero',
    mining4: 'Giorni di blocco',
    mining5: 'Livello d\'acquisto',
    mining6: 'Rendimento totale dell\'acquisto',
    mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
    //邀请
    inviteusers1:'Link di invito',
    inviteusers2:'Numero di invitati',
    inviteusers3:'Codice di invito',
    inviteusers4:'Utente invitato',
    inviteusers5:'Data di invito',
    inviteusers6: 'Copiato con successo',
    inviteusers7:'Nessun utente invitato',
    inviteusers8:'Copia',
    inviteusers10:'Number of subscribers',
    inviteusers11:'Is it active',
    //订单
    order1: 'Ordine di acquisto',
    order2: 'Ordine di investimento',
    order3: 'Numero di ordine di acquisto',
    order4: 'Quantità di acquisto',
    order5: 'Ora di acquisto',
    order7: 'Numero di ordine di investimento',
    order8: 'Quantità bloccata',
    order9: 'Quantità di bonus',
    order10: 'Ora di inizio',
    order11: 'Ora di fine',
    order12: 'Nessuna informazione sull\'acquisto disponibile',
    order13: 'Nessuna informazione sugli ordini di investimento disponibile',
    
    //个人中心
    personal1: 'Informazioni personali',
    personal2: 'Stato della verifica reale',
    personal3: 'Verificato',
    personal4: 'In corso di verifica',
    personal5: 'Non verificato - clicca per verificare',
    personal6: 'Verifica fallita - clicca per verificare',
    personal7: 'Nome utente',
    personal8: 'Indirizzo email',
    personal9: 'Numero di telefono',
    personal10: 'Livello VIP',
    personal11: 'Membro normale',
    personal12: 'Codice di invito',
    personal13:'Informazioni di verifica',
    personal14:'Carica le relative informazioni di verifica dell\'identità',
    personal15:'Modifica password',
    personal16:'Password originale',
    personal17:'Nuova password',
    personal18:'Conferma password',
    personal19:'Inserisci la password',
    personal20:'La lunghezza della password non può essere inferiore a 6 caratteri',
    personal21:'Inserisci nuovamente la password',
    personal22:'Le due password inserite non coincidono',
    authentication1:'Aggiungi informazioni di verifica',
    authentication2:'Conferma caricamento',
    authentication3:'È possibile caricare solo file JPG/PNG e la dimensione non deve superare i 2 MB',
    authentication4:'Carica file',
    authentication5:'Aggiunta utente e caricamento file avvenuto con successo',
    authentication6:'Aggiunta utente e caricamento file non riuscito',
    authentication7:'L\'immagine del profilo deve essere in formato JPG!',
    authentication8:'La dimensione dell\'immagine del profilo non deve superare i 2 MB!',
    authentication9: 'Numero di file superiore a 5',
    
    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
    // 登录
    account0:'Account',
    account: 'Accesso al conto BNBNCOIN',
    phone: 'Numero di telefono',
    email: 'Indirizzo email',
    sign: 'Accedi',
    password: 'Password',
    password2: 'Password',
    password3: 'Hai dimenticato la password',
    account2: 'Crea un account',
    logErr: 'Inserisci l\'account',
    logErr2: 'Inserisci la password',
    logErr3: 'La lunghezza della password deve essere almeno di 6 caratteri',
    logSuccess: 'Accesso avvenuto con successo',
    resetpassword:'Reimposta password',
    reset:'Reimposta',
    // 注册
    account3: 'Crea un account personale',
    phone1: 'Registrazione tramite telefono',
    email1: 'Registrazione tramite email',
    email2: 'Indirizzo email',
    code: 'Codice di verifica SMS',
    code2: 'Invia codice di verifica',
    code3: 'Codice di verifica',
    password4: 'Conferma password',
    invite: 'Codice invito',
    agree: 'Ho letto e accetto',
    agreement: 'Accordo utente',
    register: 'Registrati',
    regErr: 'Inserisci il codice di verifica SMS',
    confirmpwdtip: 'Inserisci la conferma della password',
    confirmpwderr: 'Le due password non corrispondono!',
    
    // Sezione dimenticata password
    wjtab: 'Ripristina password del telefono',
    wjtab2: 'Ripristina password email',
    wjlabel: 'Nuova password',
    wjerr: 'Inserisci una nuova password',
    Tailpage:'Pagina di coda',
	homepage:'home page',
	lastpage:'ultima pagina',
	nextpage:'pagina successiva',
	off:'chiudi',
	problem:'In caso di problemi, tra cui autenticazione del nome reale o depositi che non sono ancora arrivati, si prega di consultare il servizio clienti in modo tempestivo',
    // Annunci comuni
    announcementtitle: 'Titolo dell\'annuncio',
    announcement: '<p>Successivamente al lancio della blockchain principale nel aprile 2019, Binance Chain ha dimostrato prestazioni ad alta velocità e elevate capacità di elaborazione. Il suo focus principale, l’applicazione decentralizzata nativa (\"dApp\") Binance DEX, è stata testata per gestire volumi di transazioni milioni in tempi brevi, evidenziando le capacità di matching con bassa latenza del suo motore di trading.</p>\
    La flessibilità e l’usabilità spesso procedono di pari passo con le prestazioni. Mentre si concentra sull’offrire luoghi comodi per l’emissione e lo scambio di asset digitali, tale progettazione porta anche alcuni limiti. La richiesta più pressante dalla comunità è sempre stata quella di vedere \
    funzionalità estendibili programmabili introdotte sulla catena di Binance, o in altre parole, funzionalità di smart contract e macchine virtuali. \
    A causa delle funzionalità attualmente limitate di Binance Chain, gli emittenti e i proprietari di asset digitali hanno difficoltà ad aggiungere nuove funzionalità distribuite ai loro asset o ad introdurre qualsiasi forma di \
    gestione e attività comunità-oriented.\
    Anche se le aspettative per l’integrazione dei funzionali di smart contract su Binance Chain sono alte, questa rappresenta una \"decisione difficile\". L’esecuzione di smart contract potrebbe rallentare la velocità di DEX e aumentare l’incertezza nei trasferimenti di asset. Anche se questo compromesso potrebbe essere tollerato, la soluzione più ovvia da pensare sarebbe quella di implementare una macchina virtuale \
    basata sul protocollo di consenso Tendermint sottostante e sull’interfaccia RPC principale di Binance Chain. Tuttavia, tale approccio aumenterebbe il costo di apprendimento per la comunità esistente di dApp e probabilmente non diventerebbe una soluzione popolare. \
    Qui proponiamo il concetto di una blockchain parallela di Binance Chain per mantenere alte prestazioni del DEX nativo e allo stesso tempo supportare amichevolmente le funzionalità di smart contract.',
    
    // Laboratorio di innovazione
    labTxt: 'Laboratorio Bitisan • Condividi risultati innovativi',
    labTxt2: 'Open lab per un vantaggio congiunto tra investitori, proponenti di progetti ed exchange',
    labTab1: 'Tutti',
    labTab2: 'Prossimamente',
    labTab3: 'In corso',
    labTab4: 'Distribuzione in corso',
    labTab5: 'Completato',
    tokendistribute: "Distribuzione token in corso",
    activitytype: "Tipo di sottoscrizione",
    labStart: 'Data di inizio',
    labEnd: 'Data di fine',
    labStartPlaceholder: 'Seleziona data di inizio',
    labDetail: 'Visualizza dettagli',
    labSpeen: "Progresso",
    labNumber: "Quantità totale dell'attività",
    labType1: 'Prima elencazione (acquisto rapido)',
    labType2: 'Prima elencazione (spartizione uniforme)',
    labType3: 'Scomposizione posizione',
    labType4: 'Sottoscrizione libera',
    labType5: 'Acquisto minatore cloud',
    labType6: 'Rilascio vincolato',
    baseinfo: 'Informazioni di base',
    ruledetail: "Descrizione tipo",
    releaseType: "Tipo di rilascio",
    releaseType0: "Rilascio uguale",
    releaseType1: "Rilascio proporzionale",
    lock: " Rilascio a scadenza",
    releaseDetail: "Dettagli di rilascio",
    lockday: " Giorno(i)",
    lockweek: " Settimana(e)",
    lockmonth: " Mese(i)",
    lockyear: " Anno(i)",
    releasePercent: "Percentuale di rilascio",
    lockFee: "Soglia di posizione vincolata",
    releaseTimes: "Numero di rilasci multipli",
    times: "Volte",
    publishprice: "Prezzo di sottoscrizione",
    holdcoin: "Moneta richiesta per la posizione",
    limittimes: "Numero massimo di acquisti per persona",
    limitamount: "Quantità massima di acquisto per persona",
    unlimited: "Illimitato",
    leveloneCount: "Numero di amici di primo livello necessari",
    alreadyamount: "Quantità già acquistata",
    leftamount: "Quantità rimanente",
    myalreadyholdamount: "La mia posizione partecipativa",
    alreadyholdamount: "Posizione totale partecipativa all'attività",
    currentdivided: "La mia posizione attuale può suddividere circa",
    holdtips: "Per partecipare a tipi di attività come 'Scomposizione posizione', è necessario tenere la posizione bloccata fino alla fine dell'attività.",
    inputholdamount: "Inserisci quantità di posizione da bloccare",
    pleaseinputamount: "Inserisci la quantità da convertire",
    pleaseinputholdamount: "Inserisci la quantità di posizione da bloccare",
    inputamount: "Inserisci la quantità da scambiare",
    inputminingamount: "Inserisci il numero di minatori da acquistare",
    attend: "Partecipa ora",
    attention: "Attenzione",
    attentiontxt1: "1. Dopo aver inviato la richiesta di partecipazione alle attività di tipo ['Scomposizione posizione', 'Sottoscrizione libera', 'Acquisto minatore cloud', 'Vincolo posizione'], gli asset verranno congelati e scongelati o sbloccati al termine dell'attività.",
    attentiontxt2: "2. Dopo aver partecipato alle attività di tipo ['Scomposizione posizione', 'Sottoscrizione libera', 'Acquisto minatore cloud', 'Vincolo posizione'], gli utenti non possono revocare la loro richiesta di partecipazione.",
    attentiontxt3: "3. In caso di annullamento dell'attività da parte del proponente o per cause di forza maggiore, gli asset congelati verranno restituiti tramite la stessa via.",
    attentiontxt4: "* I diritti finali di interpretazione di questa attività appartengono all'ufficio ufficiale di Bitisan (WWW.BITISAN.NET).",
    
    // Account contratto valutario
    transfer: 'Trasferimento fondi',
    placeholder: 'Cerca moneta',
    bith1: 'Trading di contratti',
    bith2: 'Equity del conto',
    bith3: 'P/L non realizzato',
    bith4: 'Margine disponibile',
    bith5: 'Margine utilizzato',
      bith6: 'Margin bloccato',
      bith7: 'Conto contratti',
      // Conto spot
      btn1: 'Ricarica',
      btn2: 'Preleva',
      btn3: 'Trasferisci',
      btn4: 'Carica criptovaluta',
      btn5: 'Estrai criptovaluta',
      totalAssets: 'Valore totale convertito',
      th1: 'Nome della criptovaluta',
      th2: 'Asset disponibili',
      th3: 'Asset congelati',
      th4: 'Asset in fase di rilascio',
      th5: 'Azione',
      // Ricarica
      czTxt: "Ricarica criptovaluta ",
      czTxt2: "Criptovaluta ",
      czTxt3: "Rete di trasferimento ",
      czTxt4: "Saldo disponibile ",
      czTxt5: "Indirizzo di prelievo ",
      czTxt6: "Quantità di prelievo ",
      czTxt7: "Quantità accreditata ",
      czTxt8: "Commissione ",
      czTxt9: "Suggerimenti ",
      czTxt10: "Importo minimo di ricarica: ",
      czTxt10_1: "Ricariche inferiori all'importo minimo non verranno registrate. ",
      czTxt11: "Non effettuare ricariche verso l'indirizzo sopra con asset diversi dalla criptovaluta, altrimenti gli asset saranno irrecuperabili. ",
      czTxt12: "Dopo aver ricaricato l'indirizzo sopra, è necessario che la rete blockchain intera confermi la transazione, tipicamente richiedendo 3 conferme della rete principale prima dell'accreditamento. ",
      czTxt13: "Il tuo indirizzo di ricarica non cambierà spesso e può essere utilizzato per ricariche multiple; nel caso di eventuali modifiche, cercheremo di informarti tramite un annuncio sul sito o via email. ",
      czTxt14: "Assicurati che il tuo computer e browser siano sicuri per evitare la modifica o la divulgazione delle informazioni. ",
      czTxt15: "Cronologia ricariche ",
      czTxt16: "Per garantire la sicurezza dei fondi, in caso di cambiamenti nella politica di sicurezza del tuo account, modifica password o uso di nuovi indirizzi per prelievi, eseguiremo una verifica manuale dei prelievi; si prega di attendere pazientemente la chiamata o la mail del nostro staff. ",
      czTxt17: "La quantità minima di prelievo è: ",
      czth1: 'Ora di prelievo',
      czth2: 'Da',
      czth3: 'A',
      czth4: 'Quantità scambio istantaneo',
      czth5: 'Rapporto di conversione',
      czth6: 'Quantità convertita',
      placeholder2: 'Inserisci',
      placeholder3: 'Seleziona',
      czEmpty: 'Nessun record',
      logintip: 'Effettua prima l\'accesso!',
      cztip: 'Non hai ancora richiesto un indirizzo di ricarica, clicca sul pulsante per ottenerlo',
      czget: 'Ottieni indirizzo di ricarica',
      czplaceholder: 'Seleziona il protocollo',
      // Account contratti secondi
      mAccount: "Conto contratti secondi ",
      // Cronologia portafoglio
      btn6: 'Cerca',
      wtTxt: 'Intervallo di tempo',
      wtTxt2: 'Tipo di operazione',
      wtth1: 'Ora dell\'operazione',
      wtth2: 'Tipo',
      wtth3: 'Criptovaluta',
      wtth4: 'Quantità',
      wtth5: 'Commissione dovuta',
      wtth6: 'Commissione detratta',
      wtth7: 'Commissione pagata',
      wtth8: 'Stato',
      // Sicurezza account
      nav1: 'Sicurezza account',
      nav2: 'Mie inviti',
      nav3: 'Mie carte promozionali',
      acTxt: 'Livello di sicurezza',
      acbtn1: 'Non verificato',
      acbtn2: 'Associa',
      acbtn3: 'Modifica',
      acbtn4: 'Verificato',
      acbtn5: 'Associato',
      acbtn6: 'Imposta',
      acbtn7: 'In fase di revisione',
      acbtn8: 'Riprova',
      acTxt2: 'Verifica nome reale',
      acTxt3: 'Email',
      acTxt4: 'Telefono',
      acTxt5: 'Password di accesso',
      acTxt6: 'Password finanziaria',
      acTxt7: 'Google Authenticator',
      acTxt8: 'Imposta password finanziaria',
      acTxt9: 'Cambia password finanziaria',
      acTxt10: 'Recupera password finanziaria',
      acDesc2: 'Per garantire la sicurezza del tuo account, completa la verifica nome reale prima di effettuare operazioni di trading! ',
      acDesc3: 'Associa email',
      acDesc4: 'Associa telefono',
      acDesc5: 'Utilizzato per accedere alla piattaforma',
      acDesc6: 'Quando le transazioni finanziarie vengono modificate, è necessario verificare la password finanziaria',
      acDesc7: 'Associa Google Authenticator',
      acDesc8: 'Google Authenticator già associato',
      acDesc9: 'Annulla associazione',
      acTit: 'Associazione telefono',
      acTit2: 'Associazione email',
      acTit3: 'Associazione Google',
      acTit4: 'Annulla associazione Google',
      mailtip: 'Inserisci l\'email',
      emailerr2: 'Formato email non corretto, riprova per favore',
      agreementtip: 'Leggi e accetta l\'accordo utente',
      acverify: "Verifica non superata ",
      again: "Riprova ",
      verifiedmsg: 'Effettua prima la verifica nome reale',

    
    // 更改登录密码
    chTxt: 'Cambiare la password di accesso',
    chTxt2: 'Associazione tramite scansione del codice QR',
    chTxt3: 'Inserisci il codice di verifica',
    chTxt4: 'Vecchia password del conto',
    chTxt5: 'Nuova password del conto',
    chTxt6: 'Conferma nuova password',
    chTxt7: 'Password dimenticata',
    chTxt8: 'Cambia verifica cellulare',
    chTxt9: 'Vecchia password di login',
    chTxt10: 'Nuova password di login',
    chTxt11: 'Codice di verifica email',
    chTxt12: 'Codice di verifica cellulare',
    telerr: 'Numero di telefono non corretto',
    emailerr: 'Indirizzo email non valido',
    codeerr: 'Codice di verifica errato',
    save: 'Salva',
    chtip: 'Inserisci la tua password originale',
    chtip2: 'Inserisci una nuova password di accesso di almeno 6 caratteri',
    chtip3: 'La nuova password di accesso non coincide',
    chtip4: 'Inserisci la password del conto corretta',
    chtip5: 'La password deve contenere almeno 6 caratteri',
    chtip6: 'Inserisci una nuova password di almeno 6 caratteri',
    chtip7: 'Le password non coincidono',
    chtip8: 'Inserisci la password del conto',
    chtip9: 'Inserisci il numero di telefono',
    chtip10: 'Inserisci la password di accesso',
    realnametip: 'Inserisci il tuo nome reale',
    idcardtip: 'Inserisci il tuo numero di carta d\'identità',
    
    // 收款 方式
    clTxt: 'Imposta i tuoi metodi di pagamento assicurandoti di utilizzare un account verificato con il tuo nome reale',
    clTxt2: 'Aggiungi metodo di pagamento',
    edit: 'Modifica',
    delete: 'Elimina',
    name: 'Nome',
    clTxt3: 'Numero ID',
    clTxt4: 'Numero IBAN',
    clTxt5: 'Tutti i metodi di pagamento',
    clLink1: 'Nome dell\'account',
    clLink2: 'Modifica metodo di pagamento',
    clLink3: 'Dettagli di pagamento (opzionale)',
    clplaceholder1: 'Inserisci il tuo nome completo',
    clplaceholder2: 'Inserisci la password del tuo conto bancario',
    clplaceholder3: 'Inserisci i dettagli di pagamento',
    cltip: 'Avviso speciale',
    cltip2: 'Assicurati di aggiungere il tuo numero di carta di credito per effettuare pagamenti istantanei. Non inserire informazioni relative ad altre banche o metodi di pagamento. Devi specificare le informazioni di pagamento/ricezione della banca selezionata.',
    cltip3: 'Suggerimento: Quando vendi criptovalute, il metodo di pagamento scelto verrà mostrato all\'acquirente. Verifica che le informazioni siano accurate e usa un conto di ricezione coerente con l\'identità verificata sulla piattaforma Binance.',
    clTxt6: 'Opzionale',
    upload: 'Carica',
    uploadTip: '(Supporta formati JPG/JPEG/PNG/BMP, dimensione inferiore a 1MB)',
    
    // 我的推广卡
    tgbtn: 'Scambia codice promozionale',
    tgth1: 'Nome carta',
    tgth2: 'Codice promozionale',
    tgth3: 'Valuta sulla carta',
    tgth4: 'Importo sulla carta',
    tgth5: 'Totale',
    tgth6: 'Già convertito',
    tgth7: 'Ora di riscossione',
    tgbtn2: 'Materiale promozionale',
    tgbtn3: 'Converti subito',
    tgplaceholder: 'Inserisci il codice promozionale',
    exchangesuccess : "Conversione completata! Vai alla gestione patrimonio per visualizzare il saldo! ",
    // 我的邀请
    inth1: 'Utenti invitati',
    inth2: 'Data di registrazione',
    inth3: 'Stato di verifica',
    // 我的广告
    adTxt: 'Miei annunci',
    adTxt2: 'Quando la quantità minima di transazione annuncio più le commissioni è maggiore della quantità rimanente, l\'annuncio viene automaticamente rimosso',
    adbtn: 'Cancella condizioni',
    adbtn2: 'Metti in vendita',
    adbtn3: 'Rimuovi',
    adth1: 'Numero annuncio',
    adth2: 'Tipo di annuncio',
    adth3: 'Limiti ordine',
    adth4: 'Quantità rimanente',
    adtip: 'Sei sicuro di voler eliminare?',
    num_text1: 'Inserisci la quantità di',
    num_text2: 'di cui hai bisogno',
    // 当前委托
    wTxt: 'Spot',
    wTxt2: 'Ordini attivi',
    wTxt3: 'Pari di trading',
    wTxt4: 'Direzione',
    wth1: 'Ora',
    wth2: 'Direzione',
    wth3: 'Prezzo',
    wth4: 'Eseguito',
    wth5: 'Importo eseguito',
    wth6: 'Tipo di ordine',
    wth7: 'Prezzo trigger',
    wth8: 'Prezzo di esecuzione',
    wth9: 'Quantità dell\'ordine',
    wth10: 'Guadagno/Perdita liquidato',
    wth11: 'Stato dell\'ordine',
    wth12: 'Apri/Chiudi posizione',
    wth13: 'Mantenimento marginale',
    wbtn: 'Annulla ordine',
    wtip: 'Suggerimento di annullamento ordine',
    wtiptxt: 'Confermare l\'annullamento dell\'ordine',
    
    // 赚币
    zbplaceholder: 'Inserisci il numero d\'ordine per iniziare la ricerca',
    zbtab1: 'Piani di investimento periodico',
    zbtab2: 'Cronologia degli investimenti periodici',
    zbth1: 'Periodo di investimento',
    zbth2: 'Attivato/Disattivato',
    zbth3: 'Importo investito complessivo',
    zbth4: 'Quantità detenuta',
    zbth5: 'Costo medio',
    zbth6: 'Profitto non realizzato',
    zbth7: 'Data prossimo investimento',
    zbth8: 'Data creazione piano',
    
    // 当前委托-1
    etTxt: 'Centro annunci',
    etTxt2: 'Condividi tramite scansione',
    etmenu1: 'Criptovalute',
    etmenu2: 'Aggiornamenti al server Bitisan',
    
    // 当前委托-3
    et2menu1: 'Introduzione a RX. Tron',
    et2menu2: "L'\Ethereum è una truffa Ponzi?",
    
    // 历史委托
    hisTxt: 'Ordini storici',
    
    // 我的订单
    orTab1: 'Non pagato',
    orTab2: 'Pagato',
    orTab3: 'Completato',
    orTab4: 'Annullato',
    orTab5: 'In contestazione',
    orth1: 'Numero ordine',
    orth2: 'Valuta di transazione',
    orth3: 'Tipo di transazione',
    orth4: 'Oggetto della transazione',
    orth5: 'Importo',
    
    // 期权合约
    qcTab1: 'Risultati precedenti',
    qcTab2: 'Andamento in tempo reale',
    qcTxt: 'Numero',
    qcTxt2: 'Fascia',
    qcTxt3: 'Ora di apertura',
    qcTxt4: 'Ora di chiusura',
    qcTxt5: 'Prezzo di apertura',
    qcTxt6: 'Prezzo di chiusura',
    qcTxt7: 'Somma acquisti rialzisti',
    qcTxt8: 'Somma acquisti ribassisti',
    qcTxt9: 'Mio posizionamento',
    qcTxt10: 'Progresso previsione',
    qcTxt11: 'Prezzo di apertura',
    qcTxt12: 'Prezzo di chiusura',
    qcTxt13: 'Periodo di previsione corrente',
    qcbtn1: 'Acquista rialzo',
    qcbtn2: 'Acquista ribasso',
    qcth1: 'Numero contratto',
    qcth2: 'Importo posizione',
    qcth3: 'Direzione prevista',
    qcth4: 'Risultato previsione',
    qcth5: 'Quantità bonus',
    qcth6: 'Commissione di apertura',
    qcth7: 'Provvigione',
    
    // 币种详细信息
    Internet: 'Errore di connessione alla rete',
    mplaceholder: 'Inserisci nome valuta per cercare',
    mTab: 'Selezionati',
    mth: 'Aggiunto ai preferiti',
    mth1: 'Prezzo più recente',
    mth2: 'Variazione 24h',
    mTxt: 'Massimo 24h',
    mTxt2: 'Minimo 24h',
    mTxt3: 'Quantità scambiata',
    mTxt4: 'Tasso dei fondi',
    mTxt5: 'Apertura',
    mTxt6: 'Massimo',
    mTxt7: 'Minimo',
    mTxt8: 'Chiusura',
    indexth: 'Importo totale delle transazioni',
    indexbtn: 'Dettagli',
    indexbtn1: 'Prezzo',
    indexbtn2: 'Quantità',
    indexbtn3: 'Volume di negoziazione',
    indexbtn4: 'Ora',
    echat1: 'Grafico candlestick',
    echat2: 'Grafico profondità del mercato',
    mTab2: 'Posizioni attuali',
    mTab3: 'Storia ordini',
    mth3: 'Garanzia',
    mth4: 'Prezzo di entrata',
    mth5: 'Periodo',
    mth6: 'Prezzo di uscita',
    mth7: 'Profitto',
    mTxt9: 'Modalità di trading',
    mTxt10: 'Ora di entrata',
    mTxt11: 'Tasso di profitto',
    mTxt12: 'Saldo',
    mTxt13: 'Quantità acquistata',
    mTxt14: '中', 
    
    // 创建广告
    cadTxt: 'Crea un annuncio di transazione',
    cadTxt2: 'Se effettui transazioni frequenti, puoi creare il tuo annuncio di vendita.',
    cadTxt2_1: 'Se tratti occasionalmente, ti consigliamo di cercare direttamente',
    cadTxt2_2: 'Creare un annuncio è gratuito.',
    cadLink2: 'Annunci di transazione',
    cadTxt3: 'Per modificare un annuncio già creato, consulta',
    cadLink3: 'Miei annunci',
    cadTxt4: 'Prezzo di partecipazione al mercato',
    cadTxt5: 'Il premio si riferisce alla percentuale sopra il prezzo corrente del mercato per la vendita',
    cadTxt6: 'Formula di calcolo',
    cadTxt7: '【Suggerimento】 Puoi associare o aggiungere metodi di pagamento nel centro personale',
    cadTxt8: 'La somma massima della transazione non può superare il tuo totale importo in vendita di 0 CNYI',
    cadTxt9: 'Abilitando questa opzione, agli utenti che inviano richieste attraverso questo annuncio, verrà automaticamente inviata la risposta automatica selezionata da te.',
    cadTxt10: 'Il premio si riferisce alla percentuale sopra il prezzo corrente del mercato per l\'acquisto',
    cadLabel1: 'Voglio acquistare:',
    cadLabel2: 'Scambio di valuta:',
    cadLabel3: 'Nazione:',
    cadLabel4: 'Valuta:',
    cadLabel5: 'Attiva prezzo fisso',
    cadLabel6: 'Premio: ',
    cadLabel7: 'Prezzo di transazione',
    cadLabel8: 'Quantità in vendita:',
    cadLabel9: 'Metodo di pagamento:', // 这一行重复，保留最新版本即可
    cadLabel10: 'Importo minimo di transazione:',
    cadLabel11: 'Importo massimo di transazione:',
    cadLabel12: 'Attiva risposta automatica',
    cadLabel13: 'Password del fondo:',
    cadLabel14: 'Informazioni aggiuntive:',
    cadLabel15: 'Prezzo fisso:',
    cadLabel16: 'Risposta automatica:',
    cadLabel17: 'Quantità desiderata per l\'acquisto:',
    radio: 'Vendita online',
    radio2: 'Acquisto online',
    cadplaceholder: 'Imposta il tuo premio',
    cadplaceholder2: 'Inserisci la quantità che desideri vendere',
    cadplaceholder3: 'Inserisci il tuo importo minimo di transazione',
    cadplaceholder4: 'Inserisci il tuo importo massimo di transazione',
    cadplaceholder5: 'Puoi inserire richieste speciali nella nota, ad esempio: requisiti per l\'acquirente, orari di connessione, ecc.',
    cadplaceholder6: 'Inserisci il tuo prezzo di transazione',
    cadplaceholder7: 'Messaggio automatico da inviare all\'acquirente dopo aver ricevuto l\'ordine, ad esempio: modalità di pagamento, account di ricezione, ecc.',
    cadplaceholder8: 'Inserisci la quantità che desideri acquistare',
    caderr1: 'Inserisci il tuo importo minimo di transazione',
    caderr2: 'L\'importo minimo deve essere maggiore o uguale a 100!',
    caderr3: 'L\'importo minimo deve essere inferiore all\'importo massimo',
    caderr4: 'Inserisci il tuo importo massimo di transazione!',
    caderr5: 'Inserisci un numero intero',
    caderr6: 'L\'importo massimo deve essere maggiore dell\'importo minimo!',
    caderr7: 'L\'importo massimo non può superare il tuo totale importo in vendita',
    caderr8: 'Seleziona un metodo di transazione',
    submit: 'Invia',
    cadnew: 'Abilitandolo, il prezzo della tua criptovaluta rimarrà immutato e indipendente dalle fluttuazioni di mercato.',
    
    // 快速兑换
    dhTxt: 'Conversione rapida·Gode dei profitti',
    dhTxt2: 'Limite di prezzo',
    dhTxt3: 'Vendi',
    dhTxt4: 'Compra',
    dhTxt5: 'Massimo',
    dhTxt6: 'Inserisci importo',
    dhplaceholder: 'Saldo disponibile nella wallet spot',
    dhSuccess: 'Complimenti! La conversione è avvenuta con successo!',
    
    // 法币交易
    crTxt: 'Trading di valuta fiat',
    crTxt2: 'Conveniente, sicuro e veloce per comprare o vendere criptovalute',
    crTxt3: 'Ajuste dinámico',
    crTxt4: 'Senza commissioni',
    crTxt5: 'Transazione immediata',
    crTxt6: 'Garanzia della piattaforma', // 这一行缺少翻译，请根据上下文补充
    crTxt7: 'Basato sulle variazioni di prezzo del mercato in tempo reale',
    crTxt8: 'I prezzi visualizzati dagli utenti sono finali, nessuna commissione aggiuntiva sulla piattaforma',
    crTxt9: 'Integrazione di commercianti del servizio della piattaforma, abbinamento intelligente, completamento degli ordini senza attendere la negoziazione',
    crTxt10: 'Commercianti certificati dalla piattaforma, sicurezza garantita, assistenza clienti 24 ore per proteggere le transazioni',
    crth1: 'Commercianti',
    crth2: 'Numero di transazioni',
    crth3: 'Metodi di pagamento',
    crth4: 'Limiti',
    crth5: 'Prezzo unitario',
    crBtn: 'Acquista',
    crbtn2: 'Diventa un commerciante',
    
    // 法币交易1
    cr1Txt: 'Complimenti! L\'approvazione della verifica del tuo status di commerciante è stata completata',
    cr1Txt2: 'Prepara i documenti',
    cr1Txt3: 'Invia la richiesta di approvazione',
    cr1Txt4: 'Verificato',
    cr1Txt5: 'Hai ottenuto i seguenti privilegi:',
    cr1Txt6: 'Spazio espositivo dedicato',
    cr1Txt7: 'Servizio one-to-one',
    cr1Txt8: 'I commercianti hanno uno spazio pubblicitario dedicato che aumenta la probabilità di successo delle transazioni',
    cr1Txt9: 'Commissioni inferiori',
    cr1Btn: 'Pubblica annuncio',
    cr1btn2: 'Richiedi cancellazione della garanzia',
    
    // 定期理财
    reTxt: 'Investimento periodico Bitisan · Soluzione di investimento tutto incluso',
    reTxt2: 'Inizia a guadagnare subito, semplice & sicuro',
    reTab: 'Investimento periodico',
    reTab2: 'Investimento pianificato',
    reTxt3: 'Periodico',
    reTxt4: 'Personalizzazione flessibile, potenziale rendimento più elevato',
    reTxt6: 'Pianificato', 
    reTxt7: 'Inizia ad utilizzare il Rendimento Automatico e aumenta il tuo patrimonio',
    reTxt8: 'Espandi tutti i 18 prodotti a termine',
    reth1: 'Tasso di rendimento annuo',
    reth2: 'Durata (giorni)',
    reth3: 'Limite di transazione',
    reth4: 'Prodotto',
    reth5: 'Tasso di rendimento storico',
    rebtn: 'Crea piano',
    redTxt: 'Crea un',
    redTxt2: 'Piano di investimento automatico',
    redTxt3: 'Inserisci la quantità di investimento',
    redTxt4: 'Il mio saldo disponibile è',
    redTxt5: 'Disponibile',
    redTxt6: 'Ciclo di ricorrenza',
    redTxt7: 'Ogni giorno',
    redTxt8: 'Ogni settimana',
    redTxt9: 'Ogni mese',
    redTxt10: 'Ora locale',
    redTxt11: 'Il tuo primo ciclo di investimento automatico inizierà il',
    redTxt12: 'Inizio',
    confirm: 'CO NFERMA',
    confirm2: 'Conferma',
    cancel: 'Annulla',
    redongoing: "In corso",
    redcompleted: "Completato",
    minNumErr: 'Minore della quantità minima di investimento',
    minNumErr2: 'La quantità di investimento non può essere 0',
    buyamounttip: 'Inserisci la quantità da acquistare',
    pricetipwarning: 'L\'importo di trading non può essere superiore a',
    buyamounttipwarning: 'La quantità da acquistare non può essere superiore a',
    sellamounttip: 'Inserisci la quantità da vendere',
    sellamounttipwarning : 'La quantità da vendere non può essere superiore a',
    sellpricetip: 'Inserisci il prezzo di vendita',
    sellpricetipwarning: 'Il prezzo di vendita non può essere superiore a',
    sellmore: 'Puoi vendere al massimo',
    sellunit: 'unità',
    loginFirst: "Effettua prima l'accesso ",
    betsuccess: "Congratulazioni! La tua scommessa è stata eseguita con successo!",
    selectAmount: "Seleziona la quantità da scommettere ",
    balancenotenough: "Saldo disponibile insufficiente! ",
    balancenotenough2: "Saldo insufficiente!",
    minlimitamountfailed: "La quantità di conversione non può essere inferiore alla quantità minima di scambio!",
    maxlimitamountfailed: "La quantità di conversione non può essere superiore alla quantità massima acquistabile!",
    limittimesfailed: "Hai superato il numero massimo di volte acquistabili per utente!",
    commitfailed: "Invio della partecipazione fallito! ",
    // Trading spot
    trplaceholder: 'Ricerca',
    trplaceholder2: "Prezzo più recente ",
    trplaceholder3: 'Acquista al miglior prezzo del mercato',
    trplaceholder4: 'Vendi al miglior prezzo del mercato',
    triggleplaceholder: "Il prezzo di ordine predefinito è il prezzo di mercato ",
    triggerpriceshouldbigger: "Il prezzo trigger deve essere maggiore di 0 ",
    marginModeSuccessTip: "Modifica del modello conto contratti riuscita ",
    noenoughbalance: "Margine del conto insufficiente ",
    pleaseinputopenvolume: "La quantità di apertura deve essere maggiore di 0 ",
    pleaseinputcorrectopenvolume: "Inserisci una quantità di apertura corretta ",
    pleaseinputrightentrustprice: "Inserisci un prezzo di mandato corretto ",
    noenoughposition: "Posizioni chiudibili insufficienti ",
    pleaseinputclosevolume: "La quantità di chiusura deve essere maggiore di 0 ",
    pleaseinputcorrectclosevolume: "Inserisci una quantità di chiusura corretta ",
    option1: 'Linea giornaliera',
    option2: 'Linea settimanale',
    option3: 'Linea mensile',
    trTxt: 'Importo di trading',
    trTxt2: 'Prezzo di mercato',
    trTxt3: 'Quantità di vendita',
    trSuccess: 'Invio riuscito',
    // u-based
    udTxt: 'Modalità margine',
    udTxt2: 'Regola il multiplatore del contratto',
    udTxt3: 'Leva finanziaria',
    udTxt4: 'Perpetuo',
    udTxt4_1: 'Perpetuo',
    udTxt5: 'Quantità trasferibile',
    udTxt6: 'Quantità di trasferimento',
    udTxt7: 'Trasferisci tutto',
    udTxt8: 'Conto Perpetuo',
    ubtn: 'Mantieni margini cumulativi',
    ubtn2: 'Margini separati',
    uplaceholder: 'Conto contratti perpetui',
    uradio1: 'A tempo reale',
    uradio2: '15 minuti',
    uradio3: '1 ora',
    uradio4: '4 ore',
    uradio5: '1 giorno',
    uth1: 'Multiplatore del contratto',
    uth2: 'Profitto',
    uth3: 'Tasso di rendimento',
    uth4: 'Quantità in posizione',
    uth5: 'Quantità disponibile per la chiusura',
    uth6: 'Prezzo medio di apertura',
    uth7: 'Prezzo di liquidazione forzata',
    uth8: 'Margine attuale',
    uth9: 'Tasso di margine',
    zhang: 'pezzi',
    or: 'o',
    ureg: 'Registrati ora ',
    uTxt8: 'Effettua il trading',
    uTab: 'Apri posizione',
    uTab2: 'Chiudi posizione',
    
    uTxt9: 'Posso aprire una posizione long',
    uTxt10: 'Posso aprire una posizione short',
    uTxt11: 'Prezzo limite',
    uplaceholder2: 'Esegui alla miglior offerta di mercato',
    uTxt12: 'Acquisto per aprire una posizione long',
    uTxt13: 'Vendita per aprire una posizione short',
    closeup: "Acquisto per chiudere una posizione short",
    closedown: "Vendita per chiudere una posizione long",
    uTab3: 'Stop loss e take profit',
    uTab3_yprice: 'Prezzo di take profit',
    uTab3_sprice: 'Prezzo di stop loss',
    uTab3Txt: 'Stop loss/Take profit',
    uTxt14: 'Il mio conto contratti',
    ulabel1: 'Modalità account',
    ulabel2: 'Guadagno totale dell\'account',
    ulabel3: 'Margine della posizione',
    ulabel4: 'Utilizzo dei fondi',
    ulabel5: 'Long',
    ulabel6: 'Short',
    uTxt15: 'Long position',
    uTxt16: 'Short position',
    total: 'Totale',
    limited_price: 'Ordine al prezzo limite',
    market_price: 'Ordine al prezzo di mercato',
    spot_price: 'Stop loss/take profit',
    ent_status1: "In sospeso ",
    ent_status2: "Revocato ",
    ent_status3: "Ordine fallito ",
    ent_status4: "Ordine eseguito con successo ",
    ent_statusblast: "Liquidazione ",
    uTxt17: 'Posizioni long da chiudere',
    uTxt18: 'Posizioni short da chiudere',
    //
    prev: 'Articolo precedente',
    next: 'Articolo successivo',
    // Partner di promozione
    prtxt: 'Mio link di invito',
    copy: 'Copia',
    prtxt2: 'I miei amici di primo livello',
    prtxt3: 'I miei amici di secondo livello',
    prtxt4: 'Provvigioni guadagnate (convertite in USDT)',
    prtxt5: 'Premi extra',
    prtxt6: 'Livello di partnership',
    prtit: 'Dettagli delle regole',
    prdesc: 'Il programma [Partner di Promozione] di Bitisan è l\'iniziativa di incasso delle provvigioni con il più alto tasso di ritorno e la durata più lunga (fino all\'incasso a vita) su internet, permettendo ai promotori che investono sforzi adeguati di diventare veri e propri "partner" della piattaforma, godendo dei benefici della crescita con Bitisan. Ecco i dettagli specifici:',
    prli1: 'Uno, per diffondere le idee degli asset digitali e ampliare la base utenti, Bitisan lancia il piano [Partner di Promozione], attivo a lungo termine.',
    prli2: 'Due, l\'invito agli amici viene suddiviso in due livelli: se A invita B, B diventa un amico di primo livello di A; se poi B invita C, C è sia un amico di primo livello di B che uno di secondo livello di A.',
    prli3: 'Tre, per registrarsi come amico di primo livello del promotore, un nuovo utente deve utilizzare il link fornito dal promotore o inserire manualmente il codice di invito del promotore.',
    prli4: 'Quattro, dopo aver completato la registrazione tramite il codice di invito del promotore e la verifica dell\'identità, gli incentivi per le provvigioni diventano effettivi.',
    prli5: 'Cinque, il periodo di incasso delle provvigioni inizia dalla data di completamento della verifica dell\'identità dell\'utente invitato e dura N mesi, con proporzioni di incasso diverse a seconda del livello raggiunto.',
    prli6: 'Sei, le provvigioni vengono erogate sulla commissione di trading tra amici di livello inferiore e sono pagate in una delle tre criptovalute principali: USDT, BTC, ETH.',
    prli7: 'Sette, la percentuale di provvigioni e la durata dell\'incasso sono direttamente proporzionali al numero di amici di primo livello invitati: più invitati si hanno, maggiore sarà la percentuale di incasso.',
    prli8: 'Otto, a intervalli irregolari, i primi 10 o 100 nella classifica delle provvigioni ricevono premi aggiuntivi da parte ufficiale di Bitisan.',
    prli9: 'Nove, nel caso di conflitto con altre attività, le modifiche temporanee al programma [Partner di Promozione] verranno decise mediante negoziazione e chiediamo la comprensione di tutti i partner.',
    prli10: 'Dieci, i partner di livello L4 (Censore), L5 (Prefetto) e L6 (Governatore) beneficiano rispettivamente di un bonus del 5%, 10% e 15% sulle provvigioni totali annuali.',
    prli11: 'Undici, Bitisan mantiene il diritto finale di interpretazione di questa attività.',
    prth1: 'Livello',
    prth2: 'Numero di amici di primo livello',
    prth3: 'Percentuale di incasso sugli amici di primo livello / Durata',
    prth4: 'Percentuale di incasso sugli amici di secondo livello / Durata',
    prth5: 'Dividendi per i partner',
    prlev1: 'Ricognitore',
    prlev2: 'Sindaco',
    prlev3: 'Prefetto',
    prlev4: 'Censore',
    prlev5: 'Prefetto',
    prlev6: 'Governatore',
    prtd1: 'mesi',
    prtd2: 'Incasso a vita',
    prtit2: 'Esempio esplicativo',
    prtxt7: 'Se l\'utente Xiao Yan invita 100 amici di primo livello e questi invitano ciascuno 5 amici di secondo livello, Xiao Yan avrà 100 amici di primo livello e 500 di secondo livello. Se ogni persona fa media di 3000 yuan di transazioni al giorno, il reddito mensile di Xiao Yan sarebbe circa: (100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200 yuan/mese.',
    prtxt8: 'Se l\'utente Xiao Yan invita 1000 amici di primo livello e questi invitano ciascuno 5 amici di secondo livello, Xiao Yan avrà 1000 amici di primo livello e 5000 di secondo livello. Se ogni persona fa media di 3000 yuan di transazioni al giorno, il reddito mensile di Xiao Yan sarebbe circa: (1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000 yuan/mese.',
    prtit3: 'Casella degli strumenti di promozione',
    prtit4: 'Top 20 incassi di provvigioni (classifica generale)',
    prtit5: '',
    prtxt9: 'Data di fine conteggio:',
    
    // Depositi
    recth1: 'Ora di accredito',
    recth2: 'Protocollo di rete',
    recth3: 'Indirizzo di deposito',
    recth4: 'Quantità depositata',
    recbtn: 'Carica con codice prelievo',
    recplaceholder: 'Inserisci il codice prelievo',
    recstatus_0: 'Fallito',
    recstatus_1: 'Non accreditato',
    recstatus_2: 'Accreditato',
    
    // Prelievi
    wittit: 'Prelevare criptovaluta',
    wittext: 'Registro prelievi',
    wittext2: 'Codice prelievo',
    wittext3: 'Lista codici prelievo',
    witbtn: 'Prelievo con codice prelievo',
    witbtn2: 'Genera codice di prelievo',
    witdia: 'Verifica di sicurezza',
    witdia2: 'Codice di prelievo generato con successo',
    witplaceholder: 'Inserisci la quantità da prelevare',
    witerr: 'Seleziona una criptovaluta',
    addresstip: 'Inserisci l\'indirizzo',
    witstate: 'In attesa di conversione',
    witstate2: 'Fallito',
    witstate3: 'Conversione completata',
    witstatus_1: 'In revisione',
    witstatus_2: 'Trasferimento in corso',
    witstatus_3: 'Fallito',
    witstatus_4: 'Completato',
    
    // Tipi di operazioni
    type1: 'Trasferimento',
    type2: 'Scambio cripto-cripto',
    type3: 'Acquisto con valuta fiat',
    type4: 'Vendita con valuta fiat',
    type5: 'Ricompensa per attività',
    type6: 'Premio per promozione',
    type7: 'Dividendi',
    type8: 'Voto',
    type9: 'Carica manuale',
    type10: 'Abbinamento',
    type11: 'Conversione attività',
    type12: 'Acquisto C2C',
    type13: 'Vendita C2C',
    type14: 'Invia pacchetto rossi',
    type15: 'Ricevi pacchetto rossi',
    type16: 'Prelievo tramite codice',
    type17: 'Carica tramite codice',
    type18: 'Commissioni contratto futures perpetuo',
    type19: 'Guadagno contratto futures perpetuo',
    type20: 'Perdita contratto futures perpetuo',
    type21: 'Contratto opzioni non riuscito',
    type22: 'Commissioni contratto opzioni',
    type23: 'Bonus contratto opzioni',
    type24: 'Ribasso contratti',
    type25: 'Ricompensa livello',
    type26: 'Entrate commissioni piattaforma',
    type27: 'Contratto istantaneo non riuscito',
    type28: 'Bonus contratto istantaneo',
    type29: 'Interessi deposito',
    type30: 'Spese finanziarie',
    type31: 'Guadagni finanziari',
    type32: 'Acquisto piano di investimento periodico',
    type33: 'Vendita piano di investimento periodico',
    type34: 'Acquista deposito a termine',
    type35: 'Riscatta deposito a termine',
    type36: 'Trasferimento in entrata posizione contratto futures in base alla criptovaluta',
    type37: 'Trasferimento in uscita posizione contratto futures in base alla criptovaluta',
    type38: 'Trasferimento in entrata posizione contratto futures in base all\'unità U',
    type39: 'Trasferimento in uscita posizione contratto futures in base all\'unità U',
    type40: 'Trasferimento in entrata posizione contratto istantaneo',
    type41: 'Trasferimento in uscita posizione contratto istantaneo',
    type42: 'Trasferimento in entrata cripto-cripto',
    type43: 'Trasferimento in uscita cripto-cripto',
    
    // Conto investimenti
    lctit: 'Conto investimento',
    lcbtn: 'Deposita nel conto investimento',
    lcth1: 'Interessi accumulati',
    day: 'Giorno/i',
    lctxt: 'Criptovaluta dell\'offerta',
    lctxt2: 'Criptovaluta accettata',
    lctxt3: 'Quantità minima di investimento',
    lctxt4: 'Scegli il periodo',
    year: 'Anno/i',
    month: 'Mese/i',
    
    // Ordini di investimento
    ldtab1: "Record delle posizioni aperte ",
    ldtab2: "Record dei guadagni chiusi ",
    ldth1: "Data di inizio rendimento ",
    ldth2: "Data di riscatto ",
    ldth3: "Valore stimato del rendimento ",
    ldth4: "Rendimento effettivo ",
    
    // Piani di investimento periodici
    attit: "Modifica piano di investimento periodico ",
    attxt: "Quantità d\'investimento ",
    attxt2: "Periodicità dell\'investimento ",
    atth1: 'Data di investimento periodico',
    atth2: 'Quantità di investimento',
    atth3: 'Tasso di commissione',
    success: 'Successo',
    
    // Gestione innovativa
    cxth1: 'Nome',
    cxth2: 'Tipo',
    cxth3: 'Quantità sottoscritta',
    cxth4: 'Criptovaluta partecipata',
    cxth5: 'Unità di sottoscrizione',
    cxth6: 'Stato attuale',
    cxth7: 'Volume di transazione',
    cxth8: 'Data di creazione',
    cxnav1: 'Partecipazioni mie',
    cxnav2: 'Miner attivi',
    cxnav3: 'Blocco del portafoglio',
    cxtit2: 'Lista dei miner attivi',
    cxtit3: 'Lista dei blocchi del portafoglio',
    cxkth1: 'Moneta prodotta',
    cxkth2: 'Periodo di produzione',
    cxkth3: 'Tempo di mining',
    cxkth4: 'Tempo di estrazione',
    cxkth5: 'Capacità base',
    cxkth6: 'Capacità effettiva',
    cxstate1: 'Non distribuito',
    cxstate2: 'Distribuito',
    cxstate3: 'Revocato',
    cxstate4: 'In attesa di completamento',
    cxstate5: 'Completato',
    cxstate6: 'In attesa di rilascio',
    cxstate7: 'Rilascio in corso',
    cxcth1: 'Valuta rilasciata ',
    cxcth2: 'Blocco totale',
    cxcth3: 'Periodo di rilascio totale',
    cxcth4: 'Periodo di rilascio già avvenuto',
    cxcth5: 'Rilascio base',
    cxcth6: 'Rilascio effettivo',
    more: 'Altro',
    // Informazioni su di noi
    abtit: 'Caratteristiche della piattaforma',
    abtit2: 'Informazioni su di noi',
    abtit3: 'Contatti',
    abtit4: 'Comunità Bitisan',
    abdesc: 'Bitisan è una piattaforma leader a livello globale per la negoziazione di asset digitali, registrata alle Isole Cayman, con il team operativo principale situato a Hong Kong. I membri chiave di Bitisan provengono dalle società tecnologiche e finanziarie di vertice e la maggior parte sono sostenitori profondi del bitcoin e della blockchain; crediamo fermamente che la blockchain cambierà l\'attuale sistema finanziario monopolistico, creando una struttura sociale più democratica ed autonoma.',
    abdesc2: 'La piattaforma Bitisan dispone di un architettura di trading professionale di livello finanziario, con un motore di matching basato sulla memoria ad alta concorrenza sviluppato internamente, utilizzando un sistema di portafoglio di ricarica completamente offline + firma multipla + un sistema di protezione avanzato contro gli attacchi DDoS, garantendo così la sicurezza dei beni dei clienti.',
    abdesc3: 'Per consentire ai clienti di cogliere al meglio le opportunità d\'investimento, il dipartimento assistenza clienti di Bitisan adotta una gestione piatta e ha istituito un meccanismo di risposta rapida, con manager dell\'assistenza al patrimonio sempre online 24/7 per fornire servizi di ricarica e prelievo di asset, garantendo che i clienti possano completare entrambe le operazioni in meno di 5 minuti.',
    abdesc4: 'Bitisan seleziona rigorosamente progetti di qualità elevata e offre servizi affidabili e stabili di custodia degli asset. Basandosi sulle idee di "onestà, giustizia, passione ed apertura", Bitisan si impegna a creare per gli utenti uno scambio sicuro, affidabile, efficiente ed accogliente al massimo livello.',
    abdesc5: 'Anche se gli asset digitali oggi circolano in un piccolo ambiente, siamo convinti che questo tipo di proprietà controllabile interamente da ciascuno diventerà popolare in futuro, quindi unitiamoci e lavoriamo insieme verso questa aspettativa!',
    abdesc6: 'Assistenza clienti',
    abdesc7: 'Supporto tecnico',
    abdesc8: 'Richiesta di elenco di criptovalute',
    abdesc9: 'Segnalazioni e suggerimenti',
    abdesc10: 'Collaborazioni commerciali',
    abdesc11: 'WeChat',
    abdesc12: 'Weibo',
    abdesc13: 'Twitter',
    abdesc14: 'CoinUse',
    abdesc15: 'Telegram',
    abdesc16: 'Aggiungi “bmate601” come amico su WeChat per entrare nella community con vantaggi speciali',
    // Verifica reale del nome
    realtxt: 'Nome reale',
    realtxt2: 'Numero di carta d\'identità',
    realtxt3: 'Foto fronte carta d\'identità',
    realtxt4: 'Foto retro carta d\'identità',
    realtxt5: 'Foto del documento d\'identità tenuto in mano con firma personale',
    realtxt6: '1. Supporta formati JPG, PNG, dimensione file inferiore a 8MB; le informazioni del documento devono essere chiaramente visibili senza modifiche o coperture, necessario leggere chiaramente il numero di documento e il nome.',
    realtxt7: '2. La foto deve essere senza cappello, preferibilmente senza trucco, il viso del titolare del documento deve essere nitidamente riconoscibile e deve mostrare completamente il braccio.',
    realtxt8: "3. È necessario mostrare contemporaneamente il documento d'identità e una scritta manoscritta (nome + data di richiesta): 'Tutte le mie azioni su questo sito sono operate personalmente e sono consapevole dei rischi correlati, assumendomi tutte le responsabilità legali conseguenti dal mio account.'",
    realbtn: 'Clicca per caricare',
    realstate: 'Non verificato',
    realstate2: 'Verificato',
    uploadimg: "Carica foto fronte carta d'identità ",
    uploadimg2: "Carica foto retro carta d'identità ",
    uploadimg3: "Carica foto del documento d'identità tenuto in mano ",
    uploaderr: "Dimensione immagine non può superare ",
    save_success: 'Salvataggio avvenuto con successo!',
    save_failure: 'Errore durante il salvataggio!',
    // Trasferimento
    close: 'Chiudi',
    hztxt: 'Conferma trasferimento',
    // Verifica commerciante
    submittip1: 'Per la tua sicurezza finanziaria, per favore esegui prima la [[Verifica reale del nome]] !',
    submittip2: 'Per la tua sicurezza finanziaria, assicurati di associare prima il tuo telefono!',
    submittip3: 'Per la tua sicurezza finanziaria, imposta innanzitutto la password del denaro!',
    submittip4: 'Per favore associa almeno un metodo di pagamento.',
    aderr1: 'Inserisci un numero valido',
    aderr2: 'Il sovrapprezzo deve essere compreso tra 0 e 20',
    aderr3: 'Il sovrapprezzo deve essere compreso tra 0 e 20 e non può essere zero',
    aderr4: 'Inserisci un prezzo fisso corretto',
    aderr5: 'Inserisci un numero valido e il quantitativo massimo di transazione non deve superare le 100 monete',
    orderTypeNo: "No ",
    orderTypeYes: "Sì ",
    win: "Vittoria ",
    lose: "Sconfitta ",
    tied: "Pareggio ",
    cancelsuccess: "Annullamento ordine riuscito!",
    shelves: 'È necessario rimuovere l\'annuncio prima di poterlo eliminare!',
    shelves2: 'È necessario rimuovere l\'annuncio prima di poterlo modificare!',
    business: 'Per favore, richiedi prima la verifica commerciante!',
    swappleaseselectwallet: "Seleziona prima l\'account di trasferimento/ricezione ",
    swappleaseinputamount: "Inserisci l\'importo di trasferimento ",
    clickchange: "Clicca per cambiare direzione del trasferimento ",
    daterange: "Seleziona l\'intervallo di date di ricerca ",
    // API
    apierr: 'Inserisci una nota informativa',
    apierr2: 'Inserisci un indirizzo IP',
    apierr3: 'Inserisci un indirizzo IP valido',
    apierr4: 'Inserisci il codice di verifica',
    clickget: 'Clicca per ottenere',
    apisecond: 'secondo/i',
    apitips: "La chiave viene visualizzata solo durante la creazione, assicurati di salvarla tempestivamente ",
    apiaccess: "Chiave di accesso ",
    // Personalizzato
    do_favorite: 'Aggiunto ai preferiti',
    cancel_favorite: 'Rimuovi dai preferiti',
    fail_favorite: 'Operazione fallita',
    deptotal: 'Totale',
    chart: 'Grafico',
    book: 'Ordine libro',
    trade: 'Transazioni recenti',
    pt_title: "Carta regalo per criptovalute ",
    pt_desc: "Dopo il riscatto, diventa automaticamente un affiliato di livello inferiore, invia ad amici, familiari, clienti e partner. Aiuta gli affiliati promozionali a far crescere la propria rete.",
    pt_more: "Altri strumenti in arrivo, stay tuned!",
    pt_tips: "Se hai idee creative, invia una mail a promotion@Bitisan.biz, se accettate avrai una ricompensa!",
    pt_card_amount: "Importo sul biglietto",
    pt_card_deadline: "Periodo di validità",
    pt_card_noend: "Illimitato",
    pt_card_btn: "Gli affiliati promozionali possono ricevere gratuitamente 30 carte (circa 2000 Yuan)",
    pt_card_btn_login: "Accedi per ricevere gratuitamente 30 carte ",
    pt_card_rule: "Dettagli delle regole ",
    pt_card_summary: "Le carte regalo criptovalute sono uno degli strumenti di promozione sviluppati per consentire agli affiliati di invitare più facilmente amici affiliati. Quando l'utente destinatario del regalo esegue il riscatto della carta, diventa automaticamente un affiliato di primo livello dell'invitante.",
    pt_card_rule1: "I. Ogni utente può richiedere al massimo 30 carte regalo gratuite. Per ottenere più carte è necessario personalizzarle a pagamento, con possibilità di modificare l'importo, il logo e le note esplicative. Invia le tue richieste di personalizzazione a ",
    pt_card_rule2: "II. Ogni utente può riscattare un solo biglietto regalo gratuito. I biglietti regalo gratuiti forniti ufficialmente da Bitisan hanno limitazioni: ogni utente può riscattare soltanto una carta, indipendentemente dal fatto che abbia ricevuto codici diversi da altri utenti. Le carte regalo create su misura non sono soggette a questa restrizione.",
    pt_card_rule3: "III. Prima di riscattare una carta regalo, se un utente non ha un 'invitatore' (ovvero non ha inserito un codice di invito durante la registrazione), viene automaticamente associato come affiliato di primo livello del mittente della carta. Se A registra un account senza inserire un codice di invito, quindi A non è affiliato di primo o secondo livello di nessuno; tuttavia, quando A riscatta una carta regalo emessa da B, A diventa automaticamente un affiliato di primo livello di B.",
    pt_card_rule4: "IV. Per prevenire abusi, l'importo della carta regalo non viene pagato immediatamente ma richiede la verifica dell'identità prima di essere utilizzato.",
    pt_card_rule5: "V. Per ricevere le carte regalo gratuite è necessario completare la verifica dell'identità, mentre per riscattarle non è necessaria la verifica ulteriore. Questa scelta facilita agli affiliati di acquisire rapidamente nuovi affiliati.",
    pt_card_rule6: "VI. Il diritto finale di interpretazione delle carte regalo gratuite spetta a Bitisan.",
    pt_card_day: "giorni ",
    pt_card_title_tips: "Raccogli 2000 Yuan per incentivare la tua attività promozionale!",
    pt_invite_desc: "Codice QR esclusivo, l'utilizzo del codice QR per registrarsi trasforma automaticamente l'utente in un affiliato di livello inferiore, ideale da condividere nei gruppi o sui forum.",
    saveimage: "Salva immagine",
    imagetips: "Se non riesci a scaricare l'immagine correttamente, usa uno strumento di cattura schermo per salvarla!",
    pt_card_receivew_success: "Complimenti! Hai riscattato con successo la carta regalo per affiliati! Vai alla sezione 【Centro personale】 -> 【Centro carte e coupon】 per verificare!",
    usepromotion: "Usa materiali promozionali ",
    context_title: "Codice di scambio esclusivo per affiliati promozionali ",
    context_title1: "Scansiona per saperne di più ",
    context_title2: "Il mio codice di invito ",
    context_title3: "Immagine di invito ",
    pt_card_title: "Carta promozionale per affiliati ",
    copysuccess: 'Copia riuscita!',
    copyerr: 'Copia non riuscita! Copialo manualmente',
    noopening: "Nessun contratto di opzione attualmente disponibile ",
    createSuccess: "Creazione completata ",
    tradewarning1: 'Massimo 2 decimali per l\'input',
    tradewarning2: 'L\'importo dell\'ordine è pari a',
    tradewarning3: 'Massimo 8 decimali per l\'input',
    tradewarning4: 'Quantità dell\'ordine pari a',
    tradeconfirmbuyin: 'Conferma acquisto',
    tradeconfirmsellout: 'Conferma vendita',
    foot:{
        aboutus1:'Chi siamo',
        aboutus: ' <p >Dopo il lancio della blockchain principale nel aprile 2019, la Binance Chain ha dimostrato prestazioni elevate e una progettazione altamente concorrente. Il suo punto focale - l\'applicazione decentralizzata locale (dApp) Binance Chain DEX (Exchange Decentralizzato), è stata testata per gestire milioni di transazioni in breve tempo, evidenziando le sue funzionalità di matching a bassa latenza del motore delle transazioni.</p >\
        <p >La flessibilità e l\'accessibilità sono spesso strettamente correlate alle prestazioni. Mentre l\'enfasi era sulla facilità di emissione e negoziazione degli asset digitali, tale progettazione ha introdotto anche alcune limitazioni. La voce più alta nella comunità era quella di vedere l\'aggiunta di funzionalità estendibili programmabili alla Binance Chain, o in parole povere, funzioni di smart contract e macchine virtuali. Poiché la Binance Chain attuale dispone di funzionalità limitate, gli emittenti e i proprietari di asset digitali trovano difficile aggiungere nuove funzionalità decentralizzate ai loro asset o introdurre qualsiasi forma di gestione comunitaria e attività comunitarie...</p >\
    consenso PoA (Proof of Authority), simile al motore di consenso Clique di Ethereum,\
    un insieme di validatori è stato selezionato e,\
    l\'eliminazione della governance basata sulla delega di stake su catena.\
    Durante la fase dell\'attivazione della rete all\'origine blocchetto, molti nodi attendibili opereranno come\
    il primo insieme di validatori. Dopo l\'inizio della produzione dei blocchi, chiunque\
    può candidarsi per diventare un validatore. Lo stato di delega di stake determina i primi 21 nodi con il maggior numero di stake per diventare il prossimo\
    insieme di validatori. Queste elezioni e eliminazioni avvengono ogni 24 ore.\
    Il token BNBN rappresenta la delega di stake di BNB.\
    Per rimanere allineato ai protocolli di consenso Ethereum (compresi gli\
    imminenti aggiornamenti), BNBN ha scelto di utilizzare BNB per la gestione del stake. Esiste un modulo specifico per la delega di stake BNBN\
    su BNB dove accetta la delega di stake di BNB da parte dei titolari di BNBN e calcola\
    l\'insieme di nodi con il maggiore interesse. Ogni volta che UTC raggiunge zero, BNB invia\
    un messaggio "ValidatorSetUpdate" cross-chain verificabile, notificando a BNBN di aggiornare il suo\
    insieme di validatori. Prima della generazione di nuovi blocchi, i validatori BNBN attuali controllano regolarmente se esiste un "ValidatorSetUpdate"\
    messaggio da inoltrare a BNB. Se sì, aggiornano l\'insieme di validatori dopo una certa altezza (cioè un intervallo di blocchi predefinito).\
    Ad esempio, se BNB genera un blocco ogni 5 secondi e il ciclo di controllo è di 240 blocchi, l\'attuale\
    insieme di validatori viene verificato e aggiornato entro 1200 secondi (20 minuti) per il successivo ciclo.\
    Sicurezza e finalità\
    Considerando che più della metà degli ½*N+1 validatori sono onesti e affidabili, una rete basata su PoA lavora generalmente in modo sicuro ed efficiente. \
    Tuttavia, in alcuni casi, validatori byzantini possono ancora attaccare la rete, ad esempio attraverso un "attacco di clonazione". Per garantire che BNBN abbia lo stesso alto livello di sicurezza di BNB,\
    incoraggiamo gli utenti di BNBN ad attendere che i blocchi ricevuti siano confermati da diverse convalidhe di⅔*N+1 validatori diversi. In questo modo, BNBN può raggiungere\
    livelli di sicurezza simili a quelli di BNB tollerando meno di 1/3*N di validatori byzantini. Per 21 validatori, se il tempo di blocco è di 5 secondi,⅔* N+1\
    validatori diversi richiedono (2/3*21+1)*5=75 secondi per la conferma. Qualsiasi applicazione importante su BNBN potrebbe dover aspettare⅔*N+1 per ottenere una finale relativa sicurezza.\
    Più avanti nella sezione "Delega di stake e gestione", verrà introdotto un meccanismo di penalizzazione che espone rapidamente i validatori malintenzionati\
    rendendo estremamente difficile o poco conveniente l\'esecuzione di un "attacco di clonazione", anche se tentato. Con questo meccanismo di punizione, ½*N+1 blocchi o meno sono sufficienti per la maggior parte delle transazioni per raggiungere la finalità.\
    I redditi Tutti i validatori BNB nell\'attuale insieme di validatori guadagnano ricavi dai costi di transazione.\
    Poiché BNB non è un token inflazionario, non produce ricavi di mining come Bitcoin o la rete Ethereum. Le commissioni sono la principale fonte di reddito\
    per i validatori. Poiché BNB è anche un token utility per altre applicazioni,\
    i validatori beneficeranno comunque degli altri vantaggi derivanti dall\'essere detentori di BNBN.',
    newbie: '',
  newbie1: 'Guida per i nuovi utenti',
  commonproblem: '',
  commonproblem1: 'Domande frequenti',
  information: '',
  information1: 'Informazioni sulle valute',
  disclaimer: '',
  disclaimer1: 'Clausola di esclusione di responsabilità',
  privacyclause: '',
  privacyclause1: 'Informativa sulla privacy',
  agreement: '',
  agreement1: 'Accordo con l\'utente',
},
tradeinfo: {
    emaildone: 'E-mail verificata',
    emailundo: 'E-mail non verificata',
    teldone: 'Numero di telefono verificato',
    telundo: 'Numero di telefono non verificato',
    idcarddone: 'Carta d\'identità verificata',
    idcardundo: 'Carta d\'identità non verificata',
    exchangetimes: 'Numero di transazioni',
    price: 'Prezzo',
    num: 'Quantità',
    paymethod: 'Metodo di pagamento',
    exchangelimitamount: 'Limite di transazione',
    location: 'Località',
    location_text: 'Cina',
    exchangeperiod: 'Periodo di transazione',
    minute: 'minuti',
    amounttip: 'Inserisci l\'importo',
    numtip: 'Inserisci la quantità',
    remarktip: 'Comunica le tue richieste',
    remarktitle: 'Informazioni aggiuntive',
    exchangetitle: 'Note sull\'acquisto',
    exchange_tip1: 'Dopo aver inviato la tua richiesta di transazione, le criptovalute verranno bloccate in custodia protetta dalla piattaforma. Se sei un venditore, invia la richiesta e ricarica il conto aspettando il pagamento dell\'acquirente entro il limite di tempo stabilito. Una volta ricevuto il pagamento, rilascia le criptovalute in custodia.',
    exchange_tip2: 'Prima di effettuare una transazione, leggi attentamente i Termini del servizio online della piattaforma e i documenti di aiuto come le domande frequenti e la guida alla negoziazione.',
    exchange_tip3: 'Stai attento agli imbrogli! Controlla le recensioni ricevute dall\'altro utente prima di procedere alla transazione e prestare particolare attenzione alle nuove registrazioni.',
    exchange_tip4: 'Tenere presente che arrotondamenti e fluttuazioni dei prezzi possono influire sulla quantità finale delle criptovalute scambiate. L\'importo fisso inserito determinerà la quantità finale, che sarà calcolata al momento dell\'invio della richiesta utilizzando il tasso di cambio corrente.',
    exchange_tip5: 'Il servizio di custodia protegge sia acquirenti che venditori nelle transazioni online. In caso di controversie, valuteremo tutte le informazioni fornite e rilasceremo le criptovalute in custodia al loro legittimo proprietario.',
    warning1: 'Massimo 2 decimali consentiti',
    warning2: 'Importo ordine pari a',
    warning3: 'Massimo 8 decimali consentiti',
    warning4: 'Quantità ordine pari a',
    confirmbuyin: 'Conferma acquisto',
    confirmsellout: 'Conferma vendita',
    buyin: 'Acquisto',
    sellout: 'Vendita',
    warning5: 'Compila gli ordini secondo le istruzioni',
  },
  }
