export default {


	//خطأ موجه
	error1: 'خطأ في النظام يرجى الاتصال بخدمة العملاء',
	error2: 'تسجيل الدخول منتهية الصلاحية ، يرجى تسجيل الدخول مرة أخرى !',
	meun1: 'الصفحة الرئيسية',
	meun2: 'اتجاهات السوق',
	meun3: 'BNBNالاشتراك',
	meun4: 'شراء آلة التعدين',
	meun5: 'ترويج المستخدم',
	meun6: 'ورق ابيض',
	meun7: 'إعلان الحملة',
	meun8: 'تسجيل الدخول',
	meun9: 'يسجل',
	meun10: 'محفظة',
	meun10_1: 'حساب النقدية',
	meun10_2: 'يو معيار حساب العقد',
	meun10_3: 'حساب العقد بهامش العملة',
	meun10_4: 'تسوية سريعة للعقود',
	meun10_5: 'حساب مالي',
	meun10_6: 'تاريخ المحفظة',
	meun11: 'سجل الطلب',
	meun11_1: 'أمر الاشتراك',
	meun11_2: 'أوامر التعدين',
	meun12: 'خدمة الزبائن',
	meun12_1: 'معلومات خدمة العملاء',
	meun12_2: 'الرقم التسلسلي لخدمة العملاء',
	meun12_3: 'البريد الإلكتروني لخدمة العملاء',
	meun13: 'رمز المكافأة',
	meun13_2:'المطالبة برمز المكافأة',
	meun13_3:'الرجاء إدخال رمز المكافأة',
	meun13_4:'إلغاء العملية',
	meun13_6:'يستلم',
	meun13_7:'تم الإلغاء بنجاح',
	meun13_1: 'ادارة الحساب',
	meun13_5: 'إلغاء تسجيل الدخول',
	meun14_1: 'لغة',
	meun14_3: 'اختر لغة',

	scan: 'iOS & Android مسح الرمز للتحميل',
	footmeun1: 'خلفية',
	footmeun1_1: 'معلومات عنا',
	footmeun1_2: 'انضم إلينا',
	footmeun1_3: 'إشعار المنصة',
	footmeun1_4: 'ورق ابيض',
	footmeun2: 'مركز المساعدة',
	footmeun2_1: 'دليل المبتدئين',
	footmeun2_2: 'مشكلة شائعة',
	footmeun2_3: 'دليل التداول',
	footmeun2_4: 'معلومات العملة',
	footmeun3: 'اتفاقية الشروط',
	footmeun3_1: 'تنصل',
	footmeun3_2: 'سياسة الخصوصية',
	footmeun3_3: 'اتفاقية المستخدم',
	footmeun3_4: 'وصف التعريفة',
	footmeun4: 'اتصل بخدمة العملاء',
	footmeun4_1: 'البريد الإلكتروني لخدمة العملاء',
	footmeun4_2: 'التعاون التجاري',
	footmeun4_3: 'تطبيق قائمة العملة',
	footmeun4_4: 'شكاوي',
	foottext: 'BNBNمنصة الاشتراك العالمية',
	countdown:'انتهى الحدث',
	indexTxt1: 'BNBNالاشتراك العالمي الأول',
	indexTxt2: 'BNBNالاشتراك العالمي الأول',
	indexTxt3: 'العد التنازلي على الانترنت',
	indexLabel1: 'اتجاه السوق',
	indexVal1: 'أسعار العملات المشفرة في الوقت الحقيقي',
	indexLabel2: 'BNBNالاشتراك',
	indexVal2: 'استخدم الأصول الرقمية للتبادل مباشرة',
	indexLabel3: 'شراء آلة التعدين',
	indexVal3: 'BNBNتعدين العملات المشفرة',
	indexLabel4: 'ترويج المستخدم',
	indexVal4: 'فوائد المشتركين',

	indexh5: 'أمن الدرجة المالية',
	indexh6: 'عمليات إيداع وسحب سريعة للغاية',
	indexh7: 'خدمة عالمية',
	indexh8: 'العناصر المختارة بعناية',
	indexp1: '',
	indexp2: '',
	indexp3: 'نظام شامل لمراقبة المخاطر المالية ونظام مضاد للسرقة والمحافظ الساخنة والباردة ونظام التوقيع المتعدد يضمن سلامة الأموال',
	indexp4: 'يمكن إتمام عمليات الإيداع والسحب في أقل من 3 دقائق، كما أن المراجعة اليدوية عبر الإنترنت على مدار 24 ساعة تحمي العملاء من فقدان أفضل الفرص الاستثمارية.',
	indexp5: 'تغطية شبكة خدمات الأعمال العالمية لمساعدتك على الاستثمار في أصول التشفير العالمية والتداول مع المستخدمين العالميين',
	indexp6: 'حدد بدقة مشاريع التشفير عالية الجودة وقم بتصفية 80% من المشاريع عالية المخاطر للغاية بالنسبة لك',
	market: 'اتجاه السوق',
	//新币申购-钱包

	wallet1 :' شحن كمية ',
wallet2 : ' usdt دفع كتلة بروتوكول الشبكة الحالية trc20 ',
wallet3 : " المبلغ المدفوع إلى الحساب يجب أن تكون متسقة مع المبلغ المبين أدناه ، وإلا فإن النظام لا يمكن تأكيد ما إذا كانت إعادة شحن ناجحة " ,
wallet4 : " رقم الطلب ",
wallet5 : " المبلغ المدفوع ",
wallet6:  "！! إذا كان لديك مشكلة في الدفع ، يرجى الاتصال بخدمة العملاء في الوقت المناسب مع رقم الطلب !",
wallet7 : " مهلة الدفع ، يرجى إعادة تشغيل الدفع !",


	usdt:'USDT',
	platform:'BNBN',
	usdt1: 'الرصيد المتوفر',
	usdt2: 'المبلغ المقفل',
	platform1: 'سحب المال',
	platform2: 'مبلغ إعادة الشحن',
	platform3: 'الاشتراك',
	transaction: 'تفاصيل الصفقه',
	transaction0: 'رقم سري',
	transaction1: 'الرقم التسلسلي للمعاملة',
	transaction2: 'عدد المعاملات',
	transaction3: 'ساعة الصفقة',
	transaction4: 'إيداع USDT',
	transaction5: 'حساب إيداع',
	transaction6: 'سحب USDT',
	transaction7: 'في اليوم الأول والثاني من كل شهر، يكون السحب مجانيًا؛ في الأوقات الأخرى، تكون رسوم السحب 20% من إجمالي المبلغ.',
	transaction8: 'سحب الحساب',
	transaction9: 'كمية السحب',
	transaction10: 'إلغاء العملية',
	transaction11: 'تأكيد',
	transaction12: 'الاشتراك',
	transaction13: 'تشير قيمة كمية الاشتراك إلى كمية USDT المشتركة',
	transaction14: 'عدد الاشتراكات',
	transaction15: 'يجب أن يكون عدد الاشتراكات أكبر من 50',
	transaction16: '1يجب أن يكون مبلغ السحب أكبر من 0',
	transaction17: 'نوع العملة',
	transaction18: 'لا توجد تفاصيل الصفقة حتى الآن',
	transaction19: 'يرجى تحديد نوع الإيداع',
	transaction20: 'يرجى الانتظار لمدة 10 دقائق قبل النقر على الزر مرة أخرى',
	transaction21: 'الكمية القابلة للتحويل',
	transaction22: 'قابل للتحويل',
	transaction23: '(1BNBN=1.25USDT)يشير كمية التبادل إلى عدد BNBN الذي يجب تبادله في USDT.',
	transaction24: 'يجب أن يكون مبلغ التبادل أكبر من 0',
	transaction25: 'تتطلب عمليات السحب قدرًا معينًا من رسوم المناولة'+'1.98U',
	//锁仓挖矿
	mining1: 'الاشتراك',
	mining2: 'سعر',
	mining3: 'الدخل اليومي',
	mining4: 'أيام القفل',
	mining5: 'مستوى الشراء',
	mining6: 'آلة التعدين إجمالي الإيرادات',
	mining7: 'وقت البدء',
	mining8: 'نهاية الوقت',
	miningmachine: 'بلدي آلة التعدين',
	mining9: 'العد التنازلي للعرض',
	//邀请
	inviteusers1:'رابط الدعوة',
	inviteusers2:'عدد الأشخاص المدعوين',
	inviteusers3:'رقم الدعوة',
	inviteusers4:'دعوة مستخدمين جدد',
	inviteusers5:'انضم إلى الوقت',
	inviteusers6: 'تم النسخ بنجاح',
	inviteusers7:'لا يوجد مستخدمين مدعوين حتى الآن',
	inviteusers8:'نسخ',
	inviteusers10:'مستخدم نشط',
	inviteusers11:'هل نشط',
	// 订单
	order1: 'أمر الاشتراك',
	order2: 'النظام المالي',
	order3: 'رقم الاشتراك',
	order4: 'عدد الاشتراكات',
	order5: 'وقت الاشتراك',
	order7: 'رقم الإدارة المالية',
	order8: 'كمية مقفلة',
	order9: 'عدد المكافآت',
	order10: 'وقت البدء',
	order11: 'وقت النهاية',
	order12: 'لا توجد معلومات الاشتراك حتى الآن',
	order13: 'لا توجد معلومات مالية حتى الآن',
	Activationsuccessful:'تفعيل بنجاح',
    activation:'تفعيل',
    Atwork:'في العمل',
    Completed:'انتهى',

	Subscriptionrecords :'فواتير الاشتراك',
	Rechargerecords :'سجل إعادة الشحن',
	Rechargenumber :'رقم إعادة الشحن',
	Rechargeamount :'مبلغ إعادة الشحن',
	Rechargeaddress :'عنوان إعادة الشحن',
	Rechargetime :'الوقت إعادة تعبئة الرصيد', 
	Withdrawalrecords :'سجل عمليات السحب',
	Withdrawalnumber :'رقم السحب',
	Withdrawalquantity :'كمية السحب',
	Applicationtime :'وقت التطبيق',
	Timeofreceipt :'وقت الوصول',
	Withdrawalstatus :'حالة الانسحاب',
	operation :'العمل',
	cancel:'يلغي',
	recordsavailable :'لا يوجد سجل إعادة شحن حتى الآن',
	withdrawalrecord :'لا يوجد سجل الانسحاب حتى الآن',

	//个人中心
	personal1: 'معلومات شخصية',
	personal2: 'حالة مصادقة الاسم الحقيقي',
	personal3: 'تم التحقق',
	personal4: 'جاري التصديق',
	personal5: 'لم تتم المصادقة  انقر فوق مصادقة',
	personal6: 'فشلت المصادق  انقر فوق المصادقة',
	personal7: 'اسم المستخدم',
	personal8: 'بريد',
	personal9: 'رقم التليفون',
	personal10: 'مستوى كبار الشخصيات',
	personal11: 'عضو عادي',
	personal12: 'شفرة الدعوة',
	personal13:'معلومات الشهادة',
	personal14:'يرجى تحميل المعلومات المتعلقة بمصادقة الهوية',
	personal15:'تغيير كلمة المرور',
	personal16:'كلمة المرور الأصلية',
	personal17:'تغيير كلمة المرور',
	personal18:'تأكيد كلمة المرور',
	personal19:'الرجاء إدخال كلمة المرور',
	personal20:'لا يمكن أن يقل طول كلمة المرور عن 6 أحرف',
	personal21:'الرجاء إدخال كلمة المرور مرة أخرى',
	personal22:'كلمات المرور التي تم إدخالها مرتين غير متناسقة',

	
	authentication1:'إضافة معلومات المصادقة',
	authentication2:'تأكيد التقديم',
	authentication3:'يمكن تحميل ملفات jpg/png فقط ويجب ألا  M2',
	authentication4:'تحميل الملفات',
	authentication5:'تمت إضافة المستخدم وتحميل الملف بنجاح',
	authentication6:'فشلت إضافة المستخدم وتحميل الملف',
	authentication7:'يمكن تحميل الصور الرمزية بتنسيق JPG فقط!',
	authentication8:'لا يمكن أن يتجاوز حجم الصورة الرمزية التي تم تحميلها 2 ميغابايت!',
	authentication9: 'أكثر من 5 ملفات',

	
    //奖池
    pool1: 'النشاط لم يبدأ بعد!',
	pool2: 'عدد الجوائز',
	pool3: 'تفاصيل الجوائز',
	pool4: 'انضم الآن',
	pool5: 'لقد شاركت في الحدث!',
	pool6: 'المستخدمون المشاركون',
	pool7: 'انتهى الحدث، وقائمة الفائزين هي كما يلي',
	pool8: 'قائمة الفائزين بالجوائز',

	// 登录
	account0:'حساب',
	account: 'BNBNCOINتسجل الدخول',
	phone: 'رقم التليفون',
	email: 'بريد',
	sign: 'تسجيل الدخول',
	password: 'كلمة المرور',
	password2: 'كلمة المرور',
	password3: 'ننسى كلمة المرور',
	account2: 'إنشاء حساب',
	logErr: 'الرجاء إدخال اسم المستخدم',
	logErr2: 'الرجاء إدخال كلمة المرور',
	logErr3: 'لا يمكن أن يقل طول كلمة المرور عن 6 أحرف',
	logSuccess: 'تم تسجيل الدخول بنجاح',
	resetpassword:'إعادة تعيين كلمة المرور',
	reset:'إعادة ضبط',
	// 注册
	account3: 'إنشاء حساب شخصي',
	phone1: 'سجل هاتفك',
	email1: 'تسجيل البريد الإلكتروني',
	email2: 'عنوان البريد الإلكتروني',
	code: 'رمز التحقق عبر الرسائل القصيرة',
	code2: 'أرسل رمز التحقق',
	code3: 'رمز التحقق',
	password4: 'تأكيد كلمة المرور',
	invite: 'شفرة الدعوة',
	agree: 'لقد قرأت وأوافق',
	agreement: 'اتفاقية المستخدم',
	register: 'يسجل',
	regErr: 'الرجاء إدخال رمز التحقق عبر الرسائل القصيرة',
	confirmpwdtip: 'الرجاء إدخال كلمة المرور التأكيدية',
	confirmpwderr: 'إدخالا كلمة المرور غير متناسقين!',
	verificationcode:'يرجى الحصول على رمز التحقق',
	captcha:'لا انقر مرة أخرى',
	//忘记密码
	wjtab: 'إعادة تعيين كلمة مرور الهاتف المحمول',
	wjtab2: 'إعادة تعيين كلمة المرور للبريد الإلكتروني',
	wjlabel: 'كلمة المرور الجديدة',
	wjerr: 'الرجاء إدخال كلمة مرور جديدة',
	Tailpage:'نهاية الصفحة',
	homepage:'الصفحة الرئيسية',
	lastpage:'الصفحة السابقة',
	nextpage:'الصفحة التالية',
	off:'غلق',
	problem:'إذا كان لديك أي أسئلة ، بما في ذلك شهادة الاسم الحقيقي ، يرجى الاتصال بخدمة العملاء في الوقت المناسب',

	//公告
	announcementtitle:'عنوان الإعلان',
	announcement: '<p>بعد إطلاق الشبكة الرئيسية في أبريل 2019، أظهرت Binance Chain أدائها عالي السرعة وتصميمها عالي الإنتاجية. تركيزها الرئيسي - التطبيق اللامركزي الأصلي ("dApp") Binance Chain DEX (التبادل اللامركزي)، تم اختباره للتعامل مع ملايين الصفقات في فترة زمنية قصيرة، مما يعكس الأداء المنخفض لمحرك التداول الخاص به.</p>\
<p>غالبًا ما تسير المرونة والتوافر جنبًا إلى جنب مع الأداء. عندما يركز الناس على كيفية توفير مكان مناسب لإصدار الأصول الرقمية وتداولها، فإن هذا التصميم يجلب أيضًا\
القيود إلى حد ما. وكان أعلى صوت في المجتمع يرى\
تضيف Binance Chain وظائف ملحقة قابلة للبرمجة، أو ستفعل ذلك\
ببساطة، إنها عقود ذكية ووظائف الآلة الافتراضية. </p><p> نظرًا للوظائف المحدودة الحالية لـ Binance Chain، فمن الصعب على مصدري الأصول الرقمية ومالكيها إضافة جديد\
نشر الوظائف عبر أصولها أو تقديم أي شكل من أشكال\
إدارة المجتمع والأنشطة المجتمعية.</p>\
<p>على الرغم من أن التوقعات عالية بالنسبة لـ Binance لإضافة وظائف العقود الذكية\
تشين، لقد كان "قرارًا صعبًا". قد يؤدي تنفيذ العقود الذكية إلى إبطاء DEX وإضافة عدم اليقين إلى تداول الأصول. وحتى هذه التسوية\
ربما يكون الحل الأسهل الذي يمكن التفكير فيه هو تنفيذ حل افتراضي\
جهاز يعتمد على بروتوكول توافق Tendermint الأساسي الحالي وRPC الرئيسي\
واجهة سلسلة بينانس. ومع ذلك، سيزيد هذا الحل من تكلفة التعلم لمجتمع التطبيقات اللامركزية الحالي ولن يصبح حلاً شائعًا.\
نقترح هنا مفهوم blockchain الموازي لـ Binance Chain للحفاظ على ارتفاعه\
يدعم أداء DEX الأصلي أيضًا وظائف العقود الذكية.</p>',
	// 创新实验室
	labTxt: '',
	labTxt2: '',
	labTab1: '',
	labTab2: '',
	labTab3: '',
	labTab4: '',
	labTab5: '',
	tokendistribute: "",
	activitytype: "",
	labStart: '',
	labEnd: '',
	labStartPlaceholder: '',
	labDetail: '',
	labSpeen: "",
	labNumber: "",
	labType1: '',
	labType2: '',
	labType3: '',
	labType4: '',
	labType5: '',
	labType6: '',
	baseinfo: '',
	ruledetail: "",
	releaseType: "",
	releaseType0: "",
	releaseType1: "",
	lock: " ",
	releaseDetail: "",
	lockday: " ",
	lockweek: " ",
	lockmonth: "",
	lockyear: "",
	releasePercent: "",
	lockFee: "",
	releaseTimes: "",
	times: "",
	publishprice: "",
	holdcoin: "",
	limittimes: "",
	limitamount: "",
	unlimited: "",
	leveloneCount: "",
	alreadyamount: "",
	leftamount: "",
	myalreadyholdamount: "",
	alreadyholdamount: "",
	currentdivided: "",
	holdtips: "",
	inputholdamount: "",
	pleaseinputamount: "",
	pleaseinputholdamount: "",
	inputamount: "",
	inputminingamount: "",
	attend: "",
	attention: "",
	attentiontxt1: "",
	attentiontxt2: "",
	attentiontxt3: "",
	attentiontxt4: "",
	// 币本位合约账户
	transfer: '',
	placeholder: '',
	bith1: '',
	bith2: '',
	bith3: '',
	bith4: '',
	bith5: '',
	bith6: '',
	bith7: '',
	// 现货账户
	btn1: '',
	btn2: '',
	btn3: '',
	btn4: '',
	btn5: '',
	totalAssets: '',
	th1: '',
	th2: '',
	th3: '',
	th4: '',
	th5: '',
	// 充值
	czTxt: "إعادة شحن العملة الرقمية",
	czTxt2: "عملة",
	czTxt3: "شبكة النقل",
	czTxt4: "الرصيد المتوفر",
	czTxt5: "عنوان السحب",
	czTxt6: "كمية العملات المسحوبة",
	czTxt7: "وصول الكمية",
	czTxt8: "رسوم المعالجة",
	czTxt9: "نصائح",
	czTxt10: "الحد الأدنى لمبلغ الإيداع:",
	czTxt10_1: "لن يتم إضافة المبالغ الإضافية التي تقل عن الحد الأدنى للمبلغ.",
	czTxt11: "يرجى عدم إيداع أي أصول غير العملة على العنوان المذكور أعلاه، وإلا فلن يتم استرداد الأصول.",
	czTxt12: "بعد إعادة الشحن إلى العنوان المذكور أعلاه، يلزم التأكيد من عقدة شبكة blockchain بأكملها. بشكل عام، سيتم إضافة الحساب إلى الحساب بعد 3 تأكيدات للشبكة من شبكة blockchain الرئيسية.",
	czTxt13: "لن يتغير عنوان إعادة الشحن الخاص بك بشكل متكرر ويمكن إعادة شحنه بشكل متكرر، وفي حالة حدوث أي تغيير، سنبذل قصارى جهدنا لإعلامك من خلال إعلانات الموقع الإلكتروني أو رسائل البريد الإلكتروني.",
	czTxt14: "يرجى التأكد من أن جهاز الكمبيوتر والمتصفح الخاص بك آمنان لمنع التلاعب بالمعلومات أو تسريبها.",
	czTxt15: "سجل إعادة الشحن",
	czTxt16: "من أجل ضمان أمان الأموال، عندما تقوم بتغيير سياسة أمان حسابك، أو تعديل كلمة المرور الخاصة بك، أو استخدام عنوان جديد لسحب العملات المعدنية، سنقوم بإجراء مراجعة يدوية للسحب، يرجى التحلي بالصبر وانتظار اتصال الموظفين لك عن طريق الهاتف أو البريد الإلكتروني.",
	czTxt17: "الحد الأدنى لمبلغ السحب هو:",
	czth1: 'وقت الانسحاب',
	czth2: 'من',
	czth3: 'ل',
	czth4: 'كمية الاسترداد الفوري',
	czth5: 'نسبة الصرف',
	czth6: 'كمية الصرف',
	placeholder2: 'املأ من فضلك',
	placeholder3: 'اختر من فضلك',
	czEmpty: 'لا سجلات',
	logintip: 'الرجاء تسجيل الدخول أولا!',
	cztip: 'لم تتقدم بطلب للحصول على حساب وديعة، يرجى الضغط على الزر للحصول على حساب وديعة',
	czget: 'الحصول على رقم حساب الوديعة',
	czplaceholder: 'الرجاء تحديد البروتوكول',
	// 秒合约账户
	mAccount: "",
	// 钱包历史记录
	btn6: 'يبحث',
	wtTxt: 'وقت البدء والانتهاء',
	wtTxt2: 'نوع العملية',
	wtth1: 'ساعة الصفقة',
	wtth2: 'يكتب',
	wtth3: 'عملة',
	wtth4: 'كمية',
	wtth5: 'رسوم المناولة المستحقة',
	wtth6: 'خصم رسوم المناولة',
	wtth7: 'رسوم المناولة الفعلية',
	wtth8: 'ولاية',
	// 账户安全
	nav1: 'أمن الحساب',
	nav2: 'دعوتي',
	nav3: '',
	acTxt: 'مستوى الأمان',
	acbtn1: 'لا توجد مصادقة الاسم الحقيقي',
	acbtn2: 'ربط',
	acbtn3: 'يتغير',
	acbtn4: 'أكملت مصادقة الاسم الحقيقي',
	acbtn5: 'تم ربطه بالفعل',
	acbtn6: 'مصحح الأخطاء',
	acbtn7: 'قيد المراجعة',
	acbtn8: 'أعد المحاولة',
	acTxt2: 'مركز التصديق',
	acTxt3: 'بريد',
	acTxt4: 'الهاتف الخلوي',
	acTxt5: 'كلمة سر الدخول',
	acTxt6: 'كلمة مرور الصندوق',
	acTxt7: 'أداة مصادقة جوجل',
	acTxt8: 'تعيين كلمة مرور الصندوق',
	acTxt9: 'تغيير كلمة مرور الصندوق',
	acTxt10: 'استرجاع كلمة مرور الصندوق',
	acDesc2: 'لضمان أمان حسابك، يرجى إكمال مصادقة الاسم الحقيقي قبل التداول!',
	acDesc3: 'البريد الإلكتروني المرتبط',
	acDesc4: 'رقم الجوال المرتبط',
	acDesc5: 'تستخدم عند تسجيل الدخول إلى المنصة',
	acDesc6: 'عندما تتغير أموال الحساب، يجب التحقق من كلمة مرور الصندوق',
	acDesc7: 'الرجاء توصيل Google Authenticator',
	acDesc8: 'تم ربط Google Authenticator',
	acDesc9: 'نهاية الاتصال',
	acTit: 'رقم الجوال المرتبط',
	acTit2: 'البريد الإلكتروني المرتبط',
	acTit3: 'الاتصال بأداة مصادقة Google',
	acTit4: 'إنهاء الاتصال بأداة مصادقة Google',
	mailtip: 'يرجى إدخال البريد الإلكتروني الخاص بك',
	emailerr2: 'تنسيق البريد الإلكتروني غير صحيح، يرجى إعادة إدخاله.',
	agreementtip: 'يرجى قراءة اتفاقية المستخدم والموافقة عليها',
	acverify: "فشلت المراجعة",
	again: "حاول مرة اخرى",
	verifiedmsg: 'الرجاء إجراء مصادقة الاسم الحقيقي أولاً',

	
	pdfwait:'يتم الآن تحميل الملف، يرجى الانتظار لأن الملف كبير الحجم...',
	pdfstuck:'إذا واجهت مشكلة، يمكنك تحديث الصفحة وإعادة التحميل...',
	// 更改登录密码
	chTxt: 'تغيير كلمة المرور لتسجيل الدخول',
	chTxt2: 'مسح الرمز للاتصال',
	chTxt3: 'الرجاء إدخال رمز التحقق',
	chTxt4: 'كلمة المرور الأصلية للصندوق',
	chTxt5: 'كلمة المرور الجديدة للصندوق',
	chTxt6: 'قم بتأكيد كلمة المرور الجديدة',
	chTxt7: 'ننسى كلمة المرور',
	chTxt8: 'تغيير التحقق من رقم الهاتف المحمول',
	chTxt9: 'كلمة المرور الأصلية لتسجيل الدخول',
	chTxt10: 'كلمة المرور الجديدة لتسجيل الدخول',
	chTxt11: 'رمز التحقق من البريد الإلكتروني',
	chTxt12: 'رمز التحقق من الهاتف المحمول',
	telerr: 'رقم الجوال غير صحيح',
	emailerr: 'البريد الإلكتروني غير صحيح',
	codeerr: 'رمز التحقق غير صحيح',
	save: 'يحفظ',
	chtip: 'الرجاء إدخال كلمة المرور الأصلية',
	chtip2: 'الرجاء إدخال كلمة مرور جديدة لتسجيل الدخول لا تقل عن 6 أرقام',
	chtip3: 'كلمة مرور تسجيل الدخول الجديدة غير متناسقة',
	chtip4: 'الرجاء إدخال كلمة المرور الصحيحة للصندوق',
	chtip5: 'يجب ألا تقل كلمة المرور عن 6 أحرف',
	chtip6: 'الرجاء إدخال كلمة مرور لا تقل عن 6 أرقام',
	chtip7: 'كلمات المرور غير متناسقة',
	chtip8: 'الرجاء إدخال كلمة المرور للصندوق',
	chtip9: 'الرجاء إدخال رقم الهاتف',
	chtip10: 'من فضلك أدخل رقمك السري',
	realnametip: 'الرجاء إدخال اسمك الحقيقي',
	idcardtip: 'الرجاء إدخال رقم الهوية الخاص بك',

	// 收款方式
	clTxt: 'يرجى تعيين طريقة الدفع الخاصة بك والتأكد من استخدام حساب الاسم الحقيقي الخاص بك',
	clTxt2: 'إضافة طريقة الاستلام',
	edit: 'تعديل',
	delete: 'يمسح',
	name: 'اسم',
	clTxt3: 'رقم الهوية',
	clTxt4: 'رقم الآيبان',
	clTxt5: 'جميع طرق الاستقبال',
	clLink1: 'إسم الحساب',
	clLink2: 'تعديل طريقة الدفع',
	clLink3: 'تفاصيل الدفع (اختياري)',
	clplaceholder1: '',
	clplaceholder2: '',
	clplaceholder3: '',
	cltip: '',
	cltip2: '',
	cltip3: '',
	clTxt6: 'اختياري',
	upload: '',
	uploadTip: '',
	// 我的推广卡
	tgbtn: '',
	tgth1: '',
	tgth2: '',
	tgth3: '',
	tgth4: '',
	tgth5: '',
	tgth6: '',
	tgth7: '',
	tgbtn2: '',
	tgbtn3: '',
	tgplaceholder: '',
	exchangesuccess: "",
	// 我的邀请
	inth1: 'دعوة المستخدمين',
	inth2: 'وقت التسجيل',
	inth3: 'ما إذا كان سيتم إجراء مصادقة الاسم الحقيقي',
	// 我的广告
	adTxt: '',
	adTxt2: '',
	adbtn: '',
	adbtn2: '',
	adbtn3: '',
	adth1: '',
	adth2: '',
	adth3: '',
	adth4: '',
	adtip: '',
	num_text1: '',
	num_text2: '',
	// 当前委托
	wTxt: '',
	wTxt2: '',
	wTxt3: '',
	wTxt4: '',
	wth1: '',
	wth2: '',
	wth3: '',
	wth4: '',
	wth5: '',
	wth6: '',
	wth7: '',
	wth8: '',
	wth9: '',
	wth10: '',
	wth11: '',
	wth12: '',
	wth13: '',
	wbtn: '',
	wtip: '',
	wtiptxt: '',
	// 赚币
	zbplaceholder: '',
	zbtab1: '',
	zbtab2: '',
	zbth1: '',
	zbth2: '',
	zbth3: '',
	zbth4: '',
	zbth5: '',
	zbth6: '',
	zbth7: '',
	zbth8: '',
	// 当前委托-1
	etTxt: '',
	etTxt2: '',
	etmenu1: '',
	etmenu2: '',
	// 当前委托-3
	et2menu1: '',
	et2menu2: '',
	// 历史委托
	hisTxt: '',
	// 我的订单
	orTab1: '',
	orTab2: '',
	orTab3: '',
	orTab4: '',
	orTab5: '',
	orth1: '',
	orth2: '',
	orth3: '',
	orth4: '',
	orth5: '',
	// 期权合约
	qcTab1: '',
	qcTab2: '',
	qcTxt: '',
	qcTxt2: '',
	qcTxt3: '',
	qcTxt4: '',
	qcTxt5: '',
	qcTxt6: '',
	qcTxt7: '',
	qcTxt8: '',
	qcTxt9: '',
	qcTxt10: '',
	qcTxt11: '',
	qcTxt12: '',
	qcTxt13: '',
	qcbtn1: '',
	qcbtn2: '',
	qcth1: '',
	qcth2: '',
	qcth3: '',
	qcth4: '',
	qcth5: '',
	qcth6: '',
	qcth7: '',

	// 币种详细信息
	Internet:'خطأ في الاتصال بالشبكة',
	mplaceholder: 'أدخل اسم العملة للبحث',
	mTab: 'خياري',
	mth: 'يجمع',
	mth1: 'أحدث السعر',
	mth2: 'صعود وهبوط 24 ساعة',
	mTxt: '24 ساعة أعلى سعر',
	mTxt2: '24 ساعة بأقل سعر',
	mTxt3: 'عدد المعاملات',
	mTxt4: 'معدل التمويل',
	mTxt5: 'يبدأ',
	mTxt6: 'الأعلى',
	mTxt7: 'أدنى',
	mTxt8: 'ينهي',
	indexth: 'إجمالي مبلغ الصفقة',
	indexbtn: 'تفاصيل',
	indexbtn1:'سعر',
	indexbtn2:'كمية',
	indexbtn3:'سعر الصفقة الإجمالي',
	indexbtn4:'وقت',


	echat1: 'مخطط خط K',
	echat2: 'خريطة العمق',
	mTab2: '',
	mTab3: '',
	mth3: '',
	mth4: '',
	mth5: '',
	mth6: '',
	mth7: '',
	mTxt9: '',
	mTxt10: '',
	mTxt11: '',
	mTxt12: '',
	mTxt13: '',
	mTxt14: '',
	// 创建广告
	cadTxt: '',
	cadTxt2: '',
	cadTxt2_1: '',
	cadTxt2_2: '',
	cadLink2: '',
	cadTxt3: '',
	cadLink3: '',
	cadTxt4: '',
	cadTxt5: '',
	cadTxt6: '',
	cadTxt7: '',
	cadTxt8: '',
	cadTxt9: '',
	cadTxt10: '',
	cadLabel1: '',
	cadLabel2: '',
	cadLabel3: '',
	cadLabel4: '',
	cadLabel5: '',
	cadLabel6: '',
	cadLabel7: '',
	cadLabel8: '',
	cadLabel9: '',
	cadLabel10: '',
	cadLabel11: '',
	cadLabel12: '',
	cadLabel13: '',
	cadLabel14: '',
	cadLabel15: '',
	cadLabel16: '',
	cadLabel17: '',
	radio: '',
	radio2: '',
	cadplaceholder: '',
	cadplaceholder2: '',
	cadplaceholder3: '',
	cadplaceholder4: '',
	cadplaceholder5: '',
	cadplaceholder6: '',
	cadplaceholder7: '',
	cadplaceholder8: '',
	caderr1: '',
	caderr2: '',
	caderr3: '',
	caderr4: '',
	caderr5: '',
	caderr6: '',
	caderr7: '',
	caderr8: '',
	submit: '',
	cadnew: '',
	// 快速兑换
	dhTxt: 'تبادل بنقرة واحدة والتمتع بالفوائد',
	dhTxt2: 'حد السعر',
	dhTxt3: 'للبيع',
	dhTxt4: 'اشتر',
	dhTxt5: 'أقصى',
	dhTxt6: 'أدخل المبلغ',
	dhplaceholder: 'الرصيد المتوفر في المحفظة',
	dhSuccess: 'تهانينا! الفداء ناجح!',
	// 法币交易
	crTxt: '',
	crTxt2: '',
	crTxt3: '',
	crTxt4: '',
	crTxt5: '',
	crTxt6: '',
	crTxt7: '',
	crTxt8: '',
	crTxt9: '',
	crTxt10: '',
	crth1: '',
	crth2: '',
	crth3: '',
	crth4: '',
	crth5: '',
	crbtn: '',
	crbtn2: '',
	// 法币交易1
	cr1Txt: '',
	cr1Txt2: '',
	cr1Txt3: '',
	cr1Txt4: '',
	cr1Txt5: '',
	cr1Txt6: '',
	cr1Txt7: '',
	cr1Txt8: '',
	cr1Txt9: '',
	cr1btn: '',
	cr1btn2: '',
	// 定期理财
	reTxt: '',
	reTxt2: '',
	reTab: '',
	reTab2: '',
	reTxt3: '',
	reTxt4: '',
	reTxt6: '',
	reTxt7: '',
	reTxt8: '',
	reth1: '',
	reth2: '',
	reth3: '',
	reth4: '',
	reth5: '',
	rebtn: '',
	redTxt: '',
	redTxt2: '',
	redTxt3: '',
	redTxt4: '',
	redTxt5: '',
	redTxt6: '',
	redTxt7: '',
	redTxt8: '',
	redTxt9: '',
	redTxt10: '',
	redTxt11: '',
	redTxt12: '',
	confirm: '',
	confirm2: '',
	cancel: '',
	redongoing: "",
	redcompleted: "",
	minNumErr: '',
	minNumErr2: '',
	buyamounttip: '',
	pricetipwarning: '',
	buyamounttipwarning: '',
	sellamounttip: '',
	sellamounttipwarning: '',
	sellpricetip: '',
	sellpricetipwarning: '',
	sellmore: '',
	sellunit: '',
	loginFirst: "",
	betsuccess: "",
	selectAmount: "",
	balancenotenough: "",
	balancenotenough2: "",
	minlimitamountfailed: "",
	maxlimitamountfailed: "",
	limittimesfailed: "",
	commitfailed: "",
	// 现货交易
	trplaceholder: '',
	trplaceholder2: "",
	trplaceholder3: '',
	trplaceholder4: '',
	triggleplaceholder: "",
	triggerpriceshouldbigger: "",
	marginModeSuccessTip: "",
	noenoughbalance: "",
	pleaseinputopenvolume: "",
	pleaseinputcorrectopenvolume: "",
	pleaseinputrightentrustprice: "",
	noenoughposition: "",
	pleaseinputclosevolume: "",
	pleaseinputcorrectclosevolume: "",
	option1: '',
	option2: '',
	option3: '',
	trTxt: '',
	trTxt2: '',
	trTxt3: '',
	trSuccess: '',
	// u本位
	udTxt: '',
	udTxt2: '',
	udTxt3: '',
	udTxt4: '',
	udTxt4_1: '',
	udTxt5: '',
	udTxt6: '',
	udTxt7: '',
	udTxt8: '',
	ubtn: '',
	ubtn2: '',
	uplaceholder: '',
	uradio1: '',
	uradio2: '',
	uradio3: '',
	uradio4: '',
	uradio5: '',
	uth1: '',
	uth2: '',
	uth3: '',
	uth4: '',
	uth5: '',
	uth6: '',
	uth7: '',
	uth8: '',
	uth9: '',
	zhang: '',
	or: '',
	ureg: '',
	uTxt8: '',
	uTab: '',
	uTab2: '',
	uTxt9: '',
	uTxt10: '',
	uTxt11: '',
	uplaceholder2: '',
	uTxt12: '',
	uTxt13: '',
	closeup: "",
	closedown: "",
	uTab3: '',
	uTab3_yprice: '',
	uTab3_sprice: '',
	uTab3Txt: '',
	uTxt14: '',
	ulabel1: '',
	ulabel2: '',
	ulabel3: '',
	ulabel4: '',
	ulabel5: '',
	ulabel6: '',
	uTxt15: '',
	uTxt16: '',
	total: '',
	limited_price: '',
	market_price: '',
	spot_price: '',
	ent_status1: "",
	ent_status2: "",
	ent_status3: "",
	ent_status4: "",
	ent_statusblast: "",
	uTxt17: '',
	uTxt18: '',
	//
	prev: 'الصفحة السابقة',
	next: 'التالي',
	// 推广合伙人
	prtxt: 'رابط دعوتي',
	copy: 'ينسخ',
	prtxt2: 'صديقي من الدرجة الأولى',
	prtxt3: 'أصدقائي من الدرجة الثانية',
	prtxt4: 'دخل العمولة (ما يعادل USDT)',
	prtxt5: 'مكافآت إضافية',
	prtxt6: 'مستوى الشريك',
	prtit: 'تفاصيل القاعدة',
	prdesc: '	يعد [الشريك الترويجي] الخاص بـ BNBNCOIN نشاطًا ترويجيًا يتمتع بأعلى نسبة خصم للعمولة وأطول مدة خصم (الحد الأقصى للخصم مدى الحياة) على الشبكة بأكملها، مما يسمح للمروجين الذين بذلوا جهودًا مماثلة بأن يصبحوا "شريكًا" حقيقيًا للمنصة والاستمتاع بأرباح الأسهم من النمو مع منصة التداول BNBNCOIN! التفاصيل المحددة هي كما يلي:',
	prli1: '1、من أجل تعزيز مفهوم الأصول الرقمية وتوسيع نطاق المستخدمين، أطلقت BNBNCOIN برنامج [Promotion Partner]، وهو برنامج فعال لفترة طويلة.',
	prli2: '2、تنقسم دعوة الأصدقاء إلى مستويين. إذا قام A بدعوة B، فإن B هو صديق المستوى الأول إذا قام B بدعوة C، فإن C هو صديق B من المستوى الأول وأيضًا صديق A من المستوى الثاني.',
	prli3: '3、عند التسجيل كصديق ترويجي، يتعين عليك استخدام الرابط الذي يقدمه الداعي أو إدخال رمز دعوة الداعي يدويًا لتصبح صديقًا من المستوى الأول للداعي.',
	prli4: '4、لا يمكن أن يصبح حافز الخصم ساري المفعول إلا بعد أن يقوم المدعو بالتسجيل باستخدام رمز دعوة المدعو وإكمال مصادقة الاسم الحقيقي.',
	prli5: '五、تبلغ فترة الخصم N شهرًا من وقت إكمال المدعو لمصادقة الاسم الحقيقي، اعتمادًا على المستوى، تختلف نسبة الخصم.',
	prli6: '六、أصل الخصم الترويجي هو رسوم المعاملة بعملة الصديق التابع، وعملة الخصم هي العملة الأساسية، وهي: USDT.',
	prli7: '七、ترتبط نسبة الخصم الترويجي ومدة الخصم بشكل إيجابي بعدد أصدقاء المستوى الأول الذين تمت ترقيتهم، وكلما زاد عدد الأشخاص الذين تقوم بترقيتهم، زادت نسبة الخصم التي يمكنك الاستمتاع بها. نسبة الخصم الترويجية المحددة هي كما يلي:',
	prli8: '八、سيحصل العشرة الأوائل أو أعلى 100 في تصنيف الخصومات الترويجية على مكافآت إضافية صادرة رسميًا عن BNBNCOIN من وقت لآخر.',
	prli9: '九、إذا تعارضت الأنشطة الأخرى مع نشاط [الشريك الترويجي]، فسيتم تحديد التغييرات المؤقتة بعد التشاور مع الشركاء.',
	prli10: '',
	prli11: '10、يعود حق التفسير النهائي لهذا النشاط إلى BNBNCOIN.',
	prth1: 'مستوى',
	prth2: 'عدد أصدقاء المستوى الأول',
	prth3: 'نسبة الخصم لصديق المستوى الأول/وقت الخصم',
	prth4: 'نسبة خصم الأصدقاء من المستوى 2/وقت الخصم',
	prth5: 'أرباح الشركاء',
	prlev1: '',
	prlev2: '',
	prlev3: '',
	prlev4: '',
	prlev5: '',
	prlev6: '',
	prtd1: '',
	prtd2: '',
	prtit2: '',
	prtxt7: '',
	prtxt8: '',
	prtit3: '',
	prtit4: '',
	prtit5: '',
	prtxt9: '',
	prth6: '',
	prth7: '',
	prth8: '',
	prth9: '',
	prth10: '',
	prtip1: '',
	prtip2: '',
	prtip3: '',
	// 充币
	recth1: 'وقت الوصول',
	recth2: 'بروتوكول الشبكة',
	recth3: 'عنوان الإيداع',
	recth4: 'إعادة شحن الكمية',
	recbtn: '',
	recplaceholder: '',
	recstatus_0: 'يفشل',
	recstatus_1: 'لم يتم الاستلام',
	recstatus_2: 'وصلت بالفعل',
	// 提币
	wittit: 'سحب العملة الرقمية',
	wittext: 'سجل عمليات السح',
	wittext2: '',
	wittext3: '',
	witbtn: '',
	witbtn2: '',
	witdia: '',
	witdia2: '',
	witplaceholder: 'الرجاء إدخال مبلغ السحب',
	witerr: 'الرجاء تحديد العملة',
	addresstip: 'يرجى ملء العنوان',
	witstate: '',
	witstate2: '',
	witstate3: '',
	witstatus_1: 'قيد المراجعة',
	witstatus_2: 'في تَقَدم',
	witstatus_3: 'يفشل',
	witstatus_4: 'نجاح',
	// 操作类型
	type1: '',
	type2: ' ',
	type3: '',
	type4: '',
	type5: '',
	type6: '',
	type7: '',
	type8: '',
	type9: '',
	type10: '',
	type11: '',
	type12: '',
	type13: '',
	type14: '',
	type15: '',
	type16: '',
	type17: '',
	type18: '',
	type19: '',
	type20: '',
	type21: '',
	type22: '',
	type23: '',
	type24: '',
	type25: '',
	type26: '',
	type27: '',
	type28: '',
	type29: '',
	type30: '',
	type31: '',
	type32: '',
	type33: '',
	type34: '',
	type35: '',
	type36: '',
	type37: '',
	type38: '',
	type39: '',
	type40: '',
	type41: '',
	type42: '',
	type43: '',
	// 理财账户
	lctit: '',
	lcbtn: '',
	lcth1: '',
	day: '',
	lctxt: '',
	lctxt2: '',
	lctxt3: '',
	lctxt4: '',
	year: '',
	month: '',
	// 理财订单
	ldtab1: "",
	ldtab2: "",
	ldth1: "",
	ldth2: "",
	ldth3: "",
	ldth4: "",
	// 定投计划
	attit: "",
	attxt: "",
	attxt2: "",
	atth1: '',
	atth2: '',
	atth3: '',
	success: '',
	// 创新管理
	cxth1: 'اسم',
	cxth2: 'الاختلاف',
	cxth3: 'عدد الاشتراكات',
	cxth4: 'العملات المشاركة',
	cxth5: 'وحدة الاشتراك',
	cxth6: 'الحالة الحالية',
	cxth7: 'مبلغ مقطوع',
	cxth8: 'وقت الشراء',
	cxnav1: 'شاركت',
	cxnav2: 'آلة التعدين الخاصة بي',
	cxnav3: 'موقعي المقفل',
	cxtit2: 'قائمة آلة التعدين الخاصة بي',
	cxtit3: 'قائمة القفل الخاصة بي',
	cxkth1: 'عملة الإخراج',
	cxkth2: 'دورة الإخراج',
	cxkth3: 'وقت التعدين',
	cxkth4: 'الوقت المنقضي',
	cxkth5: 'القدرة الأساسية',
	cxkth6: 'القدرة الفعلية',
	cxstate1: 'لم يتم نشرها',
	cxstate2: 'اكتمل الإصدار',
	cxstate3: 'ألغيت',
	cxstate4: 'الصفقة المعلقة',
	cxstate5: 'اكتمل',
	cxstate6: '',
	cxstate7: '',
	cxcth1: '',
	cxcth2: '',
	cxcth3: '',
	cxcth4: '',
	cxcth5: '',
	cxcth6: '',
	more: '',
	// 关于我们
	abtit: 'ميزات المنصة',
	abtit2: 'معلومات عنا',
	abtit3: 'اتصل بنا',
	abtit4: 'مجتمع BNBNCOIN',
	abdesc: 'BNBNCOIN هي منصة تداول الأصول الرقمية الرائدة عالميًا والمزودة بالتكنولوجيا، وهي مسجلة في جزر كايمان ويقع فريق التشغيل الأساسي الخاص بها في هونغ كونغ. يأتي الأعضاء الأساسيون في BNBNCOIN من أفضل شركات الإنترنت والشركات المالية، ومعظم الأعضاء مؤمنون بشدة بالبيتكوين و blockchain. نحن نؤمن إيمانًا راسخًا بأن blockchain سيغير النظام المالي الاحتكاري التقليدي وينشئ هيكلًا اجتماعيًا أكثر ديمقراطية واستقلالية.',
	abdesc2: 'تحتوي منصة BNBNCIN على هيكل معاملات احترافي على المستوى المالي ومحرك معاملات مطابق للذاكرة عالي التزامن تم تطويره ذاتيًا. تتبنى المنصة نظام محفظة إعادة الشحن البارد بالكامل + نظام هجوم DDOS متعدد التوقيعات + عالي الحماية وهياكل المعاملات الأخرى لضمان ذلك. سلامة أصول العملاء.',
	abdesc3: 'من أجل السماح للعملاء بفهم فرص الاستثمار بشكل أفضل، تتبنى BNBNCOIN إدارة ثابتة داخل قسم خدمة العملاء وتنشئ آلية استجابة سريعة للغاية للخدمة، ويكون مدير خدمة عملاء الأصول متصلاً بالإنترنت على مدار الساعة طوال أيام الأسبوع لتزويد العملاء بخدمات مثل الأصول إعادة الشحن والسحب، مما يضمن استجابة العملاء في غضون 5 دقائق لإكمال إيداع الأصول وسحبها.',
	abdesc4: 'تختار BNBNCOIN بدقة المشاريع عالية الجودة وتوفر خدمات آمنة ومستقرة لحفظ الأصول. تلتزم BNBNCOIN بمفهوم "الصدق والإنصاف والحماس والانفتاح" وتسعى جاهدة لإنشاء تبادل نهائي آمن وموثوق وفعال وودود للمستخدمين.',
	abdesc5: 'على الرغم من أن الأصول الرقمية موجودة في دائرة صغيرة الآن، إلا أننا نعتقد أن هذا النوع من الأصول الذي يمكن للجميع التحكم فيه بشكل كامل سيصبح بالتأكيد شائعًا في المستقبل، فلنعمل معًا ونتطلع إليه!',
	abdesc6: '',
	abdesc7: '',
	abdesc8: '',
	abdesc9: '',
	abdesc10: '',
	abdesc11: '',
	abdesc12: '',
	abdesc13: '',
	abdesc14: '',
	abdesc15: '',
	abdesc16: '',
	// 实名认证
	realtxt: 'الاسم الفعلي',
	realtxt2: 'رقم الهوية',
	realtxt3: '',
	realtxt4: 'صور الهوية',
	realtxt5: 'صورة من بطاقة الهوية والتوقيع الشخصي',
	realtxt6: '1、يتم دعم تنسيقات JPG وPNG، ويكون حجم الملف أقل من 8M، وتكون المعلومات الموجودة على الشهادة مرئية بوضوح ولا يُسمح بأي تعديل أو حظر، ويجب أن يكون رقم الشهادة واسمها مرئيين بوضوح.',
	realtxt7: '2、يجب أن يتم التقاط الصورة بدون قبعة، وينصح بعدم وضع المكياج. أن تكون ملامح وجه الشخص الذي يحمل الهوية واضحة بشكل واضح، وأن تكون الذراعين مكشوفة بالكامل.',
	realtxt8: "3、يتعين عليك الاحتفاظ بالشهادة ونسخة مكتوبة بخط اليد (الاسم + تاريخ التقديم) في نفس الوقت. جميع تصرفاتي على هذا الموقع أقوم بها بنفسي، وأنا على دراية بالمخاطر ذات الصلة، وأنا على استعداد لتحمل جميع العواقب القانونية لذلك. حساب.'",
	realbtn: 'انقر فوق \'إرسال',
	realstate: 'لا توجد مصادقة الاسم الحقيقي',
	realstate2: 'تم إجراء مصادقة الاسم الحقيقي',
	uploadimg: "",
	uploadimg2: "",
	uploadimg3: "",
	uploaderr: "",
	save_success: 'حفظ بنجاح!',
	save_failure: 'فشل عملية الحفظ!',
	// 划转
	close: '',
	hztxt: '',
	// 商家认证
	submittip1: '',
	submittip2: '',
	submittip3: '',
	submittip4: '',
	aderr1: '',
	aderr2: '',
	aderr3: '',
	aderr4: '',
	aderr5: '',
	orderTypeNo: "",
	orderTypeYes: "",
	win: "",
	lose: "",
	tied: "",
	cancelsuccess: "",
	shelves: '',
	shelves2: '',
	business: '',
	swappleaseselectwallet: "",
	swappleaseinputamount: "",
	clickchange: "",
	daterange: "",
	// API
	apierr: '',
	apierr2: '',
	apierr3: '',
	apierr4: '',
	clickget: '',
	apisecond: '',
	apitips: "",
	apiaccess: "",
	// 自定义
	do_favorite: '',
	cancel_favorite: '',
	fail_favorite: '',
	deptotal: '',
	chart: '',
	book: '',
	trade: '',
	pt_title: "",
	pt_desc: "",
	pt_more: "",
	pt_tips: "",
	pt_card_amount: "",
	pt_card_deadline: "",
	pt_card_noend: "",
	pt_card_btn: "",
	pt_card_btn_login: "",
	pt_card_rule: "",
	pt_card_summary: "",
	pt_card_rule1: "",
	pt_card_rule2: "",
	pt_card_rule3: "",
	pt_card_rule4: "",
	pt_card_rule5: "",
	pt_card_rule6: "",
	pt_card_day: "",
	pt_card_title_tips: "",
	pt_invite_desc: "",
	saveimage: "",
	imagetips: "",
	pt_card_receivew_success: "",
	usepromotion: "",
	context_title: "",
	context_title1: "",
	context_title2: "",
	context_title3: "",
	pt_card_title: "",
	copysuccess: '',
	copyerr: '',
	noopening: "",
	createSuccess: "",
	tradewarning1: '',
	tradewarning2: '',
	tradewarning3: '',
	tradewarning4: '',
	tradeconfirmbuyin: '',
	tradeconfirmsellout: '',
	foot:{
		aboutus1:'معلومات عنا',
		aboutus: '<p>بعد إطلاق الشبكة الرئيسية في أبريل 2019، أظهرت Binance Chain سرعتها العالية\
		والتصاميم عالية الإنتاجية. تركيزها الرئيسي - التطبيقات اللامركزية الأصلية（\
		dApp）Binance Chain DEX (التبادل اللامركزي)، تم اختباره للتعامل مع الملايين\
		حجم التداول خلال فترة زمنية قصيرة، مما يدل على الكمون المنخفض لمحرك التداول الخاص به\
		وظيفة المباراة.</p>\
		<p>غالبًا ما ترتبط المرونة والتوفر ارتباطًا وثيقًا بالأداء. عندما يكون التركيز على كيفية تسهيل إصدار وتداول الأصول الرقمية، فإن هذا التصميم يجلب أيضًا\
		محدودة إلى حد ما. وكان أعلى صوت في المجتمع يرى\
		تضيف Binance Chain وظائف الامتداد القابلة للبرمجة، أو تضعها\
		وبصراحة، هذه هي وظيفة العقود الذكية والأجهزة الافتراضية. نظرًا للوظائف المحدودة الحالية لـ Binance Chain، فمن الصعب على مصدري الأصول الرقمية وأصحابها إضافة أصول جديدة\
		تحقيق اللامركزية الوظيفية في أصولهم أو تقديم أي شكل من أشكال\
		إدارة المجتمع والأنشطة المجتمعية.\
		على الرغم من التوقعات العالية بإضافة وظائف العقود الذكية إلى Binance\
		تشين، لقد كان "قرارًا صعبًا". قد يؤدي تنفيذ العقود الذكية إلى إبطاء عمليات DEX وإضافة عدم اليقين إلى تداول الأصول. وحتى هذه التسوية\
		من الممكن أن يكون الحل الأبسط هو تنفيذ برنامج افتراضي\
		جهاز يعتمد على بروتوكول توافق Tendermint الأساسي الحالي وRPC الرئيسي\
		واجهة سلسلة بينانس. ومع ذلك، سيزيد هذا الحل من تكاليف التعلم لمجتمع التطبيقات اللامركزية الحالي ولن يكون حلاً شائعًا.\
		نقترح هنا مفهوم blockchain الموازي لـ Binance Chain للحفاظ على ارتفاعه\
		أداء DEX المحلي مع الدعم الودي لوظائف العقود الذكية.\
		بعد بناء سلسلتين متوازيتين في النظام البيئي Binance Chain\
		ستعمل blockchain بشكل مستقل وستقدم خدمات مختلفة. ستُسمى السلسلة الموازية الجديدة "Binance Smart Chain" (المشار إليها فيما يلي باسم "BNBN")، بينما ستظل الشبكة الرئيسية الحالية تسمى "Binance Chain" (يشار إليها فيما يلي باسم "BNB").\
		يتبع تصميم BNBN المبادئ التالية:\
		Blockchain المستقلة: من الناحية الفنية، BNBN عبارة عن blockchain مستقل وليس حلاً من الطبقة الثانية. يجب أن تكون معظم الوظائف الفنية والتجارية الأساسية لـ BSC\
		مستقلة، لذا حتى لو توقفت عملة BNB لفترة قصيرة، فلا يزال بإمكان عملة BNBN العمل بشكل جيد.\
		متوافق مع Ethereum: أول منصة عقود ذكية عملية ومستخدمة على نطاق واسع هي\
		ايثريوم. لربط التطبيقات والمجتمعات الناضجة نسبيًا، تختار BSC\
		أن تكون متوافقة مع شبكة Ethereum الرئيسية الحالية. هذا يعني أن معظم التطبيقات اللامركزية والأنظمة البيئية\
		ستكون الأجزاء والأدوات متوافقة مع BSC دون تعديل أو\
		تغييرات طفيفة فقط؛ تتطلب عقد BNBN أجهزة مماثلة أو أعلى قليلاً\
		مواصفات التشغيل ومهارات التشغيل. يجب أن يوفر هذا التنفيذ\
		مساحة للتوافق المستمر مع الإصدارات المستقبلية من BNBN وEthereum\
		الإجماع على أساس التعهد بالأسهم والإدارة عبر السلسلة: الإجماع على أساس الأسهم\
		يعتبر التوقيع المساحي (PoS) أكثر صداقة للبيئة ويوفر\
		حوكمة المجتمع. ومن المتوقع أن يكون هذا الإجماع أفضل\
		الأداء أفضل من إجماع إثبات العمل (PoW)، أي أن وقت إنشاء الكتلة قصير، و\
		قدرة عالية على معالجة المعاملات. الاتصالات المحلية عبر السلسلة: BNB و\
		ستدعم BNBN أصلاً الاتصال عبر السلسلة بين اثنين من سلاسل الكتل. هذا\
		يجب أن يكون بروتوكول الاتصال ثنائي\
		الطريقة واللامركزية وعدم الثقة في الأطراف الثالثة. وسوف تركز على النقل\
		الأصول الرقمية بين BNB وBNBN، أي رموز BEP2 ورموز BNBN الأخرى\
		تم تقديمه لاحقًا. لا ينبغي أن يهتم البروتوكول بشكل مفرط بالمعلومات الأخرى المخزنة على البلوكشين، مع استثناءات قليلة فقط.</p>\
		<p>بناءً على مبادئ التصميم المذكورة أعلاه، يتم تنفيذ بروتوكول الإجماع الخاص بـ BNBN\
		الأهداف التالية:\
		يجب أن يكون وقت الكتلة أقصر من وقت الإيثريوم، مثل 5 ثوانٍ أو حتى أقل.\
		هناك وقت محدود لانتظار اكتمال المعاملة، على سبيل المثال حوالي دقيقة واحدة أو أقل.\
		بدون التضخم والدخل\
		يأتي جزء blockchain من رسوم المناولة، والتي يتم دفعها في شكل BNBN\
		.كن متوافقًا مع Ethereum قدر الإمكان.\
		لديها آلية حوكمة على السلسلة تعتمد على التعهد بالأسهم.\
		شهادة ترخيص الأسهم القائمة على الأسهم\
		على الرغم من إثبات إثبات العمل (PoW) في التنفيذ\
		شبكة لا مركزية، وهي ليست صديقة للبيئة وتتطلب الكثير\
		عدد الجهات الفاعلة التي تحافظ على أمن الشبكة.\
		Ethereum والعديد من الشبكات الأخرى مثل MATIC Bor وTOMOChain وGoChain وxDAI،\
		استخدم إثبات السلطة (PoA) أو أشكال مختلفة منه في سيناريوهات مختلفة، بما في ذلك\
		شبكة الاختبار والشبكة الرئيسية. يمكن لـ PoA الدفاع ضد 51% من الهجمات، كما أنها أكثر فعالية\
		منع بعض العقد البيزنطية من فعل الشر. اختر PoA كأساس\
		إن التوصل إلى توافق في الآراء هو أحد الخيارات المثالية.\
		مستوحاة مما سبق، تجمع BNBN بين DPoS وPoA للتوصل إلى توافق في الآراء. حل\
		ما يستخدم هو:\
		يتم إنشاء الكتل بواسطة عدد محدود من المدققين الذين يتناوبون في الإنشاء\
		الحظر بطريقة PoA، على غرار محرك الإجماع Clique الخاص بـ Ethereum\
		تم اختيار مجموعة من المدققين، و\
		القضاء على الحوكمة على السلسلة على أساس التعهد بالأسهم.\
		أثناء مرحلة كتلة التكوين الخاصة بإطلاق الشبكة، سيعمل عدد من العقد الموثوقة كمجموعة أولية من أدوات التحقق. بعد بدء إنتاج الكتلة، أي شخص\
		يمكنك المشاركة كمرشح في انتخاب المصادقين. تحدد حالة تعهد الأسهم أعلى 21 عقدة بحيث تصبح المجموعة التالية ذات أكبر قدر من الأسهم\
		مدقق. تتم مثل هذه الانتخابات والتصفية كل 24 ساعة.\
		BNBN هو رمز تعهد أسهم BNB.\
		من أجل الحفاظ على التعاون مع بروتوكول إجماع الإيثريوم (بما في ذلك\
		الترقية القادمة)، اختارت BNBN الاعتماد على BNB لإدارة الأسهم. هناك وحدة مخصصة لتكديس BNBN\
		الرهان على BNB. ستقبل تعهدات أسهم BNB من حاملي BNBN وتحسبها\
		مجموعة العقد ذات المخاطر الأكبر. عندما يصل UTC إلى الصفر، سيتم إرسال BNB\
		رسالة عبر السلسلة "ValidatorSetUpdate" يمكن التحقق منها، لإخطار BNBN بتحديث بياناتها\
		مجموعة المدقق. قبل إنشاء كتلة جديدة، تتحقق أدوات التحقق من صحة BNBN بشكل دوري من وجود "\
		ValidatorSetUpdate”إعادة توجيه الرسالة إلىBNB\
		إذا كان الأمر كذلك، فسوف يقومون بتحديث مجموعة المدقق بعد ارتفاع معين (أي فاصل زمني محدد مسبقًا).\
		على سبيل المثال، إذا كان BNB ينشئ كتلة كل 5 ثوانٍ وكانت فترة التحقق 240 كتلة، فإن التيار\
		سيتم فحص مجموعة المدقق خلال 1200 ثانية (20 دقيقة) وتحديثها للدورة التالية.\
		السلامة والنهائية\
		مع الأخذ في الاعتبار أن أكثر من نصف أدوات التحقق من صحة ½*N+1 صادقة وجديرة بالثقة، فإن الشبكات المستندة إلى PoA تعمل بشكل آمن وبشكل صحيح بشكل عام.\
		ومع ذلك، في بعض الحالات، لا يزال بإمكان المدققين البيزنطيين مهاجمة الشبكة، على سبيل المثال من خلال "هجوم الاستنساخ". للتأكد من أن BNBN لديه نفس الارتفاع\
		فيما يتعلق بأمان BNB، فإننا نشجع مستخدمي BNBN على الانتظار حتى يتم استلام الكتل وتأكيدها بواسطة عدة مدققين مختلفين ⅔*N+1. بهذه الطريقة، يمكن لـBNBN تحقيق ذلك\
		مستوى الأمان مشابه لـ BNB، يمكنه تحمل بيزنطة أقل من 1/3*N\
		مدقق. بالنسبة لـ 21 أداة تحقق، إذا كان وقت الكتلة 5 ثوانٍ، فعندئذٍ ⅔*N+1\
		تتطلب أدوات التحقق المختلفة (2/3*21+1)*5=75 ثانية للتأكيد. من المحتمل أن يحتاج أي تطبيق مهم لـ BNBN إلى الانتظار ⅔*N+1 لضمان نتيجة نهائية آمنة نسبيًا.\
		سيتم عرض ذلك لاحقًا في قسم "رهن وإدارة الأسهم". هذه العقوبة\
		ستكشف هذه الآلية عن أدوات التحقق الضارة في وقت قصير جدًا\
		، ويجعل تنفيذ "هجوم الاستنساخ" صعبًا جدًا أو غير اقتصادي للغاية، حتى لو تم تنفيذه. مع آلية العقوبة هذه، تكون الكتل ½*N+1 أو أقل كافية لإرضاء اللاعب\
		نهائية معظم المعاملات. سيحصل جميع مدققي BNB في مجموعة المدقق الحالية على دخل من الرسوم المدفوعة\
		نظرًا لأن BNB ليس رمزًا تضخميًا، مثل شبكات Bitcoin وEthereum. رسوم المناولة هي الدخل الرئيسي\
		مدقق. وبما أن BNB هي أيضًا أداة التحقق من صحة الرمز المميز للتطبيقات الأخرى، فسوف تستمر في الحصول على مزايا أخرى من الاحتفاظ بـ BNBN.</p>',
		newbie:'',
		newbie1:'دليل المبتدئين',
		commonproblem:'',
		commonproblem1:'مشكلة شائعة',
		information:'',
		information1:'معلومات العملة',
		disclaimer:'',
		disclaimer1:'تنصل',
		privacyclause:'',
		privacyclause1:'سياسة الخصوصية',
		agreement:'',
		agreement1:'اتفاقية المستخدم',
	},
	tradeinfo: {
		emaildone: 'تم التحقق من البريد الإلكتروني',
		emailundo: 'لم يتم التحقق من البريد الإلكتروني',
		teldone: 'تم التحقق من رقم الجوال',
		telundo: 'لم يتم التحقق من رقم الجوال',
		idcarddone: 'تم التحقق من بطاقة الهوية',
		idcardundo: 'لم يتم التحقق من بطاقة الهوي',
		exchangetimes: 'عدد المعاملات',
		price: 'سعر',
		num: 'كمية',
		paymethod: 'طريقة الدفع او السداد',
		exchangelimitamount: 'حد المعاملة',
		location: 'موقع',
		location_text: 'الصي',
		exchangeperiod: 'فترة الصفقة',
		minute: 'دقيقة',
		amounttip: 'الرجاء إدخال المبلغ',
		numtip: 'الرجاء إدخال الكمية',
		remarktip: 'أخبره بطلبك',
		remarktitle: 'تعليق',
		exchangetitle: 'تعليمات التداول',
		exchange_tip1: 'بعد بدء طلب المعاملة، يتم تأمين العملة الرقمية في الضمان وتحميها المنصة. إذا كنت بائعًا، بعد بدء طلب المعاملة، يمكنك إعادة الشحن وانتظار قيام المشتري بالدفع. يدفع المشترون خلال المهلة الزمنية للدفع. يجب عليك تحرير العملة الرقمية المحتفظ بها في الضمان عند استلام الدفع.',
		exchange_tip2: 'قبل التداول، يرجى قراءة "شروط خدمة شبكة المنصة" ومستندات المساعدة مثل الأسئلة الشائعة وأدلة المعاملات.',
		exchange_tip3: 'حذار من المحتالين! يرجى التحقق من التقييمات التي تلقاها المستخدم قبل التداول، وإيلاء المزيد من الاهتمام للحسابات التي تم إنشاؤها حديثا.',
		exchange_tip4: 'يرجى ملاحظة أن التقريب وتقلبات الأسعار قد تؤثر على المبلغ النهائي للعملة الرقمية المتداولة. يحدد المبلغ الثابت الذي تدخله المبلغ النهائي، وسيتم احتساب مبلغ العملة الرقمية من سعر الصرف المباشر في نفس وقت إصدار الطلب.',
		exchange_tip5: 'تعمل خدمات الضمان على حماية المشترين والبائعين للمعاملات عبر الإنترنت. في حالة وجود نزاع، سنقوم بتقييم جميع المعلومات المقدمة وإصدار العملة الرقمية المضمونة إلى مالكها الشرعي.',
		warning1: 'أدخل ما يصل إلى منزلتين عشريتين',
		warning2: 'مبلغ الطلب هو',
		warning3: 'أدخل ما يصل إلى 8 منازل عشرية',
		warning4: 'كمية الطلب هي',
		confirmbuyin: 'تأكيد الشراء',
		confirmsellout: 'تأكيد البيع',
		buyin: 'يشتري',
		sellout: 'يبيع',
		warning5: 'يرجى ملء الطلب كما هو مطلوب',
	},
}
