//英语
export default {

    //Error message
    error1: 'System error please contact customer service',
    error2: 'Login expired, please log in again! ',
    
    
    meun1: 'Home',
    meun2: 'Market Trend',
    meun3: 'BNBN subscription',
    meun4: 'Buy mining machine',
    meun5: 'quick promotion',
    meun6: 'white paper',
    meun7: 'Announcement',
    meun8: 'Login',
    meun9: 'Register',
    meun10: 'wallet',
    meun10_1: 'Spot account',
    meun10_2: 'U-based contract account',
    meun10_3: 'Coin-based contract account',
    meun10_4: 'Second-based contract',
    meun10_5: 'Financial Account',
    meun10_6: 'Wallet History',
    meun11: 'Order',
    meun11_1: 'Purchase order',
    meun11_2: 'Mining Order',
    meun12: 'Customer Service',
    meun12_1: 'Customer Service Information',
    meun12_2: 'Customer Service Number',
    meun12_3: 'Customer Service Email',
    meun13: 'Red envelope',
    meun13_2:'Receive red envelope',
    meun13_3:'Please enter the red envelope code',
    meun13_4:'Cancel',
    meun13_6:'Receive',
    meun13_7:'Cancellation successful',
    meun13_1: 'Account Management',
    meun13_5: 'Log out',
    meun14_1: 'Language',
    meun14_3: 'Select language',
    
    scan: 'iOS & Android scan code to download',
    footmeun1: 'About',
    footmeun1_1: 'About us',
    footmeun1_2: 'Join us',
    footmeun1_3: 'Platform Announcement',
    footmeun1_4: 'White Paper',
    footmeun2: 'Help Center',
    footmeun2_1: 'Beginner\'s Guide',
    footmeun2_2: 'FAQ',
    footmeun2_3: 'Trading Guide',
    footmeun2_4: 'Currency information',
    footmeun3: 'Terms Agreement',
    footmeun3_1: 'Disclaimer',
    footmeun3_2: 'Privacy Policy',
    footmeun3_3: 'User Agreement',
    footmeun3_4: 'Charge description',
    footmeun4: 'Contact us',
    footmeun4_1: 'Customer service email',
    footmeun4_2: 'Business cooperation',
    footmeun4_3: 'Application for currency listing',
    footmeun4_4: 'Complaint report',
    foottext: 'BNBN Global Subscription Platform',
    countdown:'Activity has ended',
    indexTxt1: 'BNBN global first subscription',
    indexTxt2: 'BNBN global first subscription',
    indexTxt3: 'Countdown to online',
    indexLabel1: 'Market Trend',
    indexVal1: 'Cryptocurrency real-time price',
    indexLabel2: 'BNBN Subscription',
    indexVal2: 'Exchange directly with a digital asset',
    indexLabel3: 'Buy mining machine',
    indexVal3: 'BNBN cryptocurrency mining',
    indexLabel4: 'Quick Promotion',
    indexVal4: 'Subscription for user benefits',
    
    indexh5: 'Financial level security',
    indexh6: 'Extremely fast deposits and withdrawals',
    indexh7: 'Global Services',
    indexh8: 'Strictly selected projects',
    indexp1: 'BITISAN was founded by a group of early Bitcoin participants and geeks. The core members of the team come from well-known companies such as Google, Microsoft, Alibaba, and Tencent. They have deep R&D capabilities and rich experience in operating Internet products. ',
    indexp2: 'BITISAN is positioned as a basic blockchain service provider and is committed to providing high-quality crypto asset trading platforms for global users. It adheres to the basic principle of "don\'t do evil" and insists on serving customers with honesty, fairness and enthusiasm, and with an open attitude. Welcome all partners/projects that are beneficial to the fundamental interests of users. ',
    indexp3: 'Comprehensive financial risk control system and anti-theft system, hot and cold wallets, and multi-signature system ensure fund security',
    indexp4: 'Deposits and withdrawals can be completed in 3 minutes at the fastest, with 24-hour manual online review to protect customers from missing the best investment opportunities',
    indexp5: 'Global business service network coverage, helping you invest in global crypto assets and trade with global users',
    indexp6: 'Strictly select high-quality encryption projects and filter 80% of extremely high-risk projects for you',
    market: 'Market information',
    //New currency subscription-wallet

    wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
    usdt:'USDT',
    platform:'BNBN',
    usdt1: 'Available balance',
    usdt2: 'Lock balance',
    platform1: 'Withdraw',
    platform2: 'Recharge',
    platform3: 'Subscription',
    transaction: 'Transaction details',
    transaction0: 'serial number',
    transaction1: 'transaction number',
    transaction2: 'Transaction quantity',
    transaction3: 'Transaction time',
    transaction4: 'USDT recharge',
    transaction5: 'Recharge address',
    transaction6: 'USDT withdrawal',
    transaction7: 'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
    transaction8: 'Withdrawal address',
    transaction9: 'Withdrawal amount',
    transaction10: 'Cancel',
    transaction11: 'OK',
    transaction12: 'Subscription',
    transaction13: 'The quantity value of the subscription refers to the amount of USDT subscribed',
    transaction14: 'Subscription quantity',
    transaction15: 'Subscription quantity must be greater than 50',
    transaction16: 'Withdrawal amount must be greater than 10',
    transaction17: 'Currency type',
    transaction18: 'No transaction details yet',
    transaction19: 'Please select a recharge address',
    transaction20: 'Please click the button again after 10 minutes',
    transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    transaction25: 'Withdrawal requires a certain amount of handling fee'+'1.98U',
    //Lock-up mining
    mining1: 'Subscription',
    mining2: 'Purchase price',
    mining3: 'Daily profit',
    mining4: 'Lock days',
    mining5: 'Buy Level',
    mining6: 'Total purchase income',
    mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
    //invite
    inviteusers1:'invitation link',
    inviteusers2:'number of invitees',
    inviteusers3:'invitation number',
    inviteusers4:'Invite users',
    inviteusers5:'invitation time',
    inviteusers6: 'Copy successful',
    inviteusers7:'No users are invited yet',
    inviteusers8:'copy',
    inviteusers10:'Active users',
    inviteusers11:'Is it active',
    // Order
    order1: 'Subscription order',
    order2: 'Financial management order',
    order3: 'subscription number',
    order4: 'subscription quantity',
    order5: 'subscription time',
    order7: 'Financial management number',
    order8: 'Locked quantity',
    order9: 'reward quantity',
    order10: 'Start time',
    order11: 'end time',
    Activationsuccessful:'Activation successful',
    activation:'activation',
    Atwork:'At work',
    Completed:'Completed',
    Subscriptionrecords :'Subscription records',
	Rechargerecords :'Recharge records',
	Rechargenumber :'Recharge number',
	Rechargeamount :'Recharge amount',
	Rechargeaddress :'Recharge address',
	Rechargetime :'Recharge time', 
	Withdrawalrecords :'Withdrawal records',
	Withdrawalnumber :'Withdrawal number',
	Withdrawalquantity :'Withdrawal quantity',
	Applicationtime :'Application time',
	Timeofreceipt :'Time of receipt',
	Withdrawalstatus :'Withdrawal status',
	operation :'operation',
	cancel:'cancel',
	recordsavailable :'There are currently no recharge records available',
	withdrawalrecord :'There is currently no withdrawal record',


    //Personal center
    personal1: 'Personal information',
    personal2: 'Real-name authentication status',
    personal3: 'Authenticated',
    personal4: 'Authenticating',
    personal5: 'Click to authenticate without authentication',
    personal6: 'Click to authenticate if authentication fails',
    personal7: 'username',
    personal8: 'email',
    personal9: 'Mobile phone number',
    personal10: 'VIP level',
    personal11: 'Ordinary member',
    personal12: 'invitation code',
    personal13:'Authentication information',
    personal14:'Please upload identity authentication related information',
    personal15:'Change password',
    personal16:'original password',
    personal17:'Change password',
    personal18:'Confirm password',
    personal19:'Please enter password',
    personal20:'The password length cannot be less than 6 characters',
    personal21:'Please enter your password again',
    personal22:'The passwords entered twice are inconsistent',
    authentication1:'Add authentication information',
    authentication2:'Confirm upload',
    authentication3:'Only jpg/png files can be uploaded, and no more than 2M',
    authentication4:'Upload file',
    authentication5:'User added and file uploaded successfully',
    authentication6:'User addition and file upload failed',
    authentication7:'Uploaded avatar pictures can only be in JPG format!',
    authentication8:'The size of the uploaded avatar image cannot exceed 2MB!',
    authentication9: 'Exceeding 5 files',

    //奖池
	pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',


    // Log in
    account0:'Account ',
    account: 'BNBNCOIN account login',
    phone: 'mobile phone number',
    email: 'email',
    sign: 'Login',
    password: 'password',
    password2: 'password',
    password3: 'Forgot password ',
    account2: 'Create account ',
    logErr: 'Please enter your account number',
    logErr2: 'Please enter password',
    logErr3: 'Password length cannot be less than 6 characters',
    logSuccess: 'Login successful',
    resetpassword:'reset password',
    reset:'reset',
    // register
    account3: 'Create personal account',
    phone1: 'Mobile phone registration',
    email1: 'Email registration',
    email2: 'email address',
    code: 'SMS verification code',
    code2: 'Send verification code',
    code3: 'Verification code',
    password4: 'Confirm password',
    invite: 'invitation code',
    agree: 'I have read and agree',
    agreement: 'User Agreement',
    register: 'Register',
    regErr: 'Please enter the SMS verification code',
    confirmpwdtip: 'Please enter the confirmation password',
    confirmpwderr: 'The two password inputs are inconsistent!',
    //forget the password
    wjtab: 'Reset mobile phone password',
    wjtab2: 'Reset email password',
    wjlabel: 'New password',
    wjerr: 'Please enter a new password',
    Tailpage:'endpage',
	homepage:'homepage',
	lastpage:'lastpage',
	nextpage:'nextpage',
	off:'OFF',
	problem:'If you encounter any problems, including real name authentication or deposits that have not yet arrived, please consult customer service in a timely manner',
    //Common notice
    announcementtitle:'announcement title',
    announcement: '<p>After the mainnet launch in April 2019, Binance Chain demonstrated its high-speed performance and high-throughput design. Its main focus - the native decentralized application ("dApp") Binance Chain DEX (decentralized exchange), has been tested to handle millions of trades in a short period of time, reflecting the low performance of its trading engine. Delayed matching capabilities. </p>\
    <p>Flexibility and availability often go hand in hand with performance. When people focus on how to provide a convenient place for the issuance and trading of digital assets, this design also brings \
    restrictions to a certain extent. The loudest voice in the community has been seeing\
    Binance Chain adds programmable extension functions, or will\
    To put it bluntly, it is smart contracts and virtual machine functions. </p><p> Due to the current limited functionality of Binance Chain, it is difficult for digital asset issuers and owners to add new\
    Spread functionality across its assets or introduce any form of \
    Community management and community activities. </p>\
    <p>Despite high expectations for Binance to add smart contract functionality\
    Chain, it was a "tough decision." Smart contract execution may slow down DEXs and add uncertainty to asset trading. Even this compromise\
    Tolerable, the easiest solution to think of might be to implement virtual\
    Machine based on the current underlying Tendermint consensus protocol and main RPC\
    Binance Chain interface. However, this solution will increase the learning cost of the existing dApp community and will not become a popular solution. \
    Here we propose the concept of Binance Chain parallel blockchain to maintain high\
    The performance of native DEX also supports smart contract functions. </p>',
    // Innovation Lab
    labTxt: 'Bitisan Lab•Sharing Innovation Achievements',
    labTxt2: 'A win-win open laboratory for investors, project parties, exchanges and other parties',
    labTab1: 'All',
    labTab2: 'About to start',
    labTab3: 'In progress',
    labTab4: 'Distributing',
    labTab5: 'Completed',
    tokendistribute: "Token is being distributed",
    activitytype: "Subscription Type",
    labStart: 'Start time',
    labEnd: 'End time',
    labStartPlaceholder: 'Please select a start time',
    labDetail: 'View details',
    labSpeen: "Progress",
    labNumber: "Total amount of activities",
    labType1: 'First time online (rush purchase)',
    labType2: 'First time online (equally divided)',
    labType3: 'position sharing',
    labType4: 'Free subscription',
    labType5: 'Cloud Mining Machine Subscription',
    labType6: 'Lock release',
    baseinfo: 'Basic information',
    ruledetail: "type description",
    releaseType: "release type",
    releaseType0: "Equal release",
    releaseType1: "Equal ratio release",
    lock: "release",
    releaseDetail: "Release details",
    lockday: "day",
    lockweek: "week",
    lockmonth: "month",
    lockyear: "year",
    releasePercent: "release ratio",
    lockFee: "Lock Threshold",
    releaseTimes: "release multiple",
    times: "times",
    publishprice: "subscription price",
    holdcoin: "position required currency",
    limittimes: "Personal purchase limit times",
    limitamount: "Personal purchase limit quantity",
    unlimited: "no limit",
    leveloneCount: "Number of level one friends required",
    alreadyamount: "Purchased quantity",
    leftamount: "remaining amount",
    myalreadyholdamount: "My participating holdings",
    alreadyholdamount: "Total holdings participating in the event",
    currentdivided: "My current position can be divided into contracts",
    holdtips: "Participating in [Hold Share] type activities requires locking up positions until the end of the activity",
    inputholdamount: "Enter the lock amount",
    pleaseinputamount: "Please enter the redemption amount",
    pleaseinputholdamount: "Please enter the lock amount",
    inputamount: "Enter the exchange amount",
    inputminingamount: "Enter the number of mining machines to purchase",
    attend: "Participate now",
    attention: "note",
    attentiontxt1: "1. After submitting to participate in [Position Sharing], [Free Subscription], [Cloud Mining Machine Subscription], [Lock-up] type activities, the assets will be frozen and will be unfrozen or unlocked after the activity is over",
    attentiontxt2: "2. After participating in [Position Sharing], [Free Subscription], [Cloud Mining Machine Subscription], [Lock-up] type activities, users shall not cancel their participation application",
    attentiontxt3: "3. If the event is canceled due to the project party or other force majeure factors, the frozen assets will be returned to the original route",
    attentiontxt4: "* The final interpretation right of this event belongs to the official Bitisan (WWW.BITISAN.NET)",
    // Coin-margined contract account
    transfer: 'Fund transfer',
    placeholder: 'Search currency',
    bith1: 'Contract transaction',
    bith2: 'Account Equity',
    bit3: 'Unrealized profit and loss',
    bith4: 'Available margin',
    bith5: 'Margin used',
    bith6: 'Freezing margin',
    bith7: 'Contract account',
    // spot account
    btn1: 'Recharge',
    btn2: 'Withdraw',
    btn3: 'Transfer',
    btn4: 'Deposit',
    btn5: 'Withdraw coins',
    totalAssets: 'Total assets converted',
    th1: 'Currency name',
    th2: 'Available assets',
    th3: 'Frozen assets',
    th4: 'Assets to be released',
    th5: 'Operation',
    // top up
    czTxt: "Digital currency recharge",
    czTxt2: "Currency",
    czTxt3: "Transfer Network",
    czTxt4: "Available balance",
    czTxt5: "Withdrawal address",
    czTxt6: "Withdrawal amount",
    czTxt7: "Amount received",
    czTxt8: "handling fee",
    czTxt9: "Warm reminder",
    czTxt10: "Minimum deposit amount:",
    czTxt10_1: "Recharges less than the minimum amount will not be credited.",
    czTxt11: "Please do not deposit any non-currency assets to the above address, otherwise the assets will not be retrieved.",
    czTxt12: "After you recharge to the above address, confirmation from the entire blockchain network node is required. Generally, the account will be credited after 3 network confirmations from the main blockchain network.",
    czTxt13: "Your recharge address will not change frequently and you can recharge repeatedly; if there is any change, we will try our best to notify you through website announcements or emails.",
    czTxt14: "Please make sure your computer and browser are secure to prevent information from being tampered with or leaked.",
    czTxt15: "Recharge Record",
    czTxt16: "To ensure the security of funds, when your account security policy changes, your password is modified, or you use a new address to withdraw coins, we will conduct a manual review of the withdrawal. Please be patient and wait for the staff to contact you by phone or email.",
    czTxt17: "The minimum withdrawal amount is:",
    czth1: 'Withdrawal time',
    czth2: 'from',
    czth3: 'to',
    czth4: 'Quantity of flash exchange',
    czth5: 'Exchange ratio',
    czth6: 'Exchange quantity',
    placeholder2: 'Please enter',
    placeholder3: 'Please select',
    czEmpty: 'No record',
    logintip: 'Please log in first! ',
    cztip: 'You have not applied for a recharge address, please click the button to get the recharge address',
    czget: 'Get recharge address',
    czplaceholder: 'Please select a protocol',
    //Second contract account
    mAccount: "Second Contract Account",
    // Wallet history
    btn6: 'Search',
    wtTxt: 'Start and end time',
    wtTxt2: 'Operation type',
    wtth1: 'Trading time',
    wtth2: 'type',
    wtth3: 'Currency',
    wtth4: 'Quantity',
    wtth5: 'handling fee payable',
    wtth6: 'Deduction of handling fee',
    wtth7: 'Actual fee',
    wtth8: 'status',
    // account security
    nav1: 'Account security',
    nav2: 'My invitation',
    nav3: 'My promotion card',
    acTxt: 'Security level',
    acbtn1: 'Not authenticated',
    acbtn2: 'bind',
    acbtn3: 'Modify',
    acbtn4: 'Authenticated',
    acbtn5: 'Bound',
    acbtn6: 'Settings',
    acbtn7: 'under review',
    acbtn8: 'Retry',
    acTxt2: 'Real-name authentication',
    acTxt3: 'Mailbox',
    acTxt4: 'Mobile phone',
    acTxt5: 'Login password',
    acTxt6: 'Fund password',
    acTxt7: 'Google Authenticator',
    acTxt8: 'Set fund password',
    acTxt9: 'Change fund password',
    acTxt10: 'Retrieve fund password',
    acDesc2: 'To ensure the security of your account, please complete real-name authentication before trading! ',
    acDesc3: 'Bind email',
    acDesc4: 'Bind mobile phone',
    acDesc5: 'Use when logging into the platform',
    acDesc6: 'When account funds change, the fund password needs to be verified',
    acDesc7: 'Please bind Google authenticator',
    acDesc8: 'Google authenticator has been bound',
    acDesc9: 'Unbind',
    acTit: 'Mobile phone binding',
    acTit2: 'Email binding',
    acTit3: 'Google binding',
    acTit4: 'Google unbind',
    mailtip: 'Please enter your email',
    emailerr2: 'The email format is incorrect, please re-enter',
    agreementtip: 'Please read and agree to the user agreement',
    acverify: "The review failed",
    again: "Please try again",
    verifiedmsg: 'Please perform real-name verification first',
    

    //Change login password
    chTxt: 'Change login password',
    chTxt2: 'Scan code to bind',
    chTxt3: 'Please enter the verification code',
    chTxt4: 'Original fund password',
    chTxt5: 'New fund password',
    chTxt6: 'Confirm new password',
    chTxt7: 'Forgot password',
    chTxt8: 'Change mobile phone verification',
    chTxt9: 'Original login password',
    chTxt10: 'New login password',
    chTxt11: 'Email verification code',
    chTxt12: 'Mobile phone verification code',
    telerr: 'Mobile phone number is incorrect',
    emailerr: 'Email is incorrect',
    codeerr: 'Verification code is incorrect',
    save: 'save',
    chtip: 'Please enter the original password',
    chtip2: 'Please enter a new login password of no less than 6 digits',
    chtip3: 'New login password is inconsistent',
    chtip4: 'Please enter the correct fund password',
    chtip5: 'Password must not be less than 6 characters',
    chtip6: 'Please enter a password of no less than 6 digits',
    chtip7: 'Password inconsistent',
    chtip8: 'Please enter the fund password',
    chtip9: 'Please enter your mobile phone number',
    chtip10: 'Please enter your login password',
    realnametip: 'Please enter your real name',
    idcardtip: 'Please enter your ID number',
    
    // payment method
    clTxt: 'Please set your payment method and be sure to use your real-name account',
    clTxt2: 'Add payment method',
    edit: 'edit',
    delete: 'delete',
    name: 'name',
    clTxt3: 'ID number',
    clTxt4: 'IBAN number',
    clTxt5: 'All payment methods',
    clLink1: 'Account name',
    clLink2: 'Edit payment method',
    clLink3: 'Payment details (optional)',
    clplaceholder1: 'Please enter your complete name',
    clplaceholder2: 'Please enter your bank account password',
    clplaceholder3: 'Please enter your payment details',
    cltip: 'Special Tip',
    cltip2: 'Please make sure to add your bank card number for instant payment. Do not include other bank or payment method details. You must add payment/receipt information for the bank of your choice. ',
    cltip3: 'Warm reminder: When you sell digital currency, the payment method you choose will be displayed to the buyer. Please confirm that the information is filled in accurately. Please use a payment account that is consistent with your Binance platform KYC identity verification. ',
    clTxt6: 'Optional',
    upload: 'upload',
    uploadTip: '(Supports JPG/JPEG/PNG/BMP format, less than 1MB)',
    //My promotion card
    tgbtn: 'Redemption code',
    tgth1: 'card name',
    tgth2: 'Redemption code',
    tgth3: 'Card currency',
    tgth4: 'Card face amount',
    tgth5: 'Total number',
    tgth6: 'Redeemed',
    tgth7: 'Collection time',
    tgbtn2: 'Promotion material',
    tgbtn3: 'Redeem now',
    tgplaceholder: 'Please enter the redemption code',
    exchangesuccess: "Exchange successful! Please go to asset management to check the balance!",
    // my invitation
    inth1: 'Invite users',
    inth2: 'Registration time',
    inth3: 'Real-name status',
    // my ad
    adTxt: 'My ad',
    adTxt2: 'When the minimum transaction amount of the ad plus the handling fee is greater than the remaining quantity of the ad, the ad will be automatically removed',
    adbtn: 'Clear condition',
    adbtn2: 'on the shelves',
    adbtn3: 'Remove',
    adth1: 'Advertising number',
    adth2: 'Ad type',
    adth3: 'Order limit',
    adth4: 'remaining quantity',
    adtip: 'Are you sure to delete',
    num_text1: 'Please enter what you want',
    num_text2: 'Quantity',
    // current delegate
    wTxt: 'In Stock',
    wTxt2: 'Current delegation',
    wTxt3: 'Trading pair',
    wTxt4: 'Trading direction',
    wth1: 'time',
    wth2: 'direction',
    wth3: 'price',
    wth4: 'Dealed',
    wth5: 'Transaction amount',
    wth6: 'Delegate type',
    wth7: 'trigger price',
    wth8: 'Transaction price',
    wth9: 'Amount of commission',
    wth10: 'Settlement profit and loss',
    wth11: 'Delegation status',
    wth12: 'Open/Close',
    wth13: 'Margin',
    wbtn: 'Cancel order',
    wtip: 'Cancellation tip',
    wtiptxt: 'Do you confirm the order cancellation',
    // Earn coins
    zbplaceholder: 'Enter the order number to start searching',
    zbtab1: 'Fixed investment plan',
    zbtab2: 'Fixed investment record',
    zbth1: 'Investment cycle',
    zbth2: 'on/off',
    zbth3: 'Cumulative investment amount',
    zbth4: 'Hold quantity',
    zbth5: 'average cost',
    zbth6: 'Unrealized profit and loss',
    zbth7: 'Next investment date',
    zbth8: 'Plan creation date',
    // Current delegate -1
    etTxt: 'Announcement Center',
    etTxt2: 'Scan to share',
    etmenu1: 'Cryptocurrency',
    etmenu2: 'Bitisan server update announcement',
    //Current commission-3
    et2menu1: 'RX. Wave field introduction',
    et2menu2: 'Is Bitcoin a Ponzi scheme?',
    // Historical commission
    hisTxt: 'Historical commission',
    // My Order
    orTab1: 'Unpaid',
    orTab2: 'Paid',
    orTab3: 'Complete',
    orTab4: 'Cancelled',
    orTab5: 'Appealing',
    ortho1: 'Order number',
    ortho2: 'Transaction currency',
    ortho3: 'Transaction type',
    ortho4: 'Trading object',
    ortho5: 'amount',
    // options contract
    qcTab1: 'Previous results',
    qcTab2: 'Real-time Quotes',
    qcTxt: 'No.',
    qcTxt2: 'period',
    qcTxt3: 'Opening time',
    qcTxt4: 'Closing time',
    qcTxt5: 'Opening price',
    qcTxt6: 'Closing price',
    qcTxt7: 'Total purchase price',
    qcTxt8: 'Amount of buy and sell',
    qcTxt9: 'My open position',
    qcTxt10: 'Forecast progress',
    qcTxt11: 'Opening price',
    qcTxt12: 'Close price',
    qcTxt13: 'Forecast period of this period',
    qcbtn1: 'Bullish',
    qcbtn2: 'Bearish',
    qcth1: 'Contract period',
    qcth2: 'Opening amount',
    qcth3: 'Prediction direction',
    qcth4: 'Prediction result',
    qcth5: 'Bonus amount',
    qcth6: 'position opening fee',
    qcth7: 'pump',
    
    // Currency details
    Internet: 'Network connection error',
    mplaceholder: 'Enter currency name to search',
    mTab: 'Select',
    mth: 'Collection',
    mth1: 'Latest price',
    mth2: '24h ups and downs',
    mTxt: '24h highest price',
    mTxt2: '24h lowest price',
    mTxt3: 'Transaction quantity',
    mTxt4: 'Funding rate',
    mTxt5: 'on',
    mTxt6: 'High',
    mTxt7: 'Low',
    mTxt8: 'Receive',
    indexth: 'Total transaction amount',
    indexbtn: 'Details',
    indexbtn1:'price',
    indexbtn2:'Quantity',
    indexbtn3:'Transaction volume',
    indexbtn4:'time',
    
    echat1: 'K-line chart',
    echat2: 'depth map',
    mTab2: 'Current position',
    mTab3: 'Delegation history',
    mth3: 'Compensation guaranteed',
    mth4: 'Opening price',
    mth5: 'Period',
    mth6: 'Close price',
    mth7: 'Profit',
    mTxt9: 'Trading mode',
    mTxt10: 'position opening time',
    mTxt11: 'Profit rate',
    mTxt12: 'Balance',
    mTxt13: 'Buy volume',
    mTxt14: '中',
    //Create ad
    cadTxt: 'Create an advertising transaction',
    cadTxt2: 'If you trade frequently, you can create your own trade ads. ',
    cadTxt2_1: 'If you only trade occasionally, we recommend you search directly',
    cadTxt2_2: 'Creating a trade ad is free. ',
    cadLink2: 'Trade Advertisement',
    cadTxt3: 'If you want to directly edit the created advertisement, please check',
    cadLink3: 'My Ad',
    cadTxt4: 'Market participation price',
    cadTxt5: 'Premium refers to the percentage above the current market price for selling',
    cadTxt6: 'Pricing formula',
    cadTxt7: '[Tip] You can go to the personal center to bind/add payment methods',
    cadTxt8: 'The maximum transaction amount cannot exceed your total selling amount 0CNYI',
    cadTxt9: 'After enabled, if a user initiates a transaction with you through this advertisement, the system will automatically send the automatic reply phrase you selected to the other party. ',
    cadTxt10: 'Premium refers to the percentage above the current market price for buying',
    cadLabel1: 'I want:',
    cadLabel2: 'Currency transaction:',
    cadLabel3: 'Country:',
    cadLabel4: 'Currency:',
    cadLabel5: 'Enable fixed price',
    cadLabel6: 'Premium:',
    cadLabel7: 'Transaction price',
    cadLabel8: 'Selling quantity:',
    cadLabel9: 'Payment method:',
    cadLabel10: 'Minimum transaction amount:',
    cadLabel11: 'Maximum transaction amount:',
    cadLabel12: 'Turn on automatic reply',
    cadLabel13: 'Fund password:',
    cadLabel14: 'Remarks:',
    cadLabel15: 'Fixed price:',
    cadLabel16: 'Autoreply:',
    cadLabel17: 'Buy quantity:',
    radio: 'for sale online',
    radio2: 'Buy online',
    cadplaceholder: 'Please set your premium',
    cadplaceholder2: 'Please enter the quantity you want to sell',
    cadplaceholder3: 'Please enter the minimum transaction amount you want',
    cadplaceholder4: 'Please enter the maximum transaction amount you want',
    cadplaceholder5: 'You can fill in your special requirements in the remarks information, such as: requirements for buyers, online time, etc.',
    cadplaceholder6: 'Please enter your transaction price',
    cadplaceholder7: 'After receiving the order, automatically reply to the buyer\'s information, such as: payment method, payment account number, etc. ',
    cadplaceholder8: 'Please enter the quantity you want to buy',
    caderr1: 'Please enter your minimum transaction amount',
    caderr2: 'The minimum transaction amount must be greater than or equal to 100! ',
    caderr3: 'The minimum transaction amount must be less than the maximum transaction amount',
    caderr4: 'Please enter your maximum transaction amount! ',
    caderr5: 'Please enter an integer',
    caderr6: 'The maximum transaction amount must be greater than the minimum transaction amount! ',
    caderr7: 'The maximum transaction amount cannot exceed the total amount of your sales',
    caderr8: 'Please select a transaction method',
    submit: 'submit',
    cadnew: 'After enabling, your currency price will not fluctuate with the market and the price will remain unchanged. ',
    //Quick exchange
    dhTxt: 'One-click exchange and enjoy the benefits',
    dhTxt2: 'Limit price',
    dhTxt3: 'Sell',
    dhTxt4: 'Buy',
    dhTxt5: 'maximum',
    dhTxt6: 'Enter amount',
    dhplaceholder: 'Available balance of spot wallet',
    dhSuccess: 'Congratulations! Redemption successful! ',
    // Fiat currency transaction
    crTxt: 'fiat currency transaction',
    crTxt2: 'Convenient, safe and fast buying and selling digital currencies',
    crTxt3: 'Dynamic adjustment',
    crTxt4: 'No handling fee',
    crTxt5: 'Instant transaction',
    crTxt6: 'Platform guarantee',
    crTxt7: 'Real-time fluctuations according to market prices',
    crTxt8: 'What users see is what they get, and there are no platform fees other than the buying and selling price',
    crTxt9: 'Introducing a platform to serve merchants, intelligent matching, and transaction orders without waiting for matching',
    crTxt10: 'Platform certified merchants, safe and secure, 24-hour customer service to protect transactions',
    crth1: 'Merchant',
    crth2: 'Number of transactions',
    crth3: 'Payment method',
    crth4: 'limit',
    crth5: 'unit price',
    crbtn: 'purchase',
    crbtn2: 'Become a merchant',
    // Fiat currency transaction 1
    cr1Txt: 'Congratulations! Your merchant certification review has passed',
    cr1Txt2: 'Prepare information',
    cr1Txt3: 'Submit for review',
    cr1Txt4: 'Authenticated',
    cr1Txt5: 'You have the following permissions',
    cr1Txt6: 'Exclusive booth',
    cr1Txt7: 'One-on-one service',
    cr1Txt8: 'Merchants have exclusive advertising booths to increase transaction success rate',
    cr1Txt9: 'Lower handling fee',
    cr1btn: 'Publish advertisement',
    cr1btn2: 'Application for surrender',
    // Regular financial management
    reTxt: 'Bitisan Financial Management• One-stop investment solution',
    reTxt2: 'Earn money immediately, simple & safe',
    reTab: 'Regular financial management',
    reTab2: 'Fixed investment and financial management',
    reTxt3: 'Regular',
    reTxt4: 'Flexible customization, higher potential income',
    reTxt6: 'fixed investment',
    reTxt7: 'Start using fixed investment and increase your assets',
    reTxt8: 'Expand all 18 periodic products',
    reth1: 'Annualized rate of return',
    reth2: 'Term (days)',
    reth3: 'Transaction limit',
    reth4: 'product',
    reth5: 'Historical return on investment',
    rebtn: 'Create plan',
    redTxt: 'Create one',
    redTxt2: 'Fixed investment plan',
    redTxt3: 'Enter the investment amount',
    redTxt4: 'My available balance is',
    redTxt5: 'Available',
    redTxt6: 'Cycle period',
    redTxt7: 'Every day',
    redTxt8: 'Weekly',
    redTxt9: 'Monthly',
    redTxt10: 'Local time',
    redTxt11: 'Your first automatic investment cycle will be',
    redTxt12: 'Start',
    confirm: 'confirm',
    confirm2: 'OK',
    cancel: 'cancel',
    redongoing: "in progress",
    redcompleted: "Completed",
    minNumErr: 'Less than the minimum starting amount',
    minNumErr2: 'The starting quantity cannot be 0',
    buyamounttip: 'Please enter the purchase amount',
    pricetipwarning: 'The transaction amount cannot be higher than',
    buyamounttipwarning: 'Buy amount cannot be higher than',
    sellamounttip: 'Please enter the sell amount',
    sellamounttipwarning: 'The selling quantity cannot be higher than',
    sellpricetip: 'Please enter the selling price',
    sellpricetipwarning: 'The selling price cannot be higher than',
    sellmore: 'The most you can sell',
    sellunit: 'piece',
    loginFirst: "Please log in first",
    betsuccess: "Congratulations! The bet was successful!",
    selectAmount: "Please select the betting amount",
    balancenotenough: "Insufficient available balance!",
    balancenotenough2: "Insufficient balance!",
    minlimitamountfailed: "The redemption amount cannot be less than the minimum redemption amount!",
    maxlimitamountfailed: "The redemption amount cannot be greater than the maximum purchase amount!",
    limittimesfailed: "The number of times you have participated exceeds the maximum number of personal purchases!",
    commitfailed: "Submit participation failed!",
    // Spot Trading
    trplaceholder: 'query',
    trplaceholder2: "Latest price",
    trplaceholder3: 'Buy at the best price on the market',
    trplaceholder4: 'Sell at the best price on the market',
    triggleplaceholder: "The default order price is the market price",
    triggerpriceshouldbigger: "Trigger price must be greater than 0",
    marginModeSuccessTip: "Contract account mode modified successfully",
    noenoughbalance: "Account margin is insufficient",
    pleaseinputopenvolume: "The opening volume must be greater than 0",
    pleaseinputcorrectopenvolume: "Please enter the correct opening volume",
    pleaseinputrightentrustprice: "Please enter the correct order price",
    noenoughposition: "Insufficient liquidable positions",
    pleaseinputclosevolume: "The closing quantity must be greater than 0",
    pleaseinputcorrectclosevolume: "Please enter the correct closing volume",
    option1: 'antenna',
    option2: 'weekly line',
    option3: 'monthly line',
    trTxt: 'Transaction amount',
    trTxt2: 'Market price',
    trTxt3: 'Selling volume',
    trSuccess: 'Submission successful',
    //u-based
    udTxt: 'Margin mode',
    udTxt2: 'Adjust contract multiple',
    udTxt3: 'Leverage',
    udTxt4: 'Perpetual',
    udTxt4_1: 'Perpetual',
    udTxt5: 'Transferable amount',
    udTxt6: 'Transfer quantity',
    udTxt7: 'Transfer all',
    udTxt8: 'Perpetual Account',
    ubtn: 'Full position',
    ubtn2: 'Isolated position',
    uplaceholder: 'perpetual contract account',
    uradio1: 'time sharing',
    uradio2: '15 minutes',
    uradio3: '1 hour',
    uradio4: '4 hours',
    uradio5: '1 day',
    uth1: 'Contract multiple',
    uth2: 'revenue',
    uth3: 'yield',
    uth4: 'Position',
    uth5: 'Measurable',
    uth6: 'Average opening price',
    uth7: 'Strong parity',
    uth8: 'Current deposit',
    uth9: 'Margin rate',
    zhang: '张',
    or: 'or',
    ureg: 'Register now',
    uTxt8: 'Perform transaction',
    uTab: 'Open a position',
    uTab2: 'Close position',
    uTxt9: 'can open long',
    uTxt10: 'Can be opened short',
    uTxt11: 'Order price',
    uplaceholder2: 'Transaction at the best price in the market',
    uTxt12: 'Buy to open long',
    uTxt13: 'Sell and open short',
    closeup: "Buy to close",
    closedown: "Sell to close long",
    uTab3: 'Take profit and stop loss',
    uTab3_yprice: 'Take profit price',
    uTab3_sprice: 'Stop loss price',
    uTab3Txt: 'Take profit/stop loss',
    uTxt14: 'My contract account',
    ulabel1: 'Account mode',
    ulabel2: 'Total account income',
    ulabel3: 'position margin',
    ulabel4: 'Fund utilization rate',
    ulabel5: 'Multiple',
    ulabel6: 'empty',
    uTxt15: 'Long',
    uTxt16: 'Short',
    total: 'total',
    limited_price: 'limit price order',
    market_price: 'Market price order',
    spot_price: 'Take profit and stop loss',
    ent_status1: "Delegating",
    ent_status2: "Canceled",
    ent_status3: "Delegation failed",
    ent_status4: "Delegation successful",
    ent_statusblast: "Liquidation",
    uTxt17: 'Long positions can be closed',
    uTxt18: 'Short position can be closed',
    //
    prev: 'previous article',
    next: 'next article',
    // Promotion partners
    prtxt: 'My invitation link',
    copy: 'copy',
    prtxt2: 'My first-level friend',
    prtxt3: 'My second-level friend',
    prtxt4: 'Commission income (equivalent to USDT)',
    prtxt5: 'Extra reward',
    prtxt6: 'Partnership level',
    prtit: 'Rule details',
    prdesc: 'Bitisan\'s [Promotion Partner] is a promotion activity with the highest rebate ratio and the longest rebate period (maximum lifetime rebate) on the entire network, allowing promoters who put in corresponding efforts to become true "partners" of the platform ”, enjoy the dividends of growing together with the Bitisan trading platform! The specific details are as follows: ',
    prli1: '1. In order to promote the concept of digital assets and expand the scale of users, Bitisan launched the [Promotion Partner] program, which is effective for a long time. ',
    prli2: '2. Inviting friends is divided into two levels. If A invites B, then B is A\'s first-level friend. If B invites C, then C is B\'s first-level friend and also A\'s second-level friend. ',
    prli3: '3. When registering as a promotion friend, you need to use the link provided by the inviter or manually enter the inviter\'s invitation code to become the first-level friend of the inviter. ',
    prli4: '4. The rebate incentive can only take effect after the invitee registers through the inviter\'s invitation code and completes real-name authentication. ',
    prli5: '5. The rebate period is N months from the time the invitee completes the real-name authentication. Depending on the level, the rebate ratio is different. ',
    prli6: '6. The promotion rebate asset is the transaction fee of the subordinate friend\'s currency, and the rebate currency is the three major market base currencies, namely: USDT, BTC, and ETH. ',
    prli7: '7. The promotion rebate ratio and rebate duration are positively correlated with the number of first-level friends promoted. The more people you promote, the higher the rebate ratio you can enjoy. The specific promotion rebate ratio is as follows:',
    prli8: '8. The top 10 or top 100 in the promotion commission ranking list will receive additional rewards officially issued by Bitisan from time to time. ',
    prli9: '9. If other activities conflict with [Promotion Partner] activities, temporary changes will be decided after consultation. We hope for your understanding from partners. ',
    prli10: 'Ten, L4 (Governor), L5 (Prefect), and L6 (Jiedushi) level partners enjoy dividend rewards of 5%, 10%, and 15% of the total annual commission rebate respectively. ',
    prli11: '11. The final interpretation right of this activity belongs to Bitisan. ',
    prth1: 'Level',
    prth2: 'Number of first-level friends',
    prth3: 'First-level friend rebate ratio/rebate time',
    prth4: 'Second-level friend rebate ratio/rebate time',
    prth5: 'Partner dividends',
    prlev1: 'Li Zheng',
    prlev2: 'County magistrate',
    prlev3: 'prefect',
    prlev4: 'Governor',
    prlev5: 'Prefect',
    prlev6: 'Jiedushi',
    prtd1: 'month',
    prtd2: 'Lifetime commission',
    prtit2: 'Example',
    prtxt7: 'If user Xiao Yan invites 100 first-level friends, and each of his first-level friends invites 5 second-level friends, Xiao Yan will have 100 first-level friends and 500 second-level friends. If the average person trades 3,000 yuan per day, then Xiao Yan’s monthly income is approximately: (100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/month. ',
    prtxt8: 'If user Xiao Yan invites 1,000 first-level friends, and each of his first-level friends invites 5 second-level friends, Xiao Yan will have 1,000 first-level friends and 5,000 second-level friends. If the average person trades 3,000 yuan per day, then Xiao Yan’s monthly income is approximately: (1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135,000/month. ',
    prtit3: 'Promotion Toolbox',
    prtit4: 'Total promotion rebate TOP20 (total list)',
    prtit5: 'TOP20 promoters (total list)',
    prtxt9: 'Statistical deadline:',
    prth6: 'Ranking',
    prth7: 'Member',
    prth8: 'Promotion number',
    prth9: 'Rebate (equivalent to USDT)',
    prth10: 'Extra reward',
    prtip1: 'The above commission data is not updated in real time, and the system counts and updates it every 24 hours. ',
    prtip2: 'The number of invitees is the number of first-level friends, and this list is the ranking of the total rebate amount',
    prtip3: 'The number of invitees is the number of first-level friends, and this list is the ranking of the number of first-level invitees',
    // Deposit coins
    recth1: 'Arrival time',
    recth2: 'Network protocol',
    recth3: 'Deposit address',
    recth4: 'Recharge quantity',
    recbtn: 'Withdraw code to recharge',
    recplaceholder: 'Please enter the withdrawal code',
    recstatus_0: 'Failed',
    recstatus_1: 'Not received',
    recstatus_2: 'Arrived',
    // Withdraw coins
    wittit: 'Digital currency withdrawal',
    wittext: 'Withdrawal record',
    wittext2: 'Withdrawal code',
    wittext3: 'Withdrawal code list',
    witbtn: 'Withdraw coins with code',
    witbtn2: 'Generate currency withdrawal code',
    witdia: 'security verification',
    witdia2: 'Withdrawal code generated successfully',
    witplaceholder: 'Please enter the withdrawal amount',
    witerr: 'Please select currency',
    addresstip: 'Please fill in the address',
    witstate: 'to be redeemed',
    witstate2: 'failed',
    witstate3: 'Exchange completed',
    witstatus_1: 'under review',
    witstatus_2: 'Transfer in progress',
    witstatus_3: 'Failed',
    witstatus_4: 'Success',
    // operation type
    type1: 'Transfer',
    type2: 'Coin-to-coin transaction',
    type3: 'Buy legal currency',
    type4: 'Sell legal currency',
    type5: 'Activity reward',
    type6: 'Promotion reward',
    type7: 'Dividend',
    type8: 'vote',
    type9: 'Manual recharge',
    type10: 'pair',
    type11: 'Activity redemption',
    type12: 'C2C buy',
    type13: 'C2C sell',
    type14: 'Send red envelope',
    type15: 'Receive red envelope',
    type16: 'Withdraw Coin Code',
    type17: 'Withdrawal code and deposit',
    type18: 'perpetual contract handling fee',
    type19: 'Perpetual Contract Profit',
    type20: 'Perpetual Contract Loss',
    type21: 'Option contract failed',
    type22: 'Option contract handling fee',
    type23: 'Option contract bonus',
    type24: 'Contract rebate',
    type25: 'Level reward',
    type26: 'Platform fee income',
    type27: 'Second contract failed',
    type28: 'Second contract bonus',
    type29: 'Financial management interest',
    type30: 'expenditure of funds',
    type31: 'Fee for obtaining funds',
    type32: 'fixed investment',
    type33: 'fixed investment sell',
    type34: 'Buy regular',
    type35: 'Regular redemption',
    type36: 'Coin-based contract transfer',
    type37: 'Coin-based contract transfer out',
    type38: 'U-based contract transfer',
    type39: 'U-based contract transfer out',
    type40: 'Second contract transfer transfer',
    type41: 'Second contract transfer out',
    type42: 'Coin transfer transfer',
    type43: 'Coin transfer out',
    // Financial account
    lctit: 'Financial management account',
    lcbtn: 'Financial transfer',
    lcth1: 'Accumulated interest',
    day: 'day',
    lctxt: 'Activity currency',
    lctxt2: 'Accept currencies',
    lctxt3: 'Starting amount',
    lctxt4: 'Select time',
    year: 'year',
    month: 'month',
    // Financial management order
    ldtab1: "Position Record",
    ldtab2: "Close record",
    ldth1: "Start earnings day",
    ldth2: "Redemption Day",
    ldth3: "Earnings estimate",
    ldth4: "profit",
    // Fixed investment plan
    attit: "Modify fixed investment plan",
    attxt: "Investment quantity",
    attxt2: "Investment cycle",
    atth1: 'fixed investment date',
    atth2: 'fixed investment amount',
    atth3: 'Transaction rate',
    success: 'success',
    // Innovation Management
    cxth1: 'name',
    cxth2: 'type',
    cxth3: 'Subscription quantity',
    cxth4: 'Participating currencies',
    cxth5: 'Subscription unit',
    cxth6: 'Current status',
    cxth7: 'Transaction volume',
    cxth8: 'Creation time',
    cxnav1: 'I participated',
    cxnav2: 'My mining machine',
    cxnav3: 'My lock',
    cxtit2: 'My mining machine list',
    cxtit3: 'My lock list',
    cxkth1: 'Output currency',
    cxkth2: 'Output cycle',
    cxkth3: 'Mining time',
    cxkth4: 'Mined time',
    cxkth5: 'Basic capacity',
    cxkth6: 'Actual capacity',
    cxstate1: 'Not deployed',
    cxstate2: 'Deployed',
    cxstate3: 'Revoked',
    cxstate4: 'To be completed',
    cxstate5: 'Ended',
    cxstate6: 'To be released',
    cxstate7: 'Releasing',
    cxcth1: 'Release currency',
    cxcth2: 'Total Lock',
    cxcth3: 'Total release period',
    cxcth4: 'Cycle released',
    cxcth5: 'Basic release',
    cxcth6: 'Actual release',
    more: 'more',
    // about Us
    abtit: 'Platform Features',
    abtit2: 'About us',
    abtit3: 'Contact us',
    abtit4: 'Bitisan Community',
    abdesc: 'Bitisan is the world\'s leading digital asset trading platform with technology, registered in the Cayman Islands, and its core operating team is located in Hong Kong. The core members of Bitisan come from top Internet and financial companies, and most of the members are deep believers in Bitcoin and blockchain. We firmly believe that blockchain will change the traditional monopolistic financial system and establish a more democratic and autonomous social structure. ',
    abdesc2: 'The Bitisan platform has a professional financial-level transaction structure and a self-developed high-concurrency memory matching transaction engine. The platform adopts a fully cold recharge wallet system + multi-signature + high-protection DDOS attack system and other transaction structures to ensure the safety of customer assets. ',
    abdesc3: 'In order to allow customers to better grasp investment opportunities, Bitisan adopts flat management within the customer service department and establishes an extremely fast service response mechanism. The asset customer service manager is online 7*24H all year round to provide customers with services such as asset recharge and withdrawal, ensuring that customers are Asset deposits and withdrawals are completed within 5 minutes. ',
    abdesc4: 'Bitisan strictly selects high-quality projects and provides safe and stable asset custody services. Bitisan adheres to the concept of "honesty, fairness, enthusiasm, and openness" and strives to create the ultimate exchange for users that is safe, reliable, efficient, and friendly. ',
    abdesc5: 'Although digital assets are only in a small circle now, we believe that this kind of asset that everyone can fully control will definitely become popular in the future. Let\'s work together and look forward to it! ',
    abdesc6: 'Customer Service',
    abdesc7: 'technical support',
    abdesc8: 'Application for currency listing',
    abdesc9: 'Complaints and suggestions',
    abdesc10: 'Business cooperation',
    abdesc11: 'WeChat',
    abdesc12: 'Weibo',
    abdesc13: 'Twitter',
    abdesc14: 'coin use',
    abdesc15: 'Telegram',
    abdesc16: 'Add "bmate601" as a WeChat friend and enter the WeChat welfare community',
    // Verified
    realtxt: 'real name',
    realtxt2: 'ID card number',
    realtxt3: 'ID card front photo',
    realtxt4: 'reverse side photo of ID card',
    realtxt5: 'Photo of holding ID card and personal signature',
    realtxt6: '1. JPG and PNG formats are supported, and the file size is less than 8M; the information on the certificate is clearly visible and no modification or blocking is allowed. The certificate number and name must be clearly visible. ',
    realtxt7: '2. The photo must be taken without a hat, and it is recommended not to wear makeup. The facial features of the person holding the ID are clearly visible, and the arms are completely exposed. ',
    realtxt8: "3. You need to hold the certificate and handwritten copy (name + application date) at the same time. 'All my actions on this site are conducted by myself, I am aware of the relevant risks, and I am willing to bear all legal consequences of this account.'",
    realbtn: 'Click to upload',
    realstate: 'Not real-named',
    realstate2: 'Realized',
    uploadimg: "Please upload a front-facing photo of your ID card",
    uploadimg2: "Please upload the reverse side photo of your ID card",
    uploadimg3: "Please upload a photo of your ID card",
    uploaderr: "The size of the uploaded image cannot exceed",
    save_success: 'Save successfully!',
    save_failure: 'Save failed!',
    // transfer
    close: 'close',
    hztxt: 'Confirm transfer',
    //Merchant certification
    submittip1: 'For the safety of your funds, please conduct [real-name authentication] first! ',
    submittip2: 'For the safety of your funds, please perform [mobile phone binding] first! ',
    submittip3: 'For the security of your funds, please set a [fund password] first! ',
    submittip4: 'Please bind at least one payment method',
    aderr1: 'Please enter the correct number',
    aderr2: 'The premium value is 0-20',
    aderr3: 'The premium value is 0-20 and cannot be 0',
    aderr4: 'Please enter the correct fixed price',
    aderr5: 'Please enter the correct number, and the maximum transaction amount does not exceed 100 coins',
    orderTypeNo: "No",
    orderTypeYes: "Yes",
    win: "success",
    lose: "failure",
    tied: "tie",
    cancelsuccess: "Cancellation successful!",
    shelves: 'The advertisement can be deleted only after it is removed from the shelves! ',
    shelves2: 'Adds can only be edited after they are removed! ',
    business: 'Please apply for merchant certification first! ',
    swappleaseselectwallet: "Please select the transfer in/out account first",
    swappleaseinputamount: "Please enter the transfer amount",
    clickchange: "Click to switch the transfer direction",
    daterange: "Please select a search date range",
    // API
    apierr: 'Please enter remark information',
    apierr2: 'Please enter the IP address',
    apierr3: 'Please enter the correct IP address',
    apierr4: 'Please enter the verification code',
    clickget: 'Click to get',
    apisecond: 'second',
    apitips: "The key is only displayed when it is added, please save it in time",
    apiaccess: "key",
    // customize
    do_favorite: 'Favorite',
    cancel_favorite: 'Cancel favorite',
    fail_favorite: 'failed',
    deptotal: 'cumulative',
    chart: 'chart',
    book: 'Order Book',
    trade: 'Latest transaction',
    pt_title: "Digital Currency Gift Card",
    pt_desc: "After redemption, you will automatically become a lower-level friend, giving gifts to friends, relatives, customers, and partners. Help promotion partners to promote better.",
    pt_more: "More tools, please stay tuned",
    pt_tips: "If you have a good idea, please send an email to promotion@Bitisan.biz. You will be rewarded if you adopt it!",
    pt_card_amount: "Card face amount",
    pt_card_deadline: "validity period",
    pt_card_noend: "Indefinitely",
    pt_card_btn: "Promotion partners can receive 30 cards for free (about 2,000 yuan)",
    pt_card_btn_login: "Get 30 free cards after logging in",
    pt_card_rule: "Rule details",
    pt_card_summary: "Digital currency gift card is one of the promotion tools developed to allow promotion partners to better invite offline friends. When promotion target users redeem gift cards, they will automatically become the promoter's offline friends.",
    pt_card_rule1: "1. Each user is limited to 30 free gift cards. If more gift cards need to be issued, the user needs to pay for customization. The card value, LOGO, explanatory text, etc. can be customized. Please send an email to for customization requirements.",
    pt_card_rule2: "2. Each free gift card can be redeemed only once per user. Each free gift card officially issued by Bitisan can only be redeemed once per user. Even if free gift cards with different redemption codes are received through different users, they can only redeem one free gift card. Redeem 1 copy. Custom-made gift cards are not subject to this restriction. ",
    pt_card_rule3: "3. Before the user redeems the gift card, if there is no \"inviter\" (that is, no invitation code was entered when registering the account), the user will automatically be associated as a first-level friend of the card issuer. For example, A did not enter the invitation code when registering the account. , at this time A does not belong to anyone's first-level or second-level friend, but when A redeems the gift card issued by B, then A automatically becomes B's first-level friend. ",
    pt_card_rule4: "4. In order to prevent fraud, the book amount of the gift card is not redeemed in real time. After receiving it, real-name authentication must be completed before it can be operated.",
    pt_card_rule5: "5. You need to complete real-name authentication when receiving free gift cards. You do not need to complete real-name authentication when redeeming. This is to facilitate promoters to obtain lower-level friends faster.",
    pt_card_rule6: "6. The final interpretation right of free gift cards belongs to Bitisan.",
    pt_card_day: "day",
    pt_card_title_tips: "Receive 2,000 yuan promotion grant",
    pt_invite_desc: "Exclusive QR code. Register through the QR code to automatically become a subordinate friend. Suitable for forwarding in various groups or forums.",
    saveimage: "Save image",
    imagetips: "If you cannot download the image normally, please use a screenshot tool to take a screenshot and save it!",
    pt_card_receivew_success: "Congratulations! Successfully received the partner promotion gift card! Please go to [Personal Center]->[Card and Coupon Center] to check!",
    usepromotion: "Use promotional materials",
    context_title: "Promotion Partner Exclusive Redemption Code",
    context_title1: "Scan to learn more",
    context_title2: "My invitation code",
    context_title3: "Invitation Picture",
    pt_card_title: "Partner Promotion Card",
    copysuccess: 'Copy successful! ',
    copyerr: 'Copy failed! Please copy manually',
    noopening: "No options contracts pending",
    createSuccess: "Created successfully",
    tradewarning1: 'Enter up to 2 decimal places',
    tradewarning2: 'The order amount is',
    tradewarning3: 'Enter up to 8 decimal places',
    tradewarning4: 'The order quantity is',
    tradeconfirmbuyin: 'Confirm buy',
    tradeconfirmsellout: 'Confirm sell',
    foot:{
    aboutus1:'About us',
    aboutus: '<p>After launching mainnet in April 2019, Binance Chain demonstrated its high speed\
    and high-throughput designs. Its main focus - native decentralized applications (\
    dApp) Binance Chain DEX (decentralized exchange), has been tested to handle millions\
    The trading volume in a short period of time demonstrates the low latency of its trading engine\
    Match function. </p>\
    <p>Flexibility and availability are often closely related to performance. When the focus is on how to facilitate the issuance and trading of digital assets, this design also brings \
    Limited to some extent. The loudest voice in the community has been seeing\
    Binance Chain adds programmable extension functionality, or puts in \
    To put it bluntly, it is the function of smart contracts and virtual machines. Due to the current limited functionality of Binance Chain, it is difficult for digital asset issuers and owners to add new\
    Decentralize functionality into their assets or introduce any form of \
    Community management and community activities. \
    Although expectations are high for smart contract functionality to be added to Binance\
    Chain, it was a "tough decision." Smart contract execution may slow down DEXs and add uncertainty to asset trading. Even this compromise\
    Tolerable, the simplest solution might be to implement virtual\
    Machine based on the current underlying Tendermint consensus protocol and main RPC\
    Binance Chain interface. However, this solution will increase learning costs for the existing dApp community and will not be a popular solution. \
    Here we propose the concept of parallel blockchain of Binance Chain to keep high\
    The performance of local DEX while friendly support for smart contract functions. \
    After building two parallel blockchains in the Binance Chain ecosystem\
    The blockchain will operate independently and provide different services. The new parallel chain will be called "Binance Smart Chain" (hereinafter referred to as "BNBN"), while the existing mainnet will still be called "Binance Chain" (hereinafter referred to as "BNB"). \
    The design of BNBN follows the following principles:\
    Standalone Blockchain: Technically, BNBN is a standalone blockchain and not a second layer solution. Most of the basic technical and business functions of the BSC should\
    Independent, so even if BNB stops for a short time, BNBN can still run well. \
    Compatible with Ethereum: The first practical and widely used smart contract platform is \
    Ethereum. In order to connect relatively mature applications and communities, BSC chooses\
    To be compatible with the existing Ethereum mainnet. This means that most dApps, ecosystems\
    Parts and tools will be BSC compatible without modification or\
    Only minor changes; BNBN nodes only require similar or slightly higher hardware\
    Operating specifications and operating skills. This implementation should provide \
    Space for continued compatibility with future versions of BNBN and Ethereum\
    Consensus based on equity pledge and on-chain management: Equity-based consensus\
    Staking (PoS) is more environmentally friendly and is\
    Community governance. It can be expected that this consensus will be better\
    Performance is better than PoW consensus, i.e. block generation time is short, and\
    High transaction processing ability. Local cross-chain communication: BNB and \
    BNBN will natively support cross-chain communication between two blockchains. this\
    The communication protocol should be two-\
    way, decentralized and distrustful of third parties. It will focus on transfer\
    Digital assets between BNB and BNBN, i.e. BEP2 tokens, and other BNBN tokens\
    Introduced later. The protocol should not be overly concerned with other information stored on the blockchain, with only a few exceptions. </p>\
    <p>Based on the above design principles, BNBN’s consensus protocol is implemented\
    The following goals:\
    Block time should be shorter than Ethereum time, such as 5 seconds or even less. \
    There is a limited time to wait for the transaction to complete, say about 1 minute or less. \
    Without inflation, income\
    The blockchain part comes from handling fees, which are paid in the form of BNBN\
    .Be as compatible with Ethereum as possible. \
    It has an on-chain governance mechanism based on equity pledge. \
    Equity-based equity authorization certificate\
    Although Proof of Work (PoW) has been proven to be implemented\
    Decentralized network, it is not environmentally friendly and requires a lot of \
    Number of actors maintaining network security. \
    Ethereum and several other networks such as MATIC Bor, TOMOChain, GoChain and xDAI,\
    Use Proof of Authorization (PoA) or its variations in different scenarios, including\
    Testnet and mainnet. PoA can defend against 51% of attacks, which is more effective\
    Prevent some Byzantine nodes from doing evil. Choose PoA as the basis\
    Reaching consensus is one of the ideal options. \
    Inspired by the above, BNBN combines DPoS and PoA to reach consensus. solution\
    The following is used: \
    Blocks are generated by a limited number of validators validators taking turns generating \
    Blocked in a PoA manner, similar to Ethereum’s Clique consensus engine\
    A set of validators has been selected and\
    On-chain governance elimination based on equity pledge. \
    During the origin block phase of network startup, many trusted nodes will\
    Operates as an initial set of validators. After block production starts, anyone\
    You can participate in the election of validators as a candidate. The equity pledge status determines the top 21 nodes with the most equity to become the next group\
    validator. Such elections and eliminations take place every 24 hours. \
    BNBN is the symbol of BNB equity pledge. \
    In order to maintain cooperation with the Ethereum consensus protocol (including\
    Upcoming upgrade), BNBN has chosen to rely on BNB for equity management. There is a module dedicated to BNBN equity\
    Bet on BNB. It will accept BNB equity pledges from BNBN holders and calculate\
    The set of nodes with the greatest stakes. Whenever UTC reaches zero, BNB will send \
    Verifiable "ValidatorSetUpdate" cross-chain message, notifying BNBN to update its\
    validator set. Before generating a new block, existing BNBN validators periodically check for the presence of "\
    ValidatorSetUpdate" message is forwarded to BNB\
    If so, they will update the validator set after a certain height (i.e. a predefined block interval). \
    For example, if BNB generates a block every 5 seconds and the check period is 240 blocks, the current\
    The validator set will be checked within 1200 seconds (20 minutes) and updated for the next cycle. \
    Safety and finality\
    Considering that more than half of ½*N+1 validators are honest and trustworthy, PoA-based networks generally work securely and correctly. \
    However, in some cases, Byzantine validators can still attack the network, such as through a "clone attack." To ensure that BNBN has the same height\
    As a matter of BNB security, we encourage BNBN users to wait until the block is received\
    Confirmed by multiple ⅔*N+1 different validators. In this way, BNBN can achieve \
    A security level similar to BNB, which can tolerate Byzantium less than 1/3*N\
    validator. For 21 validators, if the block time is 5 seconds, then ⅔* N+1\
    Different validators require (2/3*21+1)*5=75 seconds to confirm. Any significant application of BNBN will likely need to wait ⅔*N+1 to ensure a relatively safe end result. \
    This will be introduced later in the “Equity Pledge and Management” section. This punishment\
    This mechanism will expose malicious validators in a very short time\
    , and makes "clone attacks" very difficult to perform or very uneconomical, even if\
    implement. Through this penalty mechanism, ½*N+1 or less blocks are enough to satisfy\
    Finality of most transactions. \
    All BNB validators in the current validator set will receive income from the fees paid\
    Since BNB is not an inflationary token, it does not generate mining revenue\
    Such as the Bitcoin and Ethereum networks. Handling fees are the main income\
    validator. Since BNB is also a utility token for other applications\
    Validators will still receive the other benefits of holding BNBN. </p>',
    newbie:'',
    newbie1:'Beginner\'s Guide',
    commonproblem:'',
    commonproblem1:'Frequently asked questions',
    information:'',
    information1:'Currency information',
    disclaimer:'',
    disclaimer1:'disclaimer',
    privacyclause:'',
    privacyclause1:'Privacy Clause',
    agreement:'',
    agreement1:'User Agreement',
    },
    tradeinfo: {
    emaildone: 'Email has been authenticated',
    emailundo: 'Email not authenticated',
    teldone: 'Mobile phone number has been verified',
    telundo: 'Mobile phone number not verified',
    idcarddone: 'ID card has been authenticated',
    idcardundo: 'ID card not authenticated',
    exchangetimes: 'Number of transactions',
    price: 'price',
    num: 'Quantity',
    paymethod: 'payment method',
    exchangelimitamount: 'Transaction limit',
    location: 'location',
    location_text: 'China',
    exchangeperiod: 'Transaction period',
    minute: 'minute',
    amounttip: 'Please enter the amount',
    numtip: 'Please enter the quantity',
    remarktip: 'Tell him your request',
    remarktitle: 'Remarks information',
    exchangetitle: 'Transaction Notes',
    exchange_tip1: 'After you initiate a transaction request, the digital currency is locked in custody and protected by the platform. If you are a seller, after initiating a transaction request, you can recharge and wait for the buyer to pay. Buyers pay within the payment time limit. You shall release the digital currency held in escrow upon receipt of payment. ',
    exchange_tip2: 'Please read the "Platform Network Service Terms" and help documents such as FAQs and Transaction Guide before trading. ',
    exchange_tip3: 'Beware of scammers! Please check the reviews received by the user before trading, and pay more attention to newly created accounts. ',
    exchange_tip4: 'Please note that rounding and price fluctuations may affect the final amount of digital currency traded. The fixed amount you enter determines the final amount, and the digital currency amount will be calculated from the live exchange rate at the same time the request is issued. ',
    exchange_tip5: 'Escrow services protect buyers and sellers of online transactions. In the event of a dispute, we will evaluate all information provided and release the escrowed digital currency to its rightful owner. ',
    warning1: 'Enter up to 2 decimal places',
    warning2: 'The order amount is',
    warning3: 'Enter up to 8 decimal places',
    warning4: 'The order quantity is',
    confirmbuyin: 'Confirm buy',
    confirmsellout: 'Confirm sell',
    buyin: 'buy',
    sellout: 'Sell',
    warning5: 'Please fill in the order as required',
    }
}