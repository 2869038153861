export default{
	//エラープロンプト
	error1:'システムエラーはカスタマーサービスに連絡してください',
	error2:'ログイン期限が切れています。再ログインしてください！',
	meun1:'トップページ',
	meun2:'市場トレンド',
	meun3:'BNBN請購',
	meun4:'購入鉱机',
	meun5:'ラピッドプロモーション',
	meun6:'白書',
	meun7:'公告',
	meun8:'ログイン',
	meun9:'登録',
	meun10:'財布',
	meun10_1:'現物口座',
	meun10_2:'U本位契約口座',
	meun10_3:'通貨本位契約口座',
	meun10_4:'秒本位契約',
	meun10_5:'理財勘定',
	meun10_6:'ウォレット履歴',
	meun11:'オーダー',
	meun11_1:'注文',
	meun11_2:'マイニングオーダー',
	meun12:'客服',
	meun12_1:'カスタマーサービス情報',
	meun12_2:'カスタマーサービス番号',
	meun12_3:'客服郵便受け',
	meun13:'紅包',
	meun13_2:'受取紅包',
	meun13_3:'紅包受取コードを入力してください',
	meun13_4:'キャンセル',
	meun13_6:'受領',
	meun13_7:'キャンセル成功',
	meun13_1:'アカウント管理',
	meun13_5:'ログアウト',
	meun14_1:'言語',
	meun14_3:'言語を選択',

	scan:'iOS&Androidスキャンコードダウンロード',
	footmeun1:'について',
	footmeun1_1:'私たちについて',
	footmeun1_2:'参加',
	footmeun1_3:'プラットフォーム公告',
	footmeun1_4:'白書',
	footmeun2:'ヘルプセンター',
	footmeun2_1:'初心者ガイド',
	footmeun2_2:'よくある質問',
	footmeun2_3:'取引ガイド',
	footmeun2_4:'通貨資料',
	footmeun3:'条項契約',
	footmeun3_1:'免責条項',
	footmeun3_2:'プライバシー規約',
	footmeun3_3:'ユーザープロトコル',
	footmeun3_4:'料金説明',
	footmeun4:'連絡先',
	footmeun4_1:'客服郵便受け',
	footmeun4_2:'ビジネス提携',
	footmeun4_3:'上幣申請',
	footmeun4_4:'苦情通報',
	foottext:'BNBNグローバル申し込みプラットフォーム',
	countdown:'アクティビティが終了しました',
	indexTxt1:'BNBN世界初買付',
	indexTxt2:'BNBN世界初買付',
	indexTxt3:'オンラインカウントダウン',
	indexLabel1:'市場トレンド',
	indexVal1:'暗号通貨のリアルタイム価格',
	indexLabel2:'BNBN購入',
	indexVal2:'デジタル資産と直接交換',
	indexLabel3:'マイニングマシンの購入',
	indexVal3:'BNBN暗号通貨マイニング',
	indexLabel4:'クイックプロモーション',
	indexVal4:'購入ユーザー福利厚生',

	indexh5:'金融レベルのセキュリティ',
	indexh6:'超速充提',
	indexh7:'グローバルサービス',
	indexh8:'厳選アイテム',
	indexp1:'BITISANはビットコインの初期の参加者とギークによって創立され、チームの中心メンバーはグーグル、マイクロソフト、アリババ、テンセントなどの有名な企業から来て、深い研究開発実力と豊富なインターネット製品の運営経験を持っています。',
	indexp2:'BITISANはブロックチェーンの基礎プロバイダーに位置付けて、全世界のユーザーに優良な暗号資産取引プラットフォームを提供することに尽力して、\'悪を行わない\'の基本原則を堅持して、誠実、公正、情熱のサービスを堅持して、開放的な態度ですべてのユーザーの根本的な利益に有利なパートナー/プロジェクトを迎えます。',
	indexp3:'全方位金融風制御システムと盗難防止システム、冷熱財布、多署名システム保証資金安全',
	indexp4:'最短3分でチャージ、24Hオンライン監査、最高の投資機会を逃さないように顧客を保護',
	indexp5:'グローバル・ビジネス・サービス・ネットワークをカバーし、グローバル暗号資産への投資を支援し、グローバル・ユーザーとの取引を支援する',
	indexp6:'高品質の暗号化アイテムを厳選し、リスクの高いアイテムの80%をフィルタリング',
	market:'市場情報',
	//新貨申し込み-ウォレット
	wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
	USDT:'USDT',
	platform:'BNBN',
	usdt1:'使用可能残高',
	usdt2:'残高のロック',
	platform1:'現金化',
	platform2:'チャージ',
	platform3:'お申し込み',
	transaction:'取引明細',
	transaction0:'連番',
	transaction1:'トランザクション番号',
	transaction2:'取引数',
	transaction3:'取引時間',
	transaction4:'USDTチャージ',
	transaction5:'チャージアドレス',
	transaction6:'USDTキャッシュアウト',
	transaction7:'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
	transaction8:'キャッシュアドレス',
	transaction9:'キャッシュ数',
	transaction10:'キャンセル',
	transaction11:'了解',
	transaction12:'購入',
	transaction13:'購入数量値は購入したUSDTの数量',
	transaction14:'購入数量',
	transaction15:'購入数量は50より大きくなければなりません',
	transaction16:'キャッシュアウト数は10より大きくなければなりません',
	transaction17:'通貨タイプ',
	transaction18:'取引の詳細はありません',
	transaction19:'チャージアドレスを選択してください',
	transaction20:'10分後にボタンをクリックしてください',
	transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
	transaction24: 'The amount of exchange must be greater than 0',
	transaction25:'現金化には一定の手数料が必要です',
	//ロックダウン
	mining1:'OP',
	mining2:'購入価格',
	mining3:'毎日収益',
	mining4:'ロック日数',
	mining5:'購買等級',
	mining6:'購入総収益',
	mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
	//招待
	inviteusers1:'招待リンク',
	inviteusers2:'招待人数',
	inviteusers3:'招待番号',
	inviteusers4:'招待ユーザー',
	inviteusers5:'招待時間',
	inviteusers6:'複製成功',
	inviteusers7:'招待されていないユーザー',
	inviteusers8:'複製',
	inviteusers10:'Number of subscribers',
	inviteusers11:'Is it active',
	//注文
	order1:'注文書',
	order2:'財テクオーダー',
	order3:'購入番号',
	order4:'購入数量',
	order5:'購入時間',
	order7:'財テク番号',
	order8:'ロック数',
	order9:'ボーナス数',
	order10:'開始時間',
	order11:'終了時間',
	order12:'購入情報はありません',
	order13:'資産運用情報はありません',
	//個人センター
	personal1:'個人情報',
	personal2:'実名認証ステータス',
	personal3:'認証済み',
	personal4:'認定中',
	personal5:'認証なしクリック認証',
	personal6:'認証失敗クリック認証',
	personal7:'ユーザー名',
	personal8:'メールボックス',
	personal9:'携帯電話番号',
	personal10:'VIP等級',
	personal11:'一般会員',
	personal12:'招待コード',
	personal13:'認証情報',
	personal14:'ID認証情報をアップロードしてください',
	personal15:'パスワードの変更',
	personal16:'元のパスワード',
	personal17:'パスワードの変更',
	personal18:'パスワードの確認',
	personal19:'パスワードを入力してください',
	personal20:'パスワードの長さは6桁未満にすることはできません',
	personal21:'パスワードをもう一度入力してください',
	personal22:'2回入力されたパスワードが一致しません',
	authentication1:'認証情報の追加',
	authentication2:'アップロードOK',
	authentication3:'アップロードできるのはjpg/pngファイルのみで、2M以下',
	authentication4:'ファイルのアップロード',
	authentication5:'ユーザーの追加とファイルのアップロードに成功しました',
	authentication6:'ユーザーの追加とファイルのアップロードに失敗しました',
	authentication7:'アバター画像のアップロードはJPG形式のみ！',
	authentication8:'アップロードするアバターのサイズは2MBを超えてはいけません！',
	authentication9:'ファイルが5つを超えています',
	//ログイン
	account0:'アカウント',
	account:'BNBNCOINアカウントログイン',
	phone:'携帯電話番号',
	email:'メールボックス',
	sign:'ログイン',
	password:'パスワード',
	password2:'パスワード',
	password3:'パスワードを忘れました',
	account2:'アカウントの作成',
	logErr:'アカウントを入力してください',
	logErr2:'パスワードを入力してください',
	logErr3:'パスワードの長さは6桁未満にすることはできません',
	logSuccess:'ログイン成功',
	resetpassword:'パスワードのリセット',
	reset:'リセット',
	
    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
	//登録
	account3:'個人アカウントの作成',
	phone1:'携帯電話登録',
	email1:'メールボックス登録',
	email2:'メールアドレス',
	code:'SMS認証コード',
	code2:'認証コードの送信',
	code3:'認証コード',
	password4:'パスワードの確認',
	invite:'招待コード',
	agree:'読んで同意しました',
	agreement:'ユーザープロトコル',
	register:'登録',
	regErr:'SMS認証コードを入力してください',
	confirmpwdtip:'確認パスワードを入力してください',
	confirmpwderr:'2回のパスワード入力が一致しません！',
	//パスワードを忘れた
	wjtab:'携帯電話のパスワードをリセット',
	wjtab2:'メールボックスのパスワードをリセット',
	wjlabel:'新しいパスワード',
	wjerr:'新しいパスワードを入力してください',
	Tailpage:'末尾',
	homepage:'トップページ',
	lastpage:'前のページ',
	nextpage:'次ページ',
	off:'オフ',
	problem:'実名認証を含む問題に遭遇した場合、預金が到着していない場合は速やかにカスタマーサービスに問い合わせてください',
	//共告
	announcementtitle:'告知タイトル',
	announcement:'<p>2019年4月にメインオンライン化された後、コインアンチェーンはその高速性能とハイスループット設計を披露しました。その主要な焦点-ネイティブ分散型アプリケーション（"dApp"）コインアンチェーンDEX（分散型取引所）は、数百万の短期間の取引量を処理するテストを受けており、その取引エンジンの低遅延マッチング能力を具現化しています。</p>\
<p>多くの場合、柔軟性と可用性はパフォーマンスとともに向上します。この設計は、デジタル資産の発行と取引のための便利な場所を提供することに注目が集まっているときに、\
ある程度の制限。コミュニティの中で一番大きな声はいつも見ています\
コインアンペアチェーンにプログラム可能な拡張機能を追加するか、\
端的に言えば、スマートコントラクトと仮想マシン機能です。</p><p>コインアンペアチェーンの現在の機能は制限されているため、デジタル資産の発行者や所有者が新たな\
機能を資産に分散するか、または任意の形式の\
コミュニティマネジメントとコミュニティ活動。</p>\
<p>コイン安にスマートコントラクト機能が追加されることへの期待が高まっているにもかかわらず\
チェイン、“苦渋の決断”でした。スマートコントラクトの実行は、DEXの速度を低下させ、資産取引に不確実性を高める可能性があります。たとえこのような妥協があっても\
仮想化\
現在基盤となっているTendermintコンセンサスプロトコルとマスターRPCに基づくマシン\
コインアンチェーンのインターフェース。しかし、このような案は既存のdAppコミュニティの学習コストを増やすことになり、ポピュラーな案にはなりません。\
ここでは、Binance Chain並列ブロックチェーンの概念を提案し、高さを維持します\
ネイティブDEXのパフォーマンスはスマートコントラクト機能をサポートしています。</p>',
	//イノベーションラボ
	labTxt:'Bitisan Lab•革新的な成果の共有',
	labTxt2:'投資家、プロジェクト、取引所など多方面がウィンウィンのオープンラボ',
	labTab1:'すべて',
	labTab2:'まもなく開始',
	labTab3:'進行中',
	labTab4:'ディスパッチ中',
	labTab5:'完了',
	tokendistribute:'Token配布中',
	activitytype:'応募タイプ',
	labStart:'開始時間',
	labEnd:'終了時間',
	labStartPlaceholder:'開始時間を選択してください',
	labDetail:'詳細を表示',
	labSpeen:'進行状況',
	labNumber:'アクティビティの合計',
	labType1:'初回オンライン(買い占め)',
	labType2:'初上線(山分け)',
	labType3:'持倉分割',
	labType4:'自由応募',
	labType5:'云鉱机加入',
	labType6:'ロックリリース',
	baseinfo:'プロフィール',
	ruledetail:'型の説明',
	releaseType:'リリースタイプ',
	releaseType0:'均等解放',
	releaseType1:'等比解放',
	lock:"期解放",
	releaseDetail:'リリースの詳細',
	lockday:"日",
	lockweek:"周",
	lockmonth:'月',
	lockyear:"年",
	releasePercent:'解放スケール',
	lockFee:'鍵倉敷居',
	releaseTimes:'解放倍数',
	times:"倍",
	publishprice:'引受価格',
	holdcoin:'保有要件通貨',
	limittimes:'個人の購入回数制限',
	limitamount:'個人限定数',
	unlimited:"無制限",
	leveloneCount:'1レベルの友達数が必要です',
	alreadyamount:'購入数量',
	leftamount:'残数',
	myalreadyholdamount:'私の参加保有',
	alreadyholdamount:'イベント参加総持高',
	currentdivided:'現在の私の持ち株は分割可能です',
	holdtips:'参加【持倉分割】型活働要ロック~活働終了',
	inputholdamount:'ロック数を入力',
	pleaseinputamount:'両替数量を入力してください',
	pleaseinputholdamount:'ロック数を入力してください',
	inputamount:'換算数量を入力',
	inputminingamount:'マイニングマシンの数を入力',
	attend:'今すぐ参加',
	attention:'注意',
	attentiontxt1:'1、提出参与【持倉分割】、【自由引受】、【云鉱机引受】、【ロック】類型活働後将凍結資産、活働終了後解氷或いは解錠釈放',
	attentiontxt2:'2、参加【持倉分割】、【自由応募】、【云鉱机応募】、【ロック】タイプ活働後、ユーザーは参加申請を取り消すことはできません',
	attentiontxt3:'3、プロジェクトおよびその他の不可抗力要因によりイベントがキャンセルされた場合は、元のルートを凍結資産に戻します',
	attentiontxt4:'*この活働の最終的な解釈権はBitisan(WWW.BITISAN.NET)が公式に所有します',
	//通貨本位契約勘定
	transfer:'資金振り替え',
	placeholder:'検索通貨',
	bith1:'契約取引',
	bith2:'勘定権益',
	bith3:'未実現損益',
	bith4:'使用可能保証金',
	bith5:'使用済保証金',
	bith6:'凍結保証金',
	bith7:'契約口座',
	//現物口座
	btn1:'チャージ',
	btn2:'現金化',
	btn3:'',
	btn4:'充幣',
	btn5:'提幣',
	totalAssets:'総資産換算',
	th1:'通貨名',
	th2:'使用可能資産',
	th3:'凍結資産',
	th4:'待釈放資産',
	th5:'オペレーション',
	//チャージ
	czTxt:'デジタル通貨チャージ',
	czTxt2:'通貨',
	czTxt3:'振り込みネットワーク',
	czTxt4:'使用可能残高',
	czTxt5:'ティコインアドレス',
	czTxt6:'コインの数',
	czTxt7:'入金数',
	czTxt8:'手数料',
	czTxt9:'お知らせ',
	czTxt10:'最小チャージ金額:',
	czTxt10_1:'最小金額未満のチャージはチャージされません。',
	czTxt11:'上記アドレスに非通貨資産をチャージしないでください。チャージしないと、資産は回収できません。',
	czTxt12:'あなたが上記のアドレスにチャージした後、ブロックチェーンネットワークノード全体の確認が必要であり、一般的なブロックチェーンマスターネットワークの3回のネットワーク確認後に入金されます。',
	czTxt13:'あなたのチャージアドレスは頻繁に変更されることはなく、繰り返しチャージすることができます、変更があった場合は、Webサイトの告知またはメールでできる限りお知らせします。',
	czTxt14:'コンピュータおよびブラウザのセキュリティを必ず確認し、情報の改ざんや漏洩を防止してください。',
	czTxt15:'チャージ記録',
	czTxt16:'資金の安全性を確保するために、あなたのアカウントのセキュリティポリシーの変更、パスワードの変更、新しいアドレスを使用してコインをティアップするとき、私たちは手動でコインを監査しますので、スタッフが電話またはメールで連絡するまでお待ちください。',
	czTxt17:'最小コイン数:',
	czth1:'キャッシュタイム',
	czth2:'from',
	czth3:'~',
	czth4:'フラッシュ数',
	czth5:'換算比率',
	czth6:'換金数量',
	placeholder2:'入力してください',
	placeholder3:'選択してください',
	czEmpty:'レコードなし',
	logintip:'まずログインしてください！',
	cztip:'あなたはチャージアドレスを申請したことがありません。ボタンをクリックしてチャージアドレスを取得してください',
	czget:'チャージアドレスを取得',
	czplaceholder:'プロトコルを選択してください',
	//秒契約口座
	mAccount:'秒契約アカウント',
	//ウォレットの履歴
	btn6:'Search',
	wtTxt:'開始時間',
	wtTxt2:'操作タイプ',
	wtth1:'取引時間',
	wtth2:'タイプ',
	wtth3:'通貨',
	wtth4:'数',
	wtth5:'支払手数料',
	wtth6:'手数料控除',
	wtth7:'',
	wtth8:'ステータス',
	//アカウントセキュリティ
	nav1:'アカウントセキュリティ',
	nav2:'私の招待',
	nav3:'私のプロモーションカード',
	acTxt:'セキュリティレベル',
	acbtn1:'未認証',
	acbtn2:'バインド',
	acbtn3:'変更',
	acbtn4:'認証済み',
	acbtn5:'バインド済み',
	acbtn6:'設定',
	acbtn7:'レビュー中',
	acbtn8:'再試行',
	acTxt2:'実名認証',
	acTxt3:'メールボックス',
	acTxt4:'携帯電話',
	acTxt5:'ログインパスワード',
	acTxt6:'資金パスワード',
	acTxt7:'Googleバリデータ',
	acTxt8:'資金パスワードの設定',
	acTxt9:'資金パスワードの変更',
	acTxt10:'資金パスワードを取り戻す',
	acDesc2:'アカウントのセキュリティを確保するため、取引を行う前に実名認証を完了してください！',
	acDesc3:'メールボックスのバインド',
	acDesc4:'携帯電話をバインド',
	acDesc5:'プラットフォームへのログイン時に使用',
	acDesc6:'口座の資金が変更された場合、資金パスワードを確認する必要があります',
	acDesc7:'Googleバリデータをバインドしてください',
	acDesc8:'Googleベリファイアがバインドされました',
	acDesc9:'バインド解除',
	acTit:'携帯電話のバインド',
	acTit2:'メールボックスバインド',
	acTit3:'Googleバインド',
	acTit4:'Googleバインド解除',
	mailtip:'メールボックスを入力してください',
	emailerr2:'メールボックスの形式が正しくありません。再入力してください',
	agreementtip:'ユーザー契約を読んで同意してください',
	acverify:'モデレートに失敗しました',
	again:'再試行してください',
	verifiedmsg:'まず実名認証を行ってください',

	
	//ログインパスワードの変更
	chTxt:'ログインパスワードの変更',
	chTxt2:'コードバインディング',
	chTxt3:'認証コードを入力してください',
	chTxt4:'原資暗証番号',
	chTxt5:'新資金パスワード',
	chTxt6:'新しいパスワードの確認',
	chTxt7:'パスワードを忘れました',
	chTxt8:'携帯電話の認証を変更',
	chTxt9:'元のログインパスワード',
	chTxt10:'新しいログインパスワード',
	chTxt11:'メールボックス認証コード',
	chTxt12:'携帯電話認証コード',
	telerr:'携帯電話番号が正しくありません',
	emailerr:'不正なメールボックス',
	codeerr:'不正な認証コード',
	save:'保存',
	chtip:'元のパスワードを入力してください',
	chtip2:'6桁以上の新しいログインパスワードを入力してください',
	chtip3:'新しいログインパスワードが一致しません',
	chtip4:'正しい資金パスワードを入力してください',
	chtip5:'パスワードは6文字以内です',
	chtip6:'6桁以上のパスワードを入力してください',
	chtip7:'パスワードが一致しません',
	chtip8:'資金パスワードを入力してください',
	chtip9:'携帯電話番号を入力してください',
	chtip10:'ログインパスワードを入力してください',
	realnametip:'実名を入力してください',
	idcardtip:'ID番号を入力してください',

	//入金方法
	clTxt:'あなたの入金方法を設定してください。必ず本人の実名アカウントを使用してください',
	clTxt2:'入金方法の追加',
	edit:'編集',
	delete:'削除',
	name:'名前',
	clTxt3:'ID番号',
	clTxt4:'IBAN番号',
	clTxt5:'すべての入金方法',
	clLink1:'アカウント名',
	clLink2:'入金方法の編集',
	clLink3:'支払いの詳細(オプション)',
	clplaceholder1:'フルネームを入力してください',
	clplaceholder2:'銀行口座のパスワードを入力してください',
	clplaceholder3:'お支払いの詳細を入力してください',
	cltip:'特別ヒント',
	cltip2:'即時の支払いには必ず銀行カード番号を追加してください。 その他の銀行や支払方法の詳細は含めないでください。選択した銀行の支払/入金情報を追加する必要があります。',
	cltip3:'ご注意:デジタル通貨を販売する際には、選択した入金方法が買い手に表示されます。情報が間違いなく記入されていることを確認してください。ご本人のコイン安プラットフォームKYC身分認証と一致する入金口座をご利用ください。',
	clTxt6:'オプション',
	upload:'アップロード',
	uploadTip:'(JPG/JPEG/PNG/BMP形式をサポート、1MB未満)',
	//マイ・プロモーション・カード
	tgbtn:'交換コード',
	tgth1:'カード名',
	tgth2:'交換コード',
	tgth3:'カード面通貨',
	tgth4:'カード面金額',
	tgth5:'総数',
	tgth6:'すでに',
	tgth7:'受領時間',
	tgbtn2:'推荐素材',
	tgbtn3:'今すぐ換金',
	tgplaceholder:'交換コードを入力してください',
	exchangesuccess:'交換に成功しました！アセットマネジメントで残高をチェックしてください！',
	//私の招待
	inth1:'招待ユーザー',
	inth2:'登録時間',
	inth3:'実名ステータス',
	//私の広告
	adTxt:'マイアド',
	adTxt2:'広告の最小取引額に加えて購入した数が広告残数より多い場合、その広告は自働的に棚から下がる',
	adbtn:'条件をクリア',
	adbtn2:'上架',
	adbtn3:'下架',
	adth1:'広告番号',
	adth2:'広告タイプ',
	adth3:'オーダー制限',
	adth4:'残数',
	adtip:'削除してもよろしいですか',
	num_text1:'入力してください',
	num_text2:'の数',
	//現在の委任
	wTxt:'現物',
	wTxt2:'現在の委任',
	wTxt3:'取引対',
	wTxt4:'取引方向',
	wth1:'タイム',
	wth2:'方向',
	wth3:'価格',
	wth4:'成約済み',
	wth5:'成約金額',
	wth6:'委任タイプ',
	wth7:'',
	wth8:'落札価格',
	wth9:'依頼数',
	wth10:'決算損益',
	wth11:'委任状態',
	wth12:'開倉/平倉',
	wth13:'保証金',
	wbtn:'下げ単',
	wtip:'下げ単提示',
	wtiptxt:'撤去を確認するか',
	//コインを稼ぐ
	zbplaceholder:'注文番号を入力して検索を開始',
	zbtab1:'定投計画',
	zbtab2:'定投記録',
	zbth1:'投資サイクル',
	zbth2:'オン/オフ',
	zbth3:'累計投資額',
	zbth4:'保有数',
	zbth5:'平均コスト',
	zbth6:'未実現損益',
	zbth7:'次回定投日',
	zbth8:'スケジュール作成日',
	//現在の委任-1
	etTxt:'公告センター',
	etTxt2:'掃一掃分享',
	etmenu1:'暗号化デジタル通貨',
	etmenu2:'Bitisanサーバ更新のお知らせ',
	//現在の委任-3
	et2menu1:'RX.波場簡介',
	et2menu2:'ビットコインはポンジ・ペテンか？',
	//歴史的依頼
	hisTxt:'歴史的依頼',
	//私の注文
	orTab1:'未払い',
	orTab2:'支払い済み',
	orTab3:'完了',
	orTab4:'キャンセル',
	orTab5:'訴え中',
	orth1:'オーダー番号',
	orth2:'取引通貨',
	orth3:'取引タイプ',
	orth4:'取引対象',
	orth5:'金額',
	//オプション契約
	qcTab1:'過去の結果',
	qcTab2:'リアルタイム相場',
	qcTxt:'第',
	qcTxt2:'期',
	qcTxt3:'開始時間',
	qcTxt4:'終了時間',
	qcTxt5:'寄り付き価格',
	qcTxt6:'引け値',
	qcTxt7:'買上げ総額',
	qcTxt8:'',
	qcTxt9:'私の開倉',
	qcTxt10:'予測進捗',
	qcTxt11:'始値',
	qcTxt12:'終値',
	qcTxt13:'当期予測サイクル',
	qcbtn1:'コール',
	qcbtn2:'',
	qcth1:'契約期間数',
	qcth2:'開倉金額',
	qcth3:'予測方向',
	qcth4:'予測結果',
	qcth5:'ボーナス数',
	qcth6:'開倉手数料',
	qcth7:'水',

	//通貨詳細
	インターネット:'ネットワーク接続エラー',
	mplaceholder:'通貨名検索の入力',
	mTab:'オプション',
	mth:'收藏',
	mth1:'最新価格',
	mth2:'24h騰落',
	mTxt:'24h最高値',
	mTxt2:'24h最安値',
	mTxt3:'取引数量',
	mTxt4:'資金レート',
	mTxt5:'開',
	mTxt6:'高',
	mTxt7:'低',
	mTxt8:'収',
	indexth:'トランザクション合計',
	indexbtn:'詳細',
	indexbtn1:'価格',
	indexbtn2:'数量',
	indexbtn3:'出来高',
	indexbtn4:'時間',


	echat1:'K線図',
	echat2:'深さ図',
	mTab2:'現在のポジション',
	mTab3:'依頼履歴',
	mth3:'謝',
	mth4:'開倉価格',
	mth5:'サイクル',
	mth6:'平倉価格',
	mth7:'利益',
	mTxt9:'取引モード',
	mTxt10:'開倉時間',
	mTxt11:'収益率',
	mTxt12:'残高',
	mTxt13:'購入量',
	mTxt14:'中',
	//広告の作成
	cadTxt:'広告取引を作成',
	cadTxt2:'頻繁に取引を行う場合は、独自の取引広告を作成することができます。',
	cadTxt2_1:'たまにしか取引しない場合は、直接検索することをお勧めします',
	cadTxt2_2:'トランザクション広告の作成は無料です。',
	cadLink2:'トランザクション広告',
	cadTxt3:'作成した広告を直接編集する場合は、',
	cadLink3:'私の広告',
	cadTxt4:'市場参加価格',
	cadTxt5:'プレミアムとは現在の市場価格よりも何パーセント高く売ることです',
	cadTxt6:'評価式',
	cadTxt7:'【ヒント】個人センターへのバインド/支払い方法の追加が可能',
	cadTxt8:'最大取引額はあなたの売却総額を超えてはいけません0CNYI',
	cadTxt9:'を有効にすると、ユーザーはこの広告を通じてあなたに取引を開始し、システムはあなたが選択した自動返信用語を自動的に相手に送信します。',
	cadTxt10:'プレミアムとは、現在の市場価格より何パーセント高い値で購入することです',
	cadLabel1:'欲しい:',
	cadLabel2:'通貨取引:',
	cadLabel3:'国:',
	cadLabel4:'通貨:',
	cadLabel5:'開開固定価格',
	cadLabel6:'プレミアム:',
	cadLabel7:'取引価格',
	cadLabel8:'販売数量:',
	cadLabel9:'支払方法:',
	cadLabel10:'最小取引:',
	cadLabel11:'最大取引高:',
	cadLabel12:'自動返信を有効にする',
	cadLabel13:'資金パスワード:',
	cadLabel14:'コメント情報:',
	cadLabel15:'固定価格:',
	cadLabel16:'自動返信:',
	cadLabel17:'購入数量:',
	radio:'オンライン販売',
	radio2:'オンライン購入',
	cadplaceholder:'プレミアムを設定してください',
	cadplaceholder2:'販売数量を入力してください',
	cadplaceholder3:'最小取引額を入力してください',
	cadplaceholder4:'最大取引額を入力してください',
	cadplaceholder5:'コメントメッセージにあなたの特別な要求を記入することができます、例えば:バイヤーへの要求、オンライン時間など',
	cadplaceholder6:'取引価格を入力してください',
	cadplaceholder7:'注文を受けた後、購入者に自動的に返信される情報(入金方法、入金アカウントなど)。',
	cadplaceholder8:'購入数量を入力してください',
	caderr1:'最小取引額を入力してください',
	caderr2:'最小取引は100以上でなければなりません！',
	caderr3:'最小取引高は最大取引高より小さくなければなりません',
	caderr4:'最大取引額を入力してください！',
	caderr5:'整数を入力してください',
	caderr6:'最大トランザクションは最小トランザクションより大きくなければなりません！',
	caderr7:'最大取引額はあなたの総売却額を超えてはいけません',
	caderr8:'取引方法を選択してください',
	submit:'コミット',
	cadnew:'有効にすると、あなたのコインの価格は市場によって変動せず、価格は変わりません。',
	//スピード両替
	dhTxt:'ワンタッチ両替・座享収益',
	dhTxt2:'指値',
	dhTxt3:'売',
	dhTxt4:'買い',
	dhTxt5:'最大',
	dhTxt6:'金額を入力',
	dhplaceholder:'現物財布使用可能残高',
	dhSuccess:'おめでとう！換金成功！',
	//法貨取引
	crTxt:'法貨取引',
	crTxt2:'デジタル通貨の簡単で安全で迅速な売買',
	crTxt3:'動的調整',
	crTxt4:'手数料無料',
	crTxt5:'即時成約',
	crTxt6:'プラットフォーム保証',
	crTxt7:'市場価格に応じてリアルタイムで変動',
	crTxt8:'ユーザーのWYSIWYG、売買価格以外のプラットフォーム手数料は一切不要',
	crTxt9:'導入プラットフォームサービス業者、スマートマッチング、成約注文、マッチングを待つ必要はありません',
	crTxt10:'プラットフォーム認証業者、安全が保障されて、24時間カスタマーサービスが取引のために護衛',
	crth1:'商店街',
	crth2:'取引件数',
	crth3:'支払い方法',
	crth4:'クォータ',
	crth5:'単価',
	crbtn:'購入',
	crbtn2:'マーチャンダイジング',
	//法貨取引1
	cr1Txt:'おめでとう！マーケティング認定の審査に合格しました',
	cr1Txt2:'準備資料',
	cr1Txt3:'監査の送信',
	cr1Txt4:'認証済み',
	cr1Txt5:'次の権限を取得しました',
	cr1Txt6:'専属ブース',
	cr1Txt7:'1対1サービス',
	cr1Txt8:'代理店の専属広告ブースを持って、取引成功率を増加する',
	cr1Txt9:'より低い手数料',
	cr1btn:'リリース広告',
	cr1btn2:'解約申請',
	//定期的な資産運用
	reTxt:'Bitisan財テク・ワンストップ投資ソリューション',
	reTxt2:'即時収益、簡単&安全',
	reTab:'定期管理',
	reTab2:'',
	reTxt3:'定期的',
	reTxt4:'柔軟なカスタマイズで潜在的なメリットを拡大',
	reTxt6:'定投',
	reTxt7:'固定投資を開始して資産を増やしてください',
	reTxt8:'18の定期製品すべてを展開',
	reth1:'年換算収益率',
	reth2:'期限(日)',
	reth3:'取引限度額',
	reth4:'製品',
	reth5:'過去のROI',
	rebtn:'計画の作成',
	redTxt:'作成',
	redTxt2:'定投計画',
	redTxt3:'投資数量を入力',
	redTxt4:'自分の使用可能残高',
	redTxt5:'利用可能',
	redTxt6:'循環サイクル',
	redTxt7:'毎日',
	redTxt8:'毎週',
	redTxt9:'毎月',
	redTxt10:'ローカル時間',
	redTxt11:'最初の自動投資サイクルは',
	redTxt12:'開始',
	confirm:'確認',
	confirm2:'OK',
	cancel:'キャンセル',
	redongoing:'進行中',
	redcompleted:'完了',
	minNumErr:'最小開始数未満',
	minNumErr2:'投げ開始数を0にすることはできません',
	buyamounttip:'購入数量を入力してください',
	pricetipwarning:'取引高を超えることはできません',
	buyamounttipwarning:'より多くの数を購入することはできません',
	sellamounttip:'販売量を入力してください',
	sellamounttipwarning:'より多くの数を販売することはできません',
	sellpricetip:'売却価格を入力してください',
	sellpricetipwarning:'売却価格を上回ることはできません',
	sellmore:'最大で売る',
	sellunit:'つ',
	loginFirst:'最初にログインしてください',
	betsuccess:'おめでとう！ ベット成功！',
	selectAmount:'ベット数を選択してください',
	balancenotenough:'使用可能残高が不足しています！',
	balancenotenough2:'残高不足！',
	minlimitamountfailed:'両替数量は最低開始数量を下回ることはできません！',
	maxlimitamountfailed:'両替数量は、最大金額を超えてはいけません！',
	limittimesfailed:'あなたの参加回数が個人の最大購入回数を超えました！',
	commitfailed:'参加の送信に失敗しました！',
	//現物取引
	trplaceholder:'クエリ',
	trplaceholder2:"最新価格",
	trplaceholder3:'市場で最も良い価格で購入',
	trplaceholder4:'市場で最も良い価格で売る',
	triggleplaceholder:'デフォルトの委託価格は相場です',
	triggerpriceshouldbigger:'トリガー値は0より大きくなければなりません',
	marginModeSuccessTip:'契約アカウントモードの変更に成功しました',
	noenoughbalance:'口座保証金不足',
	pleaseinputopenvolume:'在庫数は0より大きくなければなりません',
	pleaseinputcorrectopenvolume:'正しい在庫数を入力してください',
	pleaseinputrightentrustprice:'正しい依頼価格を入力してください',
	noenoughposition:'アンダーポジション',
	pleaseinputclosevolume:'在庫数は0より大きくなければなりません',
	pleaseinputcorrectclosevolume:'正しい在庫数を入力してください',
	option1:'アンテナ',
	option2:'週線',
	option3:'月線',
	trTxt:'取引高',
	trTxt2:'相場',
	trTxt3:'販売量',
	trSuccess:'コミット成功',
	//u本位
	udTxt:'マージンモード',
	udTxt2:'契約倍数の調整',
	udTxt3:'レバー',
	udTxt4:'永続',
	udTxt4_1:'永続',
	udTxt5:'転換限度額',
	udTxt6:'ドロップ数',
	udTxt7:'すべてをなぞる',
	udTxt8:'永続アカウント',
	ubtn:'全倉',
	ubtn2:'追倉',
	uplaceholder:'永続契約口座',
	uradio1:'分時',
	uradio2:'15分',
	uradio3:'1時間',
	uradio4:'4時間',
	uradio5:'1日',
	uth1:'契約倍数',
	uth2:'収益',
	uth3:'利回り',
	uth4:'持ち高',
	uth5:'可平量',
	uth6:'開倉均価',
	uth7:'強平価',
	uth8:'現在のマージン',
	uth9:'マージン率',
	zhang:'チャン',
	or:'または',
	ureg:'今すぐ登録',
	uTxt8:'取引を行う',
	uTab:'開倉',
	uTab2:'平倉',
	uTxt9:'可開多',
	uTxt10:'可開空',
	uTxt11:'委託価格',
	uplaceholder2:'市場最安値で落札',
	uTxt12:'買開多',
	uTxt13:'売り開空',
	closeup:'買い平空',
	closedown:'売平多',
	uTab3:'止盈止損',
	uTab3_yprice:'締まり価格',
	uTab3_sprice:'損切り価格',
	uTab3Txt:'ストップ/ストップロス',
	uTxt14:'私の契約アカウント',
	ulabel1:'アカウントモード',
	ulabel2:'口座収益',
	ulabel3:'ポジション保証金',
	ulabel4:'資金使用率',
	ulabel5:'多',
	ulabel6:'空',
	uTxt15:'多頭',
	uTxt16:'空頭',
	total:'合計',
	limited_price:'指値依頼',
	market_price:'時価委託',
	spot_price:'ストップロス',
	ent_status1:'委任中',
	ent_status2:'取り消されました',
	ent_status3:'委任に失敗しました',
	ent_status4:'委任成功',
	ent_statusblast:'パンク',
	uTxt17:'多倉可平',
	uTxt18:'空倉可平',
	//
	prev:'下一篇',
	next:'次篇',
	//推進パートナー
	prtxt:'私の招待リンク',
	copy:'コピー',
	prtxt2:'私の一級親友',
	prtxt3:'私の二級親友',
	prtxt4:'手数料収入(USDT換算)',
	prtxt5:'ボーナス',
	prtxt6:'パートナーレベル',
	prtit:'ルールの詳細',
	prdesc:'Bitisanの【プロモートパートナー】は全ネットで最も高い割合を返し、最も長い時間を返して（最高終身返し）のプロモート活働で、相応の努力を払ったプロモートが本当の意味でプラットフォームの\'パートナー\'になることができて、Bitisan取引プラットフォームと共同成長の配当を享受します！具体的な細則は以下の通り:',
	prli1:'一、デジタル資産の理念を説教し、ユーザーの規模を拡大するために、Bitisanは【プロモーションパートナー】計画を出して、この活働は長期的に有効です。',
	prli2:'二、友達を招待して二級に分けて、もしAがBを招待すれば、BはAの一級の友達で、もしBはまたCを招待すれば、CはBの一級の友達に属して、同時にAの二級の友達にも属します。',
	prli3:'三、友達登録を促進する時、招待者が提供するリンクまたは手入力招待者招待コードを介して招待者の1級友達になることができます。',
	prli4:'四、被招待者が招待者招待コードで登録され、実名認証が完了すると、返納インセンティブが有効になります。',
	prli5:'五、リベート期間は招待者が実名認証を完了してからNヶ月間とし、ランクによって享受できるリベートの割合が異なります。',
	prli6:'6、プロモーションリターン資産は下級親友コインの取引手数料、リターンコインは三大市場の基礎コイン、即ち:USDT、BTC、ETH。',
	prli7:'七、推し返しの割合、返しの時間と推し一級の友達の人数は正の相関を呈して、推しの人数が多ければ多いほど、返しの割合が高くなります。具体的な普及率は以下の通り:',
	prli8:'八、プロモーションリターンランキングTOP10またはTOP100には、Bitisan公式からのボーナスが不定期に穫得されます。',
	prli9:'九、他の活働が【プロモーションパートナー】活働と衝突する場合は、協議の上、臨時に変更するかどうかを決定し、パートナーの了解を得てください。',
	prli10:'十、L4(巡撫)、L5(太守)、L6(節度使)級のパートナーはそれぞれ毎年の総リターンの5%、10%、15%の配当奨励を享受する。',
	prli11:'11、本イベントの最終解釈権はBitisanに帰属します。',
	prth1:'レベル',
	prth2:'1級友達数',
	prth3:'一級好友返メイド比率/返メイド時間',
	prth4:'二級親友返メイド比率/返メイド時間',
	prth5:'パートナー配当',
	prlev1:'里正',
	prlev2:'県令',
	prlev3:'知府',
	prlev4:'巡撫',
	prlev5:'太守',
	prlev6:'節度使',
	prtd1:'ヶ月',
	prtd2:'終身返使用人',
	prtit2:'例',
	prtxt7:'もしユーザーの小厳招待した100人の一級の友達、TAの一級の友達はそれぞれ招待した5人の二級の友達、この時小厳は持って100人の一級の友達、500人の二級の友達。もし一人が毎日3000元取引すれば、厳さんの月収は約:（100*3000*0.001*30%+500*3000*0.001*10%）*30=7200/月。',
	prtxt8:'もしユーザーの小厳招待した1000人の一級の友達、TAの一級の友達はそれぞれ招待した5人の二級の友達、この時小厳は持って1000人の一級の友達、5000人の二級の友達。もし一人が毎日3000元取引すれば、厳さんの月収は約:（1000*3000*0.001*50%+5000*3000*0.001*20%）*30=135000/月。',
	prtit3:'普及ツールボックス',
	prtit4:'プロモートバック総額TOP20(総ランキング)',
	prtit5:'プロモーション人数TOP20(総ランキング)',
	prtxt9:'統計の締め切り時間:',
	prth6:'ランキング',
	prth7:'会員',
	prth8:'プロモーター数',
	prth9:'リターン(USDT)',
	prth10:'ボーナス',
	prtip1:'以上の手数料データは非リアルタイムで更新され、システムは24Hごとに集計して更新します。',
	prtip2:'招待人数為一級朋友数、此榜為返メイド総金額ランキング',
	prtip3:'招待人数は一級友達数、このランキングは一級招待人数ランキング',
	//チャージ
	recth1:'入金時間',
	recth2:'ネットワークプロトコル',
	recth3:'チャージアドレス',
	recth4:'チャージ数',
	recbtn:'ティップコードチャージ',
	recplaceholder:'ティッピングコードを入力してください',
	recstatus_0:'失敗',
	recstatus_1:'未入金',
	recstatus_2:'入金済み',
	//貨幣を持ち出す
	wittit:'デジタル通貨現金化',
	wittext:'キャッシュレコード',
	wittext2:'ティンバーコード',
	wittext3:'ティンバーランド',
	witbtn:'提幣符号提幣',
	witbtn2:'生成ティンバーコード',
	witdia:'セキュリティ認証',
	witdia2:'キャッシュコード生成成功',
	witplaceholder:'コインの数を入力してください',
	witerr:'通貨を選択してください',
	addrestip:'アドレスを入力してください',
	witstate:'待換',
	witstate2:'失敗',
	witstate3:'交換完了',
	witstatus_1:'監査中',
	witstatus_2:'振替中',
	witstatus_3:'失敗',
	witstatus_4:'成功',
	//操作タイプ
	type1:'振替',
	type2:'通貨貨取引',
	type3:'法幣買',
	type4:'',
	type5:'イベント奨励',
	type6:'推荐奨励',
	type7:'配当',
	type8:'投票',
	type9:'手動チャージ',
	type10:'ペア',
	type11:'イベント換金',
	type12:'C2C買付',
	type13:'C2C売',
	type14:'発紅包',
	type15:'領紅包',
	type16:'提幣符号提幣',
	type17:'提幣符号充幣',
	type18:'永続契約手数料',
	type19:'永続契約利益',
	type20:'永続契約欠損',
	type21:'オプション契約失敗',
	type22:'オプション契約手数料',
	type23:'オプション契約ボーナス',
	type24:'契約リターン',
	type25:'平級奨励',
	type26:'プラットフォーム手数料収入',
	type27:'秒契約失敗',
	type28:'秒契約ボーナス',
	type29:'理財利子',
	type30:'支出資金費',
	type31:'獲得資金費用',
	type32:'定投買',
	type33:'定投売',
	type34:'定期購入',
	type35:'定期償還',
	type36:'通貨本位約定繰入',
	type37:'通貨本位約定転出',
	type38:'U本位契約転入',
	type39:'U本位契約転出',
	type40:'秒転入',
	type41:'秒転出',
	type42:'幣貨転入',
	type43:'幣貨転出',
	//資産運用口座
	lctit:'資産運用口座',
	lcbtn:'お金の繰り入れ',
	lcth1:'累計利息',
	day:'天',
	lctxt:'アクティブ通貨',
	lctxt2:'受け入れ通貨',
	lctxt3:'開始数',
	lctxt4:'選択時間',
	year:'年',
	month:'月',
	//資産運用の注文
	ldtab1:'持ち高',
	ldtab2:'フラットレコード',
	ldth1:'収益開始日',
	ldth2:'償還日',
	ldth3:"収益推計値",
	ldth4:"収益",
	//契約計画
	attit:'予定を修正します',
	attxt:"投資件数",
	attxt2:"投資サイクル",
	atth1:'指定日',
	atth2:'定投数',
	atth3:'取引レート',
	success:'成功',
	//イノベーションマネジメント
	cxth1:'名前',
	cxth2:'タイプ',
	cxth3:'応募数',
	cxth4:'参加通貨',
	cxth5:'',
	cxth6:'現在のステータス',
	cxth7:'出来高',
	cxth8:'作成時間',
	cxnav1:'私が参加する',
	cxnav2:'私の鉱山機械',
	cxnav3:'',
	cxtit2:'マイマイザーリスト',
	cxtit3:'マイロックリスト',
	cxkth1:'産出通貨',
	cxkth2:'アウトプットサイクル',
	cxkth3:'マイニングタイム',
	cxkth4:'掘られた時間',
	cxkth5:'ベース生産能力',
	cxkth6:'実際の生産能力',
	cxstate1:'未配備',
	cxstate2:'配備済み',
	cxstate3:'取り消されました',
	cxstate4:'待成約',
	cxstate5:'終了',
	cxstate6:'リリース予定',
	cxstate7:'リリース中',
	cxcth1:'リリース通貨',
	cxcth2:'総ロック倉',
	cxcth3:'総リリースサイクル',
	cxcth4:'リリースサイクル',
	cxcth5:'ベースリリース',
	cxcth6:'実解放',
	more:'more',
	//私たちについて
	abtit:'プラットフォーム特色',
	abtit2:'について',
	abtit3:'連絡先',
	abtit4:'Bitisanコミュニティ',
	abdesc:'Bitisanは、ケイマン諸島に登録された世界的なデジタル資産取引プラットフォームであり、香港に拠点を置くコア運営チームです。 Bitisanの中心メンバーはトップクラスのインターネットや金融企業の出身で、メンバーの大半は深いビットコインやブロックチェーン信仰者で、ブロックチェーンが従来の独占的な金融システムを変え、より　的で自治的な社会構造を構築すると信じています。',
	abdesc2:'Bitisanプラットフォームは専門的な金融レベルの取引構造を持っていて、自主開発の高同時メモリマッチング取引エンジンを持っていて、プラットフォームは全コールドチャージウォレットシステム+多重署名+高防護DDOS攻撃システムなどの取引構造を採用して、顧客資産の安全を保証します。',
	abdesc3:'Bitisan顧客により良い把握の投資機会、顧客サービス部門の内部でフラット化管理を採用して、非常に速いサービス応答メカニズムを確立して、資産顧客サービスマネージャーは長年7*24Hオンラインで、顧客のために資産チャージ現金化などのサービスを提供して、顧客が5分以内に資産チャージを完了することを保障します。',
	abdesc4:'Bitisanは、質の高いプロジェクトを厳選し、安全で安定した資産管理サービスを提供しています。Bitisanは、\'誠実、公正、情熱、オープン\'という理念のもと、安全で信頼性が高く、効率的でフレンドリーな取引所を目指しています。',
	abdesc5:'デジタル資産は今は小さなサークルの中にしかありませんが、このような誰もが完全に把握できる資産が将来的に普及することを信じています。一緒に頑張りましょう。そして楽しみにしています！',
	abdesc6:'カスタマーサービス',
	abdesc7:'テクニカルサポート',
	abdesc8:'上貨申請',
	abdesc9:'クレーム提案',
	abdesc10:'ビジネス提携',
	abdesc11:'微信',
	abdesc12:'微博',
	abdesc13:'Twitter',
	abdesc14:'通貨用',
	abdesc15:'電報',
	abdesc16:'bmate601\'を微信の友達に追加して、微信の福祉社群に入る',
	//実名認証
	realtxt:'実名',
	realtxt2:'ID番号',
	realtxt3:'IDカード正面写真',
	realtxt4:'IDカードの裏写り',
	realtxt5:'IDカードと個人署名写真を持っています',
	realtxt6:'1、JPG、PNG形式をサポートし、ファイルは8M未満、証明書上の情報は、はっきりと見え、いかなる修正や遮ることも許されず、必ず証明書番号と氏名がはっきりと見えるものでなければならない。',
	realtxt7:'2、写真は無冠、提案していない化粧、手に持って証明書人の五官ははっきりして鑑、完全に腕を露出します。',
	realtxt8:'3、同時に手に持って証明書と手書きのコピー（名前+申請日）\'私は当駅でのすべての行為は私の操作で、そして関連するリスクを知って、この口座のすべての法的結果を負担することを望んでいます。',
	realbtn:'クリックしてアップロード',
	realstate:'実名なし',
	realstate2:'実名',
	uploadimg:'身分証明書の正面写真をアップロードしてください',
	uploadimg2:'身分証明書の裏面写真をアップロードしてください',
	uploadimg3:'携帯IDカードをアップロードしてください',
	uploaderr:'アップロードする画像のサイズを超えることはできません',
	save_success:'保存に成功しました！',
	save_failure:'保存に失敗しました！',
	//こぐ
	close:'閉じる',
	hztxt:'指定を確認',
	//マーチャンダイジング認定
	submittip1:'あなたの資金の安全のために、まず【実名認証】を行ってください！',
	submittip2:'あなたの資金の安全のために、先に[携帯電話のバインド]を行ってください！',
	submittip3:'あなたの資金の安全のために、最初に[資金パスワード]を設定してください！',
	submittip4:'少なくとも1つの支払い方法をバインドしてください',
	aderr1:'正しい数字を入力してください',
	aderr2:'逸失価値0-20',
	aderr3:'逸失価値は0~20で、0にはできません',
	aderr4:'正しい固定価格を入力してください',
	aderr5:'正しい数を入力し、最大取引数が100コインを超えないようにしてください',
	orderTypeNo:'いいえ',
	orderTypeYes:'はい',
	win:"成功",
	lose:"失敗",
	tied:"ドロー",
	cancelsuccess:'撤去に成功しました！',
	shelves:'広告を削除する前に削除することができます！',
	shelves2:'下段の広告を編集してから修正することができます！',
	business:'まず事業者認定を申請してください！',
	swappleaseselectwallet:'最初に転入/転出口座を選択してください',
	swappleaseinputamount:'回転数を入力してください',
	clickchange:'クリックして移動方向を切り替えます',
	daterange:'検索日の範囲を選択してください',
	//API
	apierr:'コメント情報を入力してください',
	apierr2:'IPアドレスを入力してください',
	apierr3:'正しいIPアドレスを入力してください',
	apierr4:'認証コードを入力してください',
	clickget:'クリックして取得',
	apisecond:'秒',
	apitips:'キーは追加されたときにのみ表示されます。すぐに保存してください',
	apiaccess:'キー',
	//カスタマイズ
	do_favorite:'お気に入り',
	cancel_favorite:'お気に入り解除',
	fail_favorite:'失敗',
	deptotal:'累計',
	chart:'チャート',
	book:'注文帳',
	trade:'最新成約',
	pt_title:"デジタル通貨ギフトカード",
	pt_desc:'交換すると自動的に下位の友達になり、友達、家族、取引先、パートナーに送ります。パートナーのより良いプロモーションを促進するよう支援する。',
	pt_more:'その他のツールをお楽しみください',
	pt_tips:'もし良いアイデアがあれば、promotion@Bitisan.bizまでメールを送ってください。採用された場合は報酬がありますよ！',
	pt_card_amount:'カード金額',
	pt_card_deadline:'有効期間',
	pt_card_noend:'無期限',
	pt_card_btn:'プロモートパートナー30枚（約2000元）',
	pt_card_btn_login:"ログインすると30枚無料でもらえる",
	pt_card_rule:'ルールの詳細',
	pt_card_summary:'デジタル通貨ギフトカードは、プロモーションパートナーがオフラインの友達をよりよく招待できるように開発されたプロモーションツールの1つで、プロモーション対象ユーザーがギフトカードを交換すると、自動的にプロモーションパートナーのオフラインの友達になります。',
	pt_card_rule1:'一、無料ギフトカードは1ユーザーにつき30枚まで。さらにギフトカードを発行する場合は、ユーザーが有料でカスタマイズする必要があり、カードの額面やロゴ、説明文などをカスタマイズすることができる。カスタマイズの要件については、\',\'にメールを送信してください',
	pt_card_rule2:'2、無料ギフトカードは1ユーザーにつき1部に制限されています。Bitisanから公式に発行される無料ギフトカードは、1ユーザーにつき1部に制限されており、異なるユーザーから異なる交換コードの無料ギフトカードを受け取っても1部しか交換できません。ギフトカードのカスタマイズ作成はこの限りではありません。',
	pt_card_rule3:'三、ユーザーがギフトカードを交換する前に、\'招待者\'が存在しない場合（アカウント登録時に招待コードが入力されていない場合）は、自動的にカード発行者の1次友達として関連付けられます。Aがアカウントを登録する時に招待コードを入力しなければ、この時Aは誰の1級または2級の友達に属しませんが、AがBの発行したギフトカードで交換すると、Aは自動的にBの1級の友達になります。',
	pt_card_rule4:'四、盗難防止のため、ギフトカードの帳簿上の金額はリアルタイムではありません。受け取った後に実名認証を受けなければ操作できません。',
	pt_card_rule5:'五、無料ギフトカードを受け取るには実名認証が必要です。換金するには実名認証なしで受け取ることができます。これはプロモーターがより早く下級の友達を獲得しやすいようにするためです。',
	pt_card_rule6:'六、無料ギフトカードの最終解釈権はBitisanに帰属します。',
	pt_card_day:'日',
	pt_card_title_tips:'リーディング補助金2000元',
	pt_invite_desc:'専用の二次元コード、二次元コード登録によって自動的に下位の友達になり、さまざまなグループやフォーラムで転送するのに適しています。',
	saveimage:'画像を保存',
	imagetips:'画像を正常にダウンロードできない場合は、スクリーンショットツールを使用して保存してください！',
	pt_card_receivew_success:'おめでとうございます！パートナー・プロモーション・ギフトカードの受け取りに成功！【パーソナルセンター）->【カードチケットセンター】でチェックしてください！',
	usepromotion:"使用プロモーション素材",
	context_title:'プロモートパートナー専用の交換コード',
	context_title1:'詳細情報',
	context_title2:'マイ招待コード',
	context_title3:'招待状',
	pt_card_title:'パートナープロモーションカード',
	copysuccess:'コピーに成功しました！',
	copyerr:'コピーに失敗しました！手動でコピーしてください',
	noopening:'オプション契約はありません',
	createSuccess:'作成成功',
	tradewarning1:'小数点以下2桁まで入力',
	tradewarning2:'注文金額',
	tradewarning3:'小数点以下8桁まで入力',
	tradewarning4:'注文数量',
	tradeconfirmbuyin:'購入確認',
	tradeconfirmsellout:'販売確認',
	foot:{
		aboutus1:'について',
		aboutus:'<p>は2019年4月にメインネットをリリースした後、コインアンチェーンはその高速\
		そしてハイスループット設計。 主な関心事であるローカル分散アプリケーション（\
		dApp）Binance Chain DEX（分散型取引所）。数百万の処理がテストされています\
		短期間の取引量で、トレーディングエンジンの低レイテンシを示します\
		マッチング機能。</p>\
		<p>柔軟性と可用性は、多くの場合、パフォーマンスと密接に関連しています。デジタル資産の発行と取引を容易にすることに重点を置くと、この設計は\
		ある程度限界がある。コミュニティの中で一番大きな声はいつも見ています\
		コインアンペアチェーンにプログラム可能な拡張機能を追加するか、\
		端的に言えば、スマートコントラクトと仮想マシンの機能です。現在のコインアンペアチェーンの機能は制限されているため、デジタル資産の発行者や所有者は、新しい\
		資産への分散型機能、またはあらゆる形式の\
		コミュニティマネジメントとコミュニティ活動。\
		スマートコントラクト機能をコイン安に追加することへの期待が高まっているにもかかわらず\
		チェイン、“苦渋の決断”でした。スマートコントラクトの実行は、DEXを遅らせ、資産取引に不確実性を高める可能性があります。たとえこのような妥協があっても\
		可能性があります。最もシンプルなソリューションは、仮想化\
		現在基盤となっているTendermintコンセンサスプロトコルとマスターRPCに基づくマシン\
		Binance Chainのインタフェース。しかし、このようなソリューションは既存のdAppコミュニティの学習コストを増加させ、人気のあるソリューションではありません。\
		ここでは、コインアンペアチェーンのパラレルブロックチェーンの概念を提案して、高さを維持します\
		スマートコントラクト機能をサポートしながら、ローカルDEXのパフォーマンスをサポートします。\
		コインアンペアチェーンエコシステムに2つの平行ブロックチェーンを構築した後、\
		ブロックチェーンは独立して機能し、異なるサービスを提供するようになります。新しいパラレルチェーンは\'コイン安スマートチェーン\'（以下\'BNBN\'）と呼ばれるが、既存のメインネットは引き続き\'コイン安チェーン\'（以下\'BNB\'）と呼ばれる。\
		BNBNは次の原則に従って設計されています。\
		スタンドアロンブロックチェーン:技術的には、BNBNは2層目のソリューションではなく、スタンドアロンブロックチェーンです。BSCの基本的なテクノロジーおよびビジネス機能のほとんどは、\
		スタンドアロンであるため、BNBNが短時間停止しても、BNBNは良好に動作する。\
		互換性のあるイーサリアム:実用的で広く使用されている最初のスマートコントラクトプラットフォームは\
		イーサリアム。比較的成熟したアプリケーションとコミュニティに接続するために、BSCは\
		既存のイーサリアム・マスター・ネットワークとの互換性を実現する。つまり、ほとんどのdApp、エコシステム\
		部品とツールはBSCと互換性があり、変更や\
		わずかな変化しかありません。BNBNノードに必要なのは、同じか少し高いハードウェアだけです\
		操作仕様と操作スキル。この実装では、\
		BNBNおよびEthereumの将来のバージョンとの互換性を維持するための容量\
		株式質権設定とチェーン管理に基づくコンセンサス:株式に基づくコンセンサス\
		環境に配慮したPoS(PoS):\
		コミュニティガバナンス。このコンセンサスはより良いものになることが予想されます\
		PoWのコンセンサスよりもパフォーマンスが優れています。つまり、ブロック生成時間が短く、\
		事務処理能力が高い。ローカル・クロス・チェーン通信:BNBおよび\
		BNBNは、2つのブロックチェーン間のクロスチェーン通信をネイティブにサポートする。この\
		通信プロトコルは2つである必要があります-\
		やり方は、第三者を信頼しない分散となっている。ステージングにフォーカスします\
		BNBとBNBNの間のデジタル資産、すなわちBEP2トークン、およびその他のBNBNトークン\
		後ほどご紹介します。このプロトコルは、ブロックチェーン上に保存されている他の情報に過度に注意を払うべきではなく、少数の例外があります。</p>\
		<p>上記の設計原則に基づき、BNBNのコンセンサスは\
		次のターゲット:\
		ブロック時間は、イーサリアム時間よりも短くしてください。たとえば、5秒またはそれよりも短くしてください。\
		取引完了までの待ち時間は、約1分かそれ以下など限られています。\
		インフレ、所得なし\
		ブロックチェーンの部分は手数料から来ており、手数料はBNBNという形で支払われます\
		.可能な限りイーサリアムと互換性があります。\
		持分権の質権設定に基づくチェーン上の管理メカニズムを備えている。\
		出資比率ベースの出資比率証明書\
		作業証明(PoW)が実装されていることが証明されていますが\
		分散型ネットワークは環境にやさしくなく、大量のネットワークを必要とします\
		ネットワークセキュリティを維持している参加者の数。\
		Ethereumおよびその他のネットワーク（MATIC Bor、TOMOChain、GoChain、xDAIなど）\
		さまざまなシナリオでの認定証明(PoA)またはそのバリエーションの使用。\
		テストネットとメインネット。PoAは51%の攻撃を防御し、より効果的\
		一部のビザンチンノードが悪事を働くのを防ぐ。ベースとしてPoAを選択\
		合意形成は理想的な選択肢の一つです。\
		上記に触発され、BNBNはDPoSとPoAを組み合わせてコンセンサスを形成している。ソリューション\
		を使用しています:\
		ブロックは限られた数のベリファイア生成ベリファイアによって交互に生成されます\
		EthereumのCliqueコンセンサスエンジンと同様にPoAでブロック\
		一連のバリデータが選択され、\
		株式質権設定に基づくチェーン上のガバナンスを解消する。\
		ネットワーク起動の開始ブロック段階では、信頼できるノードの多くは\
		バリデータの初期セットとして動作します。ブロックの作成が開始されたら、次のいずれかのユーザー\
		検証人の選挙に候補者として立候補することができます。持分質権設定の状態により、持分権を最も多く保有する上位21ノードが次のグループになることが決定される\
		バリデータ。こうした選挙と淘汰は24時間ごとに行われる。\
		BNBNはBNBの株式質権設定の象徴である。\
		Ethereumとのコンセンサス契約を維持するために、\
		今後予定されているアップグレード）では、BNBNは株式管理をBNBに依存することを選択しました。 BNBNエクイティ専用のモジュールがあります\
		BNBにベットします。BNBN所有者からBNB株式の質権設定を受け入れ、\
		最大の利害関係を持つノードの集合。UTCがゼロに達するたびに、BNBは\
		検証可能な\'ValidatorSetUpdate\'クロスチェーンメッセージは、BNBNに更新を通知します。\
		バリデータセット。新しいブロックを生成する前に、既存のBNBNベリファイアがの存在を定期的にチェックします。\
		\'ValidatorSetUpdate\'メッセージはBNBに転送されます\
		もしそうであれば、一定の高さ(つまり、事前に定義されたブロック間隔)の後にバリデータセットを更新します。\
		たとえば、BNBが5秒ごとにブロックを生成し、チェックサイクルが240ブロックである場合、現在の\
		バリデータセットは1200秒(20分)以内に次のサイクルのバリデータセットをチェックして更新します。\
		セキュリティと終了\
		1/2*N+1ベリファイアの半分以上が信頼できることを考慮すると、PoAベースのネットワークは通常、安全かつ正しく機能します。\
		しかし、場合によっては、ビザンチンのベリファイアは、例えば\'クローン攻撃\'によってネットワークを攻撃することができる。BNBNの高さが同じであることを確認するために、\
		BNBのセキュリティとして、BNBNユーザはブロックを受信するまで待つことを推奨します\
		複数の確認*N+1個の異なる検証器による。このようにして、BNBNは\
		BNBと同様のセキュリティレベル。1/3*N未満のビザンチン\
		バリデータ。21ベリファイアの場合、ブロック時間が5秒の場合は、QUO*N+1\
		異なるベリファイアで確認するには(2/3*21+1)*5=75秒かかります。BNBNのいかなる重大な応用も、その後の決定を待つ必要があるかもしれないが、これは比較的安全な最終結果を保証するためである。\
		後ほど\'株式の質権設定と管理\'の項で紹介します。このペナルティ\
		このメカニズムは、悪意のあるベリファイアを短時間で公開します\
		を使用して、クローン攻撃を実行することが非常に困難または不経済になります。たとえ\
		に設定されています。このペナルティでは、1/*N+1以下のブロックで、\
		ほとんどの取引の最終性。\
		収益現在のベリファイアセット内のすべてのBNBベリファイアは、支払い済みの費用から収益を取得します。\
		BNBはインフレトークンではないので、マイニング収入は発生しません\
		例えばビットコインやイーサリアムのネットワーク。手数料Yesの主な収益\
		バリデータ。 BNBは他のアプリケーションのユーティリティトークンでもあるので\
		ベリファイアは、BNBNを保有することによる追加のメリットを引き続き得ることができます。</p>',
		newbie:'',
		newbie1:'初心者ガイド',
		commonproblem:'',
		commonproblem1:'よくある質問',
		information:'',
		information1:'通貨情報',
		disclaimer:'',
		disclaimer1:'免責条項',
		privacyclause:'',
		privacyclause1:'プライバシー規約',
		agreement:'',
		agreement1:'ユーザープロトコル',
	},
	tradeinfo:{
		emaildone:'メッセージは認証されました',
		emailundo:'メッセージは認証されていません',
		teldone:'携帯電話番号が認証されました',
		telundo:'携帯電話番号が認証されていません',
		idcarddone:'IDが認証されました',
		idcardundo:'IDが認証されていません',
		exchangetimes:'取引回数',
		price:'価格',
		num:'数',
		paymethod:'支払い方法',
		exchangelimitamount:'取引限度額',
		location:'所在地',
		location_text:'中国',
		exchangeperiod:'取引期限',
		minute:'分',
		amounttip:'金額を入力してください',
		numtip:'数量を入力してください',
		remarktip:'ご要望を伝えてください',
		remarktitle:'コメント情報',
		exchangetitle:'取引に関する注意事項',
		exchange_tip1:'取引要求を開始すると、デジタル通貨はホスティングにロックされ、プラットフォームによって保護されます。あなたが売り手の場合は、取引要求を開始した後、チャージして買い手からの支払いを待つことができます。購入者は支払い期限内に支払いを行う。支払いを受け取った後に、ホストされているデジタル通貨を発行する必要があります。',
		exchange_tip2:'お取引の前に、『プラットフォーム・ネットワーク・サービス条件』およびよくあるご質問、取引ガイドなどのヘルプ・ドキュメントをお読みください。',
		exchange_tip3:'詐欺師にご用心！取引前にそのユーザーが受け取った評価をチェックし、新しく作成したアカウントに注意してください。',
		exchange_tip4:'四捨五入と価格の変動は、最終的に取引されるデジタル通貨の金額に影響を与える可能性があることに注意してください。入力した固定金額によって最終金額が決定されます。デジタル通貨の金額は、要求が発行されたときに即時のレートで計算されます。',
		exchange_tip5:'マネージドサービスは、オンライン取引の売り手と買い手を保護します。紛争が発生した場合、私たちは提供されたすべての情報を評価し、預託されたデジタル通貨をその正当な所有者に発行します。',
		warning1:'小数点以下2桁まで入力',
		warning2:'注文金額',
		warning3:'小数点以下8桁まで入力',
		warning4:'注文数量',
		confirmbuyin:'購入確認',
		confirmsellout: '确认卖出',
		buyin: '買う',
		sellout: '売る',
		warning5: '必要に応じて注文を入力してください ',
	},
}
