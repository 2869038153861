<template>
<div class="page-web page-home">
		<Head />
        <div style="max-width: 1024px;  margin: 15px auto; ">
         <el-col :span="12">   
            <el-card style="width: 500px; margin: 10px auto;">
            <div slot="header" class="clearfix" >
                <img src="@/assets/photo/icon8.png" alt="" style="width: 28px;"/>
                <span style="font-size:25px !important;margin-left:30px; margin-bottom: 10px; padding-bottom: 20px;">{{$t('usdt')}}</span>
            <el-button @click="ifexpirationTime()" style="float: right; padding: 10px; ">{{$t('platform2')}}</el-button>
            <!-- <el-button @click="dialogTablerecharge=true" style="float: right; padding: 10px; ">{{$t('platform2')}}</el-button> -->
            <el-button @click="dialogTableembodiment=true" style="float: right; padding: 10px; margin-right: 10px;">{{$t('platform1')}}</el-button>
            
            </div>
             <el-col :span="20">
                <div style="width: 200px;">
                    <p style="margin-right:100px; ">{{$t('usdt1')}}</p>
                     <p style="margin-right:100px; font-size:25px;">{{usdtBalance}}</p>
                </div>
            </el-col>
            <!-- <el-col :span="10">
                <div style="width: 200px;">
                    <p style="margin-right:100px;">{{$t('usdt2')}}</p>
                     <p style="margin-right:100px; font-size:25px;">{{lockusdtBalance }}</p>
                </div>
            </el-col> -->
            </el-card>
        </el-col>
         <el-col :span="12">   
         <el-card style="width: 500px; margin: 10px auto;">
            <div slot="header" class="clearfix" >
                <img src="@/assets/photo/baloog.png" alt="" style="width: 25px;"/>
                <span style="font-size:25px !important; margin-left:10px; margin-bottom: 10px; padding-bottom: 10px;">{{$t('platform')}}</span>
                <el-button v-if="INapply" @click="dialogTablePurchase=true" style="float: right; padding: 10px; margin-right: 10px;">{{$t('platform3')}}</el-button>
                <el-button @click="dialogTableembodimentBNBN=true,embodiment.type=2" style="float: right; padding: 10px; margin-right: 10px;">{{$t('platform1')}}</el-button>
                <el-button @click="dialogBNBNUSDT=true" style="float: right; padding: 10px; ">{{$t('transaction22')}} USDT</el-button>
            </div>
             <!-- <el-col :span="10">
                <div style="width: 200px;">
                    <p style="margin-right:100px; ">{{$t('usdt1')}}</p>
                     <p style="margin-right:100px; font-size:25px;">{{platformBalance}}</p>
                </div>
            </el-col> -->
            
              <el-col :span="12">
                <div style="width: 200px;">
                    <p style="margin-right:100px; ">{{$t('usdt1')}}</p>
                     <p style="margin-right:100px; font-size:25px;">{{platformBalance}}</p>
                </div>
            </el-col>
              <el-col :span="12">
                <div style="width: 200px;">
                    <p style="margin-right:100px;">{{$t('usdt2')}}</p>
                     <p style="margin-right:100px; font-size:25px;">{{platform }}</p>
                </div>
            </el-col>
            </el-card>
         </el-col>
         <br/>

   <el-card style="width: 1000px; margin: 10px auto;background-color: black; ">
  <!-- <div slot="header" class="clearfix">
    <span>卡片名称</span>
    <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
  </div> -->

  <div class="my_table">
    <el-table 
    :data="tableData"
    :empty-text="$t('transaction18')"
    height="600"
    :label="$t('transaction')"
    style="width: 100% ;border-style: none !important; background-color: black;">
<el-table-column :label="$t('transaction0')" width="70" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template>
</el-table-column>
    <el-table-column
      prop="id"
      :label="$t('transaction1')"
      width="300">
    </el-table-column>
    <el-table-column
      prop="number"
      :label="$t('transaction2')"
      width="150">
      <template slot-scope="scope">
        <span v-if="scope.row.number<0">{{ (scope.row.number) }}</span>
        <span v-if="scope.row.number>0">+{{ (scope.row.number) }}</span>
        </template>
    </el-table-column>
    <el-table-column
      prop="time"
      :label="$t('transaction3')">
     <template slot-scope="scope">
     <i class="el-icon-time"/>
        {{ (scope.row.time) }}
        </template>
    </el-table-column>
    <el-table-column
      :label="$t('transaction17')"
      width="150">
      <template slot-scope="scope">
        <p v-show="scope.row.type==1">{{$t('usdt')}}</p>
        <p v-show="scope.row.type==2">{{$t('platform')}}</p>
      </template>
    </el-table-column>

  </el-table>
    </div>
  </el-card>

<el-dialog :visible.sync="dialogTablerecharge" width="25%">
  <p style="font-size:25px;color: #f0b90b; text-align: center;">{{$t('transaction4')}}</p>
   <button style="width:50% ; height:40px; padding: 5px;"  @click="getobtain(0)">USDT-ERC20</button>
    <button style="width:50% ; height:40px; padding: 5px;" @click="getobtain(1)">USDT-TRC20</button>  
    <!-- <p>{{$t('transaction5')}}</p> -->
    <div v-show="!acb" style="font-size:30px;color: #f0b90b; text-align: center;margin-top: 30px;">{{$t('transaction19')}}</div>
    <div v-show="acb" style="margin: 10px auto; width:100% ; text-align: center;">
      <div id="qrcode" style="margin-left: 25%"></div>
      <p style="font-size:17px;color: #f0b90b; ">{{address}}</p>
    </div>
</el-dialog>



<el-dialog :visible.sync="epusdtdialogTablerecharge" width="25%">
    <!-- <p>{{$t('transaction5')}}</p> -->
    <div v-show="!acb" style="font-size:30px;color: #f0b90b; text-align: center;margin-top: 5px;">
      <!-- {{$t('transaction19')}} -->
      <p style="font-size:25px;color: #f0b90b; text-align: center;">{{$t('transaction4')}}</p> 
      <el-form :model="Purchase" >
     <el-form-item :label="$t('wallet1')">
      <el-input v-model="Purchase.number" type="number"></el-input>
    </el-form-item>
    </el-form>
      <button style="width:50% ; height:40px; padding: 5px;"  @click="epusdtdialogTablerecharge=false">{{$t('transaction10')}}</button>
      <button style="width:50% ; height:40px; padding: 5px;" @click="getequsdt()">{{$t('transaction11')}}</button>
    </div>
    <div v-show="acb" style="margin: 10px auto; width:100% ; text-align: center;">
      <el-alert :closable="false" show-icon
    :title="$t('wallet2')"
    type="warning">
  </el-alert>
  <el-alert :closable="false" show-icon
    :title="$t('wallet3')"
    type="warning">
  </el-alert>
      <p>{{$t('wallet4')}}：{{epusdt.order_id}}</p>
      <p style="font-size:25px;color: #f0b90b; ">{{$t('wallet5')}}：{{epusdt.actual_amount}}</p>
      <div id="qrcode" style="margin-left: 25%"></div>
      <p style="font-size:17px;color: #f0b90b; ">{{address}}</p>
      <p style="font-size:25px;color: #f0b90b; ">{{hours}}h{{minutes}}m{{seconds}}s</p>
      <el-alert :closable="false" show-icon
    :title="$t('wallet6')"
    type="warning">
  </el-alert>
    </div>
    
</el-dialog>

<el-dialog :title="$t('transaction6')"   :visible.sync="dialogTableembodiment" width="25%">
<el-alert :closable="false" show-icon
    :title="$t('transaction7')"
    type="warning">
  </el-alert>
    <el-form :model="embodiment" >
      <div style="margin: 10px auto; text-align: center;">
      <template>
        <el-radio text-color="#f0b90b"  v-model="embodiment.type" label="1"  border>USDT-TRC20</el-radio>
        <el-radio text-color="#f0b90b"  v-model="embodiment.type" label="0"  border>USDT-ERC20</el-radio>
      </template>
      </div>
    <el-form-item :label="$t('transaction8')">
      <el-input v-model="embodiment.address" auto-complete="off"></el-input>
    </el-form-item>
     <el-form-item :label="$t('transaction9')">
      <el-input v-model="embodiment.number" type="number"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogTableembodiment = false">{{$t('transaction10')}}</el-button>
    <el-button type="primary" @click="getembodiment()">{{$t('transaction11')}}</el-button>
  </div>
</el-dialog>
<el-dialog   :visible.sync="dialogTableembodimentBNBN" width="25%">
<el-alert :closable="false" show-icon
    :title="$t('transaction25')"
    type="warning">
  </el-alert>
    <el-form :model="embodiment" >
      <!-- <div style="margin: 10px auto; text-align: center;">
      <template>
        <el-radio text-color="#f0b90b"  v-model="embodiment.type" label="1"  border>USDT-TRC20</el-radio>
        <el-radio text-color="#f0b90b"  v-model="embodiment.type" label="0"  border>USDT-ERC20</el-radio>
      </template>
      </div> -->
    <el-form-item :label="$t('transaction8')">
      <el-input v-model="embodiment.address" auto-complete="off"></el-input>
    </el-form-item>
     <el-form-item :label="$t('transaction9')">
      <el-input v-model="embodiment.number" type="number"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogTableembodimentBNBN = false">{{$t('transaction10')}}</el-button>
    <el-button type="primary" @click="getembodiment()">{{$t('transaction11')}}</el-button>
  </div>
</el-dialog>
<el-dialog title="" :visible.sync="dialogTablePurchase" >
<h1 style=" color:#f0b90b !important;  font-size: 20px;">{{$t('transaction12')}}</h1>
<el-alert :closable="false" show-icon
    :title="$t('transaction13')"
    type="warning">
  </el-alert>
     <el-form :model="Purchase" >
     <el-form-item :label="$t('transaction14')">
      <el-input v-model="Purchase.number" type="number"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogTablePurchase = false">{{$t('transaction10')}}</el-button>
    <el-button type="primary" @click="getsubscribe()">{{$t('transaction11')}}</el-button>
  </div>
</el-dialog>

<el-dialog title="" :visible.sync="dialogBNBNUSDT" >
<h1 style=" color:#f0b90b !important;  font-size: 20px;">{{$t('transaction22')}}</h1>
<el-alert :closable="false" show-icon
    :title="$t('transaction23')"
    type="warning">
  </el-alert>
     <el-form :model="Bnbnusdt" >
     <el-form-item :label="$t('transaction21')">
      <el-input v-model="Bnbnusdt.number" type="number"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogBNBNUSDT = false">{{$t('transaction10')}}</el-button>
    <el-button type="primary" @click="getbnbn2usdt()">{{$t('transaction11')}}</el-button>
  </div>
</el-dialog>
</div>
 <Foot />
 </div>
</template>
<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import QRCode from "qrcodejs2";
import { getwallet,getapplication,getsubscribe,getobtainerc,getobtaintrc,getbnbn2usdt,getepusdt,inapply } from '@/api/virtual/virtual.js'
  export default {
    components: {
			Head,
			Foot
		},
    data() {
      return {
        INapply:false,
        hours: '00',
        minutes: '00',
        seconds: '00',
        address:'',
        acb: false,
        activeName: 'second',
        dialogTablerecharge : false,
        epusdtdialogTablerecharge : false,
        dialogTableembodiment : false,
        dialogTableembodimentBNBN: false,
        dialogTablePurchase :false,
        dialogBNBNUSDT: false,
        usdtBalance: 0.0,
        platformBalance: 0.0,
        platform:0.0,
        lockPlatformBalance: 0.0,
        lockusdtBalance: 0.0,
        usdtRecordList:[],
        platformRecordList:[],
        tableData:[],
        embodiment:{
          userId:"",
          number: 0,
          address: "",
          type:"2",
        },
        Purchase:{
          userId:"",
          number: 0, 
        },
        Bnbnusdt:{
          userId:"",
          number: 0, 
        },
        epusdt:{
        trade_id: "",
        order_id: "",  //订单id
        amount: 0,
        actual_amount: 0,       //金额
        token: "",    //地址
        expiration_time: 0,       //到期时间
        payment_url: ""
        }
      };
    },
    mounted(){
      
    },
    created() {
            this.getList();
            this.inapply();
            },
    methods: {
      ifexpirationTime(){
          this.epusdtdialogTablerecharge=true;
          // if(localStorage.getItem('epusdt')!=null){
          //   this.epusdt = JSON.parse(localStorage.getItem('epusdt'));
          // if(this.epusdt!=null)
          // { 
          // const timeout = new Date(this.epusdt.expiration_time*1000);
          // const now = new Date();
          // const ms = timeout.getTime() - now.getTime();
          // if(ms>0)
          // {
          //   this.abc=true;
          //   this.address=this.epusdt.token;
          //   this.generateQRCode();
          //   console.log("epusdt1:",this.epusdt);
          // }
          // }
          //}
      },
      generateQRCode() {
      let element = document.getElementById("qrcode");
      let options = {
        width: 200, // 二维码宽度
        height: 200, // 二维码高度
      };
      let qrcode = new QRCode(element, options);
      qrcode.makeCode(this.address); // 使用字符串生成二维码
    },
        // handleCellMouseEnter(row, column, cell, event) {},
        // handleCellMouseLeave(row, column, cell, event) {},
      getobtain(id)
      {
      const storedTime = localStorage.getItem('lastClickGetDataTime') 
      const currentTime = Date.now() // 时间戳(秒级)
      if (!storedTime || (currentTime - storedTime) / 1000 / 60 >= 10) {
        const userId=localStorage.getItem('userId')
        if(id==0){
          getobtainerc(userId).then(response=>{
            if(response.code==200)
            {
                 this.address=response.msg;
                 this.acb=true;
                 this.generateQRCode();
            }
            else{
                localStorage.setItem('lastClickGetDataTime', currentTime-(10*1000*60)) 
                this.$message.error(response.msg);
            }
          })
        }
        else if(id==1){
            getobtaintrc(userId).then(response=>{
              if(response.code==200)
            {
                 this.acb=true;
                 this.address=response.msg;
                 this.generateQRCode();
            }
            else{
                localStorage.setItem('lastClickGetDataTime', currentTime-(10*1000*60)) 
                this.$message.error(response.msg);
            }
          })
        }
         localStorage.setItem('lastClickGetDataTime', currentTime) 
      }else {  
        // 距离上次点击时间小于10分钟，不做任何操作或给出提示  
        this.$message({  
          message: this.$t('transaction20'),  
          type: 'warning',
        })
      }  
      },
      getequsdt(){
        this.Purchase.userId=  localStorage.getItem('userId');
          getepusdt(this.Purchase).then(response=>{
            if(response.code==200)
            {
                this.acb=true;
                this.epusdt=response.data;
                console.log(this.epusdt,response);
                this.address=response.data.token;
                this.generateQRCode();
                this.startClock();
                localStorage.setItem("epusdt", JSON.stringify(this.epusdt));
                console.log("epusdt:",JSON.parse(localStorage.getItem('epusdt')));  
            }
            else{
                this.$message.error(response.msg);
            }
          })
      },
    startClock() {
      const timeout = new Date((this.epusdt.expiration_time-300)*1000);
      const now = new Date();
      const ms = timeout.getTime() - now.getTime();
      const second = Math.round(ms / 1000);
      const minute = Math.floor(second / 60);
      const hour = Math.floor(minute / 60);
      if (minute > 60) {
        this.minutes = minute % 60;
      }
      if (ms <= 0) {
        this.$message.error(this.$t('wallet7'));
        this.abc = false;
        this.epusdtdialogTablerecharge=false;
        localStorage.removeItem('epusdt');
        clearInterval(this.timer);
        return;
      }
      this.hours = hour.toString().padStart(2, '0');
      this.minutes = minute.toString().padStart(2, '0');
      this.seconds = (second % 60).toString().padStart(2, '0');
      this.timer = setTimeout(() => this.startClock(), 1000);
    },
      getbnbn2usdt(){
          if(this.Bnbnusdt.number>0)
        {
          this.dialogBNBNUSDT = false;
          this.Bnbnusdt.userId=  localStorage.getItem('userId');
          getbnbn2usdt(this.Bnbnusdt).then(response=>{
            if(response.data.code==200)
            {
                this.$message({
                message: response.data.msg,
                type: 'success'
                });
                this.getList();
            }
            if(response.data.code==500)
            {
              this.$message.error(response.data.msg);
            }
        });
        }
        else{
          this.$message.error(this.$t('transaction24'));
        }
      },
      getsubscribe(){
        if(this.Purchase.number>=50)
        {
          this.dialogTablePurchase = false;
          this.Purchase.userId=  localStorage.getItem('userId');
        getsubscribe(this.Purchase).then(response=>{
            if(response.code==200)
            {
                this.$message({
                message: response.msg,
                type: 'success'
                });
                this.getList();
            }
            if(response.code==500)
            {
              this.$message.error(response.msg);
            }
            this.getList();
        });
        }
        else{
          this.$message.error(this.$t('transaction15'));
        }
      },
      getembodiment()
      { 
        if(this.embodiment.number>=10)
        {
          this.dialogFormVisible = false;
          this.embodiment.userId=  localStorage.getItem('userId');
        getapplication(this.embodiment).then(response=>{
            if(response.code==200)
            {
                this.$message({
                message: response.msg,
                type: 'success'
                });
                this.getList();
            }
            if(response.code==500)
            {
              this.$message.error(response.msg);
            }
        });
        this.dialogTableembodiment=false;
        this.dialogTableembodimentBNBN=false;
        }
        else{
          this.$message.error(this.$t('transaction16')); 
        }
      },
      handleClick(tab, event) {
       if(this.activeName=="first")
       {
        this.tableData=this.usdtRecordList;
       }
       else if(this.activeName=="second"){
        this.tableData=this.platformRecordList;
       }
        console.log();
        console.log(tab, event);
      },
      getList() {
        const id=  localStorage.getItem('userId');
        getwallet(id).then(response => {
        this.usdtBalance = response.data.usdtBalance.toFixed(5);
        this.platformBalance = response.data.platformBalance.toFixed(5);
        this.lockPlatformBalance = response.data.lockPlatformBalance.toFixed(5);
        this.lockusdtBalance=response.data.lockusdtBalance.toFixed(5);
        this.platform=response.data.platform.toFixed(5);
        this.tableData=response.data.recordList;  
        this.platformBalance=(this.platformBalance-this.platform).toFixed(5);
        
      });
    },
      inapply(){
        inapply().then(response => {
        if(response.data==1)
        {
          this.INapply=true;
        }
        else{
          this.INapply=false;
        }
      });
    }
  
    },
  }
</script>
<style scoped>
.my_table >>> .el-table__row>td{
  /* 去除表格线 */
  border: none;
}
.my_table >>> .el-table th.is-leaf {
  /* 去除上边框 */
    border: none;
}

.my_table >>> .el-table::before{
  /* 去除下边框 */
  height: 0;
}

.my_table ::v-deep .el-table__body tr:hover > td {
     background-color: rgb(0, 0, 0) !important;
 }


.el-table__body tr:hover>td{
 
background-color: rgb(0, 0, 0) !important;
 
}
 
/* 点击之后颜色改为粉红色  如果没有效果 需要给el-table  加上  highlight-current-row  这个属性 */
/* .el-table__body tr.current-row>td{
 
  color: rgb(0, 0, 0) !important;
  background: rgb(12, 63, 192) !important;
 
} */

</style>