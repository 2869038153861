
export default {
//錯誤提示
error1: '系統錯誤請聯繫客服',
error2: '登入過期，請重新登入！',
meun1: '首頁',
meun2: '市場趨勢',
meun3: 'BNBN申購',
meun4: '購買礦機',
meun5: '快速推廣',
meun6: '白皮書',
meun7: '公告',
meun8: '登錄',
meun9: '註冊',
meun10: '錢包',
meun10_1: '現貨賬戶',
meun10_2: 'U本位合約賬戶',
meun10_3: '幣本位合約賬戶',
meun10_4: '秒本位合約',
meun10_5: '理財賬戶',
meun10_6: '錢包歷史記錄',
meun11: '訂單',
meun11_1: '申購訂單',
meun11_2: '挖礦訂單',
meun12: '客服',
meun12_1: '客服資訊',
meun12_2: '客服編號',
meun12_3: '客服郵箱',
meun13: '紅包',
meun13_2: '領取紅包',
meun13_3: '請輸入紅包領取碼',
meun13_4: '取消',
meun13_6: '領取',
meun13_7: '取消成功',
meun13_1: '帳戶 管理',
meun13_5: '退出登錄',
meun14_1: '語言',
meun14_3: '選擇語言',
scan: 'iOS & Android 掃碼下載',
footmeun1: '關於',
footmeun1_1: '關於我們',
footmeun1_2: '加入我們',
footmeun1_3: '平台公告',
footmeun1_4: '白皮書',
footmeun2: '幫助中心',
footmeun2_1: '新手指南',
footmeun2_2: '常見問題',
footmeun2_3: '交易指南',
footmeun2_4: '幣種資料',
footmeun3: '條款協議',
footmeun3_1: '免責條款',
footmeun3_2: '隱私條款',
footmeun3_3: '用戶協議',
footmeun3_4: '費用說明',
footmeun4: '聯繫我們',
footmeun4_1: '客服郵箱 ',
footmeun4_2: '商務合作',
footmeun4_3: '上幣申請',
footmeun4_4: '投訴舉報',
foottext: 'BNBN全球申購平台',
countdown: '活動已結束',
indexTxt1: 'BNBN全球首發申購',
indexTxt2: 'BNBN全球首發申購',
indexTxt3: '上線倒計時',
indexLabel1: '市場趨勢',
indexVal1: '加密貨幣實時價格',
indexLabel2: 'BNBN申購',
indexVal2: '用一種數字資產去直接換取',
indexLabel3: '購買礦機',
indexVal3: 'BNBN加密貨幣挖礦',
indexLabel4: '快速推廣',
indexVal4: '申購用戶福利',
indexh5: '金融級安全',
indexh6: '極速充提',
indexh7: '全球服務',
indexh8: '嚴選項目',
indexp1: 'BITISAN 由一群比特幣早期參與者與極客創立，團隊核心成員來自於谷歌、微軟、阿里巴巴、騰訊等知名企業，具有深厚的研发實力與豐富的互聯網產品運營經驗。',
indexp2: 'BITISAN 定位 於區塊鏈基礎服務商，致力於為全球用戶提供優質加密資產交易平台，秉承著“不作惡”的基本原則，堅持誠實、公正、熱情的服務於客戶，以開放的態度迎接一切有利於用戶根本利益的夥伴/項目。',
indexp3: '全方位金融風控系統和防盜系統，冷熱錢包、多簽系統保證資金安全',
indexp4: '充值提現最快3分鐘完成，24小時人工在線審核，保護客戶不錯過最佳投資機會',
indexp5: '全球業務服務網絡覆蓋，助您投資全球加密資產，與全球用戶交易',
indexp6: '嚴格選擇優質加密項目，為您篩選掉80%極高風險項目',
market: '市場資訊',
//新幣申購-錢包
wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
usdt: 'USDT',
platform: 'BNBN',
usdt1: '可用餘額',
usdt2: '鎖定餘額',
platform1: '提 現',
platform2: '充值',
platform3: '申購',
transaction: '交易明細',
transaction0: '序號',
transaction1: '交易編號',
transaction2: '交易數量',
transaction3: '交易時間',
transaction4: 'USDT充值',
transaction5: '充值地址',
transaction6: 'USDT提現',
transaction9: '提現數量',
transaction7: 'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
transaction8: '提現地址',

transaction10: '取 消',
transaction11: '確 定',
transaction12: '申 購',
transaction13: '申購的數量值是指申購的USDT數量',
transaction14: '申購數量',
transaction15: '申購數量必須大於50',
transaction16: '提現數量必須大於10',
transaction17: '貨幣類型',
transaction18: '暫無交易明細',
transaction19: '請選擇充值 地址',
transaction20: '請在10分鐘後再點擊按鈕',
transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    transaction25: '提現需要扣除一定數量手續費',

// 锁仓挖矿
mining1: '申购',
mining2: '购买价格',
mining3: '每日收益',
mining4: '锁定天数',
mining5: '购买等级',
mining6: '购买总收益',
mining7: '开始时间',
	mining8: '结束时间',
	miningmachine: '我的矿机',
	mining9: '优惠倒计时',

// 邀请
inviteusers1: '邀请链接',
inviteusers2: '邀请人数',
inviteusers3: '邀请编号',
inviteusers4: '邀请用户',
inviteusers5: '邀请时间',
inviteusers6: '复制成功',
inviteusers7: '暂无邀请用户',
inviteusers8: '复制',
inviteusers10:'订阅人数',
inviteusers11:'Is it active',
// 订单
order1: '申购订单',
order2: '理财订单',
order3: '申购编号',
order4: '申购数量',
order5: '申购时间',
order7: '理财编号',
order8: '锁定数量',
order9: '奖励数量',
order10: '开始时间',
order11: '结束时间',
order12: '暂无申购信息',
order13: '暂无理财信息',
// 个人中心
personal1: '个人信息',
personal2: '实名认证状态',
personal3: '已认证',
personal4: '认证中',
personal5: '未认证点击认证',
personal6: '认证失败点击认证',
personal7: '用户名',
personal8: '邮箱',
personal9: '手机号',
personal10: 'VIP等级',
personal11: '普通会员',
personal12: '邀请码',
personal13: '认证信息',
personal14: '请上传身份认证相关资料',
personal15: '修改密码',
personal16: '原始密码',
personal17: '修改密码',
personal18: '确认密码',
personal19: '请输入密码',
personal20: '密码长度不能少于6位',
personal21: '请再次输入密码',
personal22: '两次输入的密码不一致',
authentication1: '添加认证信息',
authentication2: '确定上传',
authentication3: '只能上传jpg/png文件，且不超过2MB',
authentication4: '上传文件',
authentication5: '用户添加和文件上传成功',
authentication6: '用户添加和文件上传失败',
authentication7: '上传头像图片只能是JPG格式!',
authentication8: '上传头像图片大小不能超过2MB!',
authentication9: '文件超出5个限制',

    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
// 登录
account0: '帳號',
account: 'BNBNC OIN帳號登錄',
phone: '手機號碼',
email: '電子郵箱',
sign: '登錄',
password: '密碼',
password2: '密碼',
password3: '忘記密碼',
account2: '建立帳號',
logErr: '請輸入帳號',
logErr2: '請輸入密碼',
logErr3: '密碼長度不能少於6位',
logSuccess: '登錄成功',
resetpassword: '重置密碼',
reset: '重置',
// 注册
account3: '建立個人帳號',
phone1: '手機註冊',
email1: '電子信箱註冊',
email2: '電子信箱地址',
code: '短信驗證碼',
code2: '發送驗證碼',
code3: '驗證碼',
password4: '確認密碼',
invite: '邀請碼',
agree: '我已閱讀並同意',
agreement: '用戶協議',
register: '注冊',
regErr: '請輸入短信驗證碼',
confirmpwdtip: '請輸入確認密碼',
confirmpwderr: '兩次密碼輸入不一致!',
// 忘记密码
wjtab: '重置手機 密碼',
wjtab2: '重置電子信箱密碼',
wjlabel: '新密碼',
wjerr: '請輸入新密碼',
Tailpage:'尾頁',
	homepage:'首頁',
	lastpage:'上一頁',
	nextpage:'下一頁',
	off:'關閉',
	problem:'如遇到問題包括實名認證，存款未到達的情况請及時諮詢客服',
//公告
announcementtitle: '公告标题',
announcement: '<p>2019年4月主网上线后，币安链展示了其高速性能和高吞吐量的设计特性。其核心技术亮点——原生去中心化应用（“dApp”）币安链DEX（去中心化交易所），经过测试能够在短时间内处理数百万笔交易，表现出其交易引擎卓越的低延迟撮合能力。</p>\
<p>在追求为数字资产发行和交易提供便捷环境的过程中，这一设计虽然实现了灵活性和可用性的提升，但也带来了一定程度的局限性。社区中最强烈的声音是希望币安链能够增添可编程扩展功能，或者说，集成智能合约和虚拟机功能。但由于目前币安链功能相对有限，数字资产的发行者和所有者在向其资产中添加新功能或者引入各种形式的社区管理和社区活动方面面临着困难。</p>\
<p>尽管社区对币安链添加智能合约功能有着高度期待，但这对于链本身来说是一个“艰难的决定”。智能合约的执行可能会降低DEX的速度，并给资产交易带来一定的不确定性。即使这种折衷是可以接受的，最初设想的解决方案可能是通过基于当前底层Tendermint共识协议和主RPC接口构建一个虚拟机界面，以此在币安链上实现智能合约功能。但是，这种方法会增加现有dApp开发者的学习成本，因而不太可能成为广受欢迎的选择。</p>\
<p>在此，我们提出了Binance Chain并行区块链的概念，旨在维持原生DEX高性能的同时，也能友好地支持智能合约功能。</p>',
//创新实验室
labTxt: 'Bitisan实验室 • 共享创新成果',
labTxt2: '投资者、项目方、交易所等多方共赢的开放实验室',
labTab1: '全部',
labTab2: '即将开始',
labTab3: '进行中',
labTab4: '派发中',
labTab5: '已完成',
tokendistribute: 'Token派发中',
activitytype: '认购类型',
labStart: '开始时间',
labEnd: '结束时间',
labStartPlaceholder: '请选择开始时间',
labDetail: '查看详情',
labSpeen: '进度',
labNumber: '活动总量',
labType1: '首次上线(抢购)',
labType2: '首次上线(平分)',
labType3: '持仓瓜分',
labType4: '自由认购',
labType5: '云矿机认购',
labType6: '锁仓释放',
baseinfo: '基本信息',
ruledetail: '类型说明',
releaseType: '释放类型',
releaseType0: '等额释放',
releaseType1: '等比释放',
lock: '期释放',
releaseDetail: '释放详情',
lockday: '日',
lockweek: '周',
lockmonth: '月',
lockyear: '年',
releasePercent: '释放比例',
lockFee: '锁仓门槛',
releaseTimes: '释放倍数',
times: '倍',
publishprice: '认购价格',
holdcoin: '持仓要求币种',
limittimes: '个人限购次数',
limitamount: '个人限购数量',
unlimited: '不限',
leveloneCount: '需要一级好友数',
alreadyamount: '已购数量',
leftamount: '剩余数量',
myalreadyholdamount: '我的参与持仓',
alreadyholdamount: '活动参与总持仓',
currentdivided: '当前我的持仓可瓜分约',
holdtips: '参与【持仓瓜分】类型活动需锁仓至活动结束',
inputholdamount: '输入锁仓数量',
pleaseinputamount: '请输入兑换数量',
pleaseinputholdamount: '请输入锁仓数量',
inputamount: '输入兑换数量',
inputminingamount: '输入购买矿机数',
attend: '立即参与',
attention: '注意',
attentiontxt1: '1、提交参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后将冻结资产，活动结束后解冻或解锁释放',
attentiontxt2: '2、参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后，用户不得撤销参与申请',
attentiontxt3: '3、如因项目方及其他不可抗力因素取消活动，将原路退回冻结资产',
attentiontxt4: '* 本活动最终解释权归Bitisan(WWW.BITISAN.NET)官方所有',
//币本位合约账户
transfer: '资金划转',
placeholder: '搜索币种',
bith1: '合约交易',
bith2: '账户权益',
bith3: '未实现盈亏',
bith4: '可用保证金',
bith5: '已用保证金',
bith6: '冻结保证金',
bith7: '合约账户',
// 现货账户
btn1: '充值',
btn2: '提现',
btn3: '划转',
btn4: '充币',
btn5: '提币',
totalAssets: '总资产折合',
th1: '币种名称',
th2: '可用资产',
th3: '冻结资产',
th4: '待释放资产',
th5: '操作',
// 充值
czTxt: '数字货币充值',
czTxt2: '币种',
czTxt3: '转账网络',
czTxt4: '可用余额',
czTxt5: '提币地址',
czTxt6: '提币数量',
czTxt7: '到账数量',
czTxt8: '手续费',
czTxt9: '温馨提示',
czTxt10: '最小充值金额：',
czTxt10_1: '小于最小金额的充值将不会上账。',
czTxt11: '请勿向上述地址充值任何非币种资产，否则资产将不可找回。',
czTxt12: '您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账。',
czTxt13: '您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。',
czTxt14: '请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
czTxt15: '充值记录',
czTxt16: '为保障资金安全，当您账户安全策略变更、密码修改、使用新地址提币，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系。',
czTxt17: '最小提币数量为：',
czth1: '提现时间',
czth2: '从',
czth3: '至',
czth4: '闪兑数量',
czth5: '兑换比例',
czth6: '兑换数量',
placeholder2: '请输入',
placeholder3: '请选择',
czEmpty: '无记录',
logintip: '请先登录！',
cztip: '您未申请过充值地址，请点击按钮获取充值地址',
czget: '获取充值地址',
czplaceholder: '请选择协议',
// 秒合约账户
mAccount: '秒合约账户',
// 钱包历史记录
btn6: '搜索',
wtTxt: '起止时间',
wtTxt2: '操作类型',
wtth1: '交易时间',
wtth2: '类型',
wtth3: '币种',
wtth4: '数量',
wtth5: '应付手续费',
wtth6: '抵扣手续费',
wtth7: '实付手续费',
wtth8: '状态',
// 账户安全
nav1: '账户安全',
nav2: '我的邀请',
nav3: '我的推广卡',
acTxt: '安全等级',
acbtn1: '未认证',
acbtn2: '绑定',
acbtn3: '修改',
acbtn4: '已认证',
acbtn5: '已绑定',
acbtn6: '设置',
acbtn7: '审核中',
acbtn8: '重试',
acTxt2: '实名认证',
acTxt3: '邮箱',
acTxt4: '手机',
acTxt5: '登录密码',
acTxt6: '资金密码',
acTxt7: 'Google验证器',
acTxt8: '设置资金密码',
acTxt9: '更改资金密码',
acTxt10: '找回资金密码',
acDesc2: '为保障您的账户安全，请完成实名认证后方可交易操作！',
acDesc3: '绑定邮箱',
acDesc4: '绑定手机',
acDesc5: '登录平台时使用',
acDesc6: '账户资金变动时，需要验证资金密码',
acDesc7: '请绑定Google验证器',
acDesc8: '已绑定Google验证器',
acDesc9: '解除绑定',
acTit: '手机绑定',
acTit2: '邮箱绑定',
acTit3: 'Google绑定',
acTit4: 'Google解除绑定',
mailtip: '请输入邮箱',
emailerr2: '邮箱格式不正确，请重新输入',
agreementtip: '请阅读并同意用户协议',
acverify: '审核未通过',
again: '请重试',
verifiedmsg: '请先进行实名认证',
// 更改登錄密碼
chTxt: '更改登錄密碼',
chTxt2: '掃碼綁定',
chTxt3: '請輸入驗證碼',
chTxt4: '原資金密碼',
chTxt5: '新資金密碼',
chTxt6: '確認新密碼',
chTxt7: '忘記密碼',
chTxt8: '更改手機驗證',
chTxt9: '原登錄密碼',
chTxt10: '新登錄密碼',
chTxt11: '郵箱驗證碼',
chTxt12: '手機驗證碼',
telerr: '手機號碼不正確',
emailerr: '電子郵件地址不正確',
codeerr: '驗證碼不正確',
save: '儲存',
chtip: '請輸入原始密碼',
chtip2: '請輸入不少于6位的新登錄密碼',
chtip3: '新登錄密碼不一致',
chtip4: '請輸入正確的資金密碼',
chtip5: '密碼不得少於6個字符',
chtip6: '請輸入不少于6位的密碼',
chtip7: '密碼不一致',
chtip8: '請輸入資金密碼',
chtip9: '請輸入手機號碼',
chtip10: '請輸入登錄密碼',
realnametip: '請輸入真實姓名',
idcardtip: '請輸入身份證號碼',
// 收款 方式
clTxt: '請設置您的收款方式，並務必使用本人的實名帳號',
clTxt2: '新增收款方式',
edit: '編輯',
delete: '刪除',
name: '姓名',
clTxt3: 'ID號碼',
clTxt4: 'IBAN號碼',
clTxt5: '所有收款方式',
clLink1: '帳戶名稱',
clLink2: '編輯收款方式',
clLink3: '付款詳情(選填)',
clplaceholder1: '請輸入您的完整姓名',
clplaceholder2: '請輸入您的銀行帳戶密碼',
clplaceholder3: '請輸入您的付款詳情',
cltip: '特別提示',
cltip2: '請確保添加您的銀行卡號以便進行即時付款。請勿包含其他銀行或其他付款方式的詳細信息。您必須添加所選銀行的付款/收款資訊。',
cltip3: '溫馨提示：當您出售數字貨幣時，您選擇的收款方式將向買方展示。請確認信息填寫準確無誤。請使用與您本人幣安平台KYC身份認證一致的收款帳戶。',
clTxt6: '選填',
upload: '上傳',
uploadTip: '(支援JPG/JPEG/PNG/BMP格式, 小於1MB)',
// 我的推廣卡
tgbtn: '兌換碼兌換',
tgth1: '卡名',
tgth2: '兌換碼',
tgth3: '卡面幣種',
tgth4: '卡面金額',
tgth5: '總數',
tgth6: '已兌',
tgth7: '領取時間',
tgbtn2: '推廣素材',
tgbtn3: '立即兌換',
tgplaceholder: '請輸入兌換碼',
exchangesuccess : "兌換成功！請到資產管理查看餘額！ ",
// 我的邀請
inth1: '邀請用戶',
inth2: '註冊時間',
inth3: '實名狀態',
// 我的廣告
adTxt: '我的廣告',
adTxt2: '當廣告最低交易額所購買數量加上手續費大於廣告剩餘數量時，該廣告自動下架',
adbtn: '清除條件',
adbtn2: '上架',
adbtn3: '下架',
adth1: '廣告編號 ',
adth2: '廣告類型',
adth3: '訂單限制',
adth4: '剩餘數量',
adtip: '確定刪除嗎',
num_text1: '請輸入您要',
num_text2: '的數量',
// 當前委託
wTxt: '現貨',
wTxt2: '當前委託',
wTxt3: '交易對',
wTxt4: '交易方向',
wth1: '時間',
wth2: '方向',
wth3: '價格',
wth4: '已成交',
wth5: '成交金額',
wth6: '委託類型',
wth7: '觸發價',
wth8: '成交價',
wth9: '委託數量',
wth10: '結算盈虧',
wth11: '委託狀態',
wth12: '開倉/平倉',
wth13: '保證金',
wbtn: '撤單',
wtip: '撤單提示',
wtiptxt: '是否確認撤單',
// 賺幣
zbplaceholder: '輸入訂單編號開始搜尋',
zbtab1: '定期投資計劃',
zbtab2: '定期投資紀錄',
zbth1: '投資週期',
zbth2: '開/關',
zbth3: '累計投資金額',
zbth4: '持有數量',
zbth5: '平均成本',
zbth6: '未實現盈虧',
zbth7: '下次定期投資日期',
zbth8: '計劃創建日期',
// 當前委託-1
etTxt: '公告中心',
etTxt2: '扫一掃分享',
etmenu1: '加密數字貨幣',
etmenu2: 'Bitisan伺服器更新公告',
// 當前委託-3
et2menu1: 'RX.波場簡介',
et2menu2: '比特幣是龐氏騙局嗎?',
// 歷史委託
hisTxt: '歷史委託',
// 我的訂單
orTab1: '未付款',
orTab2: '已付款',
orTab3: '已完成',
orTab4: '已取消',
orTab5: '申訴中',
orth1: '訂單號',
orth2: '交易幣種',
orth3: '交易類型',
orth4: '交易對象',
orth5: '金額',
// 期權合約
qcTab1: '往期結果',
qcTab2: '即時行情',
qcTxt: '第',
qcTxt2: '期',
qcTxt3: '開盤時間',
qcTxt4: '收盤時間',
qcTxt5: '開盤價格',
qcTxt6: '收盤價格',
qcTxt7: '買漲總額',
qcTxt8: '買跌總額',
qcTxt9: '我的開倉',
qcTxt10: '預測進度',
qcTxt11: '開盤價',
qcTxt12: '收盤價',
qcTxt13: '本期預測週期',
qcbtn1: '看漲',
qcbtn2: '看跌',
qcth1: '合約期數',
qcth2: '開倉金額',
qcth3: '預測方向',
qcth4: '預測結果',
qcth5: '獎金數量',
qcth6: '開倉手續費',
qcth7: '抽水',
// 币種詳細 信息
Internet:'網路連接錯誤',
mplaceholder: '輸入幣種名稱搜尋',
mTab: '自選',
mth: '收藏',
mth1: '最新價',
mth2: '24小時漲跌',
mTxt: '24小時最高價',
mTxt2: '24小時最低價',
mTxt3: '交易數量',
mTxt4: '資金費率',
mTxt5: '開',
mTxt6: '高',
mTxt7: '低',
mTxt8: '收',
indexth: '交易總金額',
indexbtn: '詳情',
indexbtn1:'價格',
indexbtn2:'數量',
indexbtn3:'成交額',
indexbtn4:'時間',
echat1: 'K線圖',
echat2: '深度圖',
mTab2: '當前持倉',
mTab3: '委託歷史',
mth3: '包賠',
mth4: '開倉價格',
mth5: '週期',
mth6: '平倉價格',
mth7: '盈利',
mTxt9: '交易模式',
mTxt10: '開倉時間',
mTxt11: '盈利率',
mTxt12: '餘額',
mTxt13: '買入量',
mTxt14: '中',
// 创建广告
cadTxt: '建立一個廣告交易',
cadTxt2: '如果您經常進行交易，您可以建立自己的交易廣告。',
cadTxt2_1: '如果您只是偶爾交易，我們建議您直接搜尋',
cadTxt2_2: '建立一則交易廣告是免費的。',
cadLink2: '交易廣告',
cadTxt3: '若您想直接編輯已建立的廣告，請查看',
cadLink3: '我的廣告',
cadTxt4: '市場參與價格',
cadTxt5: '溢價是指高於當前市場價格多少百分比進行賣出',
cadTxt6: '計價公式',
cadTxt7: '【提示】可前往個人中心綁定/增加支付方式',
cadTxt8: '最大交易額不能超過您的賣出總金額0CNYI',
cadTxt9: '啟用後，用戶通過此廣告向您發起交易，系統自動將您選擇的自動回復用語發送給對方。',
cadTxt10: '溢價是指高於當前市場價格多少百分比進行買入',
cadLabel1: '我想要：',
cadLabel2: '幣種交易：',
cadLabel3: '國家：',
cadLabel4: '貨幣：',
cadLabel5: '開啟固定價格',
cadLabel6: '溢價：',
cadLabel7: '交易價格',
cadLabel8: '賣出數量：',
cadLabel9: '付款方式：',
cadLabel10: '最小交易額：',
cadLabel11: '最大交易額：',
cadLabel12: '開啟自動回復',
cadLabel13: '資金密碼：',
cadLabel14: '備註資訊：',
cadLabel15: '固定價格：',
cadLabel16: '自動回復：',
cadLabel17: '買入數量：',
radio: '線上出售',
radio2: '線上購買',
cadplaceholder: '請設定您的溢價',
cadplaceholder2: '請輸入您要賣出的數量',
cadplaceholder3: '請輸入您要的最小交易額',
cadplaceholder4: '請輸入您要的最大交易額',
cadplaceholder5: '可以在備註資訊內填寫您的特殊要求，例如：對買方的要求，在線時間等',
cadplaceholder6: '請輸入您的交易價格',
cadplaceholder7: '在接收到訂單後，自動回覆給買家的資訊，例如：收款方式、收款帳號等。',
cadplaceholder8: '請輸入您要買入的數量',
cadErrMsg1: '請輸入您的最小交易額',
cadErrMsg2: '最小交易額必須大於等於100！',
cadErrMsg3: '最小交易額必須小於最大交易額',
cadErrMsg4: '請輸入您的最大交易額！',
cadErrMsg5: '請輸入整數',
cadErrMsg6: '最大交易額必須大於最小交易額！',
cadErrMsg7: '最大交易額不能超過您的賣出總金額',
cadErrMsg8: '請選擇交易方式',
submit: '提交',
cadnew: '啟用後，您的幣價不會隨市場波動而變動，價格保持不變。',

// 快速兌換
dhTxt: '一鍵兌換·輕鬆獲益',
dhTxt2: '限價',
dhTxt3: '賣出',
dhTxt4: '買入',
dhTxt5: '最大',
dhTxt6: '輸入金額',
dhplaceholder: '現貨錢包 可用餘額',
dhSuccess: '恭喜您！兌換成功！',

// 法幣交易
crTxt: '法幣交易',
crTxt2: '便捷、安全、快速買賣數字貨幣',
crTxt3: '動態調整',
crTxt4: '免手續費',
crTxt5: '即時成交',
crTxt6: '平台擔保',
crTxt7: '根據市場價格實時波動',
crTxt8: '用戶所見即所得，買賣價格之外無需支付任何平台手續費',
crTxt9: '引進平台服務商家，智慧匹配，迅速成交訂單，無需等待撮合',
crTxt10: '平台認證商家，安全有保障，24小時客服為交易全程護航',
crth1: '商家',
crth2: '交易筆數',
crth3: '付款方式',
crth4: '限額',
crth5: '單價',
crBtn: '購買',
crBtn2: '成為商家',

// 法幣交易1
cr1Txt: '恭喜！您的商家認證審核已通過',
cr1Txt2: '準備資料',
cr1Txt3: '提交審核',
cr1Txt4: '已認證',
cr1Txt5: '您獲得以下權限',
cr1Txt6: '專屬展位',
cr1Txt7: '一對一服務',
cr1Txt8: '商家享有專屬廣告展位，提升交易成功率',
cr1Txt9: '更低手續費',
cr1Btn: '發布廣告',
cr1Btn2: '申請退保',

// 定期理財
reTxt: 'Bitisan理財 • 一站式投資解決方案',
reTxt2: '立即賺取收益、簡單 &安全',
reTab: '定期理財',
reTab2: '定投理財',
reTxt3: '定期',
reTxt4: '靈活定制，潛在收益更佳',
reTxt6: '定投',
reTxt7: '開始使用定投並增加您的資產',
reTxt8: '展開所有18個定期產品',
reTh1: '年化收益率',
reTh2: '期限（天）',
reTh3: '交易限額 ',
reTh4: '產品',
reTh5: '歷史投資回報率',
reBtn: '建立計劃',
redTxt: '建立一個',
redTxt2: '定投計劃',
redTxt3: '輸入投資金額',
redTxt4: '我的可用餘額為',
redTxt5: '可用',
redTxt6: '循環週期',
redTxt7: '每天',
redTxt8: '每週 ',
redTxt9: '每月',
redTxt10: '本地下單時間',
redTxt11: '您首次自動投資週期將於',
redTxt12: '開始',
confirm: '確認',
confirm2: '確定',
cancel: '取消',
redOngoing: "進行中 ",
redCompleted: "已完成 ",
minNumErr: '低於最低起投金額',
minNumErr2: '起投金額不能為0',
buyAmountTip: '請輸入買入數量',
priceTipWarning: '交易額不能高於',
buyAmountTipWarning: '買入數量不能高於',
sellAmountTip: '請輸入賣出數量',
sellAmountTipWarning: '賣出數量不能高於',
sellPriceTip: '請輸入賣出價格',
sellPriceTipWarning: '賣出價格不能高於',
sellMore: '最多可賣',
sellUnit: '個',
loginFirst: "請先登錄 ",
betSuccess: "恭喜您！下注成功！ ",
selectAmount: "請選擇下注數量 ",
balanceNotEnough: "可用餘額不足!",
balanceNotEnough2: "餘額不足！ ",
minLimitAmountFailed: "兌換數量不能小於最低起兌量！ ",
maxLimitAmountFailed: "兌換數量不能大於最高限購量！ ",
limitTimesFailed: "您參與的次數超過個人最高限購次數！ ",
commitFailed: "提交參與失敗！ ",
// 現貨交易
trplaceholder: '查詢',
trplaceholder2: "最新價格 ",
trplaceholder3: '以市場上最優價格買入',
trplaceholder4: '以市場上最優價格賣出',
triggleplaceholder: "默認委託價為市價 ",
triggerpriceshouldbigger: "觸發價必須大於0 ",
marginModeSuccessTip: "合約賬戶模式修改成功 ",
noenoughbalance: "賬戶保證金不足 ",
pleaseinputopenvolume: "開倉數量必須大於0 ",
pleaseinputcorrectopenvolume: "請輸入正確的開値數量 ",
pleaseinputrightentrustprice: "請輸入正確的委託價格 ",
noenoughposition: "可平倉位不足 ",
pleaseinputclosevolume: "平倉數量必須大於0 ",
pleaseinputcorrectclosevolume: "請輸入正確的平倉數量 ",
option1: '日線',
option2: '週線',
option3: '月線',
trTxt: '交易額',
trTxt2: '市價',
trTxt3: '賣出量',
trSuccess: '提交成功',

// u本位
udTxt: '保證金模式',
udTxt2: '調整合約倍數',
udTxt3: '槓桿',
udTxt4: '永續',
udTxt4_1: '永續',
udTxt5: '可轉額度',
udTxt6: '劃轉數量',
udTxt7: '全部劃轉',
udTxt8: '永續賬戶',
ubtn: '全倉',
ubtn2: '逐倉',
uplaceholder: '永續合約賬戶',
uradio1: '分時',
uradio2: '15分鐘',
uradio3: '1小時',
uradio4: '4小時',
uradio5: '1天',
uth1: '合約倍數',
uth2: '收益',
uth3: '收益率',
uth4: '持倉量',
uth5: '可平量',
uth6: '開倉均價',
uth7: '強平價',
uth8: '當前保證金',
uth9: '保證金率',
zhang: '張',
or: '或',
ureg: '立即註冊 ',
uTxt8: '進行交易',
uTab: '開倉',
uTab2: '平倉',
uTxt9: '可開多',
uTxt10: '可開空',
uTxt11: '委託價',
uplaceholder2: '以市場最優價成交',
uTxt12: '買入開多',
uTxt13: '賣出開空',
closeup: "買入平空 ",
closedown: "賣出平多 ",
uTab3: '止盈止损',
uTab3_yprice: '止盈價格',
uTab3_sprice: '止损價格',
uTab3Txt: '止盈/止损',
uTxt14: '我的合約賬戶',
ulabel1: '賬戶模式',
ulabel2: '賬戶總收益',
ulabel3: '倉位保證金',
ulabel4: '資金利用率',
ulabel5: '多',
ulabel6: '空',
uTxt15: '多頭',
uTxt16: '空頭',
total: '合計',
limited_price: '限價委託',
market_price: '市價委託',
spot_price: '止盈止损',
ent_status1: "委託中 ",
ent_status2: "已撤銷 ",
ent_status3: "委託失敗 ",
ent_status4: "委託成功 ",
ent_statusblast: "爆倉 ",
uTxt17: '多倉可平',
uTxt18: '空倉可平',

// 上一篇及下一篇按鈕
prev: '上一篇',
next: '下一篇',

// 推廣夥伴
prtxt: '我的邀請連結',
copy: '複製',
prtxt2: '我的一級好友',
prtxt3: '我的二級好友',
prtxt4: '佣金收入(折合USDT)',
prtxt5: '額外獎勵',
prtxt6: '夥伴等級',
prtit: '規則詳情',
prdesc: 'Bitisan的【推廣夥伴】活動為全網最高返傭比例和最長返傭時長（最高可達終身返傭），讓付出努力的推廣者成為真正的平台「夥伴」，共享與Bitisan交易平台共同成長的紅利！具體細則如下：',
prli1: '一、為了傳播數字資產理念並擴大用戶群體，Bitisan推出了【推廣夥伴】計劃，該活動長期有效。',
prli2: '二、邀請好友分為兩級關係，例如A邀請了B，則B是一級好友；若B再邀請C，則C屬於B的一級好友，同時也是A的二級好友。',
prli3: '三、新朋友通過推廣者的邀請鏈接或手動輸入邀請碼註冊，才能成為推廣者的一級好友。',
prli4: '四、被邀請者透過邀請碼註冊並完成實名認證後，返傭激勵才會開始生效。',
prli5: '五、返傭時間從被邀請者完成實名認證開始算起，持續N個月，根據等級不同，所享受的返傭比例也不同。 ',
prli6: '六、推廣返傭資產為下級好友幣幣交易的手續費，返傭幣種為三大市場基礎幣，包括：USDT、BTC、ETH，同樣依據等級差異享有不同的返傭比例。 ',
prli7: '七、推廣返傭比例、返佣時限與推廣的一級好友人數成正比，推廣人數愈多，所能獲得的返傭比例愈高。具體推廣返傭比率如下所示：',
prli8: '八、推廣返傭排行榜前TOP10或TOP100的推廣者將不時獲得由Bitisan官方發放的額外獎勵。 ',
prli9: '九、若有其他活動與【推廣夥伴】活動產生衝突，經協商後決定是否臨時變更，敬請各位夥伴理解配合。',
prli10: '十、L4（巡撫）、L5（太守）、L6（節度使）等級的夥伴分別享受每年返傭總額的5%、10%、15%的分紅獎勵。',
prli11: '十一、本活動最終解釋權歸Bitisan所有。',
prth1: '等級',
prth2: '一級好友數量',
prth3: '一級好友返傭比例 / 返傭時長',
prth4: '二級好友返傭比例 / 返傭時長',
prth5: '夥伴分紅',
prlev1: '里正',
prlev2: '縣令',
prlev3: '知府',
prlev4: '巡撫',
prlev5: '太守',
prlev6: '節度使',
prtd1: '個月',
prtd2: '終身返傭',
prtit2: '舉例說明',
prtxt7: '若用戶小嚴邀請了100名一級好友，而TA的每一位一級好友各自又邀請了5名二級好友，此時小嚴擁有100名一級好友及500名二級好友。假設平均每人每天交易金額為3000元，那麼小嚴每月收入約為：(100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200元/月。',
prtxt8: '若用戶小嚴邀請了1000名一級好友，且TA的每位一級好友各邀請了5名二級好友，此時小嚴擁有1000名一級好友及5000名二級好友。同樣假設每人每天交易金額為3000元，則小嚴的月收入約為：(1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000元/月。',
prtit3: '推廣工具箱',
prtit4: '推廣返傭總額TOP20（總榜）',
prtit5: '推廣人數TOP20（總榜）',
prtxt9: '統計截止日期：',
prth6: '排名',
prth7: '會員',
prth8: '推廣人數',
prth9: '返傭（折合USDT）',
prth10: '額外獎勵',
prtip1: '以上佣金數據非實時更新，系統每24小時統計並刷新一次。',
prtip2: '邀請人數指一級好友數量，此榜單為返傭總金額排名',
prtip3: '邀請人數指一級好友數量，此榜單為一級邀請人數排名',
// 充值
recth1: '到賬時間',
recth2: '網絡協議',
recth3: '充幣地址',
recth4: '充值數量',
recbtn: '提幣碼充值',
recplaceholder: '請輸入提幣碼',
recstatus_0: '失敗',
recstatus_1: '未到賬',
recstatus_2: '已到賬',
// 提現
wittit: '數字貨幣提現',
wittext: '提現記錄',
wittext2: '提幣碼',
wittext3: '提幣碼列表',
witbtn: '提幣碼提現',
witbtn2: '生成提幣碼',
witdia: '安全驗證',
witdia2: '提現碼生成成功',
witplaceholder: '請輸入提現數量',
witerr: '請選擇幣種',
addresstip: '請填寫地址',
witstate: '待兌換',
witstate2: '',
witplaceholder: '請輸入提現數量',
witerr: '請選擇幣種',
addresstip: '請填寫地址',
witstate: '待兌換',
witstate2: '失敗',
witstate3: '兌換完成',
witstatus_1: '審核中',
witstatus_2: '轉帳中',
witstatus_3: '失敗',
witstatus_4: '成功',
// 操作類型
type1: '轉帳',
type2: '幣幣交易',
type3: '法幣買入',
type4: '法幣賣出',
type5: '活動獎勵',
type6: '推廣獎勵',
type7: '分紅',
type8: '投票',
type9: '人工充值',
type10: '配對',
type11: '活動兌換',
type12: 'C2C買入',
type13: 'C2C賣出',
type14: '發紅包',
type15: '領紅包',
type16: '提幣碼提現',
type17: '提幣碼充值',
type18: '永續合約手續費',
type19: '永續合約盈利',
type20: '永續合約虧損 ',
type21: '期權合約失敗',
type22: '期權合約手續費',
type23: '期權合約獎金',
type24: '合約返傭',
type25: '',
type20: '永續合約虧損 ',
type21: '期權合約失敗',
type22: '期權合約手續費',
type23: '期權合約獎金',
type24: '合約返傭',
type25: '同級獎勵',
type26: '平台手續費收入',
type27: '秒合約失敗',
type28: '秒合約獎金',
type29: '理財利息',
type30: '支付資金費用',
type31: '獲取資金費用',
type32: '定投買入',
type33: '定投賣出',

type34: '購買定期',
type35: '定期贖回',
type36: '幣本位合約劃轉转入',
type37: '幣本位合約劃轉轉出',
type38: 'U本位合約劃轉转入',
type39: 'U本位合約劃轉轉出',
type40: '秒合約劃轉转入',
type41: '秒合約劃轉轉出',
type42: '幣幣劃轉转入',
type43: '幣幣劃轉轉出',
// 理財賬戶
lctit: '理財賬戶',
lcbtn: '理財轉入',
lcth1: '累計利息',
day: '天',
lctxt: '活動幣種',
lctxt2: '接受幣種',
lctxt3: '起投數量',
lctxt4: '選擇時間',
year: '年',
month: '月',
// 理財訂單
ldtab1: "持仓記錄",
ldtab2: "平倉記錄",
ldth1: "開始收益日",
ldth2: "贖回日",
ldth3: "收益估算值",
ldth4: "實際收益",

// 定投计划
attit: "修改定投計劃 ",
attxt: "投資數量 ",
attxt2: "投資週期 ",
atth1: '定投日期',
atth2: '定投數量',
atth3: '交易費率',
success: '成功',
// 创新管理
cxth1: '名稱',
cxth2: '類型',
cxth3: '認購數量',
cxth4: '參與幣種',
cxth5: '認購單位',
cxth6: '當前狀態',
cxth7: '成交額',
cxth8: '創建時間',
cxnav1: '我參與的',
cxnav2: '我的礦機',
cxnav3: '我的鎖倉',
cxtit2: '我的礦機列表',
cxtit3: '我的鎖倉列表',
cxkth1: '產出幣種',
cxkth2: '產出週期',
cxkth3: '挖礦時間',
cxkth4: '已挖時間',
cxkth5: '基礎產能',
cxkth6: '實際產能',
cxstate1: '未部署',
cxstate2: '已部署',
cxstate3: '已撤銷',
cxstate4: '待成交',
cxstate5: '已結束',
cxstate6: '待釋放',
cxstate7: '釋放中',
cxcth1: '釋放幣種',
cxcth2: '總鎖倉',
cxcth3: '總釋放週期',
cxcth4: '已釋放週期',
cxcth5: '基礎釋放',
cxcth6: '實際釋放',
more: '更多',
// 關於我們
abtit: '平台特色',
abtit2: '關於我們',
abtit3: '聯繫我們',
abtit4: 'Bitisan社區',
abdesc: 'Bitisan是技術全球領先的數字資產交易平台，註冊於開曼群島，核心運營團隊位於香港。Bitisan核心成員來自於頂級互聯網和金融公司，大部分成員是深度比特幣和區塊鏈信仰者，我們深信區塊鏈將改變傳統壟斷性金融體系，會建立一個更加民主、自治的社會結構。',
abdesc2: 'Bitisan平台擁有專業金融級別的交易架構，擁有自主研發的高並發內存撮合交易引擎，平台採用全冷充值錢包系統+多重簽名+高防護DDoS攻擊系統等交易架構，保證客戶資產 安全。',
abdesc3: 'Bitisan為了讓客戶更好的把握投資機會，客服部門內部採用扁平化管理，建立了極速服務響應機制，資產客服經理常年24小時在線，為客戶提供資產充值提現等服務，保障客戶在5分鐘內完成資產充提。',
abdesc4: 'Bitisan嚴格篩選優質項目並提供安全穩定的資產託管服務。Bitisan秉承著“誠實、公正、熱情、開放”的理念 ，竭力為用戶打造安全、可靠、高效和友好的極致交易所。',
abdesc5: '儘管數字資產現在只在一個小小的圈子裡，但是我們相信，這種每個人都能夠完全掌握的資產在未來一定能夠流行起來，讓我們一起努力並期待著！',
abdesc6: '客戶服務',
abdesc7: '技術支援',
abdesc8: '上幣申請',
abdesc9: '投訴建議',
abdesc10: '商務合作',
abdesc11: '微信',
abdesc12: '微博',
abdesc13: '推特',
abdesc14: '幣用',
abdesc15: '電報',
abdesc16: '添加 “bmate601” 為微信好友，進入微信福利社群',
// 實名認證
realtxt: '真實姓名',
realtxt2: '身份證號碼',
realtxt3: '身份證正面照',
realtxt4: '身份證背面照',
realtxt5: '手持身份證與個人簽名照',
realtxt6: '1、支援JPG、PNG格式，檔案小於8MB；證件上的資訊，清晰可見，不允許任何修改和遮擋，必須能看清證件號碼和姓名。',
realtxt7: '2、照片需免冠，建議未化妝，手持證件人的五官清晰可鑒，完整露出手臂。',
realtxt8: "3、需要同時手持證件和手寫文案（姓名+申請日期）'我在本站的所有行為均為本人操作，並已知悉相關風險，願意承擔本帳戶的一切法律後果。'",
realbtn: '點擊上傳',
realstate: '未實名',
realstate2: '已實名',
uploadimg: "請上傳身份證正面照 ",
uploadimg2: "請上傳身份證背面照 ",
uploadimg3: "請上傳手持身份證照 ",
uploaderr: "上傳圖片大小不能超過 ",
save_success: '保存成功!',
save_failure: '保存失敗!',

// 划转
close: '關閉',
hztxt: '確認劃轉',
// 商家認證
submittip1: '為了您的資金安全，請先進行【實名認證】！',
submittip2: '為了您的資金安全，請先進行【手機綁定】！',
submittip3: '為了您的資金安全，請先設置【資金密碼】！',
submittip4: '請至少綁定一種支付方式',
aderr1: '請輸入正確數字',
aderr2: '溢價值為0-20',
aderr3: '溢價值為0-20，且不能為0',
aderr4: '請輸入正確固定價格',
aderr5: '請輸入正確數字，並且最大交易數量不超過100幣',
orderTypeNo: "否 ",
orderTypeYes: "是 ",
win: "成功 ",
lose: "失敗 ",
tied: "平局 ",
cancelsuccess: "撤單成功！ ",
shelves: '下架廣告後才可以刪除！',
shelves2: '下架廣告後才可以編輯修改！',
business: '請先申請商家認證！',
swappleaseselectwallet: "請先選擇轉入/轉出賬戶 ",
swappleaseinputamount: "請輸入劃轉數量 ",
clickchange: "點擊切換劃轉方向 ",
daterange: "請選擇搜索日期範圍 ",
// API
apierr: '請輸入備註資訊',
apierr2: '請輸入IP地址',
apierr3: '請輸入正確IP地址',
apierr4: '請輸入驗證碼',
clickget: '點擊獲取',
apisecond: '秒',
apitips: "密鑰只在新增時展示，請及時保存 ",
apiaccess: "密鑰 ",
// 自定義
do_favorite: '已收藏',
cancel_favorite: '取消收藏',
fail_favorite: '失敗',
deptotal: '累計',
chart: '圖表',
book: '訂單簿',
trade: '最新成交',
pt_title: "數字貨幣禮品卡 ",
pt_desc: "兌換後自動成為下級好友，送朋友、送親人、送客戶、送夥伴。助力推廣合伙人更好推廣。 ",
pt_more: "更多工具，敬請期待 ",
pt_tips: "如果您有好的創意，請發送郵件至promotion@Bitisan.biz，採納後有獎勵哦！ ",
pt_card_amount: "卡面金額 ",
pt_card_deadline: "有效期間 ",
pt_card_noend: "無限期 ",
pt_card_btn: "推廣合伙人免費領30張(約2000元) ",
pt_card_btn_login: "登錄後免費領取30張 ",
pt_card_rule: "規則詳情 ",
pt_card_summary: "數字貨幣禮品卡是為了讓推廣合伙人能夠更好地邀請下線好友而開發的推廣工具之一，推廣對象用戶兌換禮品卡時，會自動成為推廣者的下線好友。 ",
pt_card_rule1: "一、免費禮品卡每個用戶限領30張。如需發放更多禮品卡，則需要用戶付費定制，可定制卡面金額、LOGO、說明文等。定制需求請發送郵件至 ",
pt_card_rule2: "二、免費禮品卡每個用戶限兌1份。由Bitisan官方發放的免費禮品卡，每個用戶限兌一份，即使通過不同用戶領取到不同兌換碼的免費禮品卡，也只能兌換1份。自定義製作禮品卡不受此限制。 ",
pt_card_rule3: "三、用戶兌換禮品卡前，如不存在“邀請者”（即注冊帳號時未輸入過邀請碼），則自動關聯為發卡者的一級好友。如A注冊帳號時未輸入邀請碼，此時A不屬於任何人的一級或者二級好友，但當A通過B發放的禮品卡兌換後，那麼A自動成為B的一級好友。 ",
pt_card_rule4: "四、為防止刷客，禮品卡賬面金額並非實時兌付，領取後需完成實名認證才可操作。 ",
pt_card_rule5: "五、領取免費禮品卡時需要完成實名認證，兌換時無需完成實名認證即可領取，此舉是為了方便推廣者更快獲取下級好友。 ",
pt_card_rule6: "六、免費禮品卡最終解釋權歸Bitisan所有。 ",
pt_card_day: "天 ",
pt_card_title_tips: "領2000元推廣助力金 ",
pt_invite_desc: "專屬二維碼，通過二維碼注冊自動成為下級好友，適合在各種群或論壇轉發。 ",
saveimage: "保存圖片 ",
imagetips: "如無法正常下載圖片，請使用截圖工具截圖保存！ ",
pt_card_receivew_success: "恭喜！成功領取合伙人推廣禮品卡！請到【個人中心】->【卡券中心】查看！ ",
usepromotion: "使用推廣素材 ",
context_title: "推廣合伙人專屬兌換碼 ",
context_title1: "掃一掃了解更多 ",
context_title2: "我的邀請碼 ",
context_title3: "邀請圖片 ",
pt_card_title: "合伙人推廣卡 ",
copysuccess: '複製成功！',
copyerr: '複製失敗！請手動複製',
noopening: "暫無待期權合約 ",
createSuccess: "創建成功 ",
tradewarning1: '最多輸入2位小數',
tradewarning2: '下單金額為',
tradewarning3: '最多輸入8位小數',
tradewarning4: '下單數量為',
tradeconfirmbuyin: '確認買入',
tradeconfirmsellout: '確認賣出',
foot: {
    aboutus1: '關於我們',
    aboutus: '<p>在2019年4月推出主網後，幣安鏈展示了其高速以及高通量設計。其主要關注點——本地去中心化應用程式（dApp）—幣安鏈DEX（去中心化交易所），已被測試可在短時間內處理數百萬筆交易量，展現了其交易引擎的低延遲匹配功能。</p>\
    <p>靈活性和可用性往往與性能緊密相關。當重點是如何為數字資產的發行和交易提供便利時，這種設計也帶來了在某種程度上的局限性。社區中最響亮的聲音一直是看到幣安鏈添加可編程擴展功能，或者說得明白些，就是智能合約和虛擬機的功能。由於幣安鏈目前的功能有限，數字資產發行人和所有者很難添加新的去中心化的功能到他們的資產或引入任何形式的社區管理和社區活動。</p>\
    <p>儘管人們對於將智能合約功能添加到幣安的期待很高，但在幣安鏈上實現這一點是一個“艱難的決定”。智能合約的執行可能會減緩DEX的速度，並給資產交易帶來不確定性。即使這種妥協可以容忍，最簡單的解決方案可能是實現一個基於當前底層Tendermint共識協議和主RPC接口的虛擬機。然而，這種解決方案將增加現有dApp社區的學習成本，並且不會是一種受歡迎的解決方案。在此，我們提出了幣安鏈的並行區塊鏈概念，以便在保持高本地DEX性能的同時，友好地支援智能合約功能。</p>\
    <p>在幣安鏈生態系統中構建了兩個平行的區塊鏈後，這兩條區塊鏈將獨立運作，提供不同的服務。新的並行鏈將被稱為“幣安智能鏈”（以下簡稱“BNBN”），而現有的主網仍將被稱為“幣安鏈”（以下簡稱“BNB”）。BNBN的設計遵循以下原則：</p>\
    <ul><li>獨立區塊鏈：從技術角度來看，BNBN是一個獨立的區塊鏈，而非第二層解決方案。BSC的大部分基礎技術和業務功能應該獨立，因此即使BNB短暫停止，BNBN仍然可以正常運行。</li>\
    <li>與以太坊兼容：第一個實用且廣泛使用的智能合約平台是以太坊。為了連接相對成熟的應用程式和社區，BSC選擇與現有的以太坊主網兼容。這意味著大多數dApp、生態系統組件和工具將與BSC兼容，無需大幅修改或僅需微小變動；BNBN節點只需類似或稍高的硬體操作規範和操作技能。此實現應提供與未來版本的BNBN和以太坊繼續兼容的空間。</li>\
    <li>基於股權抵押的共識：基於股權抵押（PoS）的共識更環保，並促進社區治理。預期這種共識相比PoW共識會有更好的性能，例如更快的區塊生成時間和更高的交易處理能力。</li>\
    <li>本地区塊間的跨鏈通信：BNB和BNBN將原生支援兩個區塊鏈之間的跨鏈通信。這種通信協議應當是雙向的、分散式的，並且不依賴第三方信任。它將專注於轉移BNB和BNBN之間的數字資產，即BEP2代幣以及其他BNBN代幣（稍後介紹）。</li></ul>\
    <p>基於上述設計原則，BNBN的共識協議旨在實現以下目標：</p>\
    <ul><li>區塊時間應短於以太坊，例如5秒甚至更短。</li>\
    <li>等待交易完成的時間有限，例如大約1分鐘或更短。</li>\
    <li>無通貨膨脹，收益的一部分來自手續費，手續費以BNBN的形式支付。</li>\
    <li>盡可能與以太坊兼容，包括其即將進行的升級。</li>\
    <li>具備基於股權抵押的鏈上治理機制。</li></ul>\
    <p>雖然工作證明（PoW）已被證明是實現去中心化網絡的有效方法，但它對環境不友好，且需要大量參與者維護網絡安全。以太坊及其他一些網絡，如MATIC Bor、TOMOChain、GoChain和xDAI，在不同場景中使用授權證明（PoA）或其變體，包括測試網和主網。PoA能防禦51％攻擊，更有效地防止一些拜占庭節點惡意行為。因此，選擇PoA作為達成共識的基礎是一種理想的選擇之一。</p>\
    <p>受上述啟發，BNBN將DPoS和PoA相結合以實現共識。具體解決方案為：</p>\
    <ul><li>區塊由有限數量的驗證者生成，驗證者輪流以PoA方式生成區塊，類似於以太坊的Clique共識引擎，已選定的一組驗證者通過基於股權抵押的鏈上治理機制進行管理。</li>\
    <li>在網絡啟動的起源區塊階段，許多可信節點將作為驗證者的初始集合運作。區塊生產開始後，任何人都可以作為候選人參加驗證人的選舉。股權抵押狀態決定了擁有最多股權的前21個節點成為下一批驗證器。這種選舉和淘汰每24小時進行一次。</li>\
    <li>BNBN代表BNB的股權抵押。為了與以太坊共識協議（包括即將進行的升級）保持一致，BNBN已選擇依靠BNB進行股權管理。有一個專門模塊用於BNBN的股權抵押，接受BNBN持有者的BNB進行股權抵押，並計算擁有最多權益的節點集。每隔UTC零時刻，BNB會發送可驗證的“ValidatorSetUpdate”跨鏈消息，通知BNBN更新其驗證器集。現有BNBN驗證器定期檢查是否存在“ValidatorSetUpdate”消息，並在達到特定高度（即預定義的區塊間隔）後更新驗證器集。例如，若BNB每5秒生成一個區塊，檢查週期為240個區塊，則當前驗證器集將在1200秒（20分鐘）內檢查並更新下一個週期的驗證器集。</li></ul>\
    <p>安全性與最終性</p>\
    <p>考慮到超過一半以上的½*N+1個驗證器是誠信的，基於PoA的網絡通常能夠安全、正確地運行。然而，在某些情況下，拜占庭驗證器仍然可以攻擊網絡，例如通過“複製攻擊”。為了確保BNBN具有與BNB同樣高的安全性，我們鼓勵BNBN用戶等到收到的區塊獲得⅔*N+1個不同驗證器的確認。這樣，BNBN可以實現與BNB相似的安全水平，能夠容忍少於1/3*N的拜占庭驗證器。對於21個驗證器，如果區塊時間為5秒，那麼⅔* N+1個不同的驗證器需要（2/3*21+1）*5=75秒才能確認。BNBN的重要應用可能需要等待⅔*N+1以確保相對安全的最終結果。關於股權抵押和管理的更多細節稍後將在“股權抵押和管理”部分介紹。該懲罰機制能在短時間內揭示惡意驗證器，並使“複製攻擊”極難執行或經濟上不可行，即使嘗試執行。通過這種懲罰機制，½*N+1或更少的區塊確認就足以滿足大多數交易的最終性。</p>\
    <p>收入</p>\
    <p>當前驗證器集中的所有BNB驗證器將從支付的手續費中獲取收入。由於BNB不是通胀代幣，它不像比特幣和以太坊網絡那樣產生挖礦收入。手續費是驗證器的主要收入來源。鑑於BNB也是其他應用程序的實用代幣，持有BNBN的驗證器還將獲得其他優勢。</p>',

    newbie: '',
    newbie1: '新手指南',
    commonproblem: '',
    commonproblem1: '常見問題',
    information: '',
    information1: '幣種資料',
    disclaimer: '',
    disclaimer1: '免責聲明',
privacyclause: '',
privacyclause1: '隱私條款',
agreement: '',
agreement1: '用戶協議',
},
tradeinfo: {
    emaildone: '電子郵件已認證',
    emailundo: '電子郵件未認證',
    teldone: '手機號碼已認證',
    telundo: '手機號碼未認證',
    idcarddone: '身份證已認證',
    idcardundo: '身份證未認證',
    exchangetimes: '交易次數',
    price: '價格',
    num: '數量',
    paymethod: '付款方式',
    exchangelimitamount: '交易限額',
    location: '所在地',
    location_text: '中國',
    exchangeperiod: '交易期限',
    minute: '分鐘',
    amounttip: '請輸入金額',
    numtip: '請輸入數量',
    remarktip: '告訴他您的要求',
    remarktitle: '備註信息',
    exchangetitle: '交易須知',
    exchange_tip1: '當您發起交易請求後，數字貨幣被鎖定在託管中，受到平台保護。如果您是賣家，發起交易請求後，您可以通過充值並等待買家付款。買家在付款時限內進行付款。您在收到付款後應釋放處於託管中的數字貨幣。',
    exchange_tip2: '交易前請閱讀《平台網絡服務條款》以及常見問題、交易指南等幫助文檔。',
    exchange_tip3: '當心騙子！交易前請檢查該用戶的評價，並對新近創建的帳戶多加留意。',
    exchange_tip4: '請注意，四捨五入和價格波動可能影響最終成交的數字貨幣數額。您輸入的固定數額決定最後數額，數字貨幣金額將在請求發布的同一時間由即時匯率計算得出。',
    exchange_tip5: '託管服務保護網上交易的買賣雙方。在發生爭議的情況下，我們將評估所提供的所有信息，並將託管的數字貨幣釋放到其合法所有者手中。',
    warning1: '最多輸入2位小數',
    warning2: '下單金額為',
    warning3: '最多輸入8位小數',
    warning4: '下單數量為',
    confirmbuyin: '確認買入',
    confirmsellout: '確認賣出',
    buyin: '買入',
    sellout: '賣出',
    warning5: '請按照要求填寫訂單',
}
}