<template>
<div class="page-web page-home">
		<Head />
<div style="width:100%; margin: 0 auto; ">

<!-- <el-row class="block-col-2">
  <el-col :span="24">
    <el-button type="primary" plain style="width:380px; margin: 10px auto; height : 50px;" @click=" acb=true ">{{$t('order1')}}</el-button>
  </el-col>
  <el-col :span="24">
    <el-button type="primary" plain style="width:380px; margin: 10px auto; height : 50px;"  @click=" acb=false ">{{$t('order2')}}</el-button>
  </el-col>
</el-row> 
<div v-show="acb" class="my_table" >
<el-table 
    :border="false"
    :empty-text="$t('order12')"
    :data="tableDataSubscribe"
    height="700"
    style="width: 380px; margin: 10px;">
    <el-table-column :label="$t('transaction0')" width="50" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template></el-table-column>
    <el-table-column
      prop="id"
      :label="$t('order3')"
      width="130">
    </el-table-column>
    <el-table-column
      prop="number"
      :label="$t('order4')"
      width="80">
    </el-table-column>
    <el-table-column
      prop="time"
     :label="$t('order5')">
    </el-table-column>
  </el-table>
</div>-->
<div  style="width:100%;height: 800px;">
<el-col :span="24" v-for="(mining,key) in tableDataMining" :key="key" style="padding-top:10px;">
    <el-card style="width:90%; margin: 0 auto;">
  <div slot="header">
    <span>{{mining.name}}</span>
        <el-button v-show="mining.state==1"  type="success" style="float: right; color: #000; ">{{$t('Completed')}}</el-button>
        <el-button  v-show="mining.state==0 & mining.activation==1" type="primary" style="float: right; color: #000; ">{{$t('Atwork')}}</el-button>
        <el-button  @click="getactivation(mining.id,mining.userId)" v-show="mining.state==0 & mining.activation==0" type="warning" style="float: right; color: #000; ">{{$t('activation')}}</el-button>
    <!-- <el-button style="float: right; padding: 3px 0 ;color: #000; " @click="submit(mining.id)">{{$t('mining1')}}</el-button>-->
  </div>
  <el-row>
  <el-col :span="13" >
  <div style="height: 160px; width:160px">
     <img :src="mining.image" class="image" alt=""/>
  </div>
  </el-col>
  <el-col :span="11" style="padding-top:5px;">
  <el-progress  type="circle" :percentage="mining.platNumber" status="success"></el-progress>
  </el-col>
  </el-row>
  <el-row>
<el-col :span="24" >
  <div style="margin: 10px 0 0 0;">
    {{$t('mining2')}}:   {{mining.price}} 
  </div>
  <div style="margin: 10px 0 0 0;">
    {{$t('mining3')}}:   {{mining.earnings}}
  </div>
  <div style="margin: 10px 0 0 0;">
    {{$t('mining4')}}:   {{mining.lockoutDay}}
  </div>
  <div style="margin: 10px 0 0 0;">
    {{$t('mining6')}}:   {{parseFloat(mining.earnings*mining.lockoutDay).toFixed(2)}} 
  </div>
  <div style="margin: 10px 0 0 0;">
    {{$t('mining7')}}:   {{mining.beginTime}}
  </div>
  <div style="margin: 10px 0 0 0;">
    {{$t('mining8')}}:   {{mining.endTime}}
  </div>
  </el-col>
  </el-row>
    </el-card>
  
    </el-col>
</div>

</div>
<Foot />
</div>
</template>
<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import { useridlist,getactivation } from '@/api/virtual/virtual.js'
  export default {
    components: {
			Head,
			Foot
		},
    data() {
      return {
        tableDataMining: [],
        activation:{
          userId:"",
          miningContentId: "",
        }
      }
    },
    created() {
            this.getList();
            },
		mounted() {
		
    },	
    methods: {
     getList() {
        const id=  localStorage.getItem('userId');
        useridlist(id).then(response => {
        this.tableDataMining = response.data;
        const today = new Date();
        for(var i=0;i<this.tableDataMining.length;i++){
         const BirthDay = new Date(this.tableDataMining[i].beginTime)
         const BirthDay1 = new Date(this.tableDataMining[i].endTime)
         const timeold = (BirthDay1.getTime() - BirthDay.getTime());
         const timeold1 = (today.getTime() - BirthDay.getTime());
         this.tableDataMining[i].platNumber=(timeold1/timeold)*100;
        }
      });
      },
      getactivation(id,userId){
        this.activation.userId=userId;
        this.activation.miningContentId=id;
        getactivation(this.activation).then(response=>{
           if(response.code==500)
        {
          this.$message.error(response.msg);
        }
        else if(response.code==200)
        {
          this.$message({
          message: response.msg,
          type: 'success'
        });
        }
        else{
          this.$message.error(response.msg);
        }
        this.getList();
      });
    }
    } 
  }
</script>
<style>
  /* .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  } */
  .my_table >>> .el-table__row>td{
  /* 去除表格线 */
  border: none;
}
.my_table >>> .el-table th.is-leaf {
  /* 去除上边框 */
    border: none;
}

.my_table >>> .el-table::before{
  /* 去除下边框 */
  height: 0;
}

.my_table ::v-deep .el-table__body tr:hover > td {
     background-color: rgb(0, 0, 0) !important;
 }
.el-table__body tr:hover>td{
background-color: rgb(0, 0, 0) !important;
}
</style>