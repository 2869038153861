

// 错误提示
export default {

error1: 'Erreur système, veuillez contacter le service client',
error2: 'La session a expiré, veuillez vous reconnecter !',
meun1: 'Accueil',
meun2: 'Tendances du marché',
meun3: 'Achat préalable BNBN',
meun4: 'Achat de matériel minier',
meun5: 'Promotion rapide',
meun6: 'Livre blanc',
meun7: 'Annonces',
meun8: 'Connexion',
meun9: 'Inscription',
meun10: 'Portefeuille',
meun10_1: 'Compte Spot',
meun10_2: 'Compte contrat U-based',
meun10_3: 'Compte contrat crypto-based',
meun10_4: 'Contrat à seconde base',
meun10_5: 'Compte d\'épargne',
meun10_6: 'Historique du portefeuille',
meun11: 'Commandes',
meun11_1: 'Commandes d\'achat préalable',
meun11_2: 'Commandes de mining',
meun12: 'Service client',
meun12_1: 'Informations sur le service client',
meun12_2: 'Numéro du service client',
meun12_3: 'Email du service client',
meun13: 'Cadeaux',
meun13_2: 'Récupérer un cadeau',
meun13_3: 'Saisissez le code de récupération du cadeau',
meun13_4: 'Annuler',
meun13_6: 'Récupérer',
meun13_7: 'Annulation réussie',
meun13_1: 'Gestion de compte',
meun13_5: 'Se déconnecter',
meun14_1: 'Langue',
meun14_3: 'Choisir la langue',
scan: 'Scanner avec iOS & Android pour télécharger',
footmeun1: 'À propos',
footmeun1_1: 'À propos de nous',
footmeun1_2: 'Rejoignez-nous',
footmeun1_3: 'Annonces de la plateforme',
footmeun1_4: 'Livre blanc',
footmeun2: 'Centre d\'aide',
footmeun2_1: 'Guide du débutant',
footmeun2_2: 'Questions fréquentes',
footmeun2_3: 'Guide des transactions',
footmeun2_4: 'Informations sur les devises',
footmeun3: 'Termes et conditions',
footmeun3_1: 'Clause d\'exonération de responsabilité',
footmeun3_2: 'Conditions de confidentialité',
footmeun3_3: 'Contrat utilisateur',
footmeun3_4: 'Frais explicatifs',
footmeun4: 'Nous contacter',
footmeun4_1: 'Email du service client',
footmeun4_2: 'Coopération commerciale',
footmeun4_3: 'Demande de mise en liste',
footmeun4_4: 'Signaler une plainte',
foottext: 'Plateforme de souscription mondiale BNBN',
countdown: 'L\'événement est terminé',
indexTxt1: 'Première souscription mondiale BNBN',
indexTxt2: 'Première souscription mondiale BNBN',
indexTxt3: 'Compteur avant lancement',
indexLabel1: 'Tendances du marché',
indexVal1: 'Prix des cryptomonnaies en temps réel',
indexLabel2: 'Souscription BNBN',
indexVal2: 'Échanger une seule devise numérique contre',
indexLabel3: 'Achat de matériel de minage BNBN',
indexVal3: 'Mining de cryptomonnaies BNBN',
indexLabel4: 'Promotion rapide',
indexVal4: 'Avantages pour les souscripteurs',
indexh5: 'Sécurité financière',
indexh6: 'Dépôt / retrait ultra-rapide',
indexh7: 'Service mondial',
indexh8: 'Sélection rigoureuse de projets',
indexp1: 'BITISAN a été créé par un groupe d\'acteurs pionniers dans le bitcoin et des geeks. Les membres clés de l\'équipe proviennent de grandes entreprises telles que Google, Microsoft, Alibaba, Tencent, dotés d\'une solide expertise en R&D et d\'une riche expérience de gestion de produits web.',
indexp2: 'BITISAN se positionne comme fournisseur de services de base pour la blockchain et vise à offrir une plateforme de qualité aux utilisateurs mondiaux pour les actifs cryptographiques, respectant le principe fondamental "Ne pas faire de mal" et fournissant un service honnête, impartial et chaleureux aux clients, tout en étant ouvert à tous les partenariats/projets qui favorisent l\'intérêt fondamental des utilisateurs.',
indexp3: 'Un système de contrôle financier complet et un système anti-vol garantissent la sécurité des fonds avec des portefeuilles froids et chauds, ainsi qu\'un système multi-signature.',
indexp4: 'Les dépôts et retraits sont effectués en seulement 3 minutes, avec une vérification manuelle en ligne 24H/24 pour protéger les clients de manquer des opportunités d\'investissement optimales.',
indexp5: 'Couverture mondiale du réseau de services permettant d’investir dans des actifs cryptographiques mondiaux et de trader avec des utilisateurs du monde entier',
indexp6: 'Sélection stricte des projets cryptographiques de qualité pour filtrer jusqu\'à 80 % des risques extrêmement élevés',
market: 'Informations marché',
// Nouvelle souscription - Portefeuille
wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
usdt: 'USDT',
platform: 'BNBN',
usdt1: 'Solde disponible',
usdt2: 'Solde verrouillé',
platform1: 'Retrait',
platform2: 'Dépôt',
platform3: 'Souscrire',
transaction: 'Détails de transaction',
transaction0: 'Numéro de séquence',
transaction1: 'Numéro de transaction',
transaction2: 'Quantité de transaction',
transaction3: 'Heure de transaction',
transaction4: 'Rechargement USDT',
transaction5: 'Adresse de recharge',
transaction6: 'Retrait USDT',
transaction7: 'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
transaction8: 'Adresse de retrait',
transaction9: 'Montant du retrait',
transaction10: 'Annuler',
transaction11 : 'Confirmer',
transaction12: 'Souscription',
transaction13: 'La valeur du montant de souscription se réfère au nombre d\'USDT souscrits',
transaction14: 'Quantité de souscription',
transaction15: 'La quantité de souscription doit être supérieure à 50',
transaction16: 'Le montant de retrait doit être supérieur à 10',
transaction17: 'Type de devise',
transaction18: 'Aucun détail de transaction pour le moment',
transaction19: 'Veuillez sélectionner une adresse de recharge',
transaction20: 'Veuillez cliquer à nouveau dans 10 minutes',
transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    transaction25: 'Un certain frais sera déduit lors du retrait',
// Lock Mining
mining1: 'Souscription',
mining2: 'Prix d\'achat',
mining3: 'Bénéfice quotidien',
mining4: 'Nombre de jours de verrouillage',
mining5: 'Niveau d\'achat',
mining6: 'Total des gains acquis',
mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
// Invitation
inviteusers1:'Lien d\'invitation',
inviteusers2:'Nombre d\'invités',
inviteusers3:'Numéro d\'invitation',
inviteusers4:'Utilisateur invité',
inviteusers5:'Date d\'invitation',
inviteusers6: 'Copié avec succès',
inviteusers7:'Aucun utilisateur invité pour le moment',
inviteusers8:'Copier',
inviteusers10:'Number of subscribers',
inviteusers11:'Is it active',
// Commandes
order1: 'Commande de souscription',
order2: 'Commande de placement',
order3: 'Numéro de souscription',
order4: 'Quantité de souscription',
order5: 'Heure de souscription',
order7: 'Numéro de placement',
order8: 'Quantité verrouillée',
order9: 'Quantité de récompense',
order10: 'Date de début',
order11: 'Date de fin',
order12: 'Aucune information de souscription disponible',
order13: 'Aucune information de placement disponible',
// Centre personnel
personal1: 'Informations personnelles',
personal2: 'État de la certification en nom réel',
personal3: 'Certifié',
personal4: 'En cours de certification',
personal5: 'Non certifié - Cliquez pour certifier',
personal6: 'Échec de certification - Cliquez pour certifier',
personal7: 'Nom d\'utilisateur',
personal8: 'Courriel ',
personal9: 'Numéro de téléphone',
personal10: 'Niveau VIP',
personal11: 'Membre normal',
personal12: 'Code d\'invitation',
personal13:'Informations de certification',
personal14:'Veuillez téléverser les informations relatives à votre certification',
personal15:'Modifier le mot de passe',
personal16:'Mot de passe actuel',
personal17:'Modifier le mot de passe',
personal18:'Confirmer le mot de passe',
personal19:'Veuillez saisir un mot de passe',
personal20:'Le mot de passe doit comporter au moins 6 caractères',
personal21:'Veuillez saisir à nouveau le mot de passe',
personal22:'Les deux mots de passe ne correspondent pas',
authentication1:'Ajouter des informations de certification',
authentication2:'Confirmer le téléversement',
authentication3: 'Seuls les fichiers JPG/PNG sont autorisés, et leur taille ne doit pas excéder 2 Mo',
authentication4:'Téléverser le fichier',
authentication5:'L\'ajout de l\'utilisateur et le téléversement du fichier ont réussi',
authentication6:'Échec de l\'ajout de l\'utilisateur et du téléversement du fichier',
authentication7:'L\'image de l\'avatar doit être au format JPG uniquement !',
authentication8:'La taille de l\'image de l\'avatar ne peut pas dépasser 2 Mo !',
authentication9: 'Plus de 5 fichiers',
// Connexion
account0:'Compte ',
account: 'Connexion au compte BNBNCOIN',
phone: 'Numéro de téléphone',
email: 'Courriel',
sign: 'Connexion',
password: 'Mot de passe',
password2: 'Mot de passe',
password3: 'Mot de passe oublié ',
account2: 'Créer un compte ',
logErr: 'Veuillez saisir un compte',
logErr2: 'Veuillez saisir un mot de passe',
logErr3: 'Le mot de passe doit comporter au moins 6 caractères',
logSuccess: 'Connexion réussie',
resetpassword:'Réinitialiser le mot de passe',
reset:'Réinitialiser',

    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
// Inscription
account3: 'Créer un compte personnel',
phone1: 'Inscription par téléphone',
email1: 'Inscription par courriel',
email2: 'Adresse e-mail',
code: 'Code SMS de vérification',
code2: 'envoyer le code de vérification',
code3: 'code de vérification',
password4: 'confirmer le mot de passe',
invite: 'code d\'invitation',
agree: 'J\'ai lu et accepté',
agreement: 'contrat d\'utilisateur',
register: 's\'inscrire',
regErr: 'Veuillez saisir le code de vérification SMS',
confirmpwdtip: 'Veuillez saisir le mot de passe de confirmation',
confirmpwderr: 'Les deux mots de passe ne correspondent pas !',
// oubli de mot de passe
wjtab: 'réinitialiser le mot de passe du téléphone',
wjtab2: 'réinitialiser le mot de passe de l\'email',
wjlabel: 'nouveau mot de passe',
wjerr: 'Veuillez saisir un nouveau mot de passe',
Tailpage:'Page de queue',
	homepage:'Accueil',
	lastpage:'Page précédente',
	nextpage:'Page suivante',
	off:'Fermé',
	problem:'Si vous rencontrez des problèmes, y compris la certification du nom réel, le dépôt n\'est pas arrivé s\'il vous plaît consulter le service à la clientèle à temps',
announcementtitle: 'Titre de l’annonce',  
  
announcement: '<p>Après la mise en ligne du mainnet en avril 2019, Binance Chain a démontré sa haute performance et sa conception à haut débit. Son principal objectif - la plate-forme décentralisée native pour applications ("dApp") Binance Chain DEX (échange décentralisé), a été testée pour traiter des millions de transactions à court terme, démontrant la capacité de son moteur de transaction pour faire des matchs à faible latence.</p>\
<p>La flexibilité et la disponibilité vont souvent de pair avec la performance. Alors que les gens se focalisent sur la façon de fournir un lieu pratique pour l’émission et la négociation des actifs numériques, cette conception apporte aussi\
une certaine limite. La voix la plus forte de la communauté a toujours demandé\
à Binance Chain d’ajouter des fonctionnalités d’extension programmable, ou pour\
dire plus simplement, des fonctions de contrats intelligents et de machine virtuelle.</p><p> Du fait que les fonctionnalités de Binance Chain sont actuellement limitées, les émetteurs et les propriétaires d’actifs numériques ont beaucoup de mal à ajouter de nouvelles\
fonctionnalités décentralisées à leurs actifs ou à introduire toute forme de\
gestion communautaire et d’activités communautaires.</p>\
<p>Bien que les attentes pour l’ajout de fonctions de contrats intelligents à Binance\
Chain sont très élevées, il s’agit d’une "décision difficile". L’exécution de contrats intelligents pourrait ralentir la vitesse de DEX et ajouter incertitude aux transactions d’actifs. Même si ce compromis\
était tolérable, la solution la plus évidente pourrait être la mise en œuvre d’une machine\
virtuelle basée sur le protocole de consensus Tendermint actuel et sur l’interface RPC principale\
de Binance Chain. Mais cette solution augmenterait le coût d’apprentissage pour la communauté des dApps existants, et ne serait pas une solution populaire.\
Ici, nous proposons le concept de blockchain parallèle Binance Chain, pour maintenir les performances élevées\
du DEX natif et soutenir amicalement les fonctions de contrats intelligents.</p>',

labTxt: 'Laboratoire Bitisan • Partager les résultats innovants',
labTxt2: 'Open labo gagnant-gagnant pour investisseurs, projets et exchanges',
labTab1: 'Tout',
labTab2: 'Prochainement',
labTab3: 'En cours',
labTab4: 'Distribution en cours',
labTab5: 'Terminé',
tokendistribute: "Distribution de tokens en cours",
activitytype: "Type d'achat",
labStart: 'Date de début',
labEnd: 'Date de fin',
labStartPlaceholder: 'Sélectionnez une date de début',
labDetail: 'Voir les détails',
labSpeen: "Progression",
labNumber: "Quantité totale de l'activité",
labType1: 'Première mise en ligne (Achat rapide)',
labType2: 'Première mise en ligne (Partage égal)',
labType3: 'Répartition selon le portefeuille',
labType4: 'Achat libre',
labType5: 'Achat de machines minières cloud',
labType6: 'Verrouillage et libération',
baseinfo: 'Informations de base',
ruledetail: "Description du type",
releaseType: "Type de libération",
releaseType0: "Libération égale",
releaseType1: "Libération proportionnelle",
lock: "Verrouillage sur période",
releaseDetail: "Détails de la libération",
lockday: "Jour(s)",
lockweek: "Semaine(s)",
lockmonth: "Mois",
lockyear: "Année",
releasePercent: "Pourcentage de libération",
lockFee: "Seuil de verrouillage",
releaseTimes: "Nombre de fois de libération",
times: "fois",
publishprice: "Prix d'achat",
holdcoin: "Monnaie de portefeuille exigée",
limittimes: "Nombre maximum d'achats par personne",
limitamount: "Quantité maximale d'achat par personne",
unlimited: "Illimité",
leveloneCount: "Nombre requis de contacts directs",
alreadyamount: "Quantité déjà achetée",
leftamount: "Quantité restante",
myalreadyholdamount: "Mon engagement de portefeuille",
alreadyholdamount: "Engagement total du portefeuille pour l'activité",
currentdivided: "Ma part actuelle pouvant être divisée approximativement",
holdtips: "Pour participer à un événement de type « Répartition selon le portefeuille », vous devez verrouiller votre portefeuille jusqu'à la fin de l'événement.",
inputholdamount: "Saisir la quantité de verrouillage",
pleaseinputamount: "Saisissez le montant d'échange",
pleaseinputholdamount: "Saisissez la quantité de verrouillage",
inputamount: "Saisissez le montant d'échange",
inputminingamount: "Saisissez le nombre d'achats de machines minières",
attend: "Participer maintenant",
attention: "Attention",
attentiontxt1: "1. Les actifs sont gelés après avoir soumis une participation pour les événements de type « Répartition selon le portefeuille », « Achat libre », « Achat de machines minières cloud » et « Verrouillage », et seront dégelés ou libérés à la fin de l'événement.",
attentiontxt2: "2. Une fois qu'un utilisateur a participé à un événement de type « Répartition selon le portefeuille », « Achat libre », « Achat de machines minières cloud » ou « Verrouillage », il ne peut pas annuler sa demande de participation.",
attentiontxt3: "3. En cas d'annulation de l'événement par le projet ou en raison de circonstances imprévues, les actifs gelés seront remboursés via le même chemin.",
attentiontxt4: "* Le droit final d'interprétation de cet événement appartient à l'officiel Bitisan (WWW.BITISAN.NET).",
// Compte de contrat crypto-monnaie
transfer: 'Transfert de fonds',
placeholder: 'Rechercher une monnaie',
bith1: 'Transactions de contrat',
bith2: 'Droits du compte',
bith3: 'Bénéfice non réalisé',
bith4: 'Marge disponible',
bith5: 'Marge utilisée',
bith6: 'Marge gelée',
bith7: 'Compte de contrat',
// Compte en espèces
btn1: 'Recharger',
btn2: 'Retirer',
btn3: 'Transférer',
btn4: 'Dépôt de pièces',
btn5: 'Retrait de pièces',
totalAssets: 'Total des actifs convertis',
th1: 'Nom de la pièce',
th2: 'Actifs disponibles',
th3: 'Actifs gelés',
th4: 'Actifs à libération future',
th5: 'Action',
// Rechargement
czTxt: "Rechargement de crypto-monnaie ",
czTxt2: "Monnaie ",
czTxt3: "Réseau de transfert ",
czTxt4: "Solde disponible ",
czTxt5: "Adresse de retrait ",
czTxt6: "Quantité de retrait ",
czTxt7: "Quantité créditée ",
czTxt8: "Frais ",
czTxt9: "Conseils ",
czTxt10: "Montant minimum de recharge : ",
czTxt10_1: "Les recharges inférieures au montant minimal ne seront pas créditées. ",
czTxt11: "Ne rechargez aucune autre ressource que la monnaie spécifique sur l'adresse ci-dessus, sinon ces fonds ne pourront être récupérés. ",
czTxt12: "Une fois que vous avez effectué un rechargement vers cette adresse, la confirmation de l'ensemble des nœuds du réseau blockchain est nécessaire ; généralement, 3 confirmations réseau principales sont requises avant le crédit sur votre compte. ",
czTxt13: "Votre adresse de rechargement ne change pas souvent et peut être réutilisée pour plusieurs rechargements ; en cas de modification, nous essaierons de vous informer via des annonces sur le site ou par courriel. ",
czTxt14: "Veuillez vous assurer de la sécurité de votre ordinateur et navigateur pour éviter toute falsification ou fuite d'informations. ",
czTxt15: "Historique des rechargements ",
czTxt16: "Pour garantir la sécurité de vos fonds, lorsque votre stratégie de sécurité du compte change, que vous modifiez votre mot de passe ou utilisez une nouvelle adresse pour retirer des fonds, nous procéderons à une vérification manuelle des retraits ; veuillez patienter pendant que notre équipe vous contacte par téléphone ou courriel. ",
czTxt17: "Le montant minimum de retrait est de : ",
czth1: 'Date de retrait',
czth2: 'De',
czth3: 'À',
czth4: 'Quantité de conversion instantanée',
czth5: 'Taux de conversion',
czth6: 'Quantité convertie',
placeholder2: 'Saisissez...',
placeholder3: 'Sélectionnez...',
czEmpty: 'Aucun enregistrement',
logintip: 'Veuillez vous connecter d’abord !',
cztip: 'Vous n’avez pas demandé d’adresse de rechargement, cliquez sur le bouton pour obtenir une adresse de rechargement',
czget: 'Obtenir l’adresse de rechargement',
czplaceholder: 'Sélectionnez un protocole',
// Compte de contrat secondes
mAccount: "Compte de contrat secondes ",
// Historique du portefeuille
btn6: 'Rechercher',
wtTxt: 'Période',
wtTxt2: 'Type d\'opération',
wtth1: 'Heure de transaction',
wtth2: 'Type',
wtth3: 'Pièce',
wtth4: 'Quantité',
wtth5: 'Frais à payer',
wtth6: 'Frais compensés',
wtth7: 'Frais payés effectivement',
wtth8: 'Statut',
// Sécurité du compte
nav1: 'Sécurité du compte',
nav2: 'Mes invitations',
nav3: 'Mes cartes promotionnelles',
acTxt: 'Niveau de sécurité',
acbtn1: 'Non certifié',
acbtn2: 'Lier',
acbtn3: 'Modifier',
acbtn4: 'Certifié',
acbtn5: 'Lié',
acbtn6: 'Configurer',
acbtn7: 'En cours de vérification',
acbtn8: 'Réessayer',
acTxt2: 'Vérification d’identité',
acTxt3: 'Courriel',
acTxt4: 'Téléphone',
acTxt5: 'Mot de passe de connexion',
acTxt6: 'Mot de passe financier',
acTxt7: 'Gestionnaire de validation Google',
acTxt8: 'Configurer le mot de passe financier',
acTxt9: 'Changer le mot de passe financier',
acTxt10: 'Récupérer le mot de passe financier',
acDesc2: 'Pour assurer la sécurité de votre compte, veuillez compléter la vérification d’identité avant toute opération de trading ! ',
acDesc3: 'Lier un courriel',
acDesc4: 'Lier un téléphone',
acDesc5: 'Utilisé lors de la connexion à la plateforme',
acDesc6: 'Un changement dans les fonds du compte nécessite la validation du mot de passe financier',
acDesc7: 'Veuillez lier le gestionnaire de validation Google',
acDesc8: 'Gestionnaire de validation Google déjà lié',
acDesc9: 'Dissocier',
acTit: 'Liaison du téléphone',
acTit2: 'Liaison de l’e-mail',
acTit3: 'Liaison Google',
acTit4: 'Dissociation Google',
mailtip: 'Saisissez votre courriel',
emailerr2: 'Format d’e-mail incorrect, veuillez saisir à nouveau',
agreementtip: 'Veuillez lire et accepter les termes d’utilisation',
acverify: "Vérification non approuvée ",
again: "Réessayer s'il vous plaît ",
verifiedmsg: 'Veuillez d’abord passer la vérification d’identité',

// 更改登录密码
chTxt: 'Changer le mot de passe de connexion',
chTxt2: 'Liaison par balayage',
chTxt3: 'Saisissez le code de vérification',
chTxt4: 'Ancien mot de passe du compte',
chTxt5: 'Nouveau mot de passe du compte',
chTxt6: 'Confirmez le nouveau mot de passe',
chTxt7: 'Mot de passe oublié',
chTxt8: 'Modifier la vérification par téléphone',
chTxt9: 'Ancien mot de passe de connexion',
chTxt10: 'Nouveau mot de passe de connexion',
chTxt11: 'Code de vérification par e-mail',
chTxt12: 'Code de vérification par téléphone',
telerr: 'Numéro de téléphone incorrect',
emailerr: 'Adresse e-mail incorrecte',
codeerr: 'Le code de vérification est incorrect',
save: 'Enregistrer',
chtip: 'Veuillez saisir votre ancien mot de passe',
chtip2: 'Veuillez saisir un nouveau mot de passe de connexion d\'au moins 6 caractères',
chtip3: 'Les nouveaux mots de passe de connexion ne correspondent pas',
chtip4: 'Veuillez saisir le bon mot de passe du compte',
chtip5: 'Le mot de passe doit comporter au moins 6 caractères',
chtip6: 'Veuillez saisir un mot de passe d’au moins 6 caractères',
chtip7: 'Les mots de passe ne concordent pas',
chtip8: 'Veuillez saisir le mot de passe du compte',
chtip9: 'Veuillez saisir un numéro de téléphone',
chtip10: 'Veuillez saisir le mot de passe de connexion',
realnametip: 'Veuillez saisir votre nom réel',
idcardtip: 'Veuillez saisir votre numéro d’identité nationale',

// 收款方式
clTxt: 'Veuillez configurer vos méthodes de paiement et veuillez utiliser un compte enregistré à votre nom',
clTxt2: 'Ajouter une méthode de paiement',
edit: 'Modifier',
delete: 'Supprimer',
name: 'Nom',
clTxt3: 'Numéro d’identification',
clTxt4: 'Numéro IBAN',
clTxt5: 'Toutes les méthodes de paiement',
clLink1: 'Nom du compte',
clLink2: 'Modifier la méthode de paiement',
clLink3: 'Détails de paiement (facultatif)',
clplaceholder1: 'Veuillez saisir votre nom complet',
clplaceholder2: 'Veuillez saisir le mot de passe de votre compte bancaire',
clplaceholder3: 'Veuillez saisir les détails de votre paiement',
cltip: 'Avertissement spécial',
cltip2: 'Assurez-vous d’ajouter votre numéro de carte bancaire pour des paiements immédiats. N’incluez pas les informations détaillées d’autres banques ou modes de paiement. Vous devez ajouter les informations de paiement/d’encaissement pour la banque sélectionnée.',
cltip3: 'Astuce : Lorsque vous vendez des cryptomonnaies, la méthode de paiement choisie sera présentée à l’acheteur. Vérifiez que toutes les informations sont correctes et utilisez un compte de réception correspondant à votre identification KYC sur la plateforme Binance.',
clTxt6: 'Facultatif',
upload: 'Téléverser',
uploadTip: '(Formats pris en charge : JPG/JPEG/PNG/BMP, taille inférieure à 1 Mo)',
// Mes cartes promotionnelles
tgbtn: 'Échange de code de remise',
tgth1: 'Nom de la carte',
tgth2: 'Code d’échange',
tgth3: 'Monnaie sur la carte',
tgth4: 'Montant indiqué sur la carte',
tgth5: 'Quantité totale',
tgth6: 'Déjà échangé',
tgth7: 'Date de récupération',
tgbtn2: 'Materiels de promotion',
tgbtn3: 'Échanger maintenant',
tgplaceholder: 'Saisissez le code d’échange',
exchangesuccess : "Échange réussi ! Veuillez consulter le solde de votre gestion des actifs ! ",
// Mes invitations
inth1: 'Utilisateurs invités',
inth2: 'Date d’inscription',
inth3: 'État d’authentification en nom propre',
// Mes annonces
adTxt: 'Mes annonces',
adTxt2: 'Lorsque le montant minimal d’une transaction dans une annonce, majoré des frais, dépasse la quantité restante, l’annonce est automatiquement retirée',
adbtn: 'Effacer les critères',
adbtn2: 'Mettre en ligne',
adbtn3: 'Retirer',
adth1: 'Numéro d’annonce ',
adth2: 'Type d’annonce',
adth3: 'Limites de commande',
adth4: 'Quantité restante',
adtip: 'Êtes-vous sûr de vouloir supprimer ?',
num_text1: 'Veuillez saisir la quantité que vous souhaitez',
num_text2: '',
// Ordres en cours
wTxt: 'Marché Spot',
wTxt2: 'Ordres en cours',
wTxt3: 'Paire de devises',
wTxt4: 'Direction de la transaction',
wth1: 'Heure',
wth2: 'Direction',
wth3: 'Prix',
wth4: 'Exécuté',
wth5: 'Montant exécuté',
wth6: 'Type d’ordre',
wth7: 'Prix de déclenchement',
wth8: 'Prix de réalisation',
wth9: 'Quantité ordonnée',
wth10: 'Bénéfice/loss de liquidation',
wth11: 'État de l’ordre',
wth12: 'Ouverture/Clôture de position',
wth13: 'Marge',
wbtn: 'Annuler la commande',
wtip: 'Indication pour annulation de commande',
wtiptxt: 'Confirmez-vous l\'annulation de la commande ?',
// Gagner des cryptomonnaies
zbplaceholder: 'Saisissez le numéro de commande pour rechercher',
zbtab1: 'Plan d\'investissement périodique',
zbtab2: 'Historique d\'investissement périodique',
zbth1: 'Périodicité de l\'investissement',
zbth2: 'Ouvert / Fermé',
zbth3: 'Montant total investi',
zbth4: 'Quantité détenue',
zbth5: 'Coût moyen',
zbth6: 'Bénéfice non réalisé',
zbth7: 'Prochaine date d\'investissement',
zbth8: 'Date de création du plan',
// Ordres actuels - 1
etTxt: 'Centre d\'annonces',
etTxt2: 'Partager avec balayage QR',
etmenu1: 'Crypto-monnaies',
etmenu2: 'Mises à jour du serveur Bitisan',
// Ordres actuels - 3
et2menu1: 'Introduction à RX.Tron',
et2menu2: 'Le bitcoin est-il une arnaque pyramidale ?',
// Historique des ordres
hisTxt: 'Historique des ordres',
// Mes commandes
orTab1: 'Non payé',
orTab2: 'Payé',
orTab3: 'Terminé',
orTab4: 'Annulé',
orTab5: 'En appel',
orth1: 'Numéro de commande',
orth2: 'Monnaie de transaction',
orth3: 'Type de transaction',
orth4: 'Objet de transaction',
orth5: 'Montant',
// Contrats d'options
qcTab1: 'Résultats précédents',
qcTab2: 'Cours en temps réel',
qcTxt: 'N°',
qcTxt2: 'Échéance',
qcTxt3: 'Heure d\'ouverture',
qcTxt4: 'Heure de clôture',
qcTxt5: 'Prix d\'ouverture',
qcTxt6: 'Prix de clôture',
qcTxt7: 'Total achats haussiers',
qcTxt8: 'Total achats baissiers',
qcTxt9: 'Mes positions ouvertes',
qcTxt10: 'Progression de la prédiction',
qcTxt11: 'Prix d\'ouverture',
qcTxt12: 'Prix de clôture',
qcTxt13: 'Période de prédiction de cette échéance',
qcbtn1: 'Achat haussier',
qcbtn2: 'Achat baissier',
qcth1: 'Nombre d\'échéances',
qcth2: 'Montant de l\'ouverture de position',
qcth3: 'Direction prévue',
qcth4: 'Résultat de la prédiction',
qcth5: 'Quantité de bonus',
qcth6: 'Frais d\'ouverture',
qcth7: 'Taux de marge',

// Détails de la crypto-monnaie
Internet: 'Erreur de connexion réseau',
mplaceholder: 'Saisissez le nom de la crypto-monnaie pour chercher',
mTab: 'Mon choix',
mth: 'Favoris',
mth1: 'Prix le plus récent',
mth2: 'Variation sur 24h',
mTxt: 'Prix le plus élevé sur 24h',
mTxt2: 'Prix le plus bas sur 24h',
mTxt3: 'Quantité négociée',
mTxt4: 'Taux de financement',
mTxt5: 'Ouverture',
mTxt6: 'Maximum',
mTxt7: 'Minimum',
mTxt8: 'Clôture',
indexth: 'Montant total des transactions',
indexbtn: 'Détails',
indexbtn1: 'Prix',
indexbtn2: 'Quantité',
indexbtn3: 'Volume des transactions',
indexbtn4: 'Temps',
echat1: 'Graphique de candles',
echat2: 'Graphique de profondeur',
mTab2: 'Positions actuelles',
mTab3: 'Historique des ordres',
mth3: 'Garantie',
mth4: 'Prix d\'ouverture',
mth5: 'Période',
mth6: 'Prix de fermeture',
mth7: 'Profit',
mTxt9: 'Mode de transaction',
mTxt10: 'Heure d\'ouverture',
mTxt11: 'Taux de profit',
mTxt12: 'Solde',
mTxt13: 'Quantité achetée',
mTxt14: 'Chinois', // 中文
// Création d'une annonce publicitaire
cadTxt: 'Créer une transaction par annonce',
cadTxt2: 'Si vous effectuez des transactions fréquemment, vous pouvez créer votre propre annonce de transaction.',
cadTxt2_1: 'Si vous négociez occasionnellement, nous vous recommandons de rechercher directement',
cadTxt2_2: 'La création d\'une annonce de transaction est gratuite.',
cadLink2: 'Annonces de transaction',
cadTxt3: 'Si vous souhaitez éditer directement une annonce déjà créée, consultez',
cadLink3: 'Mes annonces',
cadTxt4: 'Prix de participation au marché',
cadTxt5: 'Le surcot est le pourcentage au-dessus du prix courant du marché à utiliser pour la vente',
cadTxt6: 'Formule de tarification',
cadTxt7: '【Astuce】Vous pouvez vous rendre dans votre centre personnel pour lier / ajouter des modes de paiement',
cadTxt8: 'Le montant total de la transaction ne peut pas dépasser votre solde de vente en CNYI',
cadTxt9: 'Lorsque vous activez cela, lorsque les utilisateurs passent par cette annonce pour vous contacter, le système enverra automatiquement le message de réponse automatique que vous avez choisi à l\'autre partie.',
cadTxt10: 'Le surcot correspond au pourcentage au-dessus du prix courant du marché utilisé pour l\'achat',
cadLabel1: 'Je recherche :',
cadLabel2: 'Transaction de crypto-monnaie :',
cadLabel3: 'Pays :',
cadLabel4: 'Devise :',
cadLabel5: 'Activer un prix fixe',
cadLabel6: 'Surcot : ',
cadLabel7: 'Prix de transaction',
cadLabel8: 'Quantité à vendre :',
cadLabel9: 'Moyen de paiement :', // Note: This line seems to be duplicated in the source
cadLabel10: 'Montant minimal de la transaction :',
cadLabel11: 'Montant maximal de la transaction :',
cadLabel12: 'Activer la réponse automatique',
cadLabel13: 'Mot de passe financier :',
cadLabel14: 'Informations de référence :',
cadLabel15 : 'Prix fixe :',
cadLabel16: 'Message de réponse automatique :',
cadLabel17: 'Quantité à acheter :',
radio: 'Vente en ligne',
radio2: 'Achat en ligne',
cadplaceholder: 'Veuillez définir votre surcot',
cadplaceholder2: 'Entrez la quantité que vous souhaitez vendre',
cadplaceholder3: 'Entrez votre montant minimal de transaction',
cadplaceholder4: 'Entrez votre montant maximal de transaction',
cadplaceholder5: 'Vous pouvez inclure vos exigences spéciales dans les informations de référence, comme les conditions pour l\'acheteur, les heures de disponibilité en ligne, etc.',
cadplaceholder6: 'Entrez votre prix de transaction',
cadplaceholder7: 'Le message qui sera automatiquement envoyé au acheteur après réception de la commande, comme les méthodes de paiement, les comptes de réception, etc.',
cadplaceholder8: 'Entrez la quantité que vous souhaitez acheter',
caderr1: 'Veuillez saisir votre montant minimal de transaction',
caderr2: 'Le montant minimal doit être supérieur ou égal à 100 !',
caderr3: 'Le montant minimal doit être inférieur au montant maximal',
caderr4: 'Veuillez saisir votre montant maximal de transaction !',
caderr5: 'Veuillez saisir un nombre entier',
caderr6: 'Le montant maximal doit être supérieur au montant minimal !',
caderr7: 'Le montant maximal de la transaction ne peut pas excéder votre solde de vente total',
caderr8: 'Veuillez sélectionner un mode de transaction',
submit: 'Soumettre',
cadnew: 'Une fois activé, le prix de votre crypto-monnaie ne fluctuera pas avec le marché et restera constant.',

// Échange rapide
dhTxt: 'Échange instantané · Profitez de vos gains',
dhTxt2: 'Limité',
dhTxt3: 'Vendre',
dhTxt4: 'Acheter',
dhTxt5: 'Maximum',
dhTxt6: 'Saisissez le montant',
dhplaceholder: 'Solde disponible du portefeuille spot',
dhSuccess: 'Félicitations ! Votre échange a réussi !',

// Trading de devises fiat
crTxt: 'Trading de devises fiat',
crTxt2: 'Facile, sécurisé et rapide pour acheter et vendre des cryptomonnaies',
crTxt3: 'Ajustement dynamique',
crTxt4: 'Pas de frais de transaction',
crTxt5: 'Résolution immédiate',
crTxt6: 'Garantie de la plateforme',
crTxt7: 'Fluctuations basées sur le marché en temps réel',
crTxt8: 'Les utilisateurs voient le prix qu\'ils obtiennent, sans frais de plateforme supplémentaires sur le prix d’achat ou de vente',
crTxt9: 'Intégration de commerçants de services de la plateforme, mise en correspondance intelligente des ordres, résolution des transactions sans attendre une consolidation',
crTxt10: 'Commerçants certifiés par la plateforme, sécurité garantie, assistance clientèle 24h/24 pour assurer la sécurité des transactions',
crth1: 'Commerçant',
crth2: 'Nombre de transactions',
crth3: 'Modes de paiement',
crth4: 'Plafonds',
crth5: 'Prix unitaire',
crBouton: 'Acheter',
crBouton2: 'Devenir un commerçant',

// Trading de devises fiat (section 1)
cr1Txt: 'Félicitations ! Votre certification marchande a été approuvée',
cr1Txt2: 'Préparez les documents',
cr1Txt3: 'Soumettre à l’approbation',
cr1Txt4: 'Approuvé',
cr1Txt5: 'Vous bénéficiez des autorisations suivantes',
cr1Txt6: 'Emplacement exclusif',
cr1Txt7: 'Service one-to-one',
cr1Txt8: 'Les commerçants bénéficient d\'un emplacement publicitaire dédié, augmentant ainsi le taux de réussite des transactions',
cr1Txt9: 'Des frais de transaction plus bas',
cr1Bouton: 'Publier une annonce',
cr1Bouton2: 'Demander un remboursement de caution',

// Investissement à terme
reTxt: 'Investissement à terme Bitisan • Solution d’investissement tout-en-un',
reTxt2: 'Gagnez des revenus immédiatement, simple & sûr',
reTab: 'Gestion financière périodique',
reTab2: 'Investissement périodique',
reTxt3: 'Périodicité',
reTxt4: 'Personnalisation souple, rendement potentiellement plus élevé',
reTxt6: 'Investissement périodique',
reTxt7: 'Commencer à utiliser l’investissement périodique pour augmenter votre patrimoine',
reTxt8: 'Développez tous les 18 produits réguliers',
reth1: 'Taux de rendement annuel',
reth2: 'Durée (jours)',
reth3: 'Plafond de transaction',
reth4: 'Produit',
reth5: 'Historique du taux de rendement investi',
rebtn: 'Créer un plan',
redTxt: 'Créer un',
redTxt2: 'Plan d’investissement périodique',
redTxt3: 'Saisissez le montant d’investissement',
redTxt4: 'Mon solde disponible est',
redTxt5: 'Disponible',
redTxt6: 'Périodicité',
redTxt7: 'Chaque jour',
redTxt8: 'Chaque semaine',
redTxt9: 'Chaque mois',
redTxt10: 'Heure locale',
redTxt11: 'Votre premier cycle d’investissement automatique commencera le',
redTxt12: 'Début',
confirm: 'C O N F I R M E R',
confirm2: 'Confirmer',
cancel: 'Annuler',
redongoing: "En cours ",
redcompleted: "Terminé ",
minNumErr: 'Inférieur au montant minimum d’investissement initial',
minNumErr2: 'Le montant d’investissement ne peut pas être nul',
buyamounttip: 'Saisissez la quantité d’achat',
pricetipwarning: 'Le montant de la transaction ne peut pas dépasser',
buyamounttipwarning: 'La quantité d’achat ne peut pas être supérieure',
sellamounttip: 'Saisissez la quantité de vente',
pricetipwarning: 'Le montant de la transaction ne peut pas excéder',
buyamounttipwarning: 'La quantité d’achat ne doit pas être supérieure',
sellamounttip: 'Saisissez la quantité de vente',
sellamounttipwarning : 'La quantité de vente ne peut pas être supérieure',
sellpricetip: 'Saisissez le prix de vente',
sellpricetipwarning: 'Le prix de vente ne peut pas être supérieur',
sellmore: 'Vous pouvez vendre jusqu’à',
sellunit: 'pièces',
loginFirst: "Veuillez vous connecter d’abord ",
betsuccess: "Félicitations ! Votre pari a été effectué avec succès ! ",
selectAmount: "Sélectionnez le montant du pari ",
balancenotenough: "Solde disponible insuffisant ! ",
balancenotenough2: "Solde insuffisant ! ",
minlimitamountfailed: "Le montant de conversion ne peut pas être inférieur au montant minimal de conversion ",
maxlimitamountfailed: "Le montant de conversion ne peut pas être supérieur au montant maximal d’achat ",
limittimesfailed: "Vous avez dépassé le nombre maximum d’achats autorisé individuellement ",
commitfailed: "Échec de la soumission de participation ",
// Trading de marchandise
trplaceholder: 'Rechercher',
trplaceholder2: "Prix actuel ",
trplaceholder3: 'Acheter au meilleur prix du marché',
trplaceholder4: 'Vendre au meilleur prix du marché',
triggleplaceholder: "Le prix par défaut de l’ordre est celui du marché ",
triggerpriceshouldbigger: "Le prix de déclenchement doit être supérieur à 0 ",
marginModeSuccessTip: "Modification réussie du mode de compte de contrat ",
noenoughbalance: "Marge insuffisante sur le compte ",
pleaseinputopenvolume: "La quantité d’ouverture doit être supérieure à 0 ",
pleaseinputcorrectopenvolume: "Saisissez une quantité d’ouverture correcte ",
pleaseinputrightentrustprice: "Saisissez un prix d’ordre valide ",
noenoughposition: "Position insuffisante pour fermer ",
pleaseinputclosevolume: "La quantité de fermeture doit être supérieure à 0 ",
pleaseinputcorrectclosevolume: "Saisissez une quantité de fermeture correcte ",
option1: 'Graphique journalier',
option2: 'Graphique hebdomadaire',
option3: 'Graphique mensuel',
trTxt: 'Montant de la transaction',
trTxt2: 'Prix du marché',
trTxt3: 'Quantité de vente',
trSuccess: 'Soumission réussie',
// u-Based
udTxt: 'Mode de marge',
udTxt2: 'Ajuster le multiple de contrat',
udTxt3: 'Marge',
udTxt4: 'Perpétuel',
udTxt4_1: 'Perpétuel',
udTxt5: 'Délai convertible',
udTxt6: 'Quantité de transfert',
udTxt7: 'Transférer tout',
udTxt8: 'Compte perpétuel',
ubtn: 'Total',
ubtn2: 'Progressif',
uplaceholder: 'Compte de contrat perpétuel',
uradio1: 'Temps partiel',
uradio2: '15 minutes',
uradio3: '1 heure',
uradio4: '4 heures',
uradio5: '1 jour',
uth1: 'Multiple de contrat',
uth2: 'Bénéfice',
uth3: 'Taux de rendement',
uth4: 'Quantité en position',
uth5: 'Quantité pouvant être fermée',
uth6: 'Prix moyen d’ouverture',
uth7: 'Prix de liquidation forcée',
uth8: 'Marge actuelle',
uth9: 'Taux de marge',
zhang: 'mots',
or: 'ou',
ureg: 'Inscrivez-vous immédiatement ',
uTxt8: 'Effectuer des transactions',
uTab: 'Ouverture',
uTab2: 'Fermeture',

uTxt9: 'Ouverture long',
uTxt10: 'Ouverture court',
uTxt11: 'Prix d’ordre',
uplaceholder2: 'Achat/sell au meilleur prix du marché',
uTxt12: 'Achat pour ouvrir long',
uTxt13: 'Vente pour ouvrir court',
closeup: "Achat pour fermer court ",
closedown: "Vente pour fermer long ",
uTab3: 'Take-profit / Stop-loss',
uTab3_yprice: 'Prix Take-profit',
uTab3_sprice: 'Prix Stop-loss',
uTab3Txt: 'Take-profit / Stop-loss',
uTxt14: 'Mon compte de contrat',
ulabel1: 'Mode de compte',
ulabel2: 'Total des gains du compte',
ulabel3: 'Garantie des positions',
ulabel4: 'Utilisation du capital',
ulabel5: 'Long',
ulabel6: 'Court',
uTxt15: 'Long position',
uTxt16: 'Short position',
total: 'Total',
limited_price: 'Ordre limité',
market_price: 'Ordre au marché',
spot_price: 'Take-profit / Stop-loss',
ent_status1: "En attente ",
ent_status2: "Annulé ",
ent_status3: "Echec de l’ordre ",
ent_status4: "Ordre réussi ",
ent_statusblast: "Liquidation forcée ",
uTxt17: 'Positions longues pouvant être closes',
uTxt18: 'Positions courtes pouvant être closes',

// ...

prtxt: 'Mon lien d’invitation',
copy: 'Copier',
prtxt2: 'Mes amis directs',
prtxt3: 'Mes amis indirects',
prtxt4: 'Revenus de commission (converti en USDT)',
prtxt5: 'Bonus supplémentaires',
prtxt6: 'Niveau de partenariat',
prtit: 'Détails des règles',
prdesc: 'Le programme Partenaire de parrainage de Bitisan est l’activité de reversement la plus avantageuse en termes de taux et durée (jusqu’à un reversement à vie) sur l’ensemble du réseau, permettant aux promoteurs investis de devenir de véritables “partenaires” de la plateforme et de bénéficier des bénéfices croissants avec Bitisan. Voici les détails spécifiques :',
prli1: 'Un : Afin de diffuser la philosophie des actifs numériques et d’étendre sa base d’utilisateurs, Bitisan lance son programme [Partenaire de parrainage], ce qui reste valable de manière permanente.',
prli2: 'Deux : L’invitation d’amis se fait en deux niveaux : si A invite B, B devient un ami direct de A ; si ensuite B invite C, C est un ami direct de B mais aussi un ami indirect d\'A.',
prli3: 'Trois : Pour que quelqu’un devienne un ami direct, il doit s’inscrire via le lien fourni par l’inviteur ou entrer manuellement le code d’invitation de l’inviteur.',
prli4: 'Quatre : Une fois qu’un invité s’est inscrit via le code d’invitation et a terminé sa vérification d’identité, les incitations de reversement peuvent prendre effet.',
prli5: 'Cinq : La période de reversement commence N mois après que l’invité ait finalisé sa vérification d’identité, la durée et le pourcentage de reversement dépendent du niveau atteint.',
prli6: 'Six : Les actifs reversés proviennent des frais de transaction de cryptomonnaies entre les amis indirects, et sont payés en USDT, BTC ou ETH, trois cryptos-monnaies de référence.',
prli7: 'Sept : Le taux de reversement et la durée du reversement sont positivement corrélés au nombre d’amis directs parrainés ; plus on parraine, plus le pourcentage de reversement augmente.',
prli8: 'Huit : Les partenaires de parrainage classés dans le TOP 10 ou le TOP 100 selon les reversements reçoivent régulièrement des bonus supplémentaires accordés par Bitisan.',
prli9: 'Neuf : En cas de conflit entre cette activité [Partenaire de parrainage] et d’autres événements, des modifications temporaires seront discutées et décidées en concertation.',
prli10: 'Dix : Les partenaires de niveau L4 (Gouverneur), L5 (Inspecteur général) et L6 (Commandant militaire) bénéficient respectivement de 5%, 10% et 15% des revenus de reversement annuels en tant que dividendes.',
prli11: 'Onze : Bitisan se réserve le droit final d’interprétation de cet événement.',

prth1: 'Niveau',
prth2: 'Nombre d’amis directs',
prth3: 'Pourcentage de reversement / Durée de reversement pour les amis directs',
prth4: 'Pourcentage de reversement / Durée de reversement pour les amis indirects',
prth5: 'Dividende partenaire',
prlev1: 'Chef local',
prlev2: 'Magistrat',
prlev3: 'Gouverneur',
prlev4: 'Gouverneur provincial',
prlev5: 'Inspecteur général',
prlev6: 'Commandant militaire',
prtd1: 'mois',
prtd2: 'Reversement à vie',
prtit2: 'Exemple explicatif',
prtxt7: '... // déjà traduit',
prtxt8: '... // déjà traduit',
prtit3: 'Boîte à outils promotionnelle',
prtit4: 'Classement des reversements totaux (général)',
prtit5: 'Classement du nombre de personnes parrainées (général)',

// Autres sections non explicitement traduites

prtxt9: 'Date de fin de statistiques',
prth6: 'Rang',
prth7: 'Membre',
prth8: 'Nombre de personnes parrainées',
prth9: 'Reversement total (converti en USDT)',
prth10: 'Bonus supplémentaires',

// Informations sur les opérations de dépôt et retrait
recth1: 'Date d’arrivée',
recth2: 'Protocole réseau',
recth3: 'Adresse de dépôt',
recth4: 'Quantité versée',
recbtn: 'Recharge avec code de retrait',
recplaceholder: 'Entrez le code de retrait',
recstatus_0: 'Échec',
recstatus_1: 'Non arrivé',
recstatus_2: 'Arrivé',
// Opérations de retrait de crypto-monnaies
wittit: 'Retrait de crypto-monnaies',
wittext: 'Historique des retraits',
wittext2: 'Code de retrait',
wittext3: 'Liste des codes de retrait',
witbtn: 'Retirer avec un code de retrait',


witbtn2: 'Générer un code de retrait',
witdia: 'Vérification de sécurité',
witdia2: 'Code de retrait généré avec succès',
witplaceholder: 'Saisissez le montant à retirer',
witerr: 'Veuillez sélectionner la crypto-monnaie',
addresstip: 'Veuillez saisir l’adresse',
witstate: 'À convertir',
witstate2: 'Échec',
witstate3: 'Conversion terminée',
witstatuses_1: 'En cours de vérification',
witstatus_2: 'Transfert en cours',
witstatus_3: 'Échec',
witstatus_4: 'Réussi',
// Types d'opération
type1: 'Virement',
type2: 'Échange de crypto-monnaies',
type3: 'Achat de crypto-monnaie contre devise fiduciaire',
type4: 'Vente de crypto-monnaie contre devise fiduciaire',
type5: 'Récompense pour les activités',
type6: 'Prime de parrainage',
type7: 'Dividende',
type8: 'Vote',
type9: 'Rechargement manuel',
type10: 'Matchmaking',
type11: 'Échange d\'activité',
type12: 'Achat C2C',
type13: 'Vente C2C',
type14: 'Envoyer des cadeaux rouges',
type15: 'Récupérer des cadeaux rouges',
type16: 'Retrait via code',
type17: 'Charger via code',
type18: 'Frais de contrat perpétuel',
type19: 'Bénéfice du contrat perpétuel',
type20: 'Perte du contrat perpétuel',
type21: 'Échec de contrat d\'option',
type22: '', // Ce champ est vide dans le texte original
type23: 'Frais de contrat d\'option',
type24: 'Bonus de contrat d\'option',
type25: 'Prime entre pairs',
type26: 'Revenus des frais de plateforme',
type27: 'Échec de contrat à secondes',
type28: 'Bonus de contrat à secondes',
type29: 'Intérêts sur épargne',
type30: 'Frais de fonds déboursés',
type31: 'Frais de fonds acquis',
type32: 'Achat programmé',
type33: 'Vente programmée',
type34: 'Achat de produit à terme',
type35: 'Rachat de produit à terme',
type36: 'Transfert vers contrat à marge (crypto-monnaie)',
type37: 'Transfert depuis contrat à marge (crypto-monnaie)',
type38: 'Transfert vers contrat à marge (U)',
type39: 'Transfert depuis contrat à marge (U)',
type40: 'Transfert vers contrat à secondes',
type41: 'Transfert depuis contrat à secondes',
type42: 'Transfert vers échange de crypto-monnaies',
type43: 'Transfert depuis échange de crypto-monnaies',
// Compte de placement
lctit: 'Compte de placement',
lcbtn: 'Déposer dans le compte de placement',
lcth1: 'Intérêts cumulés',
day: 'Jour',
lctxt: 'Crypto-monnaie de l\'événement',
lctxt2: 'Crypto-monnaie acceptée',
lctxt3: 'Quantité minimale d\'investissement',
lctxt4: 'Choisir la durée',
year: 'Année',
month: 'Mois',
// Ordres de placement
ldtab1: "Historique des positions",
ldtab2: "Historique des clôtures de position",
ldth1: "Date de début des gains",
ldth2: "Date de rachat",
ldth3: "Estimation de gain",
ldth4: "Gain réalisé",
// Plan d'investissement régulier
attit: "Modifier le plan d'investissement régulier",
attxt: "Quantité d'investissement",
attxt2: "Périodicité de l'investissement",
atth1: 'Date de versement périodique',
atth2: 'Montant investi',
atth3: 'Taux de transaction',
success: 'Réussi',
// Gestion innovante
cxth1: 'Nom',
cxth2: 'Type',
cxth3: 'Quantité souscrite',
cxth4: 'Crypto-monnaie participante',
cxth5: 'Unité d\'abonnement',
cxth6: 'État actuel',
cxth7: 'Montant des transactions',
cxth8: 'Date de création',
cxnav1: 'Mes participations',
cxnav2: 'Mes minières',
cxnav3: 'Mes verrous de crypto-monnaie',
cxtit2: 'Liste de mes minières',
cxtit3: 'Liste de mes verrous de crypto-monnaie',
cxkth1: 'Crypto-monnaie produite',
cxkth2: 'Cycle de production',
cxkth3: 'Durée de minage',
cxkth4: 'Temps déjà miné',
cxkth5: 'Capacité de base',
cxkth6: 'Capacité réelle',
cxstate1: 'Non déployé',
cxstate2: 'Déployé',
cxstate3: 'Annulé',
cxstate4: 'En attente de transaction',
cxstate5: 'Terminé',
cxstate6: 'À libérer',
cxstate7: 'En cours de libération',
cxcth1: 'Monnaie à libérer ',
cxcth2: 'Total en verrouillage',
cxcth3: 'Période totale de libération',
cxcth4: 'Période de libération effectuée',
cxcth5: 'Libération de base',
cxcth6: 'Libération réelle',
more: 'Plus',

// À propos de nous
abtit: 'Caractéristiques de la plateforme',
abtit2: 'À propos de nous',
abtit3: 'Contactez-nous',
abtit4: 'Communauté Bitisan',
abdesc: 'Bitisan est une plateforme de trading de biens numériques technologiquement avancée au niveau mondial, enregistrée aux îles Caïmans, avec son équipe opérationnelle principale basée à Hong Kong. Les membres clés de Bitisan proviennent des meilleures entreprises Internet et financières, la plupart étant profondément attachés à Bitcoin et à la blockchain ; nous croyons fermement que la blockchain changera le système financier traditionnellement monopolistique et établira une structure sociale plus démocratique et autonome.',
abdesc2: 'La plateforme Bitisan possède une architecture de trading de niveau financier professionnel avec un moteur de matchmaking haute performance en mémoire développé en interne. La plateforme utilise un système de portefeuille de recharge complet + signatures multiples + système de protection DDOS de haute sécurité pour garantir la sécurité des actifs clients.',
abdesc3: 'Pour permettre à ses clients de saisir les opportunités d’investissement de manière optimale, le département clientèle de Bitisan adopte une gestion à structure plate et met en place un mécanisme de réponse rapide. Les gestionnaires de compte client sont disponibles 7j/7 et 24h/24 pour fournir des services de recharge et retrait d’actifs, assurant que les transactions clientes soient terminées en moins de 5 minutes.',
abdesc4: 'Bitisan sélectionne rigoureusement des projets de qualité supérieure et fournit des services fiables et stables de garde d\'actifs. Bitisan s\'engage à respecter les valeurs de "Sincérité, Impartialité, Chaleur et Ouverture", visant à créer une exchange ultime, sûre, fiable, efficace et conviviale pour ses utilisateurs.',
abdesc5: 'Bien que les actifs numériques ne soient actuellement connus qu\'à l\'intérieur d\'un cercle restreint, nous sommes persuadés que ces actifs contrôlables en totalité par chacun prendront leur essor dans l\'avenir ; travaillons ensemble et soyons patients !',
abdesc6: 'Service Client',
abdesc7: 'Assistance Technique',
abdesc8: 'Demande de listing',
abdesc9: 'Avis de plainte',
abdesc10: 'Partenariat Commercial',
abdesc11: 'WeChat',
abdesc12: 'Weibo',
abdesc13: 'Twitter',
abdesc14: 'CoinUse',
abdesc15: 'Telegram',
abdesc16: 'Ajoutez "bmate601" comme ami WeChat pour rejoindre notre communauté bénéfices exclusifs sur WeChat.',

// Vérification d'identité réelle
realtxt: 'Nom réel',
realtxt2: 'Numéro d\'identité',
realtxt3: 'Photo recto de la carte d\'identité',
realtxt4: 'Photo verso de la carte d\'identité',
realtxt5: 'Photo d\'identité tenue en main avec signature personnelle',
realtxt6: '1. Format JPG ou PNG supporté, taille du fichier inférieure à 8 Mo ; les informations sur le document doivent être clairement visibles, sans aucune modification ni obstruction, et le numéro d\'identité et le nom doivent être lisibles.',
realtxt7: '2. La photo doit être sans chapeau et, idéalement, prise sans maquillage ; le visage de la personne tenant la pièce d\'identité doit être nettement visible et entièrement dégagé.',
realtxt8: "3. Il est nécessaire de tenir simultanément la pièce d'identité et de rédiger un message manuscrit (nom + date de demande) : 'Toutes mes actions sur ce site sont personnelles et je suis conscient des risques associés, acceptant toutes les conséquences juridiques liées à mon compte.'",
realbtn: 'Cliquez pour télécharger',
realstate: 'Non vérifié',
realstate2: 'Vérifié',
uploadimg: "Veuillez télécharger la photo recto de votre carte d'identité ",
uploadimg2: "Veuillez télécharger la photo verso de votre carte d'identité ",
uploadimg3: "Veuillez télécharger la photo d'identité tenue en main ",
uploaderr: "La taille de l'image téléchargée ne doit pas dépasser ",
save_success: 'Sauvegarde réussie !',
save_failure: 'Échec de la sauvegarde !',

// Transfert
close: 'Fermer',
hztxt: 'Confirmez le transfert',

// Vérification vendeur/commercial
submittip1: 'Pour votre sécurité financière, veuillez effectuer d\'abord une [Vérification d\'identité réelle] !',
submittip2: 'Pour votre sécurité financière, veuillez tout d\'abord lier votre [Téléphone] !',
submittip3: 'Pour votre sécurité financière, veuillez d\'abord définir un [Mot de passe pour les fonds] !',
submittip4: 'Veuillez lier au moins un mode de paiement',
aderr1: 'Entrez un nombre valide',
aderr2: 'La marge doit être comprise entre 0 et 20',
aderr3: 'La marge doit être comprise entre 0 et 20 et ne peut pas être nulle',
aderr4: 'Entrez un prix fixe correct',
aderr5: 'Entrez un nombre valide et assurez-vous que la quantité maximale de transaction ne dépasse pas 100 pièces',
orderTypeNo: "Non ",
orderTypeYes: "Oui ",
win: "Gagné ",
lose: "Perdu ",
tied: "Match Nul ",
cancelsuccess: "Annulation réussie ! ",
shelves: 'Vous devez retirer l’annonce avant de pouvoir la supprimer !',
shelves2: 'Vous devez d\'abord retirer l’annonce pour pouvoir la modifier !',
business: 'Veuillez d’abord demander la certification marchand !',
swappleaseselectwallet: "Veuillez d'abord sélectionner le compte de transfert/de destination ",
swappleaseinputamount: "Veuillez saisir le montant de transfert ",
clickchange: "Cliquez pour changer de direction de transfert ",
daterange: "Veuillez choisir la plage de dates de recherche ",

// API
apierr: 'Veuillez saisir une information de commentaire',
apierr2: 'Veuillez saisir une adresse IP',
apierr3: 'Veuillez saisir une adresse IP valide',
apierr4: 'Veuillez saisir un code de validation',
clickget: 'Cliquez pour obtenir',
apisecond: 'seconde',
apitips: "La clé API n'est affichée que lors de sa création, veuillez la sauvegarder immédiatement.",
apiaccess: "Clé d'accès API ",
  
// Personnalisation
do_favorite: 'Mis en favoris',
cancel_favorite: 'Retiré des favoris',
fail_favorite: 'Échec',
deptotal: 'Total cumulé',
chart: 'Graphique',
book: 'Livre des commandes',
trade: 'Transactions récentes',
pt_title: "Cartes-cadeaux de crypto-monnaies ",
pt_desc: "Échangez pour devenir automatiquement ami de niveau inférieur, offrez-le à des amis, à votre famille, à vos clients ou à vos partenaires. Aidez les partenaires de promotion à mieux promouvoir.",
pt_more: "Plus d'outils à venir, restez à l'affût !",
pt_tips: "Si vous avez des idées créatives, envoyez un e-mail à promotion@Bitisan.biz, nous récompenserons votre contribution si elle est retenue !",
pt_card_amount: "Montant sur la carte",
pt_card_deadline: "Période de validité",
pt_card_noend: "Illimité",
pt_card_btn: "Partenaires de promotion : obtenez gratuitement 30 cartes (environ 2000 €)",
pt_card_btn_login: "Connectez-vous pour obtenir gratuitement 30 cartes",
pt_card_rule: "Détails des règles",
pt_card_summary: "Les cartes-cadeaux de crypto-monnaie sont conçues comme outil de promotion pour aider les partenaires de promotion à inviter plus facilement des amis de niveau inférieur ; lorsque l'utilisateur cible échange sa carte-cadeau, il devient automatiquement ami de niveau inférieur du promoteur.",
pt_card_rule1: "I. Chaque utilisateur peut uniquement obtenir jusqu'à 30 cartes-cadeaux gratuites. Pour en distribuer davantage, il faut passer par une commande personnalisée payante incluant des options telles que le montant, le logo et le texte explicatif. Envoyez vos besoins en personnalisation à notre adresse courriel.",
pt_card_rule2: "II. Chaque utilisateur ne peut utiliser qu'une seule carte-cadeau gratuite délivrée officiellement par Bitisan, même s'il obtient plusieurs codes d'échange différents auprès de différents utilisateurs. Les cartes-cadeaux personnalisées ne sont pas soumises à cette restriction.",
pt_card_rule3: "III. Avant d'échanger une carte-cadeau, si l'utilisateur n'a pas de « parrain » (c'est-à-dire qu'il n'a pas utilisé de code d'invitation lors de son inscription), il sera automatiquement relié en tant qu'ami de premier niveau au détenteur de la carte. Par exemple, si A s'inscrit sans code d'invitation, il n'appartient à aucun ami de premier ou deuxième niveau mais deviendra automatiquement un ami de premier niveau de B après avoir échangé la carte-cadeau de B.",
pt_card_rule4: "IV. Pour éviter les abus, le solde de la carte-cadeau n'est pas versé immédiatement ; il doit être validé par la vérification de l'identité avant toute utilisation.",
pt_card_rule5: "V. La vérification de l'identité est requise pour obtenir une carte-cadeau gratuite, mais elle n'est pas nécessaire pour l'échange lui-même, ce qui facilite l'obtention rapide d'amis de niveau inférieur pour les partenaires de promotion.",
pt_card_rule6: "VI. Le droit final d'interprétation des cartes-cadeaux gratuites appartient à Bitisan.",
pt_card_day: "jours",
pt_card_title_tips: "Obtenez 2000 € d'aide à la promotion",
pt_invite_desc: "QR Code unique, inscrivez-vous via le QR Code pour devenir automatiquement ami de niveau inférieur, parfait pour partager dans les groupes ou les forums.",
saveimage: "Enregistrer l'image",
imagetips: "Si vous ne pouvez pas télécharger l'image normalement, utilisez un outil d'écran pour la capturer et la sauvegarder !",
pt_card_receivew_success: "Félicitations ! Vous avez bien récupéré les cartes-cadeaux de promotion pour partenaires ! Rendez-vous dans l'[Espace personnel] -> [Centre des coupons] pour les consulter ! ",
usepromotion: "Utiliser le matériel de promotion",
context_title: "Code d'échange exclusif pour les partenaires de promotion",
context_title1: "Scannez pour en savoir plus ",
context_title2: "Mon code d'invitation ",
context_title3: "Image d'invitation ",
pt_card_title: "Carte de promotion pour partenaires ",
copysuccess: 'Copié avec succès !',
copyerr: 'Erreur de copie ! Veuillez copier manuellement.',
noopening: "Aucun contrat d'options actuellement disponible ",
createSuccess: "Création réussie ",
tradewarning1: 'Entrez jusqu\'à 2 décimales',
tradewarning2: 'Le montant de la commande est de',
tradewarning3: 'Entrez jusqu\'à 8 décimales',
tradewarning4: 'La quantité de la commande est de',
tradeconfirmbuyin: 'Confirmer l\'achat',
tradeconfirmsellout: 'Confirmer la vente',
foot:{
    aboutus1: 'À propos de nous',
    aboutus: ' <p >Après le lancement de son réseau principal en avril 2019, la Binance Chain a montré ses performances élevées et sa capacité de traitement massive. Son point focal - l\'application décentralisée native, la Binance Chain DEX (échange décentralisé), a été testée pour gérer des millions de transactions en peu de temps, mettant en évidence les faibles délais d\'exécution de son moteur de négociation.</p >\
    <p >La flexibilité et l\'accessibilité sont souvent liées aux performances. Ce design axé sur la facilité de création et de négociation d\'actifs numériques présente également des limites. La demande la plus insistante de la communauté était de voir l\'ajout de fonctionnalités programmables, c\'est-à-dire la prise en charge des contrats intelligents et des machines virtuelles. Étant donné les limitations actuelles de la Binance Chain, les émetteurs et détenteurs d\'actifs ont eu du mal à ajouter de nouvelles fonctionnalités décentralisées ou à introduire des mécanismes de gestion communautaire et d\'activités sociales.</p >\
    <p >Bien que l\'ajout de contrats intelligents à la Binance Chain soit hautement souhaité, cela représente un choix complexe car leur exécution pourrait ralentir la DEX et introduire une certaine incertitude dans les transactions d\'actifs. Malgré cette compromission potentielle, la solution la plus simple aurait pu consister à créer un intermédiaire virtuel basé sur le protocole Tendermint et l\'interface RPC principale de la Binance Chain. Cependant, cette approche aurait accru le coût d\'apprentissage pour la communauté existante de dApps et n\'aurait probablement pas été une option populaire.</p >\
    <p >Dans ce contexte, nous avons proposé le concept de deux blockchains parallèles pour la Binance Chain afin de maintenir de hautes performances pour la DEX locale tout en offrant une compatibilité amicale avec les contrats intelligents. Après avoir construit ces deux blockchains parallèles dans l\'écosystème de la Binance Chain, chacune fonctionnait indépendamment et fournissait des services différents. La nouvelle blockchain parallèle fut nommée "Binance Smart Chain" (abrégé "BNBN"), tandis que le réseau principal conservait le nom de "Binance Chain" ("BNB").</p >\
    <p >Le design de BNBN suivait ces principes fondamentaux :<ul>\
        <li>Blockchain indépendante : Techniquement, BNBN est une blockchain distincte plutôt qu\'une solution de deuxième couche. La plupart des fonctions techniques et commerciales de la Blockchain Staking (BSC) doivent être indépendantes pour que BNBN puisse continuer à fonctionner même pendant de brèves interruptions de BNB.</li>\
        <li>Compatibilité avec Ethereum : La première plateforme de contrats intelligents largement utilisée et éprouvée est Ethereum. Pour se connecter à des applications et communautés matures, BSC a choisi de rester compatible avec le réseau principal d\'Ethereum actuel, ce qui signifie que la plupart des dApps, composants et outils de l\'écosystème seraient compatibles avec BSC sans nécessiter de grandes modifications ou juste quelques ajustements mineurs ; les nœuds BNBN auraient requis des spécifications matérielles similaires ou légèrement plus élevées.</li>\
        <li>Consensus basé sur le staking de jetons et la gouvernance sur chaîne : Le consensus Proof-of-Stake (PoS) est plus écologique et facilite la gouvernance communautaire. On prévoit que ce type de consensus offre de meilleures performances que le Proof-of-Work (PoW), notamment des temps de génération de blocs plus courts et une capacité de traitement accrue des transactions.</li>\
        <li>Communicationchaîne locale : BNB et BNBN supportaient nativement la communication entre les deux blockchains. Ce protocole devait être bidirectionnel, décentralisé et sans confiance en tiers, se concentrant sur le transfert d\'actifs numériques entre BNB et BNBN, tels que les tokens BEP2 et d\'autres tokens BNBN à venir.</li>\
    </ul> <p >Basé sur ces principes de conception, le protocole de consensus de BNBN visait à atteindre les objectifs suivants : <ul>\
        <li>Temps de génération de blocs plus courts qu\'Ethereum, par exemple 5 secondes ou moins.</li>\
        <li>Délai limité pour l\'achèvement des transactions, environ une minute ou moins.</li>\
        <li>Absence d\'inflation : les revenus provenaient en partie des frais de transaction, payés en BNBN.</li>\
        <li>Compatibilité avec Ethereum autant que possible, y compris les futures mises à jour.</li>\
        <li>Mécanisme de gouvernance sur chaîne basé sur le staking de jetons.</li>\
        <li>BNBN adopte une combinaison de Delegated Proof of Stake (DPoS) et Proof of Authority (PoA) pour son consensus, inspiré par les avantages de PoA dans la résistance aux attaques de 51 % et la prévention de comportements malveillants de certains nœuds Byzantins.</li>\
    </ul><p >Les validateurs de BNB, dont le rôle symbolisait le staking de BNB pour BNBN, étaient élus en fonction de leur participation et changeaient toutes les 24 heures. Ils recevaient des revenus grâce aux frais de transaction, étant donné que BNB n\'était pas une monnaie inflationniste comme Bitcoin ou Ethereum, où les frais miniers sont une source majeure de revenus. Toutefois, les validateurs ayant des parts de BNBN continuaient de bénéficier d\'autres avantages liés à la possession de BNBN.</p >',
    newbie:'',
    newbie1: 'Guide des débutants',
    commonproblem:'',
    commonproblem1: 'Questions fréquentes',
    information:'',
    information1: 'Informations sur la monnaie',
    disclaimer:'',
    disclaimer1: 'Clause d’exemption de responsabilité',
    privacyclause:'',
    privacyclause1: 'Clause de confidentialité',
    agreement:'',
    agreement1: 'Contrat utilisateur',
},
tradeinfo: {
    emaildone: 'E-mail vérifié',
    emailundo: 'E-mail non vérifié',
    teldone: 'Numéro de téléphone vérifié',
    telundo: 'Numéro de téléphone non vérifié',
    idcarddone: 'Carte d\'identité vérifiée',
    idcardundo: 'Carte d\'identité non vérifiée',
    exchangetimes: 'Nombre de transactions',
    price: 'Prix',
    num: 'Quantité',
    paymethod: 'Moyen de paiement',
    exchangelimitamount: 'Montant limite de transaction',
    location: 'Emplacement',
    location_text: 'Chine',
    exchangeperiod: 'Délai de transaction',
    minute: 'Minute',
    amounttip: 'Saisissez un montant',
    numtip: 'Saisissez une quantité',
    remarktip: 'Expliquez-lui vos exigences',
    remarktitle: 'Informations complémentaires',
    exchangetitle: 'Instructions de transaction',
    exchange_tip1: "Une fois votre demande de transaction lancée, les cryptomonnaies sont verrouillées dans un compte de dépôt protégé par la plateforme. Si vous êtes vendeur, déposez les fonds et attendez le paiement de l'acheteur dans le délai imparti. L'acheteur doit effectuer le paiement dans le délai prescrit. Vous devez libérer les cryptomonnaies bloquées dans le compte de dépôt une fois que vous avez reçu le paiement.",
    exchange_tip2: "Veuillez lire les Termes de service du réseau de la plateforme ainsi que les documents d'aide tels que les questions fréquentes et le guide de transaction avant toute transaction.",
    exchange_tip3: "Attention aux escrocs ! Vérifiez les évaluations reçues par l'utilisateur avant toute transaction et soyez vigilant vis-à-vis des comptes récemment créés.",
    exchange_tip4: "Notez que les arrondis et les fluctuations de prix peuvent affecter le montant final des cryptomonnaies transigées. Le montant fixe que vous saisissez détermine le montant final ; la quantité de cryptomonnaies sera calculée au moment de la publication de votre demande en fonction du taux de change en vigueur.",
    exchange_tip5: "Le service de garde est là pour protéger les acheteurs et les vendeurs lors des transactions en ligne. En cas de litige, nous analysons toutes les informations fournies et libérons les cryptomonnaies en garde à leur propriétaire légitime.",
    warning1: 'Entrer jusqu\'à 2 décimales',
    warning2: 'Montant de la commande est de',
    warning3: 'Entrer jusqu\'à 8 décimales',
    warning4: 'Quantité de la commande est de',
    confirmbuyin: 'Confirmer l\'achat',
    confirmsellout: 'Confirmer la vente',
    buyin: 'Achat',
    sellout: 'Vente',
    warning5: 'Veuillez remplir la commande selon les instructions',
},
}