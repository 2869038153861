export default {
    // Mensajes de error
    error1: 'Error del sistema, por favor contacte al servicio de atención al cliente',
    error2: 'La sesión ha expirado, vuelva a iniciar sesión',
    
    // Menús
    meun1: 'Inicio',
    meun2: 'Tendencias del mercado',
    meun3: 'Suscripción BNBN',
    meun4: 'Compra de mineros',
    meun5: 'Promoción rápida',
    meun6: 'Whitepaper',
    meun7: 'Anuncios',
    meun8: 'Iniciar sesión',
    meun9: 'Registrarse',
    meun10: 'Cartera',
    meun10_1: 'Cuenta de acciones',
    meun10_2: 'Cuenta de contratos USDT',
    meun10_3: 'Cuenta de contratos en moneda base',
    meun10_4: 'Contratos al instante',
    meun10_5: 'Cuenta de inversión',
    meun10_6: 'Historial de cartera',
    meun11: 'Órdenes',
    meun11_1: 'Órdenes de suscripción',
    meun11_2: 'Órdenes de minería',
    meun12: 'Servicio al cliente',
    meun12_1: 'Información de soporte',
    meun12_2: 'Número de asistente',
    meun12_3: 'Correo electrónico de atención al cliente',
    meun13: 'Red Packets (Bono)',
    meun13_2: 'Recibir red packet',
    meun13_3: 'Ingrese el código de recibo del red packet',
    meun13_4: 'Cancelar',
    meun13_6: 'Recibir',
    meun13_7: 'Cancelación exitosa',
    meun13_1: 'Administración de cuenta',
    meun13_5: 'Cerrar sesión',
    meun14_1: 'Idioma',
    meun14_3: 'Seleccionar idioma',
    
    scan: 'Escanear y descargar para iOS & Android',
    footmeun1: 'Acerca de',
    footmeun1_1: 'Sobre nosotros',
    footmeun1_2: 'Únete a nosotros',
    footmeun1_3: 'Anuncios de la plataforma',
    footmeun1_4: 'Whitepaper',
    footmeun2: 'Centro de ayuda',
    footmeun2_1: 'Guía para principiantes',
    footmeun2_2: 'Preguntas frecuentes',
    footmeun2_3: 'Guía de trading',
    footmeun2_4: 'Datos de las monedas',
    footmeun3: 'Términos y condiciones',
    footmeun3_1: 'Términos de exención de responsabilidad',
    footmeun3_2: 'Política de privacidad',
    footmeun3_3: 'Acuerdo de usuario',
    footmeun3_4: 'Descripción de tarifas',
    footmeun4: 'Contáctanos',
    footmeun4_1: 'Correo electrónico de soporte',
    footmeun4_2: 'Colaboraciones comerciales',
    footmeun4_3: 'Solicitud de listado de monedas',
    footmeun4_4: 'Denuncias y sugerencias',
    foottext: 'Plataforma global de suscripción BNBN',
    countdown: 'El evento ha finalizado',
    indexTxt1: 'Suscripción global inaugural de BNBN',
    indexTxt2: 'Suscripción global inaugural de BNBN',
    indexTxt3: 'Cuenta atrás para el lanzamiento',
    indexLabel1: 'Tendencias del mercado',
    indexVal1: 'Precio en tiempo real de criptomonedas',
    indexLabel2: 'Suscripción BNBN',
    indexVal2: 'Intercambiar una activo digital directamente',
    indexLabel3: 'Compra de mineros',
    indexVal3: 'Minería de criptomonedas BNBN',
    indexLabel4: 'Promoción rápida',
    indexVal4: 'Beneficios para usuarios que suscriben',
    indexh5: 'Seguridad financiera de alto nivel',
    indexh6: 'Cargas y retiros rápidos',
    indexh7: 'Servicio global',
    indexh8: 'Selección rigurosa de proyectos',
    indexp1: 'BITISAN fue fundado por un grupo de primeros adoptantes de Bitcoin y geeks, cuyos miembros clave provienen de empresas líderes como Google, Microsoft, Alibaba y Tencent, contando con sólidas capacidades de investigación y desarrollo y amplia experiencia en operaciones de productos web.',
    indexp2: 'BITISAN se posiciona como proveedor de servicios básicos en blockchain, comprometido a ofrecer a los usuarios globales una plataforma de intercambio de activos criptográficos de alta calidad, adhiriéndose al principio básico de "no hacer el mal", sirviendo con honestidad, imparcialidad y entusiasmo a los clientes, y abriendo sus puertas a cualquier asociación/proyecto que beneficie los intereses fundamentales de los usuarios.',
    indexp3: 'Sistema integral de gestión de riesgos financieros y seguridad antirrobo, sistemas de billeteras frías/calientes y multi-firma garantizan la seguridad de los fondos',
    indexp4: 'Depósitos y retiros completados en tan solo 3 minutos, revisión humana en línea las 24 horas, protegiendo a los clientes de no perder oportunidades de inversión óptimas',
    indexp5: 'Cobertura global de redes de servicios empresariales para ayudarle a invertir en activos criptográficos mundiales y realizar transacciones con usuarios de todo el mundo',
    indexp6: 'Selección rigurosa de proyectos criptográficos de alta calidad, filtrando hasta un 80% de proyectos de alto riesgo',
    market: 'Información del mercado',
    // Nueva suscripción - Cartera
    wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
    usdt: 'USDT',
    platform: 'BNBN',
    usdt1: 'Saldo disponible',
    usdt2: 'Saldo bloqueado',
    platform1: 'Retirar',
    platform2: 'Depositar',
    platform3: 'Suscribirse',
    transaction: 'Detalle de transacción',
    transaction0: 'Número',
    transaction1: 'Número de transacción',
    transaction2: 'Cantidad de la transacción',
    transaction3: 'Hora de la transacción',
    transaction4: 'Cargo de USDT',
    transaction5: 'Dirección de carga',
    transaction6: 'Retiro de USDT',
    transaction7: 'Withdrawals are free on the first and second day of each month; At other times, the withdrawal fee is 20% of the total amount.',
    transaction8: 'Dirección de retiro',
    transaction9: 'Cantidad de retiro',
    transaction10: 'Cancelar',
    transaction11: 'Confirmar',
    transaction12: 'Suscribir',
    transaction13: 'El valor de la cantidad de suscripción se refiere a la cantidad de USDT a suscribir',
    transaction14: 'Cantidad de suscripción',
    transaction15: 'La cantidad de suscripción debe ser mayor que 50',
    transaction16: 'La cantidad de retiro debe ser mayor que 10',
    transaction17: 'Tipo de moneda',
    transaction18: 'No hay detalles de transacciones disponibles',
    transaction19: 'Por favor seleccione una dirección de carga',
    transaction20: 'Presione el botón nuevamente dentro de 10 minutos',
    transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=1.25USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    transaction25: 'Los retiros requieren una cantidad determinada de comisión',
    // 锁仓挖矿
    mining1: 'Compra',
    mining2: 'Precio de compra',
    mining3: 'Ingresos diarios',
    mining4: 'Días de bloqueo',
    mining5: 'Nivel de compra',
    mining6: 'Total de ganancias de la compra',
    mining7: 'start time',
	mining8: 'End time',
    miningmachine: 'My mining machine',
    mining9: 'Discount countdown',
    // 邀请
    inviteusers1: 'Enlace de invitación',
    inviteusers2: 'Número de invitados',
    inviteusers3: 'Código de invitación',
    inviteusers4: 'Usuario invitado',
    inviteusers5: 'Fecha de invitación',
    inviteusers6: 'Copiado correctamente',
    inviteusers7: 'No hay usuarios invitados',
    inviteusers8: 'Copiar',
    inviteusers10:'Number of subscribers',
    inviteusers11:'Is it active',
    // 订单
    order1: 'Pedido de suscripción',
    order2: 'Pedido de inversión',
    order3: 'Número de suscripción',
    order4: 'Cantidad de suscripción',
    order5: 'Fecha de suscripción',
    order7: 'Número de inversión',
    order8: 'Cantidad bloqueada',
    order9: 'Cantidad de recompensa',
    order10: 'Fecha de inicio',
    order11: 'Fecha de finalización',
    order12: 'No hay información de suscripción',
    order13: 'No hay información de inversión',
    
    // 个人中心
    personal1: 'Información personal',
    personal2: 'Estado de verificación de identidad',
    personal3: 'Verificado',
    personal4: 'En proceso de verificación',
    personal5: 'Sin verificar, haga clic para verificar',
    personal6: 'Verificación fallida, haga clic para verificar',
    personal7: 'Nombre de usuario',
    personal8: 'Correo electrónico',
    personal9: 'Número de teléfono móvil',
    personal10: 'Nivel VIP',
    personal11: 'Miembro normal',
    personal12: 'Código de invitación',
    personal13: 'Información de verificación de identidad',
    personal14: 'Por favor, cargue la información relevante de verificación de identidad',
    personal15: 'Modificar contraseña',
    personal16: 'Contraseña original',
    personal17: 'Modificar contraseña',
    personal18: 'Confirmar contraseña',
    personal19: 'Ingrese la contraseña',
    personal20: 'La longitud de la contraseña debe ser de al menos 6 caracteres',
    personal21: 'Vuelva a ingresar la contraseña',
    personal22: 'Las dos contraseñas ingresadas no coinciden',
    authentication1: 'Agregar información de verificación',
    authentication2: 'Confirmar carga',
    authentication3: 'Solo se permiten archivos jpg/png, con un tamaño máximo de 2 MB',
    authentication4: 'Cargar archivo',
    authentication5: 'Se agregó correctamente el usuario y se cargaron los archivos',
    authentication6: 'Falló la adición del usuario o la carga de archivos',
    authentication7: 'La imagen del avatar solo puede tener formato JPG',
    authentication8: 'El tamaño de la imagen del avatar no puede superar los 2 MB',
    authentication9: 'Se han excedido los 5 archivos',
    
    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
    
    // 登录
    account0: 'Cuenta',
    account: 'Inicio de sesión en cuenta BNBNC OIN',
    phone: 'Número de teléfono',
    email: 'Correo electrónico',
    sign: 'Iniciar sesión',
    password: 'Contraseña',
    password2: 'Contraseña',
    password3: 'Olvidé la contraseña',
    account2: 'Crear cuenta',
    logErr: 'Ingrese su cuenta',
    logErr2: 'Ingrese su contraseña',
    logErr3: 'La longitud de la contraseña debe ser de al menos 6 caracteres',
    logSuccess: 'Inicio de sesión exitoso',
    resetpassword: 'Restablecer contraseña',
    reset: 'Restablecer',
    notwork:"Network connection error",
    // 注册
    account3: 'Crear cuenta personal',
    phone1: 'Registro por número de teléfono',
    email1: 'Registro por correo electrónico',
    email2: 'Dirección de correo electrónico',
    code: 'Código de verificación por SMS',
    code2: 'Enviar código de verificación',
    code3: 'Código de verificación',
    password4: 'Confirmar contraseña',
    invite: 'Código de invitación',
    agree: 'He leído y estoy de acuerdo',
    agreement: 'Acuerdo de usuario',
    register: 'Registrar',
    regErr: 'Ingrese el código de verificación por SMS',
    confirmpwdtip: 'Ingrese la contraseña de confirmación',
    confirmpwderr: 'Las contraseñas no coinciden!',
    
    // 忘记密码
    wjtab: 'Restablecer contraseña del teléfono',
    wjtab2: 'Restablecer contraseña del correo electrónico',
    wjlabel: 'Nueva contraseña',
    wjerr: 'Ingrese una nueva contraseña',
    Tailpage:'Página final',
	homepage:'Página de inicio',
	lastpage:'En la página anterior',
	nextpage:'Siguiente página',
	off:'Cierre',
	problem:'Si se encuentran problemas, incluida la autenticación de nombre real, consulte al servicio al cliente a tiempo si el depósito no llega.',
    // Anuncio
    announcementtitle: 'Título del anuncio',
    announcement: ' <p>Después del lanzamiento principal en abril de 2019, Binance Chain demostró sus altas prestaciones y diseño de alta capacidad de procesamiento. Su foco principal, la aplicación descentralizada nativa (\"dApp\") Binance DEX (Exchange Descentralizado), ha sido probada para manejar millones de transacciones en cortos periodos de tiempo, lo que demuestra su capacidad de emparejamiento de bajo retraso.</p >\
    La flexibilidad y la accesibilidad suelen ir de la mano con el rendimiento. Cuando el enfoque está en proveer un lugar conveniente para la emisión y el intercambio de activos digitales, este diseño también implica ciertos límites. La demanda más clamorosa en la comunidad ha sido ver\
    funcionalidades programables agregadas a Binance Chain, es decir,\
    lo que se refiere a contratos inteligentes y funciones de máquina virtual.\
    Dado que Binance Chain actualmente cuenta con capacidades limitadas, resulta difícil para emisores y propietarios de activos digitales agregar nuevas\
    características a sus activos o implementar cualquier forma de\
    gestión comunitaria y actividades comunitarias.\
    A pesar de que hay altas expectativas sobre la adición de funcionalidad de contratos inteligentes en\
    la cadena de Binance, ésta es una \"decisión difícil\". La ejecución de contratos inteligentes podría ralentizar la velocidad de DEX y añadir incertidumbre a las transacciones de activos. Incluso si tal compromiso\
    fuese tolerable, la solución más obvia sería implementar una máquina virtual\
    basada en el protocolo de consenso subyacente Tendermint y la interfaz RPC principal de\
    Binance Chain. Sin embargo, esta solución aumentaría el costo de aprendizaje para la comunidad existente de dApps y no sería una opción popular.\
    Aquí proponemos el concepto de una blockchain paralela de Binance Chain para mantener altos\
    rendimientos en la DEX nativa mientras ofrece amigablemente soporte para funciones de contratos inteligentes.',
    
    // Laboratorio de Innovación
    labTxt: 'Laboratorio Bitisan • Compartiendo Resultados Innovadores',
    labTxt2: 'Un laboratorio abierto donde inversores, proyectos y exchanges comparten beneficios mutuos',
    labTab1: 'Todo',
    labTab2: 'Próximamente',
    labTab3: 'En Progreso',
    labTab4: 'Distribución en Curso',
    labTab5: 'Completado',
    tokendistribute: "Distribución de Tokens en Curso",
    activitytype: "Tipo de Suscripción",
    labStart: 'Fecha de Inicio',
    labEnd: 'Fecha de Finalización',
    labStartPlaceholder: 'Seleccionar Fecha de Inicio',
    labDetail: 'Ver Detalles',
    labSpeen: "Progreso",
    labNumber: "Cantidad Total de Actividad",
    labType1: 'Lanzamiento por primera vez (Compra Rápida)',
    labType2: 'Lanzamiento por primera vez (Reparto Equitativo)',
    labType3: 'Partición de Posiciones',
    labType4: 'Suscripción Libre',
    labType5: 'Compra de Máquina de Minería en la Nube',
    labType6: 'Libertad después de Bloqueo',
    baseinfo: 'Información Básica',
    ruledetail: "Descripción del Tipo",
    releaseType: "Tipo de Liberación",
    releaseType0: "Liberación Igualitaria",
    releaseType1: "Liberación Proporcional",
    lock: "Bloqueo por Plazo",
    releaseDetail: "Detalles de Liberación",
    lockday: "Días",
    lockweek: "Semanas",
    lockmonth: "Meses",
    lockyear: "Años",
    releasePercent: "Porcentaje de Liberación",
    lockFee: "Umbral de Bloqueo",
    releaseTimes: "Número de Multiplicadores de Liberación",
    times: "Veces",
    publishprice: "Precio de Suscripción",
    holdcoin: "Moneda Requerida en Posición",
    limittimes: "Número Máximo de Compras por Persona",
    limitamount: "Cantidad Máxima de Compra por Persona",
    unlimited: "Ilimitado",
    leveloneCount: "Número de Amigos de Primer Nivel Requeridos",
    alreadyamount: "Cantidad Comprada",
    leftamount: "Cantidad Restante",
    myalreadyholdamount: "Mi Participación en Posición",
    alreadyholdamount: "Participación Total en Posición",
    currentdivided: "Mi participación actual puede repartir aproximadamente",
    holdtips: "Para participar en actividades de tipo «Partición de Posiciones», es necesario bloquear las posiciones hasta el final de la actividad",
    inputholdamount: "Ingrese Cantidad para Bloquear",
    pleaseinputamount: "Ingrese Cantidad para Canjear",
    pleaseinputholdamount: "Ingrese Cantidad de Posición para Bloquear",
    inputamount: "Ingrese Cantidad para Cambiar",
    inputminingamount: "Ingrese Cantidad de Máquinas Mineras para Comprar",
    attend: "Participar Ahora",
    attention: "Atención",
    attentiontxt1: "1. Una vez enviado el registro de participación en actividades de tipo «Partición de Posiciones», «Suscripción Libre», «Compra de Máquina de Minería en la Nube» o «Bloqueo», los activos serán congelados y desbloqueados al finalizar la actividad o liberados según corresponda.",
    attentiontxt2: "2. Una vez participado en actividades de tipo «Partición de Posiciones», «Suscripción Libre», «Compra de Máquina de Minería en la Nube» o «Bloqueo», el usuario no podrá revocar su solicitud de participación.",
    attentiontxt3: "3. En caso de que el proyecto cancele la actividad debido a razones imprevistas o causas de fuerza mayor, los activos congelados serán devueltos por el mismo camino.",
    attentiontxt4: "* Los términos finales de la presente actividad quedan sujetos a la interpretación oficial de Bitisan (WWW.BITISAN.NET).",
    
    // Cuenta de Contrato Monetario
    transfer: 'Transferencia de Fondos',
    placeholder: 'Buscar Moneda',
    bith1: 'Trading de Contratos',
    bith2: 'Equidad de la Cuenta',
    bith3: 'Ganancias No Realizadas',
    bith4: 'Margen Disponible',
    bith5: 'Margen Utilizado',
    bith6: 'Margen Congelado',
    bith7: 'Cuenta de Contrato',
    
    // Cuenta de Spot
    btn1: 'Depósito',
    btn2: 'Retirada',
    btn3: 'Transferencia',
    btn4: 'Cargo de moneda',
    btn5: 'Retiro de moneda',
    totalAssets: 'Total de activos convertido',
    th1: 'Nombre de la criptomoneda',
    th2: 'Activos disponibles',
    th3: 'Activos congelados',
    th4: 'Activos pendientes de liberación',
    th5: 'Acciones',
    
    // Depósitos
    czTxt: "Depósito de criptomonedas ",
    czTxt2: "Criptomoneda ",
    czTxt3: "Red de transferencia ",
    czTxt4: "Saldo disponible ",
    czTxt5: "Dirección de retiro ",
    czTxt6: "Cantidad de retiro ",
    czTxt7: "Cantidad recibida ",
    czTxt8: "Tarifa ",
    czTxt9: "Consejos ",
    czTxt10: "Monto mínimo de depósito: ",
    czTxt10_1: "Los depósitos inferiores al monto mínimo no se contabilizarán. ",
    czTxt11: "No realice depósitos de ningún activo distinto a la criptomoneda en la dirección anterior; en caso contrario, dichos activos no podrán ser recuperados. ",
    czTxt12: "Una vez que efectúe el depósito en la dirección anterior, será confirmado por toda la red de nodos de la blockchain; generalmente, después de 3 confirmaciones de la red principal de la blockchain, los fondos estarán disponibles. ",
    czTxt13: "Su dirección de depósito no cambiará frecuentemente y puede reutilizarla para múltiples depósitos; si hubiera algún cambio, intentaremos notificarlo mediante un anuncio en el sitio web o un correo electrónico. ",
    czTxt14: "Asegúrese de confirmar la seguridad de su computadora y navegador para evitar la alteración o filtración de información. ",
    czTxt15: "Historial de depósitos ",
    czTxt16: "Para garantizar la seguridad de sus fondos, cuando cambie su estrategia de seguridad de cuenta, modifique su contraseña o utilice una nueva dirección de retiro, revisaremos manualmente las solicitudes de retiro, por favor espere pacientemente la comunicación telefónica o por correo electrónico de nuestro personal. ",
    czTxt17: "La cantidad mínima de retiro es: ",
    czth1: 'Hora de retirada',
    czth2: 'Desde',
    czth3: 'Hasta',
    czth4: 'Cantidad de conversión rápida',
    czth5: 'Ratio de conversión',
    czth6: 'Cantidad convertida',
    placeholder2: 'Ingrese',
    placeholder3: 'Seleccione',
    czEmpty: 'Sin registros',
    logintip: '¡Inicie sesión primero!',
    cztip: 'Aún no ha solicitado ninguna dirección de depósito, haga clic en el botón para obtener una dirección de depósito',
    czget: 'Obtener dirección de depósito',
    czplaceholder: 'Seleccione un protocolo',
    
    // Cuenta de Contrato Al Segundo
    mAccount: "Cuenta de Contrato Al Segundo ",
    
    // Historial de cartera
    btn6: 'Buscar',
    wtTxt: 'Intervalo de tiempo',
    wtTxt2: 'Tipo de operación',
    wtth1: 'Hora de la operación',
    wtth2: 'Tipo',
    wtth3: 'Criptomoneda',
    wtth4: 'Cantidad',
    wtth5: 'Tarifa debida',
    wtth6: 'Tarifa deducida',
    wtth7: 'Tarifa efectivamente pagada',
    wtth8: 'Estado',
    
    // Seguridad de la cuenta
    nav1: 'Seguridad de la cuenta',
    nav2: 'Mis invitaciones',
    nav3: 'Mis tarjetas de promoción',
    acTxt: 'Nivel de seguridad',
    acbtn1: 'No verificado',
    acbtn2: 'Vincular',
    acbtn3: 'Modificar',
    acbtn4: 'Verificado',
    acbtn5: 'Vinculado',
    acbtn6: 'Configurar',
    acbtn7: 'En revisión',
    acbtn8: 'Reintentar',
    acTxt2: 'Verificación de nombre real',
    acTxt3: 'Correo electrónico',
    acTxt4: 'Teléfono móvil',
    acTxt5: 'Contraseña de inicio de sesión',
    acTxt6: 'Contraseña de fondos',
    acTxt7: 'Verificador de Google',
    acTxt8: 'Establecer contraseña de fondos',
    acTxt9: 'Cambiar contraseña de fondos',
    acTxt10: 'Recuperar contraseña de fondos',
    acDesc2: 'Para asegurar la seguridad de su cuenta, complete la verificación de nombre real antes de realizar operaciones comerciales. ',
    acDesc3: 'Vincular correo electrónico',
    acDesc4: 'Vincular teléfono móvil',
    acDesc5: 'Se utiliza al iniciar sesión en la plataforma',
    acDesc6: 'Cuando haya cambios en los fondos de la cuenta, se requiere verificar la contraseña de fondos',
    acDesc7: 'Vincule el Verificador de Google',
    acDesc8: 'Ya vinculado Verificador de Google',
    acDesc9: 'Desvincular',
    acTit: 'Vinculación de teléfono móvil',
    acTit2: 'Vinculación de correo electrónico',
    acTit3: 'Vinculación de Google',
    acTit4: 'Desvinculación de Google',
    mailtip: 'Ingrese su correo electrónico',
    emailerr2: 'Formato de correo electrónico incorrecto, vuelva a ingresar',
    agreementtip: 'Lea y acepte los términos del acuerdo de usuario',
    acverify: "Revisión no aprobada ",
    again: "Inténtelo nuevamente ",
    verifiedmsg: 'Primero realice la verificación de nombre real',
    
    // Cambio de contraseña de inicio de sesión
    chTxt: 'Cambiar contraseña de inicio de sesión',
    chTxt2: 'Asociación por escaneo',
    chTxt3: 'Ingrese el código de verificación',
    chTxt4: 'Contraseña antigua de fondos',
    chTxt5: 'Nueva contraseña de fondos',
    chTxt6: 'Confirmar nueva contraseña',
    chTxt7: 'Olvidé mi contraseña',
    chTxt8: 'Cambiar verificación por teléfono',
    chTxt9: 'Contraseña de inicio de sesión anterior',
    chTxt10: 'Nueva contraseña de inicio de sesión',
    chTxt11: 'Código de verificación de correo electrónico',
    chTxt12: 'Código de verificación de teléfono',
    telerr: 'Número de teléfono incorrecto',
    emailerr: 'Correo electrónico incorrecto',
    codeerr: 'Código de verificación incorrecto',
    save: 'Guardar',
    chtip: 'Ingrese su contraseña anterior',
    chtip2: 'Ingrese una nueva contraseña de inicio de sesión con al menos 6 caracteres',
    chtip3: 'La nueva contraseña de inicio de sesión no coincide',
    chtip4: 'Ingrese la contraseña de fondos correcta',
    chtip5: 'La contraseña debe tener al menos 6 caracteres',
    chtip6: 'Ingrese una contraseña con al menos 6 caracteres',
    chtip7: 'Las contraseñas no coinciden',
    chtip8: 'Ingrese la contraseña de fondos',
    chtip9: 'Ingrese su número de teléfono',
    chtip10: 'Ingrese su contraseña de inicio de sesión',
    realnametip: 'Ingrese su nombre real',
    idcardtip: 'Ingrese su número de identificación',
    // Formas de pago
    clTxt: 'Configure su método de pago, asegúrese de usar una cuenta a nombre propio y verificada',
    clTxt2: 'Agregar método de pago',
    edit: 'Editar',
    delete: 'Eliminar',
    name: 'Nombre',
    clTxt3: 'Número de ID',
    clTxt4: 'Número IBAN',
    clTxt5: 'Todos los métodos de pago',
    clLink1: 'Nombre de cuenta',
    clLink2: 'Editar método de pago',
    clLink3: 'Detalle de pago (opcional)',
    clplaceholder1: 'Ingrese su nombre completo',
    clplaceholder2: 'Ingrese su contraseña de cuenta bancaria',
    clplaceholder3: 'Ingrese los detalles de pago',
    cltip: 'Nota importante',
    cltip2: 'Asegúrese de agregar su número de tarjeta bancaria para realizar pagos inmediatos. No incluya detalles de otras cuentas bancarias u opciones de pago. Debe agregar la información de pago/recepción específica del banco seleccionado.',
    cltip3: 'Nota: Cuando venda activos digitales, el método de pago elegido se mostrará al comprador. Confirme que la información ingresada es precisa. Utilice una cuenta de recepción que coincida con su verificación KYC en la plataforma Binance.',
    clTxt6: 'Opcional',
    upload: 'Subir',
    uploadTip: '(Soporta formatos JPG/JPEG/PNG/BMP, menor a 1MB)',
    // Mis códigos promocionales
    tgbtn: 'Canjear código de bonificación',
    tgth1: 'Nombre de la tarjeta',
    tgth2: 'Código de canje',
    tgth3: 'Moneda en la tarjeta',
    tgth4: 'Valor facial de la tarjeta',
    tgth5: 'Cantidad total',
    tgth6: 'Cantidad canjeadas',
    tgth7: 'Fecha de obtención',
    tgbtn2: 'Material de promoción',
    tgbtn3: 'Canjear ahora',
    tgplaceholder: 'Ingrese el código de canje',
    exchangesuccess : "¡Canje exitoso! Vea su saldo en la sección de administración de activos.",
    
    // Mis invitaciones
    inth1: 'Usuarios invitados',
    inth2: 'Fecha de registro',
    inth3: 'Estado de verificación de nombre real',
    
    // Mis anuncios
    adTxt: 'Mis anuncios',
    adTxt2: 'Cuando el volumen de comercio mínimo en un anuncio, sumado a las tasas, sea mayor que la cantidad restante, el anuncio se quitará automáticamente',
    adbtn: 'Limpiar condiciones',
    adbtn2: 'Publicar',
    adbtn3: 'Quitar publicación',
    adth1: 'Número de anuncio ',
    adth2: 'Tipo de anuncio',
    adth3: 'Límite de orden',
    adth4: 'Cantidad restante',
    adtip: '¿Está seguro de eliminar?',
    num_text1: 'Ingrese la cantidad deseada',
    num_text2: 'de',
    
    // Órdenes actuales
    wTxt: 'Spot',
    wTxt2: 'Órdenes actuales',
    wTxt3: 'Par de trading',
    wTxt4: 'Dirección del trade',
    wth1: 'Tiempo',
    wth2: 'Dirección',
    wth3: 'Precio',
    wth4: 'Cantidad ejecutada',
    wth5: 'Cantidad monetaria ejecutada',
    wth6: 'Tipo de orden',
    wth7: 'Precio de activación',
    wth8: 'Precio de ejecución',
    wth9: 'Cantidad de la orden',
    wth10: 'Resultado de liquidación',
    wth11: 'Estado de la orden',
    wth12: 'Abrir/Cerrar posición',
    wth13: 'Mantenimiento de margen',
    wbtn: 'Cancelar orden',
    wtip: 'Advertencia de cancelación',
    wtiptxt: '¿Está seguro de cancelar la orden?',
    
    //赚取
    zbplaceholder: 'Ingrese el número de pedido para buscar',
    zbtab1: 'Plan de inversión periódica',
    zbtab2: 'Registro de inversión periódica',
    zbth1: 'Período de inversión',
    zbth2: 'Activar/Desactivar',
    zbth3: 'Cantidad invertida acumulada',
    zbth4: 'Cantidad en posesión',
    zbth5: 'Costo promedio',
    zbth6: 'Pérdidas no realizadas',
    zbth7: 'Fecha próxima de inversión',
    zbth8: 'Fecha de creación del plan',
    // Tareas actuales-1
    etTxt: 'Centro de anuncios',
    etTxt2: 'Compartir vía escaneo',
    etmenu1: 'Criptomonedas',
    etmenu2: 'Actualización de servidor Bitisan',
    // Tareas actuales-3
    et2menu1: 'Introducción a RX. Tron',
    et2menu2: '¿Es Bitcoin un esquema Ponzi?',
    // Tareas históricas
    hisTxt: 'Tareas históricas',
    // Mis pedidos
    orTab1: 'No pagado',
    orTab2: 'Pagado',
    orTab3: 'Completado',
    orTab4: 'Cancelado',
    orTab5: 'En disputa',
    orth1: 'Número de pedido',
    orth2: 'Moneda de la transacción',
    orth3: 'Tipo de transacción',
    orth4: 'Objeto de la transacción',
    orth5: 'Cantidad',
    
    // Opciones de contrato
    qcTab1: 'Resultados anteriores',
    qcTab2: 'Datos en tiempo real',
    qcTxt: 'Período',
    qcTxt2: 'Serie',
    qcTxt3: 'Hora de apertura',
    qcTxt4: 'Hora de cierre',
    qcTxt5: 'Precio de apertura',
    qcTxt6: 'Precio de cierre',
    qcTxt7: 'Total comprado en alza',
    qcTxt8: 'Total comprado a la baja',
    qcTxt9: 'Mi apertura',
    qcTxt10: 'Progreso de predicción',
    qcTxt11: 'Precio de apertura',
    qcTxt12: 'Precio de cierre',
    qcTxt13: 'Período de predicción actual',
    qcbtn1: 'Comprar al alza',
    qcbtn2: 'Comprar a la baja',
    qcth1: 'Número de período',
    qcth2: 'Cantidad de apertura',
    qcth3: 'Dirección de predicción',
    qcth4: 'Resultado de predicción',
    qcth5: 'Cantidad de premio',
    qcth6: 'Comisión de apertura',
    qcth7: 'Impuesto de minería',
    
    // Información detallada de la moneda
    Internet: 'Error de conexión a Internet',
    mplaceholder: 'Ingrese el nombre de la moneda para buscar',
    mTab: 'Monedas seleccionadas',
    mth: 'Favorito',
    mth1: 'Precio actual',
    mth2: 'Variación de 24 horas',
    mth3: 'Cantidad de transacciones',
    mth4: 'Tasa de interés',
    mth5: 'Apertura',
    mth6: 'Alto',
    mth7: 'Bajo',
    mth8: 'Cierre',
    indexth: 'Cantidad total de comercio',
    indexbtn: 'Detalles',
    indexbtn1: 'Precio',
    indexbtn2: 'Cantidad',
    indexbtn3: 'Volumen de comercio',
    indexbtn4: 'Hora',
    echat1: 'Gráfico de barras',
    echat2: 'Gráfico de profundidad',
    mTab2: 'Posiciones actuales',
    mTab3: 'Historial de órdenes',
    mth3: 'Garantía de compensación',
    mth4: 'Precio de apertura',
    mth5: 'Período',
    mth6: 'Precio de cierre',
    mth7: 'Beneficios',
    mTxt9: 'Modalidad de trading',
    mTxt10: 'Hora de apertura',
    mTxt11: 'Ratio de rentabilidad',
    mTxt12: 'Saldo',
    mTxt13: 'Cantidad comprada',
    mTxt14: 'Medio',
    
    // Crear anuncio
    cadTxt: 'Crear un anuncio de transacción',
    cadTxt2: 'Si realizas transacciones con frecuencia, puedes crear tu propio anuncio.',
    cadTxt2_1: 'Si solo operas ocasionalmente, sugerimos que busques directamente',
    cadTxt2_2: 'Crear un anuncio es gratuito.',
    cadLink2: 'Anuncios de transacción',
    cadTxt3: 'Si deseas editar tus anuncios creados, consulta',
    cadLink3: 'Mis anuncios',
    cadTxt4: 'Precio de participación en el mercado',
    cadTxt5: 'El precio de prima indica el porcentaje sobre el precio actual del mercado para vender',
    cadTxt6: 'Fórmula de cálculo',
    cadTxt7: '【Sugerencia】 Puedes ir a la configuración de tu centro de usuario para vincular/añadir métodos de pago',
    cadTxt8: 'El monto máximo de la transacción no puede exceder tu saldo total disponible de 0 CNYI',
    cadTxt9: 'Al activar esto, cuando otros usuarios hagan una oferta a través de este anuncio, el sistema automáticamente enviará la respuesta automática seleccionada por ti al comprador.',
    cadTxt10: 'La prima indica el porcentaje sobre el precio actual del mercado para comprar',
    cadLabel1: 'Quiero:',
    cadLabel2: 'Moneda de intercambio:',
    cadLabel3: 'País:',
    cadLabel4: 'Divisa:',
    cadLabel5: 'Activar precio fijo',
    cadLabel6: 'Prima:',
    cadLabel7: 'Precio de transacción',
    cadLabel8: 'Cantidad a vender:',
    cadLabel9: 'Método de pago:',
    cadLabel10: 'Monto mínimo de la transacción:',
    cadLabel11: 'Monto máximo de la transacción:',
    cadLabel12: 'Activar respuesta automática',
    cadLabel13: 'Contraseña de fondos:',
    cadLabel14: 'Información adicional:',
    cadLabel15: 'Precio fijo:',
    cadLabel16: 'Respuesta automática:',
    cadLabel17: 'Cantidad a comprar:',
    radio: 'Venta en línea',
    radio2: 'Compra en línea',
    cadplaceholder: 'Configura tu prima',
    cadplaceholder2: 'Ingresa la cantidad que deseas vender',
    cadplaceholder3: 'Ingresa tu monto mínimo de transacción',
    cadplaceholder4: 'Ingresa tu monto máximo de transacción',
    cadplaceholder5: 'Puedes incluir requisitos especiales en la información adicional, como condiciones para el comprador, horario en línea, etc.',
    cadplaceholder6: 'Ingresa tu precio de transacción',
    cadplaceholder7: 'Mensaje que se enviará automáticamente al comprador después de recibir el pedido, como forma de pago, cuenta de recepción, etc.',
    cadplaceholder8: 'Ingresa la cantidad que deseas comprar',
    caderr1: 'Ingresa tu monto mínimo de transacción',
    caderr2: 'El monto mínimo debe ser mayor o igual a 100!',
    caderr3: 'El monto mínimo debe ser menor al monto máximo',
    caderr4: 'Ingresa tu monto máximo de transacción!',
    caderr5: 'Ingresa un número entero',
    caderr6: 'El monto máximo debe ser mayor al monto mínimo',
    caderr7: 'El monto máximo no puede superar tu saldo total disponible',
    caderr8 : 'Selecciona un método de transacción',
    submit: 'Enviar',
    cadnew: 'Al habilitarlo, el precio de tu moneda no variará con las fluctuaciones del mercado, manteniendo el mismo precio.',
    
    // Cambio rápido
    dhTxt: 'Intercambio instantáneo · Disfruta de tus ganancias',
    dhTxt2: 'Límite',
    dhTxt3: 'Venta',
    dhTxt4: 'Compra',
    dhTxt5: 'Máximo',
    dhTxt6: 'Ingrese cantidad',
    dhplaceholder: 'Saldo disponible en cartera de spot',
    dhSuccess: '¡Enhorabuena! ¡Tu cambio ha sido exitoso!',
    
    // Trading de Fiat
    crTxt: 'Trading de Fiat',
    crTxt2: 'Compras y ventas cómodas, seguras y rápidas de criptomonedas',
    crTxt3: 'Ajuste dinámico',
    crTxt4: 'Sin comisiones',
    crTxt5: 'Liquidación inmediata',
    crTxt6: 'Garantía de la plataforma',
    crTxt7: 'Basado en las fluctuaciones del mercado en tiempo real',
    crTxt8: 'Lo que ves es lo que obtienes; sin comisiones adicionales de la plataforma en el precio de compra/venta',
    crTxt9: 'Incorporación de comerciantes de servicios de la plataforma, coincidencia inteligente de órdenes y liquidación inmediata, sin esperas de emparejamiento',
    crTxt10: 'Comerciantes certificados por la plataforma, garantía de seguridad, atención al cliente las 24 horas para proteger tus transacciones',
    crth1: 'Comerciante',
    crth2: 'Número de transacciones',
    crth3: 'Métodos de pago',
    crth4: 'Límites',
    crth5: 'Precio unitario',
    crbtn: 'Comprar',
    crbtn2: 'Convertirte en comerciante',
    
    // Trading de Fiat 1
    cr1Txt: '¡Enhorabuena! Tu verificación de comerciante ha sido aprobada',
    cr1Txt2: 'Preparar documentos',
    cr1Txt3: 'Enviar revisión',
    cr1Txt4: 'Verificado',
    cr1Txt5: 'Obtienes los siguientes permisos',
    cr1Txt6: 'Stand exclusivo',
    cr1Txt7: 'Servicio uno-a-uno',
    cr1Txt8: 'Los comerciantes tienen un stand publicitario exclusivo para aumentar la tasa de éxito en las transacciones',
    cr1Txt9: 'Comisiones más bajas',
    cr1btn: 'Publicar anuncio',
    cr1btn2: 'Solicitar reembolso',
    
    // Inversión periódica
    reTxt: 'Inversión Periódica de Bitisan · Solución de inversión integral',
    reTxt2: 'Gana ingresos ahora mismo, sencillo y seguro',
    reTab: 'Inversión periódica',
    reTab2: 'Inversión recurrente',
    reTxt3: 'Periódica',
    reTxt4: 'Personalización flexible, potencial de rendimiento más alto',
    reTxt6: 'Recurrente',
    reTxt7: 'Comienza a usar la inversión recurrente y aumenta tus activos',
    reTxt8: 'Despliega todos los 18 productos periódicos',
    reth1: 'Tasa de rendimiento anualizada',
    reth2: 'Plazo (días)',
    reth3: 'Límites de transacción ',
    reth4: 'Producto',
    reth5: 'Rendimiento histórico',
    rebtn: 'Crear plan',
    redTxt: 'Crear un',
    redTxt2: 'Plan de inversión recurrente',
    redTxt3: 'Ingrese cantidad de inversión',
    redTxt4: 'Mi saldo disponible es',
    redTxt5: 'Disponible',
    redTxt6: 'Ciclo de repetición',
    redTxt7: 'Diariamente',
    redTxt8: 'Semanalmente',
    redTxt9: 'Mensualmente',
    redTxt10: 'Hora local',
    redTxt11: 'Tu primer ciclo de inversión automática comenzará el',
    redTxt12: 'Comienzo',
    confirm: 'Confirmar',
    confirm2: 'Aceptar',
    cancel: 'Cancelar',
    
    redongoing: "En progreso",
    redcompleted: "Completado",
    minNumErr: 'Menor que la cantidad mínima permitida para invertir',
    minNumErr2: 'La cantidad mínima de inversión no puede ser 0',
    buyamounttip: 'Por favor, ingrese la cantidad a comprar',
    pricetipwarning: 'El monto de la transacción no puede ser superior a',
    buyamounttipwarning: 'La cantidad de compra no puede ser mayor a',
    sellamounttip: 'Por favor, ingrese la cantidad a vender',
    sellamounttipwarning: 'La cantidad de venta no puede ser mayor a',
    sellpricetip: 'Por favor, ingrese el precio de venta',
    sellpricetipwarning: 'El precio de venta no puede ser mayor a',
    sellmore: 'Más vendido',
    sellunit: 'unidades',
    loginFirst: "Por favor, inicie sesión primero",
    betsuccess: "¡Felicidades! ¡Tu apuesta ha sido realizada con éxito!",
    selectAmount: "Por favor, seleccione una cantidad para apostar",
    balancenotenough: "!Saldo insuficiente",
    balancenotenough2: "!Saldo insuficiente",
    minlimitamountfailed: "¡La cantidad a canjear no puede ser inferior al monto mínimo permitido!",
    maxlimitamountfailed: "¡La cantidad a canjear no puede ser mayor al límite de compra máximo!",
    limittimesfailed: "¡Has superado el número máximo de participaciones permitidas!",
    commitfailed: "¡Falló el envío de la participación!",
    
    // Trading de现货
    trplaceholder: 'Consulta',
    trplaceholder2: 'Precio actual',
    trplaceholder3: 'Comprar al mejor precio del mercado',
    trplaceholder4: 'Vender al mejor precio del mercado',
    triggleplaceholder: 'El precio predeterminado de la orden es el precio de mercado',
    triggerpriceshouldbigger: 'El precio de activación debe ser mayor a 0',
    marginModeSuccessTip: 'Modificación exitosa del modo de cuenta de contrato',
    noenoughbalance: 'Saldo de margen insuficiente en la cuenta',
    pleaseinputopenvolume: 'La cantidad de apertura debe ser mayor a 0',
    pleaseinputcorrectopenvolume: 'Por favor ingrese una cantidad de apertura válida',
    pleaseinputrightentrustprice: 'Por favor ingrese un precio de orden válido',
    noenoughposition: 'Posiciones disponibles insuficientes para cubrir',
    pleaseinputclosevolume: 'La cantidad de cierre debe ser mayor a 0',
    pleaseinputcorrectclosevolume: 'Por favor ingrese una cantidad de cierre válida',
    option1: 'Gráfico diario',
    option2: 'Gráfico semanal',
    option3: 'Gráfico mensual',
    trTxt: 'Volumen de negociación',
    trTxt2: 'Precio de mercado',
    trTxt3: 'Cantidad de venta',
    trSuccess: 'Envío exitoso',
    
    // Monedas U
    udTxt: 'Modo de margen',
    udTxt2: 'Ajustar múltiplo de contrato',
    udTxt3: 'Apalancamiento',
    udTxt4: 'Perpetuo',
    udTxt4_1: 'Perpetuo',
    udTxt5: 'Cantidad transferible',
    udTxt6: 'Cantidad de transferencia',
    udTxt7: 'Transferir todo',
    udTxt8: 'Cuenta de contrato perpetuo',
    ubtn: 'Full',
    ubtn2: 'Margen por posición',
    uplaceholder: 'Cuenta de contrato perpetuo',
    uradio1: 'Tiempo real',
    uradio2: '15 minutos',
    uradio3: '1 hora',
    uradio4: '4 horas',
    uradio5: '1 día',
    uth1: 'Multiplo de contrato',
    uth2: 'Beneficios',
    uth3: 'Tasa de retorno',
    uth4: 'Posición abierta',
    uth5: 'Cantidad disponible para cierre',
    uth6: 'Promedio de entrada',
    uth7: 'Precio de liquidación forzosa',
    uth8: 'Margen actual',
    uth9: 'Ratio de margen',
    zhang: 'Lot',
    or: 'o',
    ureg: 'Regístrate ahora',
    uTxt8: 'Realizar trading',
    uTab: 'Abrir posición',
    uTab2: 'Cerrar posición',
    uTxt9: 'Abierto largo permitido',
    uTxt10: 'Abierto corto permitido',
    uTxt11: 'Precio de orden',
    uplaceholder2: 'Para cerrar en el mejor precio del mercado',
    uTxt12: 'Abrir posición larga',
    uTxt13: 'Abrir posición corta',
    closeup: 'Cierre de posición corta',
    closedown: 'Cierre de posición larga',
    uTab3: 'Stop-loss/Take-profit',
    uTab3_yprice: 'Precio objetivo de beneficios',
    uTab3_sprice: 'Precio objetivo de pérdida',
    uTab3Txt: 'Stop-loss/Take-profit',
    uTxt14: 'Mi cuenta de contrato',
    ulabel1: 'Modo de cuenta',
    ulabel2: 'Beneficios totales de cuenta',
    ulabel3: 'Margen en posiciones',
    ulabel4: 'Uso de capital',
    ulabel5: 'Largo',
    ulabel6: 'Corto',
    uTxt15: 'Posición larga',
    uTxt16: 'Posición corta',
    total: 'Total',
    limited_price: 'Orden de precio límite',
    market_price: 'Orden de mercado',
    spot_price: 'Stop-loss/Take-profit',
    ent_status1: 'En proceso',
    ent_status2: 'Cancelado',
    ent_status3: 'Error en la orden',
    ent_status4: 'Orden enviada con éxito',
    ent_statusblast: 'Liquidación forzosa',
    uTxt17: 'Cantidad de cierre disponible para largo',
    uTxt18: 'Cantidad de cierre disponible para corto',
    prev: 'Anterior',
    next: 'Siguiente',
    
    // Promotor asociado
    prtxt: 'Mi enlace de invitación',
    copy: 'Copiar',
    prtxt2: 'Mis amigos de nivel 1',
    prtxt3: 'Mis amigos de nivel 2',
    prtxt4: 'Ingresos de comisión (convertidos a USDT)',
    prtxt5: 'Premios adicionales',
    prtxt6: 'Nivel de socio',
    prtit: 'Detalles de las reglas',
    prdesc: 'El programa de promotores asociados de Bitisan ofrece la mayor tasa de comisión y duración de retención de comisiones en toda la red (hasta comisión vitalicia), permitiendo a quienes invierten un esfuerzo apropiado convertirse en verdaderos "socios" de la plataforma y disfrutar de los beneficios de crecer junto con Bitisan. A continuación, se especifican las reglas detalladas:',
    prli1: '1. Para difundir la idea de activos digitales y ampliar la base de usuarios, Bitisan lanza el programa de [Promotores Asociados], el cual permanece activo indefinidamente.',
    prli2: '2. La invitación a amigos se divide en dos niveles: si A invita a B, entonces B es un amigo de nivel 1 de A. Si B invita a C, C es un amigo de nivel 1 de B y también un amigo de nivel 2 de A.',
    prli3: '3. Para que un amigo se convierta en amigo de nivel 1, debe registrarse mediante el enlace proporcionado por el promotor o manualmente ingresar el código de invitación del promotor.',
    prli4: '4. Una vez que el invitado complete la verificación de identidad mediante el código de invitación, las recompensas de incentivos se activarán.',
    prli5: '5. El tiempo de retención de comisión comienza desde la fecha en que el invitado completa la verificación de identidad y varía según el nivel alcanzado.',
    prli6: '6. Los activos de la comisión provienen de las tarifas de transacción de criptomonedas entre amigos de nivel inferior, y la divisa de la comisión es una de las tres criptomonedas básicas del mercado: USDT, BTC y ETH.',
    prli7: '7. La proporción de comisión y el tiempo de retención están correlacionados positivamente con el número de amigos de nivel 1 invitados; cuantos más amigos invite, mayor será la tasa de comisión.',
    prli8: '8. Los clasificados en los top 10 o top 100 de la tabla de comisiones de promotores pueden recibir premios adicionales otorgados por Bitisan de manera irregular.',
    prli9: '9. En caso de conflicto entre otras actividades y el programa [Promotores Asociados], se decidirá si realizar cambios temporales mediante consenso y se solicita comprensión de los socios promotores.',
    prli10: '10. Los socios de nivel L4 (Cónsul), L5 (Gobernador) y L6 (Jefe de Distrito) reciben dividendos del 5%, 10% y 15%, respectivamente, del total de comisiones anuales.',
    prli11: '11. La última interpretación de esta actividad le corresponde a Bitisan.',
    prth1: 'Nivel',
    prth2: 'Número de amigos de nivel 1',
    prth3: 'Proporción de comisión / Tiempo de retención para amigos de nivel 1',
    prth4: 'Proporción de comisión / Tiempo de retención para amigos de nivel 2',
    prth5: 'Dividendos de socio',
    prlev1: 'Funcionario Local',
    prlev2: 'Alcalde',
    prlev3: 'Prefecto',
    prlev4: 'Cónsul',
    prlev5: 'Gobernador',
    prlev6: 'Jefe de Distrito',
    prtd1: 'meses',
    prtd2: 'Retención vitalicia',
    prtit2: 'Ejemplo',
    prtxt7: 'Si el usuario Xiao Yan invita a 100 amigos de nivel 1, quienes a su vez invitan a 5 amigos cada uno de nivel 2, Xiao Yan tendrá 100 amigos de nivel 1 y 500 amigos de nivel 2. Si cada persona realiza en promedio transacciones por 3000 yuanes diarios, el ingreso mensual de Xiao Yan sería: (100*3000*0,001*30% + 500*3000*0,001*10%) * 30 = 7200 yuanes/mes.',
    prtxt8: 'Si Xiao Yan invita a 1000 amigos de nivel 1 y cada uno de ellos invita a 5 amigos de nivel 2, Xiao Yan tendría 1000 amigos de nivel 1 y 5000 amigos de nivel 2. Si cada persona hace transacciones de 3000 yuanes al día, el ingreso mensual de Xiao Yan sería: (1000*3000*0,001*50% + 5000*3000*0,001*20%) * 30 = 135000 yuanes/mes.',
    prtit3: 'Caja de herramientas de promoción',
    prtit4: 'Top 20 de comisiones de promoción (general)',
    prtit5: 'Top 20 de invitaciones (general)',
    prtxt9: 'Fecha límite de estadísticas:',
    prth6: 'Ranking',
    prth7: 'Miembro',
    prth8: 'Número de invitaciones',
    prth9: 'Comisión (convertida a USDT)',
    prth10: 'Premios adicionales',
    prtip1: 'Los datos de comisión no se actualizan en tiempo real; el sistema los actualiza cada 24 horas.',
    prtip2: 'El número de invitaciones se refiere al número de amigos de nivel 1; esta tabla muestra el ranking por el total de comisiones generadas.',
    prtip3: 'El número de invitaciones se refiere al número de amigos de nivel 1; esta tabla es un ranking por el número de invitaciones de nivel 1.',
    // Cargar fondos
    recth1: 'Tiempo de acreditación',
    recth2: 'Protocolo de red',
    recth3: 'Dirección de carga',
    recth4: 'Cantidad a cargar',
    recbtn: 'Cargar con código de retirada',
    recplaceholder: 'Por favor, ingrese el código de retirada',
    recstatus_0: 'Falló',
    recstatus_1: 'No acreditado aún',
    recstatus_2: 'Acreditado',
    // Retirada de criptomonedas
    wittit: 'Retirada de criptomonedas',
    wittext: 'Historial de retiradas',
    wittext2: 'Código de retirada',
    wittext3: 'Lista de códigos de retirada',
    witbtn: 'Retirar con código',
    witbtn2: 'Generar código de retirada',
    witdia: 'Verificación de seguridad',
    witdia2: 'Código de retirada generado con éxito',
    witplaceholder: 'Por favor, ingrese la cantidad a retirar',
    witerr: 'Seleccione una criptomoneda',
    addresstip: 'Ingrese la dirección',
    witstate: 'Pendiente de canje',
    witstate2: 'Error',
    witstate3: 'Canje completado',
    witstatus_1: 'En revisión',
    witstatus_2: 'En tránsito',
    witstatus_3: 'Error',
    witstatus_4: 'Éxito',
    // Tipos de operación
    type1: 'Transferencia',
    type2: 'Intercambio de monedas',
    type3: 'Compra de FIAT',
    type4: 'Venta de FIAT',
    type5: 'Recompensa por evento',
    type6: 'Recompensa por promoción',
    type7: 'Dividendo',
    type8: 'Voto',
    type9: 'Carga manual',
    type10: 'Emparejamiento',
    // ... Más tipos de operación
    // Estado del fondo de inversión
    lctit: 'Cuenta de inversión',
    lcbtn: 'Depósito en inversión',
    lcth1: 'Interés acumulado',
    day: 'Días',
    lctxt: 'Moneda de la oferta',
    lctxt2: 'Moneda aceptada',
    lctxt3: 'Cantidad mínima de inversión',
    lctxt4: 'Seleccionar período',
    year: 'Año',
    month: 'Mes',
    // Órdenes de inversión
    ldtab1: "Registro de posiciones ",
    ldtab2: "Registro de cierres ",
    ldth1: "Fecha de inicio de rendimiento ",
    ldth2: "Fecha de rescate ",
    ldth3: "Valor estimado de ganancias ",
    ldth4: "Ganancias ",
    
    // Plan de inversión regular
    attit:  "Modificar plan de inversión regular ",
    attxt:  "Cantidad de inversión ",
    attxt2:  "Ciclo de inversión ",
    atth1: 'Fecha de inversión',
    atth2: 'Cantidad invertida',
    atth3: 'Tasa de transacción',
    success: 'Éxito',
    // Gestión innovadora
    cxth1: 'Nombre',
    cxth2: 'Tipo',
    cxth3: 'Cantidad suscrita',
    cxth4: 'Moneda participada',
    cxth5: 'Unidad de suscripción',
    cxth6: 'Estado actual',
    cxth7: 'Valor de la operación',
    cxth8: 'Fecha de creación',
    cxnav1: 'Mis participaciones',
    cxnav2: 'Mis mineros',
    cxnav3: 'Mis reteniciones',
    cxtit2: 'Lista de mis mineros',
    cxtit3: 'Lista de mis reteniciones',
    cxkth1: 'Moneda generada',
    cxkth2: 'Ciclo de producción',
    cxkth3: 'Tiempo de minería',
    cxkth4: 'Tiempo minado',
    cxkth5: 'Capacidad básica',
    cxkth6: 'Capacidad real',
    cxstate1: 'No implementado',
    cxstate2: 'Implementado',
    cxstate3: 'Cancelado',
    cxstate4: 'En espera de negociación',
    cxstate5: 'Finalizado',
    cxstate6: 'Esperando liberación',
    cxstate7: 'En proceso de liberación',
    cxcth1: 'Moneda liberada',
    cxcth2: 'Total retenido',
    cxcth3: 'Ciclo total de liberación',
    cxcth4:  'Ciclo de liberación realizado',
    cxcth5: 'Liberación básica',
    cxcth6: 'Liberación real',
    more: 'Más',
    // Sobre nosotros
    abtit: 'Características de la plataforma',
    abtit2: 'Acerca de nosotros',
    abtit3: 'Contáctanos',
    abtit4: 'Comunidad Bitisan',
    abdesc: 'Bitisan es una plataforma líder mundial en tecnología de activos digitales, registrada en las Islas Caimán, con su equipo principal de operaciones ubicado en Hong Kong. Los miembros clave de Bitisan provienen de compañías líderes en internet y finanzas, siendo la mayoría profundos creyentes en Bitcoin y blockchain. Creemos firmemente que blockchain cambiará el sistema financiero tradicional y monopolístico, construyendo una estructura social más democrática y autónoma.',
    abdesc2: 'La plataforma Bitisan cuenta con una arquitectura de trading de nivel financiero profesional, posee un motor de coincidencia de memoria de alta concurrencia desarrollado internamente, y utiliza un sistema de billetera de carga fría + firma múltiple + protección avanzada contra ataques DDoS, garantizando la seguridad de los activos de los clientes.',
    abdesc3: 'Para permitir a los clientes aprovechar mejor las oportunidades de inversión, el departamento de servicio al cliente de Bitisan adopta una gestión plana y ha establecido un mecanismo de respuesta rápida. Los gerentes de atención al cliente de activos están disponibles las 24 horas del día, los 7 días de la semana para ofrecer servicios de carga y retiro de activos, asegurando que los clientes completen tales procesos en un plazo de 5 minutos.',
    abdesc4: 'Bitisan selecciona rigurosamente proyectos de calidad y brinda servicios confiables y estables de custodia de activos. Bitisan se adhiere a los principios de "honestidad, justicia, entusiasmo y apertura", trabajando incansablemente para crear una交易所 extremadamente segura, confiable, eficiente y amigable para sus usuarios.',
    abdesc5: 'Aunque los activos digitales ahora solo están limitados a un pequeño círculo, estamos convencidos de que este tipo de activos controlados completamente por individuos será popular en el futuro; juntos, hagamos esfuerzos y esperemos ese momento!',
    abdesc6: 'Servicio al cliente',
    abdesc7: 'Soporte técnico',
    abdesc8: 'Solicitud de listado de monedas',
    abdesc9: 'Reclamos y sugerencias',
    abdesc10: 'Cooperación comercial',
    abdesc11: 'WeChat',
    abdesc12: 'Weibo',
    abdesc13: 'Twitter',
    abdesc14: 'Biyong',
    abdesc15: 'Telegram',
    abdesc16: 'Agrega "bmate601" como amigo en WeChat para unirse a nuestra comunidad de beneficios',
    // Verificación de identidad real
    realtxt: 'Nombre real',
    realtxt2: 'Número de identificación',
    realtxt3: 'Foto delantera de la identificación',
    realtxt4: 'Foto trasera de la identificación',
    realtxt5: 'Foto de la identificación con firma personalizada',
    realtxt6: '1. Se admiten formatos JPG y PNG, el tamaño del archivo debe ser menor a 8M; la información del documento debe ser claramente visible sin modificaciones ni tapujos, y el número de identificación y nombre deben ser legibles.',
    realtxt7: '2. Las fotos deben ser sin sombrero y recomiéndase no tener maquillaje; el rostro del portador de la identificación debe estar nítidamente visible y mostrar completamente el brazo.',
    realtxt8: '3. Es necesario presentar simultáneamente la identificación y una nota escrita a mano (nombre + fecha de solicitud) con el texto: "Todos mis actos en este sitio web son realizados por mí mismo, estoy informado sobre los riesgos relacionados y asumo todas las consecuencias legales de esta cuenta". ',
    realbtn: 'Haga clic para cargar',
    realstate: 'No verificado',
    realstate2: 'Verificado',
    uploadimg: "Por favor cargue la foto delantera de la identificación ",
    uploadimg2: "Por favor cargue la foto trasera de la identificación ",
    uploadimg3: "Por favor cargue la foto de la identificación con la firma personalizada ",
    uploaderr: "El tamaño de la imagen subida no puede exceder ",
    save_success: '¡Guardaréxitoso!',
    save_failure: '¡Falló al guardar!',
    
    // Transferencia
    close: 'Cerrar',
    hztxt: 'Confirmar transferencia',
    // Verificación de comerciante
    submittip1: 'Para garantizar la seguridad de sus fondos, primero complete la [Verificación de identidad real]!',
    submittip2: 'Para garantizar la seguridad de sus fondos, primero vincule su teléfono!',
    submittip3: 'Para garantizar la seguridad de sus fondos, primero configure la contraseña de dinero!',
    submittip4: 'Por favor, vincule al menos un método de pago!',
    aderr1: 'Ingrese un número correcto',
    aderr2: 'El valor de sobreprecio debe estar entre 0 y 20',
    aderr3: 'El valor de sobreprecio debe estar entre 0 y 20 y no puede ser 0',
    aderr4: 'Ingrese un precio fijo correcto',
    aderr5: 'Ingrese un número correcto y la cantidad máxima de negociación no puede superar los 100 tokens',
    orderTypeNo: "No ",
    orderTypeYes: "Sí ",
    win: "Ganar ",
    lose: "Perder ",
    tied: "Empate ",
    cancelsuccess: "Cancelar orden con éxito!",
    shelves: '¡Primero desactivar el anuncio para poder eliminarlo!',
    shelves2: '¡Primero desactivar el anuncio para poder editarlo o modificarlo!',
    business: '¡Primero solicite la verificación de comerciante!',
    swappleaseselectwallet: "Seleccione primero la cuenta de entrada/salida ",
    swappleaseinputamount: "Ingrese la cantidad de transferencia ",
    clickchange: "Haga clic para cambiar la dirección de la transferencia ",
    daterange: "Seleccione el rango de fechas de búsqueda ",
    // API
    apierr: 'Ingrese la información de notas',
    apierr2: 'Ingrese la dirección IP',
    apierr3: 'Ingrese una dirección IP válida',
    apierr4: 'Ingrese el código de verificación',
    clickget: 'Haga clic para obtener',
    apisecond: 'segundos',
    apitips: "La clave solo se muestra al agregarla, guardela inmediatamente.",
    apiaccess: "Clave de acceso ",
    // Personalización
    do_favorite: 'Favorito agregado',
    cancel_favorite: 'Cancelar favorito',
    fail_favorite: 'Falla al agregar a favoritos',
    deptotal: 'Total acumulado',
    chart: 'Gráfico',
    book: 'Libro de órdenes',
    trade: 'Últimas operaciones',
    pt_title: "Tarjeta regalo de criptomonedas ",
    pt_desc: "Al canjear, automáticamente se convierte en un amigo afiliado. ¡Regala a amigos, familiares, clientes y socios! Ayuda a mejorar la promoción para los socios de promoción. ",
    pt_more: "Más herramientas próximamente, ¡espera atentamente!",
    pt_tips: "Si tienes buenas ideas, envía un correo electrónico a promotion@Bitisan.biz, ¡recibirás una recompensa si tu idea es aceptada!",
    pt_card_amount: "Monto en la tarjeta ",
    pt_card_deadline: "Periodo de validez ",
    pt_card_noend: "Ilimitado ",
    pt_card_btn: "Los socios de promoción pueden conseguir gratis 30 tarjetas (aprox. 2000 yuan) ",
    pt_card_btn_login: "Inicie sesión para reclamar gratis 30 tarjetas ",
    pt_card_rule: "Detalles de las reglas ",
    pt_card_summary: "Las tarjetas regalo de criptomonedas son una herramienta de promoción diseñada para que los socios de promoción inviten más fácilmente amigos nuevos. Cuando un usuario objetivo canjea la tarjeta, automáticamente se convierte en un amigo afiliado del socio que emitió la tarjeta. ",
    pt_card_rule1: "1. Cada usuario está limitado a recoger 30 tarjetas gratuitas. Si desea emitir más tarjetas, deberá personalizarlas y pagar por ello, pudiendo elegir el monto, logotipo y texto informativo en la tarjeta. Envíe sus requisitos de personalización por correo electrónico a ",
    pt_card_rule2: "2. Cada usuario está limitado a canjear 1 tarjeta gratuita proporcionada oficialmente por Bitisan. Aun cuando obtenga diferentes códigos de canje para tarjetas gratuitas a través de distintos usuarios, solo podrá canjear una. No hay límite en la cantidad de tarjetas personalizadas que se pueden canjear. ",
    pt_card_rule3: "3. Antes de canjear una tarjeta, si el usuario no tiene ningún 'invitador' (es decir, no ingresó un código de invitación al registrar su cuenta), automáticamente se asociará como amigo directo del emisor de la tarjeta. Por ejemplo, si A no ingresó un código de invitación al registrarse, no pertenece a ninguno de los niveles 1 o 2 de amigos de nadie, pero al canjear una tarjeta emitida por B, A se convierte automáticamente en un amigo directo de B. ",
    pt_card_rule4: "4. Para prevenir el abuso, el monto de la tarjeta no se liquidará de forma instantánea, sino después de completar la verificación de identidad real. ",
    pt_card_rule5: "5. Al recibir tarjetas gratuitas, es necesario completar la verificación de identidad real, pero no es necesario estar verificado para canjearlas, lo cual facilita que los promotores obtengan amigos afiliados más rápidamente. ",
    pt_card_rule6: "6. El derecho final de interpretación de las tarjetas regalo gratuitas pertenece a Bitisan. ",
    pt_card_day: "Días ",
    pt_card_title_tips: "Obtén un bono de promoción de 2000 yuan ",
    pt_invite_desc: "Código QR exclusivo, registra automáticamente a través del código QR para convertirse en un amigo afiliado, adecuado para compartir en grupos o foros. ",
    saveimage: "Guardar imagen ",
    imagetips: "Si no puede descargar la imagen de manera normal, utilice una herramienta de captura de pantalla para guardarla manualmente. ",
    pt_card_receivew_success: "¡Enhorabuena! ¡Ha recibido con éxito las tarjetas de promoción de socios! Visite [Centro de Mi Cuenta] -> [Centro de Cupones] para revisarlas. ",
    usepromotion: "Usar material de promoción ",
    context_title: "Código de intercambio exclusivo para socios de promoción ",
    context_title1: "Escanee para obtener más información ",
    context_title2: "Mi código de invitación ",
    context_title3: "Imagen de invitación ",
    pt_card_title: "Tarjeta de promoción de socios ",
    copysuccess: '¡Copiado con éxito!',
    copyerr: '¡Error al copiar, por favor cópielo manualmente!',
    noopening: "Actualmente no hay contratos de opciones pendientes ",
    createSuccess: "¡Creación exitosa!",
    tradewarning1: 'Máximo 2 dígitos decimales para la cantidad de compra',
    tradewarning2: 'Cantidad de compra es ',
    tradewarning3: 'Máximo 8 dígitos decimales para el precio',
    tradewarning4: 'Cantidad de venta es ',
    tradeconfirmbuyin: 'Confirmar compra',
    tradeconfirmsellout: 'Confirmar venta',
    
    foot: {
        aboutus1: 'Acerca de nosotros',
        aboutus: `<p>Después de lanzar su red principal en abril de 2019, la cadena de bloques Binance mostró sus características de alta velocidad y capacidad de procesamiento. Su enfoque principal fue el Binance Chain DEX, una aplicación descentralizada local (dApp) capaz de manejar millones de transacciones en cortos periodos de tiempo, demostrando su bajo tiempo de latencia en la asignación de operaciones.</p>
        <p>La flexibilidad y accesibilidad están estrechamente relacionadas con el rendimiento. La falta de funcionalidades extensibles, como contratos inteligentes y máquinas virtuales, limitaba ciertas posibilidades en la emisión y gestión de activos digitales y actividades comunitarias. La demanda más clamorosa era ver estas capacidades integradas en Binance Chain.</p>
        <p>A pesar de la alta expectativa de añadir contratos inteligentes a Binance Chain, tal incorporación podría ralentizar el DEX y aumentar la incertidumbre en las transacciones de activos. Como solución, se propuso el concepto de una cadena paralela llamada "Binance Smart Chain" (BNBN), manteniendo el alto rendimiento del DEX original y soportando funcionalidades de contratos inteligentes de manera compatible.</p>
        <p>Con la creación de dos cadenas paralelas dentro del ecosistema de Binance Chain, cada una funcionaría de manera independiente ofreciendo servicios distintos. La nueva cadena paralela BNBN sería complementaria a la cadena principal "Binance Chain" (BNB). El diseño de BNBN sigue los siguientes principios:</p>
        <ul>
            <li><strong>Cadena independiente:</strong> BNBN es técnicamente una cadena de bloques separada, no una solución de segundo nivel. La mayor parte de las tecnologías básicas y funciones empresariales de Binance Smart Chain (BSC) deberían funcionar de manera independiente, incluso durante períodos breves de inactividad de BNB.</li>
            <li><strong>Compatibilidad con Ethereum:</strong> Conscientes de que Ethereum es la primera plataforma ampliamente utilizada para contratos inteligentes, BSC optó por ser compatible con la red principal de Ethereum. Esto significa que la mayoría de las dApps, componentes y herramientas de la comunidad Ethereum podrían funcionar con BSC sin grandes cambios o con adaptaciones menores.</li>
            <li><strong>Consenso basado en la posesión y gobernanza en cadena:</strong> La consenso Proof of Stake (PoS) es más sostenible y permite la gobernanza comunitaria. En comparación con el PoW, se espera que PoS tenga tiempos de generación de bloques más cortos y una mayor capacidad de procesamiento de transacciones.</li>
            <li><strong>Comunicación cruzada nativa:</strong> BNB y BNBN nativamente soportarán la comunicación entre ambas cadenas para la transferencia de activos digitales como BEP2 tokens y otros tokens de BNBN.</li>
        </ul>
        <p>El protocolo de consenso de BNBN se enfoca en alcanzar los siguientes objetivos:</p>
        <ul>
            <li>Tiempos de bloque más cortos que Ethereum (por ejemplo, 5 segundos o menos).</li>
            <li>Limitar el tiempo de espera hasta la confirmación de transacciones (unos pocos minutos como máximo).</li>
            <li>Sin inflación; parte de los ingresos procedentes de las comisiones de transacciones, pagaderas en BNBN.</li>
            <li>Mantener compatibilidad con Ethereum, tanto actual como futuras versiones.</li>
            <li>Incluir un mecanismo de gobernanza en cadena basado en la posesión de tokens.</li>
        </ul>
        <p>Además, BNBN combina DPoS y PoA (Proof of Authority) para su consenso. Los bloques son generados por un conjunto limitado de validadores que rotan siguiendo el modelo PoA similar al motor de consenso Clique de Ethereum. Un grupo de nodos de confianza opera como los primeros validadores en el inicio de la cadena. Una vez comenzada la producción de bloques, cualquier persona puede postularse para convertirse en validador. El estado de participación determina cuáles de los nodos con la mayor participación pasan a formar el siguiente conjunto de validadores, y estas elecciones y eliminaciones ocurren cada 24 horas. BNBN funciona como símbolo de la participación en BNB, y para mantener la coherencia con el protocolo de consenso de Ethereum, incluyendo futuros upgrades, BNBN utiliza BNB para la gestión de participación.</p>
        <p>Cuando UTC alcanza cero, BNB envía un mensaje "ValidatorSetUpdate" verificable entre cadenas para notificar a BNBN la actualización de su conjunto de validadores. Los validadores actuales de BNBN comprueban periódicamente la existencia de este mensaje antes de actualizar el conjunto de validadores en un intervalo de bloques predeterminado.</p>
        <p>La seguridad y la finalidad se garantizan mediante la suposición de que la mayoría de los validadores (más de ½ * N + 1) son honestos. En caso de ataques potenciales, como el ataque de clonación, se aplican medidas para expulsar rápidamente a los validadores maliciosos y dificultar la ejecución de estos ataques.</p>
        <p>Todos los validadores de BNB que participan en la red BNBN obtienen ingresos a partir de las comisiones de transacciones. Como BNB no tiene inflación intrínseca, no genera ingresos a través de la minería como Bitcoin y Ethereum. Las comisiones representan la fuente principal de ingresos para los validadores, quienes también disfrutan de otros beneficios al poseer BNBN.</p>
        `,
        // Otros elementos de interfaz
        newbie: '',
        newbie1: 'Guía para principiantes',
        commonproblem: '',
        commonproblem1: 'Preguntas frecuentes',
        information: '',
        information1: 'Datos de la criptomoneda',
        disclaimer: '',
        disclaimer1: 'Términos de uso',
        privacyclause: '',
        privacyclause1: 'Política de privacidad',
        agreement: '',
        agreement1: 'Contrato de usuario',
    },
    tradeinfo: {
        // Información de verificación y operaciones comerciales
        emaildone: 'Correo electrónico verificado',
        emailundo: 'Correo electrónico no verificado',
        teldone: 'Teléfono verificado',
        telundo: 'Teléfono no verificado',
        idcarddone: 'Identificación verificada',
        idcardundo: 'Identificación no verificada',
        exchangetimes: 'Veces de intercambio',
        price: 'Precio',
        num: 'Cantidad',
        paymethod: 'Método de pago',
        exchangelimitamount: 'Límite de intercambio',
        location: 'Ubicación',
        location_text: 'China',
        exchangeperiod: 'Plazo de intercambio',
        minute: 'Minutos',
        amounttip: 'Ingrese el monto',
        numtip: 'Ingrese la cantidad',
        remarktip: 'Indique sus requisitos',
        remarktitle: 'Información adicional',
        exchangetitle: 'Condiciones del intercambio',
        exchange_tip1: 'Una vez enviado el pedido de intercambio, los activos digitales quedan bloqueados y protegidos por la plataforma. Si eres vendedor, carga los fondos y espera a que el comprador realice el pago. El comprador debe realizar el pago dentro del período establecido y, una vez recibido el pago, debes liberar los activos bloqueados.\
    Por favor, tenga en cuenta que los valores del archivo se refieren a mensajes y advertencias de una plataforma de intercambio de criptomonedas. Aquí están las traducciones al español manteniendo el formato de código original:',
    exchange_tip2: 'Antes de realizar la transacción, lea los Términos de Servicio de la plataforma en línea, así como las preguntas frecuentes y guías de trading.',
    exchange_tip3: '¡Cuidado con estafadores! Verifique las valoraciones recibidas por el usuario antes de realizar la operación y preste especial atención a cuentas recién creadas.',
    exchange_tip4: 'Tenga en cuenta que el redondeo y las fluctuaciones de precios pueden afectar la cantidad final de criptomonedas que se intercambian. La cantidad fija que ingresa determinará la cantidad final; el monto de las criptomonedas se calculará en el mismo momento de publicación basándose en el tipo de cambio actual.',
    exchange_tip5: 'El servicio de custodia protege a ambos comprador y vendedor en transacciones en línea. En caso de disputa, evaluaremos toda la información proporcionada y liberaremos las criptomonedas en custodia al legítimo propietario.',
    warning1: 'Ingrese hasta 2 decimales',
    warning2: 'Cantidad de la orden es',
    warning3: 'Ingrese hasta 8 decimales',
    warning4: 'Cantidad de la orden es',
    confirmbuyin: 'Confirmar compra',
    confirmsellout: 'Confirmar venta',
    buyin: 'Comprar',
    sellout: 'Vender',
    warning5: 'Rellene el pedido según lo requerido',
    },
    }
    